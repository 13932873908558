import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr"; 
import { NumericFormat } from 'react-number-format';

const ArtikelCreate = () => {
    let params = useParams();
    const { t } = useTranslation();  
    const [data, setData] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [total, setTotal] = useState(0);
    const [currency, setCurrency] = useState('EUR');
    const [projects, setProjects] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [receivable, setReceivable] = useState(0);
    const [salaryCategory, setSalaryCategory] = useState([
        {description: ' '}, 
    ]);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
 
    useEffect(() => {
        flatpickr(".flat-pickr");
        callFetch("artikels/create", "GET", []).then((res) => {
            setValue('artikel_id', res.aritkel_id); 
        });
    }, []);

   
    
    const handleChangeSalaryCategoryInput = (index, event) => {
        const values = [...salaryCategory];
        values[index][event.target.name] = event.target.value; 
        setSalaryCategory(values);
    }
   

    const handleAddField = () => {
        setSalaryCategory([...salaryCategory, {description: ''}]);
    }

    const handleRemoveField = (index) => {
        const values = [...salaryCategory];
        values.splice(index, 1); 
        setSalaryCategory(values); 
    }

    useEffect(() => {
        var i;
        var total = 0;
        for(i=0; i<salaryCategory.length; i++){
            total = total + Number(salaryCategory[i]['amount']);    
        }
        setTotal(total);
    }, [salaryCategory]);

    

    const onSubmit = (formData) => { 
        setSaving(true);
        formData.description = JSON.stringify(salaryCategory);
        callFetch("artikels", "POST", formData, setError).then((res) => {
            setSaving(false); 
            if (!res.ok) return; 
            setSubmitSuccess(true);
        });
    };
return submitSuccess ? <Navigate to={'/artikels'} /> :
    <div className="row">
        <div className="col-12">
            <div className="card mb-4">
                <div className="card-header pb-0">
                    <h6>{t('Add Artikel')}</h6>
                </div>
                <div className="card-body">
                    <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <div className="row g-3"> 
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>
                                        {t('Artikel Nr.')} *
                                    </label>
                                    <br />
                                    <input type="text" className="form-control" {...register("artikel_id", {
                                        required: true,
                                    })} readOnly/>
                                    <div className="invalid-feedback">{errors.artikel_id}</div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>
                                        {t('Artikel Title')} *
                                    </label>
                                    <br />
                                    <input type="text" className="form-control" {...register("artikel_title",{required:true})} required />
                                    {/* <input type="text" className="form-control" {...register("artikel_title", {required: true})} required /> */}
                                    <div className="invalid-feedback">{errors.artikel_title && errors.artikel_title}</div>
                                </div>
                            </div>
                              
                        </div> 


                        
                        {salaryCategory.map((category, index) => 
                            <div className="row g-3" key={index}> 
                                <div className="col-md-5">
                                <div className="form-group">
                                    <label>
                                        {t('Description')} *
                                    </label>
                                    <br />
                                    <textarea value={category.description}
                                        onChange = {event => handleChangeSalaryCategoryInput(index, event)} className="form-control" name="description" required>
                                    </textarea>
                                    {/* <input type="text" value={category.description}
                                        onChange = {event => handleChangeSalaryCategoryInput(index, event)} className="form-control" name="description"/>  */}
                                    <div className="invalid-feedback">{errors.description}</div>
                                </div>
                            </div>
                            <div className="col-md-1 mt-4"><i className="fa-solid fa-circle-xmark text-danger" style={{marginTop: '43px'}} onClick={() => handleRemoveField(index)}></i></div>
                                
                            </div>  
                        )}

                        <div className="row g-3">
                            <div className="col-sm-1"><button type="button" className="btn btn-outline-secondary mt-3" onClick={() => handleAddField()}>+{t('Add')}</button></div>
                            <div className="col-sm-9"></div>
                        </div>

                         
                            
                        <div className="row mt-3">
                            <div className="col-md-12">
                                {!saving && (
                                    <button type="submit" className="btn btn-primary">
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Saving ...')}
                                    </button>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>  
        </div>
    </div>
}

export default ArtikelCreate