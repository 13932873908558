import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import IndexTable from './IndexTable';
// @mui material components
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import SoftButton from "components/SoftButton";
function Index() {
    const { t } = useTranslation();
    const [menu, setMenu] = useState(null);
    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = () => setMenu(null);
    const [status, setStatus] = useState('All');

    const filterPayments = (status) => {
        setStatus(status);
    }
    return (
        <>
            <div className="row">
                <div className="col-12">
                    {JSON.parse(Cookies.get('permissions')).indexOf("PayC") !== -1 ? (
                        <NavLink to="/payments/book" className="btn btn-icon btn-primary float-start">
                            {t('Book Payment')}
                        </NavLink>
                    ) : <></>}
                    <div className="float-end">
                        <SoftButton className="mb-3" style={{ position: 'relative' }} variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
                            {t(status)}&nbsp;
                            <Icon>keyboard_arrow_down</Icon>
                        </SoftButton>
                        <Menu
                            anchorEl={menu}
                            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                            transformOrigin={{ vertical: "top", horizontal: "left" }}
                            open={Boolean(menu)}
                            onClose={closeMenu}
                            keepMounted
                        >
                            <>
                                <MenuItem onClick={() => { closeMenu(); filterPayments('All') }}>{t('All')}</MenuItem>
                                <MenuItem onClick={() => { closeMenu(); filterPayments('Open') }}>{t('Open')}</MenuItem>
                                <MenuItem onClick={() => { closeMenu(); filterPayments('paritial') }}>{t('1/2 Getilgt')}</MenuItem>
                                <MenuItem onClick={() => { closeMenu(); filterPayments('Paid') }}>{t('2/2 Getilgt')}</MenuItem>
                            </>
                        </Menu>
                        {JSON.parse(Cookies.get('permissions')).indexOf("PayC") !== -1 ? (
                            <a target={"_blank"} rel="noreferrer" href={Cookies.get('backend_url') + 'payroll-export'} className="btn btn-outline-dark ms-2">
                                {t('Export CSV')}
                            </a>
                        ) : <></>}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('All Payments')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <IndexTable filter={status} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;
