import { useMemo, useEffect, useState, useRef } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";

import callFetch from "../../../helpers/callFetch";
import { useForm } from "react-hook-form";

import LoadingCard from "components/LoadingCard";

function EvaluationLineChart() {

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
    
    const [loading, setLoading] = useState(false);
    const [evaluations, setEvaluations] = useState({
        Ange_Bot: true,
        Auftrag: true,
        In_Rechnung: true,
        Bezahlt: false,
    });

    const [lineChartData, setLineChartData] = useState({});

    useEffect(() => {
        setLoading(true);
        callFetch("statistics/evaluation-line-chart", "POST", evaluations, setError).then((res) => {
            setLineChartData(res.data);
            setLoading(false);
            if (!res.ok) return;
        });
      }, [evaluations]);


    return (
        <>
            {loading ? (
                <LoadingCard
                    sx={{height: '425px'}}
                />
            ) : (
            <DefaultLineChart
                title=" Auswertung"
                description={
                        <SoftBox display="flex">
                            <Grid container spacing={1}>
                                <Grid item xs={6} sm={3} lg={3}>
                                    <SoftTypography
                                        fontWeight="regular"
                                        fontSize="13px"
                                        marginTop="0.5rem"
                                        marginLeft="0.5rem"
                                    >
                                        <Checkbox
                                        checked={evaluations.Ange_Bot}
                                        onChange={() =>
                                            setEvaluations({
                                                ...evaluations,
                                                Ange_Bot: !evaluations.Ange_Bot,
                                            })
                                        }
                                        />{" "}
                                        Angebot
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={6} sm={3} lg={3}>
                                    <SoftTypography
                                        fontWeight="regular"
                                        fontSize="13px"
                                        marginTop="0.5rem"
                                        marginLeft="0.5rem"
                                    >
                                        <Checkbox
                                        checked={evaluations.Auftrag}
                                        onChange={() =>
                                            setEvaluations({
                                                ...evaluations,
                                                Auftrag: !evaluations.Auftrag,
                                            })
                                        }
                                        />{" "}
                                        Auftrag
                                    </SoftTypography>                                    
                                </Grid>
                                <Grid item xs={6} sm={3} lg={3}>
                                    <SoftTypography
                                        fontWeight="regular"
                                        fontSize="13px"
                                        marginTop="0.5rem"
                                        marginLeft="0.5rem"
                                    >
                                        <Checkbox
                                        checked={evaluations.In_Rechnung}
                                        onChange={() =>
                                            setEvaluations({
                                                ...evaluations,
                                                In_Rechnung: !evaluations.In_Rechnung,
                                            })
                                        }
                                        />{" "}
                                        In Rechnung
                                    </SoftTypography>                                    
                                </Grid>
                                <Grid item xs={6} sm={3} lg={3}>
                                    <SoftTypography
                                        fontWeight="regular"
                                        fontSize="13px"
                                        marginTop="0.5rem"
                                        marginLeft="0.5rem"
                                    >
                                        <Checkbox
                                        checked={evaluations.Bezahlt}
                                        onChange={() =>
                                            setEvaluations({
                                                ...evaluations,
                                                Bezahlt: !evaluations.Bezahlt,
                                            })
                                        }
                                        />{" "}
                                        Bezahlt
                                    </SoftTypography>                                    
                                </Grid>                                
                            </Grid>   
                        </SoftBox>
                }
                chart={lineChartData}
            />
            )}
        </>
        )
    }
    
export default EvaluationLineChart;