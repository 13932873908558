/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { React, useEffect, useState } from "react";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import Card from "@mui/material/Card";
import Header from "./components/Header";
import BasicInfo from "./components/BasicInfo";
import ChangePassword from "./components/ChangePassword";
import Notifications from "./components/Notifications";
import Status from "./components/Status";
//import DeleteAccount from "./components/DeleteAccount";
import CompanyInfo from "./components/CompanyInfo";
import EmailSetting from "./components/EmailSetting";
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import Unterschrift from './components/BasicInfo/Unterschrift'

function Settings() {
  const { t } = useTranslation();
  const [headerStatus, setHeaderStatus] = useState(false);
  const [currentTab, setCurrentTab] = useState('profile');
  return (
    <SoftBox mt={4}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={3}>
          <Card
            sx={{
              borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
              position: "sticky",
              top: "10%",
            }}
          >
            <SoftBox>
              <div class="nav flex-column nav-pills me-3 settings-nav" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <button class="nav-link active font-size-14-rem" style={{ textAlign: 'left' }} id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="true"
                  onClick={() => {
                    setHeaderStatus(false)
                    setCurrentTab('profile')
                  }}
                >{t('Benutzerprofil')}</button>
                <button class="nav-link font-size-14-rem" style={{ textAlign: 'left' }} id="v-pills-change-password-tab" data-bs-toggle="pill" data-bs-target="#v-pills-change-password" type="button" role="tab" aria-controls="v-pills-change-password" aria-selected="false"
                  onClick={() => {
                    setHeaderStatus(false)
                    setCurrentTab('change-password')
                  }
                  }>{t('Change Password')}</button>

                {JSON.parse(Cookies.get('permissions')).indexOf("SmR") !== -1 ? (
                  <button class="nav-link font-size-14-rem" style={{ textAlign: 'left' }} id="v-pills-document-tab" data-bs-toggle="pill" data-bs-target="#v-pills-document" type="button" role="tab" aria-controls="v-pills-document" aria-selected="false"
                    onClick={() => {
                      setHeaderStatus(true)
                      setCurrentTab('document')
                    }}
                  >{t('Dokumente anpassen')}</button>
                ) : <></>}

                {JSON.parse(Cookies.get('permissions')).indexOf("SmR") !== -1 ? (
                  <button class="nav-link font-size-14-rem" style={{ textAlign: 'left' }} id="v-pills-email-tab" data-bs-toggle="pill" data-bs-target="#v-pills-email" type="button" role="tab" aria-controls="v-pills-email" aria-selected="false"
                    onClick={() => {
                      setHeaderStatus(false)
                      setCurrentTab('email')
                    }}
                  >{t('Email Settings')}</button>
                ) : <></>}
                <button class="nav-link font-size-14-rem" style={{ textAlign: 'left' }} id="v-pills-notification-tab" data-bs-toggle="pill" data-bs-target="#v-pills-notification" type="button" role="tab" aria-controls="v-pills-notification" aria-selected="false"
                  onClick={() => {
                    setHeaderStatus(false)
                    setCurrentTab('notification')
                  }}
                >{t('Notifikationen')}</button>

                {JSON.parse(Cookies.get('permissions')).indexOf("SmR") !== -1 ? (
                  <button class="nav-link font-size-14-rem" style={{ textAlign: 'left' }} id="v-pills-status-tab" data-bs-toggle="pill" data-bs-target="#v-pills-status" type="button" role="tab" aria-controls="v-pills-status" aria-selected="false"
                    onClick={() => {
                      setHeaderStatus(false)
                      setCurrentTab('status')
                    }}
                  >{t('Vertriebseinstellungen')}</button>
                ) : <></>}

                <button class="nav-link font-size-14-rem" style={{ textAlign: 'left' }} id="v-pills-signature-tab" data-bs-toggle="pill" data-bs-target="#v-pills-signature" type="button" role="tab" aria-controls="v-pills-signature" aria-selected="false"
                  onClick={() => {
                    setHeaderStatus(false)
                    setCurrentTab('Unterschrift')
                  }}
                >{t('Unterschrift')}</button>
              </div>
            </SoftBox>
          </Card>

        </Grid>
        <Grid item xs={12} lg={9}>
          <SoftBox mb={3}>
            <Grid container spacing={3}>
              {!headerStatus && (
                <Grid item xs={12}>
                  <Header />
                </Grid>
              )}

              <div class="tab-content" id="v-pills-tabContent" style={{ padding: '20px 0px 20px 20px', width: '100%' }}>
                <div class="tab-pane fade show active" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabindex="0">
                  <Grid item xs={12} lg={12}>
                    {currentTab === 'profile' ? <BasicInfo /> : ''}
                  </Grid>
                </div>

                {JSON.parse(Cookies.get('permissions')).indexOf("SmR") !== -1 ? (
                  <div class="tab-pane fade" id="v-pills-document" role="tabpanel" aria-labelledby="v-pills-document-tab" tabindex="0">
                    <Grid item xs={12} lg={12}>
                      {currentTab === 'document' ? <CompanyInfo /> : ''}
                    </Grid>
                  </div>
                ) : <></>}

                {JSON.parse(Cookies.get('permissions')).indexOf("SmR") !== -1 ? (
                  <div class="tab-pane fade" id="v-pills-email" role="tabpanel" aria-labelledby="v-pills-email-tab" tabindex="0">
                    <Grid item xs={12} lg={12}>
                      <Grid item xs={12} lg={12}>
                        {currentTab === 'email' ? <EmailSetting /> : ''}
                      </Grid>
                    </Grid>
                  </div>
                ) : <></>}

                <div class="tab-pane fade" id="v-pills-change-password" role="tabpanel" aria-labelledby="v-pills-change-password-tab" tabindex="0">
                  <Grid item xs={12} lg={12}>
                    {currentTab === 'change-password' ? <ChangePassword /> : ''}
                  </Grid>
                </div>
                <div class="tab-pane fade" id="v-pills-notification" role="tabpanel" aria-labelledby="v-pills-notification-tab" tabindex="0">
                  <Grid item xs={12} lg={12}>
                    {currentTab === 'notification' ? <Notifications /> : ''}
                  </Grid>
                </div>
                {JSON.parse(Cookies.get('permissions')).indexOf("SmR") !== -1 ? (
                  <div class="tab-pane fade" id="v-pills-status" role="tabpanel" aria-labelledby="v-pills-status-tab" tabindex="0">
                    <Grid item xs={12} lg={12}>
                      {currentTab === 'status' ? <Status /> : ''}
                    </Grid>
                  </div>
                ) : <></>}

                <div class="tab-pane fade" id="v-pills-signature" role="tabpanel" aria-labelledby="v-pills-signature-tab" tabindex="0">
                  <Grid item xs={12} lg={12}>
                    {currentTab === 'Unterschrift' ? <Unterschrift /> : ''}
                  </Grid>
                </div>

                {/*
                <div class="tab-pane fade" id="v-pills-delete-account" role="tabpanel" aria-labelledby="v-pills-delete-account-tab" tabindex="0">
                  <Grid item xs={12} lg={12}>
                    {currentTab === 'delete-account' ? <DeleteAccount /> : ''}
                  </Grid>
                </div>
                */}
              </div>
            </Grid>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default Settings;
