import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useTranslation } from 'react-i18next';
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

import Order from './Order';

import Cookies from 'js-cookie';

function getSteps() {

    // var tabs = ["1. Quotation", "2. Order", "3. Digital Sign"];
    var tabs = ["1. Quotation"];

    if (JSON.parse(Cookies.get('permissions')).indexOf("OPR") !== -1) { 
        tabs.push('2. Digital Sign');
    }
    return tabs;
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return (<></>);
        case 1:
            return <Order />;
        default:
            return null;
    }
}


function Index() {
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const isLastStep = activeStep === steps.length - 1;
    const [activeTab, setActiveTab] = useState('auftrag');
    const handleNext = () => setActiveStep(activeStep + 1);
    const handleBack = () => setActiveStep(activeStep - 1);

    return (
        <>
            {steps.length > 1 ? (
                <SoftBox mt={1} mb={1}>
                    <Grid justifyContent="center">
                        <Grid item xs={12} lg={8}>
                            <Stepper activeStep={activeStep} alternativeLabel className="my-3">
                                {steps.map((label) => {
                                    if (label === '1. Quotation') {
                                        return <Step key={label}>
                                            <StepLabel>1. {t('Angebote')}</StepLabel>
                                        </Step>
                                    }else{
                                        return <Step key={label}>
                                            <StepLabel>2. {t('Digital Sign')}</StepLabel>
                                        </Step>
                                    }
                                }
                                )}
                            </Stepper>
                        </Grid>
                    </Grid>
                </SoftBox>
            ) : ''}
        </>
    );
}

export default Index;
