import React, { useState } from "react";
import Grid from "@mui/material/Grid";

import TermineQouteTable from "pages/dashboard/layouts/TermineQouteTable";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import ProgressChart from "./ProgressChart";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

function VkbEvulationIndex() {
  const [menu, setMenu] = useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Yesterday</MenuItem>
      <MenuItem onClick={closeMenu}>Last 7 days</MenuItem>
      <MenuItem onClick={closeMenu}>Last 30 days</MenuItem>
    </Menu>
  );

  const ProgressChartData = {
    labels: ["Bezahlt", "Nicht Bezahlt"],
    datasets: {
      label: "Projects",
      backgroundColors: ["info", "secondary"],
      data: [75, 25],
    },
  };

  return (
    <>
      <SoftBox py={3}>
        <SoftBox display="flex" justifyContent="flex-end" mb={3} ml={2}>
          <SoftBox ml={1}>
            <SoftButton variant="outlined" color="secondary">
              export&nbsp;&nbsp;
              <Icon>folder</Icon>
            </SoftButton>
          </SoftBox>
          <SoftBox mr={1}>
            <SoftButton variant="gradient" color="dark" onClick={openMenu}>
              today&nbsp;
              <Icon>expand_more</Icon>
            </SoftButton>

            {renderMenu}
          </SoftBox>
        </SoftBox>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} sm={12} lg={7} marginBottom="1rem">
            <TermineQouteTable />
          </Grid>
          <Grid item xs={12} md={12} sm={12} lg={5} marginBottom="1rem">
            <SoftBox>
              <ProgressChart
                icon="workspace_premium"
                title="projects"
                count={115}
                color="dark"
                chart={ProgressChartData}
                height="20rem"
              />
            </SoftBox>
            <SoftBox mt={3} height="10rem">
              <PlaceholderCard
                title={{ variant: "h6", text: "NeuT" }}
                hasBorder
              />
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
}

export default VkbEvulationIndex;
