import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import { NumericFormat } from 'react-number-format';
import SoftAlert from "components/SoftAlert";
import SoftSnackbar from "components/SoftSnackbar";
import { Checkbox } from "@mui/material";
import Select from 'react-select';

function EditEinkauf() {
    let params = useParams();
    const { t } = useTranslation();
    const [currency, setCurrency] = useState('EUR');
    const [data, setData] = useState([]);
    const [items, setItems] = useState([{ id: 0, title: '', quantity: 0, watt: 0, kwp: 0 }]);
    const [itemSummery, setItemSummery] = useState({ items: [] });
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [receivedError, setReceivedError] = useState(null);
    const [selectedProjectId, setSelectedProjectId] = useState({ id: '' });

    const [qq, setQqq] = useState([{ quantity: 0 }])
    const [isShow, setIsShow] = useState(false)
    const [isShowKwp, setIsShowKwp] = useState(false)
    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            content="Eikauf successfully created"
            dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
        />
    );

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        //var subTotal = 0;
        //items.map((item) => {
        //    subTotal += item.total;
        // });
        //itemSummery.subTotal = subTotal;
        //itemSummery.amount = parseFloat((itemSummery.amountPerchantage / 100) * itemSummery.subTotal);
        // setSubtotal(subTotal);
        //setValue('amountPerchantage', itemSummery.amountPerchantage);
        //setValue('tax', itemSummery.taxPercentage);
        //itemSummery.tax = parseFloat((itemSummery.amount * (itemSummery.taxPercentage / 100)).toFixed(2));
        //itemSummery.discount = parseFloat((itemSummery.amount * (itemSummery.discountPercentage / 100)).toFixed(2));
        //itemSummery.total = subTotal - itemSummery.discount + itemSummery.tax;
        itemSummery.items = items;
        setItemSummery(itemSummery);
    }, [refresh]);


    /*
    useEffect(() => {
        flatpickr(".flat-pickr");
        callFetch("einkauf/create", "GET", []).then((res) => {
            setData(res?.data);
            setRefresh(refresh + 1)
        });
    }, []);
    */

    useEffect(() => {
        flatpickr(".flat-pickr");
        callFetch("einkauf/" + params?.id + "/edit", "GET", []).then((res) => {
            setData(res?.data);
        });
    }, []);

    useEffect(() => {
        if (!data?.einkauf?.id) return;


        for (let [key, value] of Object.entries(data?.einkauf)) {
            setValue(key, (value == null || value == 'null' ? "" : value));
        }
        if (data?.einkauf?.project !== 0) {
            setIsShow(true);

            for (let [key, project] of Object.entries(data?.project)) {
                if (project?.id == data?.einkauf?.project) {
                    setSelectedProjectId({
                        id: project?.id,
                        label: project?.label
                    })
                }
            }
        }

        if (data?.einkauf?.isShowKwp == 1) {
            setIsShowKwp(true)
        }
        let product_details = JSON.parse(data?.einkauf?.product_details);

        for (let [key, item] of Object.entries(product_details.items)) {
            if (item?.id == 'other') {
                setValue("product_title[" + key + "]", item?.title);
            }

            setValue("product_watt[" + key + "]", item?.watt);
            setValue("product_kwp[" + key + "]", item?.kwp);
            setValue("product_quantity[" + key + "]", item?.quantity);
        }

        setItemSummery(product_details);
        setItems(product_details.items);
        setRefresh(refresh + 1)

    }, [data?.einkauf?.id]);



    function deleteProduct() {
        var titems = [];
        items.map((t) => {
            if (!t)
                return;
            titems.push(t);
        });
        setItems(titems);
        setRefresh(refresh + 1);
    }

    const onSubmit = (formData) => {
        setSaving(true);
        formData.product_details = JSON.stringify(itemSummery);
        formData.isShowKwp = (isShowKwp ? 1 : 0);

        callFetch("einkauf/" + data?.einkauf?.id, "POST", formData, setError).then((res) => {
            if (receivedError == null && res.message === "success") {
                openSuccessSB();
            }
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to={`/einkauf`} /> :
        <div className="row">
            <div className="col-md-9">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t('Einkauf hinzufugen')}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <input type="hidden" defaultValue="PUT" {...register("_method")} />
                            <div className="row g-3">
                                <div className="col-md-3">
                                    <label>{t('Einkaufsnummer.')} *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('PO-10001')}
                                        {...register("po_nummer", {
                                            required: true,
                                        })}
                                        readOnly
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.po_nummer && errors.po_nummer.message}</div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>
                                            {t('Lieferanten & Partner')} *
                                        </label>
                                        <select
                                            className="form-control"
                                            {...register("lieferanten_partner", {
                                                required: true,
                                            })}
                                            required
                                        >
                                            <option value="">--</option>
                                            {data?.liferent && data?.liferent?.map((item) => (
                                                <option key={item.id} value={item.id}>{item.lieferanten_nummer}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">{errors.lieferanten_partner && errors.lieferanten_partner.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>
                                            {t('Currency')} *
                                        </label>
                                        <br />
                                        <select
                                            className="form-control"
                                            {...register("currency", {
                                                required: true,
                                            })}
                                            onChange={(e) => {
                                                setCurrency(e.target.value)
                                            }}
                                            required
                                        >
                                            <option value="EUR">EUR (€)</option>
                                            <option value="USD">USD ($)</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.currency && errors.currency.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <label>
                                        {t('Date')} *
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control mb-4"
                                        //placeholder={t('eg. 2001-03-20')}
                                        defaultValue={dateFormat(new Date(), "yyyy-mm-dd")}
                                        {...register("date", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.date && errors.date.message}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-3 col-lg-3 col-xl-3">
                                    <div className="d-flex align-items-center">
                                        <div className={isShow && 'w-80'}>
                                            <Checkbox checked={isShow} id="project" onChange={(e) => setIsShow(!isShow)} />
                                            <label htmlFor="project" className="cursor-pointer mt-2" >{t('Projekt hinzufugen')}</label>
                                        </div>
                                    </div>
                                </div>
                                {
                                    isShow &&
                                    <div className="col-12 col-md-3 col-lg-3 col-xl-3">
                                        <Select
                                            placeholder={t('Select')}
                                            options={data?.project ? data?.project : {}}
                                            //value={selectedTeams}
                                            getOptionLabel={(option) => (option?.label ? option?.label : option?.id)}
                                            getOptionValue={(option) => option.id}
                                            value={selectedProjectId}
                                            onChange={(e) => {
                                                setValue('project', e.id);
                                                setSelectedProjectId({
                                                    id: e?.id,
                                                    label: (e?.label ? e?.label : e?.id),
                                                });
                                            }}
                                            required
                                        />
                                    </div>
                                }

                                <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                                    <div className="d-flex align-items-center mt-1">
                                        <Checkbox id="prjoektbeschaffungsliste" checked={isShowKwp} onChange={() => setIsShowKwp(!isShowKwp)} />
                                        <label htmlFor="prjoektbeschaffungsliste" className="cursor-pointer mt-1" >{t('In die prjoektbeschaffungsliste hinzufugen?')}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <label>{t('Description')}</label>
                                    <textarea className="form-control" rows="3"
                                        defaultValue=""
                                        placeholder="eg. Description"
                                        {...register("description")}></textarea>
                                </div>
                            </div>

                            <div className="mt-3">
                                {items && items.map((item, i) => (
                                    <div key={i} className="">
                                        {i > 0 && <hr />}
                                        <div className="row mt-2">
                                            <div className={`${isShowKwp && items[i].id == "other" ? 'col-md-2' : isShowKwp ? 'col-md-6' : items[i].id == "other" ? 'col-md-5' : 'col-md-10'}`}>
                                                <div>
                                                    <label>{t('Product')}</label>
                                                    <select
                                                        value={items[i].id}
                                                        className="form-control"
                                                        placeholder={t('Select Product')}
                                                        onChange={(e) => {

                                                            if (e.target.value) {
                                                                var value = e.target.value;
                                                                var sepValue = value.split('-');
                                                                if (sepValue[0] == 'module') {
                                                                    for (let [key, module] of Object.entries(data?.products?.module)) {
                                                                        if (sepValue[1] == module?.id) {

                                                                            items[i].watt = module?.watt;
                                                                            setValue("product_watt[" + i + "]", module?.watt);
                                                                        }

                                                                    }
                                                                } else {
                                                                    items[i].watt = 0;
                                                                    setValue("product_watt[" + i + "]", 0);
                                                                }
                                                            }

                                                            items[i].id = e.target.value;
                                                            items[i].title = e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text;
                                                            setItems(items);
                                                            setRefresh(refresh + 1);
                                                        }}>
                                                        <option value="">--</option>
                                                        <option value="other">Others</option>
                                                        {data?.products?.module && data?.products?.module.map((modul, i) => (
                                                            <option key={i} value={'module-' + modul.id}>{modul.name}</option>
                                                        ))}
                                                        {data?.products?.speicher && data?.products?.speicher.map((speiche, i) => (
                                                            <option key={i} value={'speicher-' + speiche.id}>{speiche.name}</option>
                                                        ))}
                                                        {data?.products?.wechselrichter && data?.products?.wechselrichter.map((wechselrichte, i) => (
                                                            <option key={i} value={'wechselrichter-' + wechselrichte.id}>{wechselrichte.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                items[i].id == "other" ? (
                                                    <>
                                                        <div className={`${isShowKwp && items[i].id == "other" ? 'col-md-4' : isShowKwp ? 'col-md-6' : items[i].id == "other" ? 'col-md-5' : 'col-md-10'}`}>
                                                            <div className="form-group m-0">
                                                                <label>
                                                                    {t('Title')} *
                                                                </label>
                                                                <br />
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('Title')}
                                                                    //value={items[i].title}
                                                                    {...register("product_title[" + i + "]", {
                                                                        required: true,
                                                                        onChange: (e) => {
                                                                            items[i].title = e.target.value;
                                                                            setItems(items);
                                                                            setRefresh(refresh + 1);
                                                                        }
                                                                    })}
                                                                    required={true}
                                                                />
                                                                {/* <div className="invalid-feedback">{errors.currency && errors.currency.message}</div> */}
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : ''
                                            }
                                            {
                                                isShowKwp && <>
                                                    <div className="col-md-2">
                                                        <div className="form-group m-0">
                                                            <label>
                                                                {t('Watt')} *
                                                            </label>
                                                            <br />
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                placeholder={t('watt')}
                                                                // value={items[i].watt}
                                                                {...register("product_watt[" + i + "]", {
                                                                    required: true,
                                                                    onChange: (e) => {
                                                                        items[i].watt = e.target.value;
                                                                        setItems(items);
                                                                        setRefresh(refresh + 1);
                                                                    }
                                                                })}
                                                                required={true}
                                                            />
                                                            {/* <div className="invalid-feedback">{errors.currency && errors.currency.message}</div> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group m-0">
                                                            <label>
                                                                {t('KWP')} *
                                                            </label>
                                                            <br />
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                placeholder={t('kwp')}
                                                                //value={items[i].kwp}
                                                                {...register("product_kwp[" + i + "]", {
                                                                    required: true,
                                                                    onChange: (e) => {
                                                                        items[i].kwp = e.target.value;
                                                                        setItems(items);
                                                                        setRefresh(refresh + 1);
                                                                    }
                                                                })}
                                                                required={true}
                                                            />
                                                            {/* <div className="invalid-feedback">{errors.currency && errors.currency.message}</div> */}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className="col-md-2">
                                                <div>
                                                    <label>{t('Qty/Hrs')}</label>
                                                    <input
                                                        type="number"
                                                        style={{ width: '84%' }}
                                                        className="form-control d-inline"
                                                        placeholder="eg. 0.00"
                                                        //value={items[i].quantity}
                                                        {...register("product_quantity[" + i + "]", {
                                                            required: true,
                                                            onChange: (e) => {
                                                                let value = 0;
                                                                if (e.target.value.length && !isNaN(e.target.value))
                                                                    value = e.target.value;
                                                                if (value > 0) {
                                                                    setQqq(parseFloat(value));
                                                                }
                                                                items[i].quantity = parseFloat(value);
                                                                setItems(items);
                                                                setRefresh(refresh + 1);
                                                            }
                                                        })}
                                                        required={true}
                                                    />
                                                    &nbsp;
                                                    <i className="fa-solid fa-circle-xmark text-danger" data-key={i} onClick={() => { delete items[i]; deleteProduct(); }}></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div className="row mt-3 ">
                                    <div className="col-md-12">
                                        <p className="btn btn-dark" style={{ cursor: 'pointer' }} onClick={() => { setItems([...items, { id: 0, title: '', quantity: 0 }]); setRefresh(refresh + 1); }}><i className="fa-solid fa-circle-plus"></i>&nbsp;{t('Add Item')}</p>
                                    </div>
                                </div>
                                <hr />
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-5">{receivedError && <SoftAlert color="error" dismissible> {receivedError} </SoftAlert>}</div>
                                <div className="col-md-12 mt-3">
                                    {!saving && (
                                        <button type="submit" className="btn btn-primary">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {renderSuccessSB}
        </div>;
}

export default EditEinkauf;
