import { useEffect, useState } from "react";
import Cookies from 'js-cookie';

function PDF(props) {
      const [data, setData] = useState([]);
      const [id, setId] = useState([]);
      const [load, setLoad] = useState(false);
      useEffect(() => {
            setData(props.data);
            //  setId('40000'+props.refresh);
            setLoad(false);
            const interval = setInterval(() => {
                  setId(props.data?.dcabname?.id);
                  setLoad(true);
            }, 1000);
            return () => clearInterval(interval);
      }, [props.refresh, props.data]);
      return (
            <div>
                  <iframe src={Cookies.get('backend_url') + (props?.signFrom == 'reklamation' ? 'reclamation/dcabname/' : 'dcabname/') + (id ? id : 'empty') + '?page=2'} style={{ width: '100%', height: '1123px' }}></iframe>
            </div>
      );
}

export default PDF;