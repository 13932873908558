import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import SoftBadge from "components/SoftBadge";
import SoftBadgeDot from "components/SoftBadgeDot";
import { NumericFormat } from 'react-number-format';
import SoftTypography from "components/SoftTypography";
import { Grid, Menu } from "@mui/material";
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import Timeline from '../project/Timeline';
import ProjectStatus from '../project/ProjectStatus';

function ProjectIndexTable() {
    const { t } = useTranslation();
    var typingTimer;
    const [loading, setLoading] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [searchKey, setSearchKey] = useState("storno-1")
    const [openMenu, setOpenMenu] = useState(null);
    const [data, setData] = useState([]);
    const [proID, setProID] = useState(0);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(null);
    const projectData = (id) => {
        setData([])
        if (id) {
            callFetch("project/timelines/" + id, "GET", []).then((res) => {
                setData(res.data);
            });
        }
    }

    const cancelProject = (cancel, id) => {

        callFetch("project/cencel", "POST", {
            cancel: cancel,
            id: id,
        }, []).then((res) => {


            setRefresh(refresh + 1)
        });


        setRefresh(refresh + 1)
    }

    const renderMenu = () => (
        data?.project && <Menu
            anchorEl={openMenu}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            keepMounted
        >
            <CloseIcon
                onClick={handleCloseMenu}
                style={{
                    width: "17px",
                    height: "25px",
                    cursor: "pointer",
                    zIndex: "1",
                }}
            />
            <Grid mt={0} item xs={12} lg={12} pl={8}>
                <Timeline datas={data} title="Project Status" />
            </Grid>
        </Menu>
    );

    const tableHeadings = [
        {
            name: t('Project Number'),
            width: '170px',
            selector: row => <NavLink to={'/projects/' + row.id} className={"text-primary font-weight-bold"}>{row.id}</NavLink>,
            sortable: true,
        },
        {
            name: t('Customer'),
            selector: row => row?.vorname + ' ' + (row?.name == null ? '' : row?.name),
        },
        {
            name: t('VKB Name'),
            selector: row => <NavLink to={'/projects/' + row.id}>{row?.salse_person?.name}</NavLink>,
        },
        {
            name: t('Erstellt am'),
            width: '130px',
            selector: row => dateFormat(row?.created_at, "dd.mm.yyyy"),
        },
        {
            name: t('Ereignisdatum'),
            width: '130px',
            selector: row => dateFormat(row?.ereignisdatum, "dd.mm.yyyy"),
        },
        {
            name: t('Status'),
            width: '250px',
            cell: row => (
                <>
                    <ProjectStatus projectid={row?.id} status={row?.status} />
                </>
            ),
        },
        {
            name: t('Price'),
            selector: row => ((
                <NumericFormat
                    value={Number(row?.quations?.gesami_netto)}
                    displayType="text"
                    thousandSeparator={"."}
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    suffix={' € '}
                />
            )),
        },
        {
            name: t('Ort'),
            selector: row => row?.ort,
        },
        {
            name: t('Project Status'),
            width: '180px',
            cell: row => <>
                {Cookies.get('user') && JSON.parse(Cookies.get('user')).rolename != 'Call Center' ? (
                    <div onClick={handleOpenMenu} className="d-flex align-items-center cursor-pointer" style={{ color: "#0048B1", textDecoration: "underline" }}>
                        <span onClick={() => projectData(row.id)} className="d-flex align-items-center cursor-pointer">

                            <Icon
                                color={row?.corrections_revision?.length > 0 ? 'error' : ''}
                                className="mx-1"
                            >info</Icon>
                            {t(
                                row?.cancel ? t('Project Cancelled')
                                    : row?.assignorderprocessings?.length >= 2 ? 'Elektromontage'
                                        : row?.assignorderprocessings?.length == 1 ? 'Dachmontage'
                                            : row?.projectstatus
                            )}
                        </span>
                    </div>
                ) : ''}
            </>,

        },
        {
            name: t('Actions'),
            cell: row => (
                <>
                    {JSON.parse(Cookies.get('permissions')).indexOf("PrU") !== -1 ||
                        JSON.parse(Cookies.get('permissions')).indexOf("PrD") !== -1 ? (
                        <div className="text-center dropstart">
                            <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v text-xs"></i>
                            </a>
                            <ul className="dropdown-menu">
                                {JSON.parse(Cookies.get('permissions')).indexOf("PrU") !== -1 ? (
                                    <>
                                        <li><a
                                            href='#'
                                            className="dropdown-item"
                                            onClick={() => {
                                                cancelProject(row.cancel ? 0 : 1, row.id);
                                            }}
                                        >{t(row.cancel ? 'Storno aufheben' : 'Stornieren')}</a>
                                        </li>
                                        <li><NavLink to={'/projects/' + row.id} className="dropdown-item">{t('Edit')}</NavLink></li>
                                        <li><hr className="dropdown-divider" /></li>
                                    </>
                                ) : <></>}
                                {JSON.parse(Cookies.get('permissions')).indexOf("PrD") !== -1 ? (
                                    <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'projects', row.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                                ) : <></>}
                            </ul>
                        </div>
                    ) : <></>}
                </>
            )
        }
    ];

    useEffect(() => {
        setLoading(true);
        callFetch("projects?search=" + searchKey + "&page=" + pageNumber, "GET", []).then((res) => {
            setTableData(res.data);
            setLoading(false);
        });
    }, [searchKey, pageNumber, refresh]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />

                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                {renderMenu()}
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>

                        {tabledata?.links.map((page) => {
                            //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                            const className = (page.active === true ? "page-item active" : "page-item");
                            return (
                                <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                    <button
                                        className="page-link"
                                        onClick={page.label != '...' ? handlePageNumber : ''}
                                        value={page.label}
                                        disabled={page.label == '...' ? true : false}
                                    >
                                        {page.label}
                                    </button>
                                </li>
                            );
                        })}

                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return <>
        <DataTable
            columns={tableHeadings}
            data={tabledata?.data}
            noDataComponent={loading ? t('Loading...') : t('There are no records to display')}
            className='data-table'
            pagination
            highlightOnHover
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationServer
            paginationTotalRows={tabledata?.total}
            onChangePage={handlePageChange}
            paginationComponent={BootyPagination}
        /*
        subHeader
        subHeaderComponent={
            <input 
                type="text"
                placeholder={ t("Search...") }
                className=' form-control w-sm-50 w-md-25 w-lg-15'
                defaultValue={searchKey}
                onKeyUp={(e) => {
                    clearTimeout(typingTimer);
                    typingTimer = setTimeout(()=> {
                        setTableData([]);
                        setSearchKey(e.target.value);
                    }, 1500);
                }}
                onKeyDown={(e) => {
                    clearTimeout(typingTimer);                            
                }}
            />
        }
        */
        />
    </>
}

export default ProjectIndexTable;
