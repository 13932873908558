/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import PropTypes from "prop-types";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
// Soft UI Dashboard PRO React components
import typography from "assets/theme/base/typography";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Grid from "@mui/material/Grid";

function DataTableOne() {
  function PagesBodyCell({ rows, noBorder }) {
    const { light } = colors;
    const { borderWidth } = borders;

    const renderRows = rows.map((row) => {
      return (
        <SoftBox
          key={row}
          component="td"
          xs={{ alignItems: "center", textAlign: "center" }}
          borderBottom={
            noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`
          }
          p={1}
          width="max-content"
          textAlign="center"
          alignItems="center"
        >
          <SoftTypography
            display="block"
            variant="button"
            fontWeight="medium"
            color="dark"
          >
            {row}
          </SoftTypography>
        </SoftBox>
      );
    });

    return <TableRow>{renderRows}</TableRow>;
  }
  function PagesHeaderCell({ children }) {
    const { light } = colors;
    const { size, fontWeightRegular } = typography;
    const { borderWidth } = borders;

    return (
      <SoftBox
        component="th"
        width="100%"
        float="center"
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        py={1}
        px={4}
      >
        <SoftBox
          fontSize={size.sm}
          fontWeight={fontWeightRegular}
          color="white"
          opacity={1}
          textAlign="center"
        >
          {children}
        </SoftBox>
      </SoftBox>
    );
  }

  // Typechecking props for the PagesHeaderCell
  PagesHeaderCell.propTypes = {
    children: PropTypes.node.isRequired,
  };
  // Setting default values for the props for PagesBodyCell
  PagesBodyCell.defaultProps = {
    noBorder: false,
  };

  // Typechecking props for the PagesBodyCell
  PagesBodyCell.propTypes = {
    rows: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ).isRequired,
    noBorder: PropTypes.bool,
  };

  return (
    <SoftBox>
      <TableContainer
        sx={{
          boxShadow: "none",
          paddingBottom: "1rem",
          backgroundColor: "#CBD5E0",
        }}
      >
        <Table>
          <SoftBox component="thead">
            <TableRow sx={{ backgroundColor: "#0048B1", textAlign: "center" }}>
              <PagesHeaderCell>Monat</PagesHeaderCell>
              <PagesHeaderCell>Termine 1-7</PagesHeaderCell>
              <PagesHeaderCell>Termine 1-4</PagesHeaderCell>
              <PagesHeaderCell>Termine Q.</PagesHeaderCell>
              <PagesHeaderCell>Schreib Quote</PagesHeaderCell>
            </TableRow>
          </SoftBox>
          <TableBody sx={{ backgroundColor: "#D9D9D9" }}>
            <PagesBodyCell rows={["Januar", 233, 123, "34%", "14,2"]} />
          </TableBody>
          <TableBody sx={{ backgroundColor: "#ffffff" }}>
            <PagesBodyCell rows={["Februar", 233, 123, "34%", "14,2"]} />
          </TableBody>
          <TableBody sx={{ backgroundColor: "#D9D9D9" }}>
            <PagesBodyCell rows={["Marz", 233, 123, "34%", "14,2"]} />
          </TableBody>
          <TableBody sx={{ backgroundColor: "#ffffff" }}>
            <PagesBodyCell rows={["April", 233, 123, "34%", "14,2"]} />
          </TableBody>
          <TableBody sx={{ backgroundColor: "#D9D9D9" }}>
            <PagesBodyCell rows={["Mai", 233, 123, "34%", "14,2"]} />
          </TableBody>
          <TableBody sx={{ backgroundColor: "#ffffff" }}>
            <PagesBodyCell rows={["Juni", 233, 123, "34%", "14,2"]} />
          </TableBody>
          <TableBody sx={{ backgroundColor: "#D9D9D9" }}>
            <PagesBodyCell rows={["Juli", 233, 123, "34%", "14,2"]} />
          </TableBody>
          <TableBody sx={{ backgroundColor: "#ffffff" }}>
            <PagesBodyCell rows={["August", 233, 123, "34%", "14,2"]} />
          </TableBody>
          <TableBody sx={{ backgroundColor: "#D9D9D9" }}>
            <PagesBodyCell rows={["September", 233, 123, "34%", "14,2"]} />
          </TableBody>
          <TableBody sx={{ backgroundColor: "#ffffff" }}>
            <PagesBodyCell rows={["Oktober", 233, 123, "34%", "14,2"]} />
          </TableBody>
          <TableBody sx={{ backgroundColor: "#D9D9D9" }}>
            <PagesBodyCell rows={["November", 233, 123, "34%", "14,2"]} />
          </TableBody>
          <TableBody sx={{ backgroundColor: "#ffffff" }}>
            <PagesBodyCell rows={["December", 233, 123, "34%", "14,2"]} />
          </TableBody>
          <TableBody sx={{ backgroundColor: "#CBD5E0" }}>
            <PagesBodyCell
              rows={["In Total", 233, 123, "34%", "14,2"]}
              noBorder
            />
          </TableBody>
        </Table>
      </TableContainer>
    </SoftBox>
  );
}

export default DataTableOne;
