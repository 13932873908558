import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { Navigate } from "react-router-dom";

import { callFetch } from "../../helpers/callFetch";

import IndexTable from './IndexTable';
import { Divider, Icon, Menu, MenuItem } from '@mui/material';
import SoftButton from 'components/SoftButton';

function Index() {
    const { t } = useTranslation();

    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [orderId, setOrderId] = useState(0);

    const [menu, setMenu] = useState(null);
    const [status, setStatus] = useState('');

    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = (statu = '') => {
        setMenu(null);
        setStatus(statu);
    };

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    const renderMenu = (
        <Menu
            anchorEl={menu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(menu)}
            onClose={() => { closeMenu('') }}
            keepMounted
        >
            <MenuItem onClick={() => { closeMenu('') }}>{t('All')}</MenuItem>
            <MenuItem onClick={() => { closeMenu('Erfolgreich') }}>Erfolgreich</MenuItem>
            <MenuItem onClick={() => { closeMenu('nicht Erfolgreich') }}>nicht Erfolgreich</MenuItem>
            {/* <Divider sx={{ margin: "0.5rem 0" }} />
        <MenuItem onClick={closeMenu}>
          <SoftTypography variant="button" color="error" fontWeight="regular">
            Remove Filter
          </SoftTypography>
        </MenuItem> */}
        </Menu>
    );

    const create = () => {
        setSaving(true);
        callFetch("order", "POST", {
            quation_id: 0,
            currency: 'EUR',
            netto: 0,
            tax: 19,
            brutto: 0,
        }, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setOrderId(res.data.id);
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to={'/orders/' + orderId} /> :
        <>
            <div className="d-sm-flex justify-content-between">
                <div className="relative-h">
                    {/*JSON.parse(Cookies.get('permissions')).indexOf("OC") !== -1 ? (
                    <NavLink to={'/orders/create'} className="btn btn-icon btn-primary">
                        {t('Create Order')}
                    </NavLink>
                ) : <></>*/}

                    <div className="float-end">

                        <SoftButton className={'mb-3'} variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
                            {status ? status : t('All')}&nbsp;
                            <Icon>keyboard_arrow_down</Icon>
                        </SoftButton>
                        {renderMenu}

                        {JSON.parse(Cookies.get('permissions')).indexOf("OC") !== -1 ? (
                            <a target={"_blank"} rel="noreferrer" href={Cookies.get('backend_url') + 'order-export'} className="btn btn-outline-dark ms--2">
                                {t('Export CSV')}
                            </a>
                        ) : <></>}


                        <a target={"_blank"} rel="noreferrer" href={Cookies.get('backend_url') + 'order-export'} className="btn btn-outline-dark ms-2">
                            {t('Export CSV')}
                        </a>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('All Orders')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <IndexTable status={status}/>
                        </div>
                    </div>
                </div>
            </div>
        </>;
}

export default Index;
