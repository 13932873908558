import {React, useState, useEffect} from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import SoftSnackbar from "components/SoftSnackbar";
import IndexTable from './IndexTable';

function EmployeeIndex() {
    const { t } = useTranslation();
    let {status} = useParams();
    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
            <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            content="Changes saved successfully"
            dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
            />
        );

    
        useEffect(() => {
            console.log(status);
            if(status == 'all'){
                openSuccessSB();
            } 
        }, []);
    return (
        <>
            <div className="d-sm-flex justify-content-between">
                <div className="relative-h">
                {JSON.parse(Cookies.get('permissions')).indexOf("QC") !== -1 && false ? (
                    <NavLink to="/quations/create" className="btn btn-icon btn-primary">
                        {t('Neues Angebot')}
                    </NavLink>
                ) : <></>}
                {JSON.parse(Cookies.get('permissions')).indexOf("QC") !== -1 ? (
                    <a target={"_blank"} rel="noreferrer" href={Cookies.get('backend_url')+'quation-export'} className="btn btn-outline-dark pull-right">
                        {t('Export CSV')}
                    </a>
                ) : <></>}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('Alle Angebote')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <IndexTable />
                        </div>
                    </div>
                </div>
                {renderSuccessSB}
            </div>
        </>
    );
}

export default EmployeeIndex;
