import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import deleteAlert from "helpers/deleteAlert";

import { Grid, Icon, Menu } from '@mui/material';
import SoftButton from 'components/SoftButton';
import callFetch from "helpers/callFetch";
import DataTable from 'react-data-table-component';
import CloseIcon from "@mui/icons-material/Close";
import ProjectStatus from 'pages/project/ProjectStatus';

function UpdateStatusProject() {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('Unbewertet');
    const [loading, setLoading] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [refresh, setRefresh] = useState(0);

    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState([]);
    const [openMenu, setOpenMenu] = useState(null);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(null);
    const projectData = (id) => {
        setData([])
        if (id) {
            callFetch("project/timelines/" + id, "GET", []).then((res) => {
                setData(res.data);
            });
        }
    }

    const renderMenu = () => (
        data?.project && <Menu
            anchorEl={openMenu}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            keepMounted
        >
            <CloseIcon
                onClick={handleCloseMenu}
                style={{
                    width: "17px",
                    height: "25px",
                    cursor: "pointer",
                    zIndex: "1",
                }}
            />
            <Grid mt={0} item xs={12} lg={12} pl={8}>

            </Grid>
        </Menu>
    );

    const tableHeadings = [
        {
            name: t('Project'),
            width: '90px',
            cell: row => <>{'#' + row.id}</>,
            sortable: true
        },
        {
            name: t('Customer'),
            width: '200px',
            selector: row => row?.vorname + ' ' + (row?.name == null ? '' : row?.name),
            sortable: true
        },
        {
            name: t('VKB Name'),
            width: '200px',
            selector: row => row?.salse_person?.name,
            sortable: true
        },
        {
            name: t('Erstellt am'),
            width: '120px',
            selector: row => dateFormat(row?.created_at, "dd.mm.yyyy"),
            sortable: true
        },
        {
            name: t('Time'),
            width: '120px',
            selector: row => row?.time,
            sortable: true
        },
        {
            name: t('Phone'),
            width: '120px',
            selector: row => row?.telefonnummer,
            sortable: true
        },
        {
            name: t('Status'),
            width: '250px',
            cell: row => (
                <>
                    <span className="badge badge-dot me-4">
                        {row.stornieren == 1 ? (
                            <>
                                <i className="bg-danger"></i>
                                <span className="text-dark text-xs">{'Storno'}</span>
                            </>
                        ) : (
                            <>
                                {row?.status && row?.sales_status?.id ? (
                                    <><i className={'status-' + row?.sales_status?.color ?? "bg-dark"}></i><span className={"text-dark text-xs"}>{t(row?.sales_status?.name ?? row?.status)}</span></>
                                ) : row?.termine_qoute?.sales_status?.name && row?.termine_qoute?.status && row?.termine_qoute?.status != 'null' ? (
                                    <><i className={'status-' + row?.termine_qoute?.sales_status?.color ?? "bg-dark"}></i><span className={"text-dark text-xs "}>{t(row?.termine_qoute?.sales_status?.name ?? row?.termine_qoute?.status)}</span></>
                                ) : <span className="text-dark text-xs">{'--'}</span>}
                            </>
                        )}
                    </span>
                </>
            ),
        },

        {
            name: t('Plz'),
            selector: row => row?.plz,
            sortable: true
        },
        {
            name: t('Bewertung'),
            width: '180px',
            cell: row => <>
                <ProjectStatus projectid={row?.id} todayCard={true} status={row?.status} salesPopup={true} errorDisplay={false} refresh={refresh} />
            </>,

        }
    ];

    useEffect(() => {
        setLoading(true);
        callFetch("projects/todaysList?activeTab=" + activeTab + "&page=" + pageNumber, "GET", []).then((res) => {
            setTableData(res.data.data);

            if (res?.data?.data?.total > 0) {
                if (JSON.parse(Cookies.get('permissions')).indexOf("UnveCC") !== -1) {
                    Cookies.set('UnveCC', 'yes');
                } else {
                    Cookies.set('UnveCC', 'no');
                }
            } else {
                Cookies.set('UnveCC', 'no');
            }
            setLoading(false);
        });
    }, [refresh, pageNumber, activeTab]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />

                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                {renderMenu()}
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>

                        {tabledata?.links.map((page) => {
                            //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                            const className = (page.active === true ? "page-item active" : "page-item");
                            return (
                                <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                    <button
                                        className="page-link"
                                        onClick={page.label != '...' ? handlePageNumber : ''}
                                        value={page.label}
                                        disabled={page.label == '...' ? true : false}
                                    >
                                        {page.label}
                                    </button>
                                </li>
                            );
                        })}

                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return (
        <>
            {tabledata?.total ? (
                <>
                    <div className="card mb-4 todays-Unbewertet-tab" style={{ zIndex: (tabledata?.total && Cookies.get('UnveCC') && Cookies.get('UnveCC') == 'yes' ? 9999 : 'unset') }}>
                        <div className='card-body' style={{ backgroundColor: '#fff', borderRadius: '15px', padding: '0px' }}>
                            <h5 style={{ fontSize: '0.875rem', padding: '15px', color: '#718096', fontWeight: '500', backgroundColor: '#FFFDFD', borderRadius: '15px' }}><img src="/assets/img/alert-triangle.png" style={{ marginRight: '15px' }} />Bitte Status für folgende Projekte abgeben</h5>
                            <div style={{ padding: '0px 15px' }}>
                                <ul className="nav nav-tabs nav-underline" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button onClick={() => { setActiveTab('Unbewertet'); }} className={"nav-link " + (activeTab == 'Unbewertet' ? 'active' : '')} id="Unbewertet-tab" data-bs-toggle="tab" data-bs-target="#Unbewertet-tab-pane" type="button" role="tab" aria-controls="Unbewertet-tab-pane" aria-selected="false" style={{ color: '#0048B1', fontSize: '0.875rem' }}>Unbewertet</button>
                                    </li>
                                    {/*
                            <li className="nav-item" role="presentation">
                                <button onClick={() => { setActiveTab('Heute'); }} className={"nav-link " + (activeTab == 'Heute' ? 'active' : '')} id="Heute-tab" data-bs-toggle="tab" data-bs-target="#Heute-tab-pane" type="button" role="tab" aria-controls="Heute-tab-pane" aria-selected="true" style={{ color: '#0048B1', fontSize: '0.875rem' }}>Heute</button>
                            </li>
                            */}
                                </ul>
                            </div>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active p-3" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                                    <DataTable
                                        columns={tableHeadings}
                                        data={tabledata?.data}
                                        noDataComponent={loading ? t('Loading...') : t('There are no records to display')}
                                        pagination
                                        highlightOnHover
                                        paginationComponentOptions={{ noRowsPerPage: true }}
                                        paginationServer
                                        paginationTotalRows={tabledata?.total}
                                        onChangePage={handlePageChange}
                                        paginationComponent={BootyPagination}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="d-none" id="dashboardProjectCardListRefresh" onClick={() => { setRefresh(refresh + 1); }}></button>
                </>
            ) : ''}
        </>
    );
}

export default UpdateStatusProject;
