import { useEffect, useState, useRef } from "react";
import SignaturePad from 'react-signature-pad-wrapper'
import { useTranslation } from 'react-i18next';
import { useCallFetchv2 } from 'helpers/callFetchv2';


function Unterschrift() {
    const { t } = useTranslation();
    const callFetch = useCallFetchv2();
    const signaturePadRef = useRef(null);
    const [saving, setSaving] = useState(false);

    //Display: signaturePadRef.current.fromDataURL(res.data?.sign?.client_sign);
    //Save Request: formData.client_sign = signaturePadRef.current.toDataURL('image/png')

    useEffect(() => {
        callFetch("signature/get", "GET", []).then((res) => {
            if(res?.data){
                signaturePadRef.current.fromDataURL(res.data);
            }
        });
    }, []);
    const onSubmit = () => {
        var formData = {}
        formData.signature = signaturePadRef.current.toDataURL('image/png')
        setSaving(true);
        callFetch("signature/update", "POST", formData, {}).then((res) => {
            setSaving(false);
            if (!res.ok) return;
        });
    };

    return (
        <>
            <div>
                <div className="sign-pad-new mb-3" style={{ width: '555px', height: '275px' }}>
                    <SignaturePad options={{ minWidth: 1, maxWidth: 1, penColor: 'rgb(255, 255, 255)', backgroundColor: '#000000' }} ref={signaturePadRef} />
                </div>

                <p style={{ borderTop: '1px solid #000', textAlign: 'center', marginTop: '18px', width: '555px', }}>Unterschrift</p>

                <button type="button" className="btn btn-outline-dark" onClick={() => { signaturePadRef.current.clear(); }}>
                    <i className="fa fa-eraser"></i>
                </button>

                {!saving && (
                    <button type="button" onClick={onSubmit} className="btn btn-primary ms-3">
                        {t('Save')}
                    </button>
                )}
                {saving && (
                    <button type="button" className="btn btn-disabled ms-2" disabled>
                        {t('Saving ...')}
                    </button>
                )}
            </div>
        </>
    );
}

export default Unterschrift;
