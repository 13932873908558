import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';

function ReplayForm(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if(!params.id) return;
        setValue('support_id', params.id);
    }, [params.id]);

    const onSubmit = (formData) => {
        if(!params.id) return;

        setSaving(true);
        callFetch("support-replay", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setValue('description', '')
            props.setRefresh(props.refresh+1);
        });
    };

    return(
        <div className="row">
            <div className="col-12">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t('Add reply to this support')}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            
                            <input type="hidden" 
                                {...register("support_id", {
                                    required: true,
                                })}
                            />

                            <div className="row g-3">

                                <div className="col-md-12">
                                    <label>{t('Description')} *</label>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        placeholder={t('')}
                                        {...register("description", {
                                            required: true,
                                        })} required></textarea>
                                    <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                </div>

                                <div className="col-12 mb-4">
                                    {!saving && (
                                        <button type="submit" className="btn btn-primary">
                                            {t('Replay')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Replay ...')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReplayForm;