import { React, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import SoftSnackbar from "components/SoftSnackbar";
import Select from 'react-select';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
const GoodsCreate = () => {
    const params = useParams();
    const { t } = useTranslation();
    const [articles, setArticles] = useState([]);
    const [articleName, setArticleName] = useState(null);
    const [lager, setLager] = useState([]);
    const [saving, setSaving] = useState(false);
    const [items, setItems] = useState([]);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [warehousId, setWarehousId] = useState(0);
    const [storageAreas, setStorageAreas] = useState([]);
    const [deliveryNotes, setDeliveryNotes] = useState([]);
    const [modules, setModules] = useState([]);
    const [speichers, setSpeicher] = useState([]);
    const [wechselrichtes, setWechselrichtes] = useState([]);
    const [quantity, setQuantity] = useState(0);
    const [projectID, setID] = useState(0);
    const [projects, setProjects] = useState([]);
    const [selectedProjectId, setSelectedProjectId] = useState({ id: '' });

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();
  
    useEffect(() => {
        const totalQty = items?.reduce((acc, item) =>{
            return acc + item.qty;
        },0)
        setQuantity(totalQty)
    },[refresh])


    useEffect(() => {
        callFetch("get-artikels-projects-employees", "GET", []).then((res) => {
            setProjects(res.projects); 
        });
    }, []);


  
    const handleArticleChange = (e, index) => {
        const articleId = e; 
        if(articleId){
            callFetch("items-by-prefix/"+articleId, "GET", []).then((res)=>{
                if(res.prefix == 'Art'){
                    setArticleName(res?.data?.article_name);
                    if(res?.data){
                        const updatedItems = [...items];
                        updatedItems[index].article_name = res?.data?.article_name;
                        updatedItems[index].article_nr = res?.id;
                        updatedItems[index].prefix = res?.prefix;
                        setItems(updatedItems);

                    }
                }else{ 
                    setArticleName(res?.data?.name);
                    if(res?.data){
                        const updatedItems = [...items];
                        updatedItems[index].article_name = res?.data?.name;
                        updatedItems[index].article_nr = res?.id;
                        updatedItems[index].prefix = res?.prefix;
                        setItems(updatedItems);

                    }
                } 

            })
        }

    };
  
    const [fileInputKey, setFileInputKey] = useState(0);

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const fileNames = files.map((file) => file.name);
        setSelectedFiles([...selectedFiles, ...fileNames]);
        setFileInputKey(fileInputKey + 1); // Trigger a re-render of the file input
        setDeliveryNotes([...deliveryNotes, ...files]);
      };
    
      const handleRemoveFile = (fileName, index) => {
        const updatedFiles = selectedFiles.filter((name) => name !== fileName);
        setSelectedFiles(updatedFiles);
        setFileInputKey(fileInputKey + 1); // Trigger a re-render of the file input
        const updatedDeliveryNotes = deliveryNotes;
              deliveryNotes.splice(index, 1);
        setDeliveryNotes(updatedDeliveryNotes);
        
      };


      useEffect(()=>{
        if(deliveryNotes){
            const updatedFileList = new DataTransfer();
            deliveryNotes.forEach((file) => {
                updatedFileList.items.add(file);
            });
            setValue('delivery_notes',updatedFileList.files);
        }
      },[handleRemoveFile])

    function TruncateText({ text, maxLength }) {
        if (text.length <= maxLength) {
          return <span>{text}</span>;
        }
      
        const truncatedText = text.slice(0, maxLength) + '...';
      
        return <span title={text}>{truncatedText}</span>;
      }

    function deleteProduct() {
        var titems = [];
        items.map((t) => {
            if (!t)
                return;
            titems.push(t);
        });
        setItems(titems);
        setRefresh(refresh + 1);
    }
  
    // useEffect(() => {
    //     if(warehousId > 0){
    //         setStorageAreas([]);
    //         callFetch("lager/"+warehousId+"/edit", "GET", []).then((res) => {
    //             if(res?.data?.warehouse_zone){
    //               const zone = JSON.parse(res?.data?.warehouse_zone);
    //               setStorageAreas(zone)
    //             }
    //         });
    //     }
    // }, [warehousId]);
  
    useEffect(() => {
      callFetch("goods-out/create", "GET", []).then((res) => { 
        setValue('out_number',res?.goods_number);


        //   setLager(res?.data?.lager)

        //   const products = [];

        //   // Assuming res.data.modules is an array
        //   var updatedProducts = res?.data?.modules.map((item) => ({
        //     id: item.module_identity,
        //     name: item.name,
        //   }));
          
        //   // Concatenate the existing products with the new items
        //   products.push(...updatedProducts); 

        //   // Assuming res.data.modules is an array
        //   updatedProducts = res?.data?.speichers.map((item) => ({
        //     id: item.speicher_identity,
        //     name: item.name,
        //   }));
          
        //   // Concatenate the existing products with the new items
        //   products.push(...updatedProducts); 
          
        //   // Assuming res.data.modules is an array
        //   updatedProducts = res?.data?.wechselrichtes.map((item) => ({
        //     id: item.identity,
        //     name: item.name,
        //   }));
          
        //   // Concatenate the existing products with the new items
        //   products.push(...updatedProducts); 
          
          
        //   // Assuming res.data.modules is an array
        //   updatedProducts = res?.data?.articles.map((item) => ({
        //     id: item.article_nr,
        //     name: item.article_name,
        //   }));
          
        //   // Concatenate the existing products with the new items
        //   products.push(...updatedProducts);
           
        //   setArticles(products);
        //   setArticles(products); 
      });
    }, [0]);
  
    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
          color="success"
          icon="check"
          title="Success"
          content="Successfully updated"
          dateTime="Just Now"
          open={successSB}
          onClose={closeSuccessSB}
          close={closeSuccessSB}
          bgWhite
        />
      );

      const [errorSB, setErrorSB] = useState(false); 
      const closeErrorSB = () => setErrorSB(false);
  
      const renderErrorSB = (
        <SoftSnackbar
            color="error"
            icon="info"
            title={t('Lieferschein (PDF) ist erforderlich')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={errorSB}
            onClose={() => closeErrorSB(false)}
            close={() => closeErrorSB(false)}
            bgSuccess
        />
    );

    const loadProjectProduct = (id) => {
        const idLength = id.toString().length;
        console.log(idLength);
        if(idLength > 5){
            setID(id);
            callFetch("get-project-goods/"+id, "GET", [], setError).then((res) => { 
                console.log(res.data);
                setItems(res?.data);
            });
        }
    }
  
  
    const onSubmit = (formData) => { 
        console.log(items);
        // if(formData.delivery_notes.length > 0){
            setSaving(true);
            formData.articles = JSON.stringify(items);
            formData.project_id = projectID;
            // formData.quantity = quantity;
            callFetch("goods-out/store", "POST", formData, setError).then((res) => {
                setSaving(false);
                if (!res.ok) return;
                if(res.message === 'success' && params?.id){
                    openSuccessSB();
                }
                setSubmitSuccess(true);
                
            });
        // }else{
        //     setErrorSB(true);
        // }
        // console.log(formData);
    };
  
return submitSuccess ? <Navigate to={'/ware-houses/goods-out'} /> :
<>
    <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
      <div className="row">
          <div className="col-lg-9">
              <div className="card mb-4">
                  <div className="card-header pb-0">
                      <h6>{t('Warenausgang Buchen')}</h6>
                  </div>
                  <div className="card-body">
                     
                          <div className="row g-3">

                              <div className="col-md-4">
                                  <label>{t('Warenausgangnr.')} *</label>
                                  <input type="text" className="form-control" {...register('out_number',{required:true})} required readOnly/>
                                  <div className="invalid-feedback">{errors.out_number && errors.out_number.message}</div>
                              </div>
                               
                              <div className="col-md-4"> 
                                  <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                               style={{marginTop: '5px'}}>
                                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Projekt Nr.
                                                    </SoftTypography>
                                                </SoftBox>
                                                <div className="form-group ">
                                                    <Select
                                                        placeholder={t('Select')}
                                                        options={projects ? projects : {}}
                                                        //value={selectedTeams}
                                                        getOptionLabel={(option) => ((option.id ? option.id : '') + (option.vorname ? ' - ' + option.vorname : '') + ' ' + (option?.name ? option?.name : ''))}
                                                        getOptionValue={(option) => option.id}
                                                        value={selectedProjectId}
                                                        onChange={(e) => {
                                                            setValue('project_nr', e.id);
                                                            loadProjectProduct(e.id);
                                                            setSelectedProjectId({
                                                                id: e?.id,
                                                                vorname: e?.vorname,
                                                                name: e?.name,
                                                                telefonnummer: e?.telefonnummer,
                                                            });
                                                        }}
                                                        required
                                                    />
                                                    {selectedProjectId?.id ? (
                                                        <p className="mt-1" style={{ fontSize: '12px', textDecoration: 'underline', fontWeight: '500' }}>{((selectedProjectId?.id ? selectedProjectId?.id : '') + (selectedProjectId?.vorname ? ' - ' + selectedProjectId?.vorname : '') + ' ' + (selectedProjectId?.name ? selectedProjectId?.name : '') + ', ' + (selectedProjectId?.telefonnummer ? selectedProjectId?.telefonnummer : ''))}</p>
                                                    ) : ''}
                                                
                                                    <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                                                </div>
                                            </SoftBox>               
                              </div>


                              

                            <div className="row g-2 mt-4">
                                {
                                    items && items?.map((item, i) =>(
                                        <>
                                        <div className="row mb-3 pb-4" style={{borderBottom: '1px solid #ddd'}}>
                                            <div className="col-md-5">
                                                <label>{t('Artikelnummer')} *</label> 
                                                <input type="text" className="form-control" defaultValue={item?.identity +' - '+item?.data?.name } onChange={(e)=>{
                                                items[i].data.name = e.target.value
                                                setItems(items);
                                                }}/>
                                            </div>

                                            <div className="col-md-5">
                                                <label>{t('Lagerauswahl')} *</label>
                                                {/* Initialize items[i].data.lager before rendering the select */}
                                                {(() => {
                                                    // Initialize lager if it hasn't been set and there are valid warehouses
                                                    if (!items[i].lager && item.warehouses?.length > 0) {
                                                        const firstWarehouse = item.warehouses.find(warehouse => warehouse?.warehouse_id);
                                                        if (firstWarehouse) {
                                                            items[i].lager = `${firstWarehouse.warehouse_id}-${firstWarehouse.storage_area}`;
                                                        }
                                                    }
                                                })()}

                                                <select
                                                    className="form-control"
                                                    value={items[i].lager || ""}
                                                    required
                                                    onChange={(e) => {
                                                        items[i].lager = e.target.value;
                                                        setItems([...items]);
                                                    }}
                                                >
                                                    {item?.warehouses?.length > 0 && item.warehouses.some(warehouse => warehouse?.warehouse_id) ? (
                                                        <>
                                                            <option value="">{t("----")}</option> {/* Placeholder option */}
                                                            {item.warehouses
                                                                .filter(warehouse => warehouse?.warehouse_id) // Filter out invalid warehouses
                                                                .map((warehouse, index) => (
                                                                    <option key={index} value={`${warehouse.warehouse_id}-${warehouse.storage_area}`}>
                                                                        {t(warehouse.warehouse_name) + ' - ' + warehouse.storage_area + ' (' + warehouse.quantity + ')'}
                                                                    </option>
                                                                ))}
                                                        </>
                                                    ) : (
                                                        <option value="">{t("Kein Lagerbestand")}</option> // Show this only when no valid warehouses
                                                    )}
                                                </select>
                                                <div className="invalid-feedback">{errors.warehous && errors.warehous.message}</div>
                                            </div>

                                            {/* <div className="col-md-3">
                                                <label>{t('Lagerbereiche')} *</label>
                                                <select className="form-control" {...register('storage_are',{required:true})} required>
                                                    <option value="">{t('----')}</option>
                                                    {
                                                        item.warehouses && item.warehouses.map((warehouse, index) => (
                                                            warehouse.storage_areas && warehouse.storage_areas.map((area, areaIndex) => (
                                                                <option key={`${index}-${areaIndex}`} value={area.id}>
                                                                    {area.zone_name}
                                                                </option>
                                                            ))
                                                        ))
                                                    }
                                                </select>
                                                <div className="invalid-feedback">{errors.storage_are && errors.storage_are.message}</div>
                                            </div> */}
                                            
                                            <div className="col-md-2">
                                                <label>{t('Anzahl')}</label>
                                                <div className="d-flex align-items-center">
                                                    <input type="number" value={parseInt(items[i].quantity)} className="form-control" readOnly placeholder="eg. 0.00" onChange={(e) => {
                                                        items[i].quantity = parseFloat(e.target.value);
                                                        setItems(items);
                                                        setRefresh(refresh + 1);
                                                        }} />
                                                    &nbsp;
                                                    <i className="fa-solid fa-circle-xmark text-danger cursor-pointer" data-key={i} onClick={() => { delete items[i]; deleteProduct(); }}></i>
                                                </div>
                                            </div>

                                        </div> 
                                        </>
                                    ))
                                }
                                {/* <div className="col-md-12 mt-3">
                                    <p className="btn btn-dark" style={{ cursor: 'pointer' }} onClick={() => { setItems([...items, { article_nr:0, article_name:"", qty:1 }]); setRefresh(refresh + 1); }}><i className="fa-solid fa-circle-plus"></i>&nbsp;{t('Add Item')}</p>
                                </div> */}
                            </div>
                            
                            {/* <div className="row g-2">
                                  <div className="col-md-12">
                                      <label>{t('Bestellnummer')}</label>
                                      <input type="text" className="form-control"
                                          placeholder={t('e.G 000')}
                                          {...register("po_number")}/>
                                      <div className="invalid-feedback">{errors.po_number && errors.po_number.message}</div>
                                  </div>
                              </div>
                              
                              <div className="row g-2">
                                  <div className="col-md-12">
                                      <label>{t('Description')}</label>
                                      <textarea
                                          className="form-control"
                                          rows="4"
                                          placeholder={t('')}
                                          {...register("description")}></textarea>
                                      <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                  </div>
                              </div> */}
                              
                              <div className="col-12 mb-4">
                                  {!saving && (
                                      <button type="submit" className="btn btn-primary">
                                          {t('Save')}
                                      </button>
                                  )}
                                  {saving && (
                                      <button type="submit" className="btn btn-disabled" disabled>
                                          {t('Saving ...')}
                                      </button>
                                  )}
                              </div>
                          </div>
                      
                  </div>
              </div>
          </div> 

      </div>
      {renderSuccessSB}
      {renderErrorSB}
      </form>
</>
}
export default GoodsCreate