import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import { NumericFormat } from 'react-number-format';
function Edit() {
    let params = useParams();
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [total, setTotal] = useState(0);
    const [currency, setCurrency] = useState('EUR');
    const [salaryCategory, setSalaryCategory] = useState([
        { categoryTitle: ' ', amount : ' ' }, 
    ]);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        flatpickr(".flat-pickr");

        callFetch("payrolls/create", "GET", []).then((res) => {
            setData(res.data);
        });
    }, []);
    useEffect(() => {
        flatpickr(".flat-pickr");

        callFetch("payrolls/" + params.id+"/edit", "GET", []).then((res) => {
            // setValue('id', res.data.id);
            setValue('employee_id', res.data.employee_id);
            setValue('currency', res.data.currency);
            setValue('month', res.data.month); 
            setSalaryCategory(JSON.parse(res.data.salary_categories));
        });
    }, []);  


    const handleChangeSalaryCategoryInput = (index, event) => {
        const values = [...salaryCategory];
        values[index][event.target.name] = event.target.value; 
        setSalaryCategory(values);
    }

    const handleAddField = () => {
        setSalaryCategory([...salaryCategory, { categoryTitle: ' ', amount : ' ' }]);
    }

    const handleRemoveField = (index) => {
        const values = [...salaryCategory];
        values.splice(index, 1); 
        setSalaryCategory(values); 
    }

    useEffect(() => {
        var i;
        var total = 0;
        for(i=0; i<salaryCategory.length; i++){
            total = total + Number(salaryCategory[i]['amount']);    
        }
        setTotal(total);
    }, [salaryCategory]);

    const onSubmit = (formData) => {
        setSaving(true);
        formData.total = total;
        formData.salary_categories = JSON.stringify(salaryCategory);
        callFetch("payrolls/" + params.id, "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            // setQuotation(res.data);
            setSubmitSuccess(true);
        });
    };

    return(
        <div className="row">
        <div className="col-12">
            <div className="card mb-4">
                <div className="card-header pb-0">
                    <h6>{t('Add Salary')}</h6>
                </div>
                <div className="card-body">
                    <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <input type="hidden" defaultValue="PUT" {...register("_method")} />
                        <div className="row g-3"> 
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>
                                        {t('Employee')} *
                                    </label>
                                    <br />
                                    <select
                                        className="form-control"
                                        {...register("employee_id", {
                                            required: true,
                                        })}
                                        required>
                                        <option value="">--</option>
                                        {data && data.employees ? data.employees.map((employee) => (
                                            <option key={employee.user.id} value={employee.user.id}>{employee.user.name}</option>
                                        )) : <></>}
                                    </select>
                                    <div className="invalid-feedback">{errors.employee && errors.currency.employee}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>
                                        {t('Currency')} *
                                    </label>
                                    <br />
                                    <select
                                        className="form-control"
                                        {...register("currency", {
                                            required: true,
                                        })}
                                        onChange={(e) => {
                                            setCurrency(e.target.value)
                                        }}
                                        required
                                    >
                                        <option value="EUR">EUR (€)</option>
                                        <option value="USD">USD ($)</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.currency && errors.currency.message}</div>
                                </div>
                            </div>  
                        </div> 
                        
                        {salaryCategory.map((category, index) => 
                            <div className="row g-3" key={index}> 
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('Salary Category')} *
                                        </label>
                                        <br />
                                        <select
                                            className="form-control"
                                            value={category.categoryTitle}
                                            name="categoryTitle"
                                            onChange = {event => handleChangeSalaryCategoryInput(index, event)}
                                            required>
                                            <option value="">--</option>
                                            <option value="Travel Expenses">{t('Travel Expenses')}</option>
                                            <option value="Payroll">{t('Payroll')}</option> 
                                            <option value="Bonus">{t('Bonus')}</option>
                                        </select> 
                                        <div className="invalid-feedback">{errors.categoryTitle && errors.currency.categoryTitle}</div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form-group">
                                        <label>
                                            {t('Amount S')} *
                                        </label>
                                        <br />
                                        <input type="text" value={category.amount}
                                            onChange = {event => handleChangeSalaryCategoryInput(index, event)} className="form-control" name="amount"/> 
                                        <div className="invalid-feedback">{errors.amount && errors.currency.amount} </div>
                                    </div>
                                </div> 
                                <div className="col-sm-1"><i className="fa-solid fa-circle-xmark text-danger" style={{marginTop: '43px'}} onClick={() => handleRemoveField(index)}></i></div> 
                            </div>  
                        )}

                        <div className="row g-3">
                            <div className="col-sm-1"><button type="button" className="btn btn-outline-secondary mt-3" onClick={() => handleAddField()}>+{t('Add')}</button></div>
                            <div className="col-sm-5"></div>
                            <div className="col-sm-2"><p><label>Total: </label> <NumericFormat 
                                                    value={total}
                                                    displayType="text" 
                                                    thousandSeparator={"."} 
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                /></p></div>
                        </div>

                        <div className="row g-3">
                            <div className="form-group">
                                <label>
                                    {t('month')} *
                                </label>
                                <br />
                                 <select className="form-control" {...register('month')}>
                                    <option value=''>---</option>
                                    <option selected value='Janaury'>Janaury</option>
                                    <option value='February'>February</option>
                                    <option value='March'>March</option>
                                    <option value='April'>April</option>
                                    <option value='May'>May</option>
                                    <option value='June'>June</option>
                                    <option value='July'>July</option>
                                    <option value='August'>August</option>
                                    <option value='September'>September</option>
                                    <option value='October'>October</option>
                                    <option value='November'>November</option>
                                    <option value='December'>December</option>
                                 </select>
                                <div className="invalid-feedback">{errors.month && errors.month}</div>
                            </div>
                        </div>
                            
                        <div className="row mt-3">
                            <div className="col-md-12">
                                {!saving && (
                                    <button type="submit" className="btn btn-primary">
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Saving ...')}
                                    </button>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>  
        </div>
    </div>
    )
}

export default Edit;
