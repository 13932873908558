import { React, useEffect, useState, useRef } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import { Grid, Menu } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Timeline from '../../project/Timeline';
import ProjectStatus from '../../project/ProjectStatus';
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";


import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";
import { useForm } from "react-hook-form";
import LoadingCard from "components/LoadingCard";

import { MenuItem, setRef } from "@mui/material";
import Select from 'react-select';
import Flatpickr from "react-flatpickr";
import Divider from "@mui/material/Divider";
import SoftBox from "components/SoftBox";
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";
import {
    useSoftUIController,
    setOpenConfigurator,
    setTransparentSidenav,
    setMiniSidenav,
    setFixedNavbar,
    setSidenavColor,
} from "context";

import ProjectFeedBack from "pages/project/create/timeline/ProjectFeedBack";

function ProjectTable(props) {
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    var typingTimer;

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [searchKey, setSearchKey] = useState("")
    const [openMenu, setOpenMenu] = useState(null);
    const [data, setData] = useState([]);

    const fp = useRef(null);
    const fpt = useRef(null);
    const statusdatum = useRef(null);
    const [controller, dispatch] = useSoftUIController();
    const { openConfigurator, transparentSidenav, miniSidenav, fixedNavbar, sidenavColor } = controller;
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
    const [firstStatusForwardedToLeadOwner, setfirstStatusForwardedToLeadOwner] = useState("");
    const [ort, setOrt] = useState("");
    const [orts, setOrts] = useState([])
    const [menuOrt, setMenuOrt] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startDateTerm, setStartDateTerm] = useState('');
    const [endDateTerm, setEndDateTerm] = useState('');
    const [statusdatumStartDate, setStatusdatumStartDate] = useState('');
    const [statusdatumEndDate, setStatusdatumEndDate] = useState('');
    const [filterKeys, setFilterKeys] = useState({});
    const [filderData, setFilderData] = useState([]);

    const [projectFeedback, setProjectFeedback] = useState([]);
    const [activity, setActivity] = useState({});

    const handleDateChange = (selectedDates) => {
        // Handle selected date range 
        if (Object.keys(selectedDates).length <= 0) {
            setStartDate('');
            setEndDate('');
        } else {
            const [startDate, endDate] = selectedDates;
            setStartDate(dateFormat(startDate, "yyyy-mm-dd"));
            setEndDate(dateFormat(endDate, "yyyy-mm-dd"));
        }
    };

    const handleDateChangeTerm = (selectedDates) => {
        // Handle selected date range 
        if (Object.keys(selectedDates).length <= 0) {
            setStartDateTerm('');
            setEndDateTerm('');
        } else {
            const [startDateTermX, endDateTermX] = selectedDates;
            setStartDateTerm(dateFormat(startDateTermX, "yyyy-mm-dd"));
            setEndDateTerm(dateFormat(endDateTermX, "yyyy-mm-dd"));
        }
    };

    const handleClearButton = () => {
        document.getElementById('ortInput').value = '';
        document.getElementById('min').value = '';
        document.getElementById('max').value = '';
        //document.getElementById('projectidInput').value = '';
        //document.getElementById('kundenInput').value = '';
        //document.getElementById('vkbInput').value = '';
        setOrt('')
        setFilterKeys({});
        fp?.current?.flatpickr?.clear();
        fpt?.current?.flatpickr?.clear();
        statusdatum?.current?.flatpickr.clear();
    }

    const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

    const [filterData, setFilterData] = useState({
        verkaufer: 0,
        created_at: 'All',
    });
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(null);

    useEffect(() => {
        callFetch("get-project-filter-data", "GET", []).then((res) => {
            setFilderData(res?.data);
        });
    }, []);


    useEffect(() => {
        if (!props?.filterData?.created_at) return;

        if (props?.filterData?.created_at != filterData?.created_at) {
            var data = filterData;
            data.created_at = props?.filterData?.created_at;
            setFilterData(data);
            setRefresh(refresh + 1)
        }
    }, [props?.filterData?.created_at]);

    const renderMenu = () => (
        data?.project && <Menu
            anchorEl={openMenu}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            keepMounted
        >
            <CloseIcon
                onClick={handleCloseMenu}
                style={{
                    width: "17px",
                    height: "25px",
                    cursor: "pointer",
                    zIndex: "1",
                }}
            />
            <Grid mt={0} item xs={12} lg={12} pl={8}>
                <Timeline datas={data} title="Project Status" />
            </Grid>
        </Menu>
    );

    const tableHeadings = [
        {
            name: t('Project Number'),
            width: '170px',
            selector: row => <NavLink to={'/projects/' + row.id} className={"text-primary font-weight-bold"}>{row.id}</NavLink>,
            sortable: true,
        },
        {
            name: t('Customer'),
            selector: row => row?.vorname + ' ' + (row?.name == null ? '' : row?.name),
        },
        {
            name: t('VKB Name'),
            selector: row => <NavLink to={'/projects/' + row.id}>{row?.salse_person?.name}</NavLink>,
        },
        {
            name: t('Erstellt am'),
            width: '130px',
            selector: row => dateFormat(row?.created_at, "dd.mm.yyyy"),
        },
        {
            name: t('Ereignisdatum'),
            width: '130px',
            selector: row => dateFormat(row?.ereignisdatum, "dd.mm.yyyy"),
        },
        {
            name: t('Status'),
            width: '250px',
            cell: row => (
                <>
                    <span className="badge badge-dot me-4">
                        {row?.status && row?.sales_status?.id ? (
                            <><i className={'status-' + row?.sales_status?.color ?? "bg-dark"}></i><span className={"text-dark text-xs"}>{t(row?.sales_status?.name ?? row?.status)}</span></>
                        ) : row?.termine_qoute?.sales_status?.name && row?.termine_qoute?.status && row?.termine_qoute?.status != 'null' ? (
                            <><i className={'status-' + row?.termine_qoute?.sales_status?.color ?? "bg-dark"}></i><span className={"text-dark text-xs "}>{t(row?.termine_qoute?.sales_status?.name ?? row?.termine_qoute?.status)}</span></>
                        ) : <span className="text-dark text-xs">{'--'}</span>}
                    </span>
                </>
            ),
        },
        {
            name: t('Storno'),
            selector: row => ((
                <>
                    {row.cancel == 1 ? (
                        <StatusCell icon="replay" color="dark" status={t("Yes")} />
                    ) : ''}

                </>
            )),
        },
        {
            name: t('Bewertele Termine'),
            selector: row => ((
                <>
                    {row?.status || row?.termine_qoute?.status ? (
                        t("Yes")
                    ) : t("No")}
                </>
            )),
        },
        {
            name: t('Bezahlt €'),
            selector: row => ((
                <>
                    {firstStatusForwardedToLeadOwner == 0 ? (
                        <>
                            {row?.status && row?.sales_status?.id && row?.sales_status?.lead_owner_wird_bezhlt === 1 ?
                                <StatusCell icon="done" color="success" status={t("Yes")} />
                                : row?.status ? <StatusCell icon="close" color="error" status={t("No")} />
                                    : '--'}
                        </>
                    ) : firstStatusForwardedToLeadOwner == 1 ? (
                        <>
                            {row?.termine_qoute?.status && row?.termine_qoute?.sales_status?.id && row?.termine_qoute?.sales_status?.lead_owner_wird_bezhlt === 1 ?
                                <StatusCell icon="done" color="success" status={t("Yes")} />
                                : row?.termine_qoute?.status ? <StatusCell icon="close" color="error" status={t("No")} />
                                    : '--'}
                        </>
                    ) : ''}
                </>
            )),
        },
        {
            name: t('Bemerkung'),
            width: '150px',
            selector: row => ((
                <>
                    {row?.status_activity && row.status_activity.map((activity, index) => {
                        return <>
                            <p className="text-sm m-0">
                                <SoftTypography variant="caption">
                                    {activity?.project_feedback ? (
                                        <>
                                            <a className="text-primary"
                                                onClick={() => {
                                                    setProjectFeedback(activity?.project_feedback);
                                                    setActivity(activity)
                                                    document.getElementById('projectStatusWithFileUploadOpenBtnUpdate').click();
                                                }}
                                            >{'Anzeigen'}</a>
                                        </>
                                    ) : ''}
                                </SoftTypography>
                            </p>
                        </>
                    })}
                </>
            )),
        },
    ];

    useEffect(() => {
        setLoading(true);

        var parameters = "search=" + searchKey + "&page=" + pageNumber + "&statusdatumStartDate=" + statusdatumStartDate + "&statusdatumEndDate=" + statusdatumEndDate + "&created_start=" + startDate + "&created_end=" + endDate + "&appointment_start=" + startDateTerm + "&appointment_end=" + endDateTerm + "&ort=" + ort;
        for (let [key, value] of Object.entries(filterKeys)) {
            parameters += "&" + key + "=" + value;
        }

        callFetch("projects?" + parameters, "GET", []).then((res) => {
            setTableData(res.data.projects);
            setfirstStatusForwardedToLeadOwner(res.first_status_forwarded_to_lead_owner);
            setLoading(false);
        });
    }, [searchKey, statusdatumStartDate, statusdatumEndDate, filterKeys, pageNumber, refresh, startDate, endDate, startDateTerm, endDateTerm, ort]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />

                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                {renderMenu()}
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>

                        {tabledata?.links.map((page) => {
                            //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                            const className = (page.active === true ? "page-item active" : "page-item");
                            return (
                                <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                    <button
                                        className="page-link"
                                        onClick={page.label != '...' ? handlePageNumber : ''}
                                        value={page.label}
                                        disabled={page.label == '...' ? true : false}
                                    >
                                        {page.label}
                                    </button>
                                </li>
                            );
                        })}

                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return <>
        <div className="card mb-4">
            <div className="card-header pb-0">
                <h6>{t('All Projects')}</h6>
            </div>
            <div className="card-body px-0 pt-0 pb-2">
                <DataTable
                    columns={tableHeadings}
                    data={tabledata?.data}
                    noDataComponent={loading ? t('Loading...') : t('There are no records to display')}
                    className='data-table'
                    pagination
                    highlightOnHover
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    paginationServer
                    paginationTotalRows={tabledata?.total}
                    onChangePage={handlePageChange}
                    paginationComponent={BootyPagination}
                    subHeader
                    subHeaderComponent={
                        <>

                            <button onClick={handleConfiguratorOpen} className='btn btn-outline-dark mx-2' style={{ paddingTop: '10px', paddingBottom: '10px', margin: '0px' }}><i className="fa-solid fa-filter"></i> Filter</button>
                            <input
                                type="text"
                                placeholder={t("Search...")}
                                className=' form-control w-sm-50 w-md-25 w-lg-15 ms-1 mt-1'
                                defaultValue={searchKey}
                                /*
                                onChange={(e)=> {
                                    setSearchKey(e.target.value);
                                }}
                                */
                                onKeyUp={(e) => {
                                    clearTimeout(typingTimer);
                                    typingTimer = setTimeout(() => {
                                        setTableData([]);
                                        setSearchKey(e.target.value);
                                    }, 1500);
                                }}
                                onKeyDown={(e) => {
                                    clearTimeout(typingTimer);
                                }}
                            />
                        </>
                    }
                />

                <ProjectFeedBack data={projectFeedback} activity={activity} setrefresh={setRefresh} />


                <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
                    <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="baseline"
                        pt={3}
                        pb={0.8}
                        px={3}
                    >
                        <SoftBox>
                            <SoftTypography variant="h5">Filters</SoftTypography>
                        </SoftBox>

                        <Icon
                            sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                                fontSize: `${size.md} !important`,
                                fontWeight: `${fontWeightBold} !important`,
                                stroke: dark.main,
                                strokeWidth: "2px",
                                cursor: "pointer",
                                mt: 2,
                            })}
                            onClick={handleCloseConfigurator}
                        >
                            close
                        </Icon>
                    </SoftBox>

                    <Divider />

                    <SoftBox pt={1.25} pb={3} px={3}>
                        <label>Project Nr</label>
                        <div className='position-relative'>

                            <Select
                                placeholder={t('Select')}
                                options={filderData?.projects ? filderData?.projects : []}
                                //defaultValue={filterKeys?.status ? JSON.parse(filterKeys?.status) : ''}
                                onChange={(e) => {
                                    var dataKeys = filterKeys;
                                    var projects = [];
                                    for (let [option_key, option] of Object.entries(e)) {
                                        projects.push(option?.value)
                                    }

                                    dataKeys.projects = JSON.stringify(projects);
                                    setFilterKeys(dataKeys);
                                    setRefresh(refresh + 1);
                                }}
                                className='react-select-filter mb-2'
                                isMulti
                            />
                        </div>

                        <label>{t("Kundenname")}</label>
                        <div className='position-relative'>
                            <Select
                                placeholder={t('Select')}
                                options={filderData?.customers ? filderData?.customers : []}
                                //defaultValue={filterKeys?.status ? JSON.parse(filterKeys?.status) : ''}
                                onChange={(e) => {
                                    var dataKeys = filterKeys;
                                    var customers = [];
                                    for (let [option_key, option] of Object.entries(e)) {
                                        customers.push(option?.value)
                                    }

                                    dataKeys.customers = JSON.stringify(customers);
                                    setFilterKeys(dataKeys);
                                    setRefresh(refresh + 1);
                                }}
                                className='react-select-filter mb-2'
                                isMulti
                            />
                        </div>

                        <label>{t("VKB Name")}</label>
                        <Select
                            placeholder={t('Select')}
                            options={filderData?.employees ? filderData?.employees : []}
                            //defaultValue={filterKeys?.status ? JSON.parse(filterKeys?.status) : ''}
                            onChange={(e) => {
                                var dataKeys = filterKeys;
                                var employees = [];
                                for (let [option_key, option] of Object.entries(e)) {
                                    employees.push(option?.value)
                                }

                                dataKeys.employees = JSON.stringify(employees);
                                setFilterKeys(dataKeys);
                                setRefresh(refresh + 1);
                            }}
                            className='react-select-filter mb-2'
                            isMulti
                        />

                        <label>{t("Erstellt am")}</label>
                        <Flatpickr
                            ref={fp}
                            options={{
                                mode: 'range',
                                dateFormat: 'Y-m-d',
                            }}
                            onChange={handleDateChange}
                        />
                        <label>{t("Termindatum")}</label>
                        <Flatpickr
                            ref={fpt}
                            options={{
                                mode: 'range',
                                dateFormat: 'Y-m-d',
                            }}
                            onChange={handleDateChangeTerm}
                        />

                        <label>{t("Status")}</label>
                        <Select
                            placeholder={t('Select')}
                            options={filderData?.salesStatus ? filderData?.salesStatus : []}
                            defaultValue={filterKeys?.status ? JSON.parse(filterKeys?.status) : ''}
                            onChange={(e) => {
                                var dataKeys = filterKeys;
                                var status = [];
                                for (let [option_key, option] of Object.entries(e)) {
                                    status.push(option?.value)
                                }

                                //dataKeys.status = status.toString();
                                dataKeys.status = JSON.stringify(status);
                                setFilterKeys(dataKeys);
                                setRefresh(refresh + 1);
                            }}
                            className='react-select-filter mb-2'
                            isMulti
                        />

                        <label>{t("Ort")}</label>
                        <div className='position-relative'>
                            <input
                                id='ortInput'
                                type="text"
                                placeholder={t("Ort...")}
                                className=' form-control mb-2'
                                defaultValue={ort}
                                /*
                                onChange={(e)=> {
                                    setSearchKey(e.target.value);
                                }}
                                */
                                onKeyUp={(e) => {
                                    clearTimeout(typingTimer);
                                    typingTimer = setTimeout(() => {
                                        setTableData([]);
                                        setOrt(e.target.value);
                                    }, 1500);
                                }}
                                onKeyDown={(e) => {
                                    clearTimeout(typingTimer);
                                }}
                            />

                            {
                                orts?.length >= 0 && <div className="card position-absolute p-0 m-0 me-10 top-100 w-100 rounded-0 mt-1" style={{ zIndex: '999' }}>
                                    {
                                        menuOrt && <div className="card-body p-0 m-0" >
                                            <ul className="p-1 m-0">
                                                {
                                                    orts.map((items, index) => (
                                                        <MenuItem style={{ text: "black", hover: { color: 'none' } }} key={index}>
                                                            {
                                                                items?.id ? (
                                                                    <NavLink
                                                                        style={{ color: "#344767" }}
                                                                        to={''}
                                                                        onClick={() => {
                                                                            setOrts([]);
                                                                            setOrt(items.ort);
                                                                            document.getElementById('ortInput').value = items.ort;
                                                                        }}
                                                                    >
                                                                        {items.ort} <br />
                                                                    </NavLink>
                                                                ) : ''
                                                            }
                                                        </MenuItem>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    }
                                </div>
                            }

                        </div>
                        <br />
                        <button type='button' className='btn btn-outline-dark mt-2' onClick={() => handleClearButton()}>Clear</button>
                    </SoftBox>
                </ConfiguratorRoot>
            </div>
        </div>
    </>
}

export default ProjectTable;
