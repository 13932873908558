import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import callFetch from 'helpers/callFetch';
import deleteAlert from 'helpers/deleteAlert';
import SoftTypography from 'components/SoftTypography';

function CustomerIndexTable() {
    const { t } = useTranslation();
    const [customers, setCustomers] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [searchKey, setSearchKey] = useState("")
    const tableHeadings = [
        {
            name: t('Customer Nr.'),
            width: '140px',
            sortable: true,
            selector: row => <NavLink to={`/customers/${row.id}`} className={"text-primary font-weight-bold"}>{row?.customer_number}</NavLink>,


        },
        {
            name: t('Name'),
            width: '300px',
            sortable: true,
            selector: row => <NavLink to={`/customers/${row.id}`}>{row?.vorname + ' ' + row?.name}</NavLink>

        },
        {
            name: t('City'),
            selector: row => row?.ort,
            sortable: true,

        },
        {
            name: t('Phone Nr'),
            selector: row => row?.mobilnummer,
            sortable: true,

        },
        {
            name: t('Sales Status'),
            selector: row => (
                row?.quatation?.status !== null && <>
                    <span style={{
                        height: '0.375rem', width: '0.375rem', backgroundColor: row?.quatation?.status === '1-Verkauft' ?
                            '#21d4fd' : row?.quatation?.status == '2-Kontte nicht verkauft werden' ?
                                '#ff0080' : row?.quatation?.status == '3-Angebot' ?
                                    '#fbcf33' : row?.quatation?.status == '4-Überleger' ?
                                        '#e6980b' : row?.quatation?.status == '5-Kunde war nicht da' ?
                                            '#9320bb' : row?.quatation?.status == '6-Kein Interesse / Keine Beratung' ?
                                                '#9320bb' : row?.quatation?.status == '7-Technisch nicht möglich' ?
                                                    '#9320bb' : row?.quatation?.status == '0-Zeitlich nicht geschaft' ?
                                                        '#9320bb' : 'dark', borderRadius: '50%', display: 'inline-block'
                    }}></span>
                    <SoftTypography style={{ position: 'relative', top: '1px', left: '3px', fontSize: '0.75rem', display: 'inline' }}>{row?.quatation?.status}</SoftTypography>
                </>
            ),
            sortable: true,

        },
        {
            name: t('Actions'),
            cell: row => <div className="text-center dropstart">
                <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fa fa-ellipsis-v text-xs"></i>
                </a>
                <ul className="dropdown-menu">
                    {JSON.parse(Cookies.get('permissions')).indexOf("CusU") !== -1 ? (
                        <li>
                            <NavLink to={'/customers/' + row?.id} className="dropdown-item">
                                {t('Edit')}
                            </NavLink>
                        </li>
                    ) : <></>}
                    <li><hr className="dropdown-divider" /></li>
                    {JSON.parse(Cookies.get('permissions')).indexOf("CusD") !== -1 ? (
                        <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'customers', row.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                    ) : <></>}
                </ul>
            </div>,
            sortable: true,


        }
    ];

    useEffect(() => {
        callFetch("customers?page=" + pageNumber+'&search='+searchKey, "GET", []).then((res) => {
            setCustomers(res.data);
        });
    }, [pageNumber, refresh, searchKey]);

    const handlePageChange = page => {
        setPageNumber(page);
    }
    
    /*
    useEffect(() => {
        if (searchKey.length > 0) {
            callFetch('customer/search/' + searchKey, "GET", []).then((res) => {
                setCustomers(res.data)
            })
        } else {
            setRefresh(refresh + 1)
        }
    }, [searchKey])
    */




    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = customers.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === customers.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {customers.from} {t('to')} {customers.to} {t('of')} {customers.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {customers?.links.map((page) => {
                            //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                            const className = (page.active === true ? "page-item active" : "page-item");
                            return (
                                <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                    <button
                                        className="page-link"
                                        onClick={page.label != '...' ? handlePageNumber : ''}
                                        value={page.label}
                                        disabled={page.label == '...' ? true : false}
                                    >
                                        {page.label}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return <DataTable
        columns={tableHeadings}
        data={customers?.data}
        noDataComponent={t('There are no records to display')}
        pagination
        highlightOnHover
        paginationComponentOptions={{ noRowsPerPage: true }}
        paginationServer
        paginationTotalRows={customers?.total}
        onChangePage={handlePageChange}
        paginationComponent={BootyPagination}
        subHeader
        className='data-table'
        subHeaderComponent={<input type="text" placeholder={t("Search...")} className='form-control w-sm-50 w-md-25 w-lg-15' value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />}
    />;
}

export default CustomerIndexTable;
