import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import SoftEditor from "components/SoftEditor";
import { Switch } from "@mui/material";

function Index() {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [cornSetups, setCornSetups] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [editContent, setEditContent] = useState('');
    const [description, setDescription] = useState('');
    const [modules, setModules] = useState({
        invoice_warning_1: '1 Mahnung',
        invoice_warning_2: '2 Mahnung',
        invoice_last_warning: 'Letzte Mahnung',
        invoice_payment_reminder: 'Zahlungserinnerung',
        invoice_overdue_bills: 'überfällige Rechnungen',
    });


    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("cron", "GET", []).then((res) => {
            setCornSetups(res.data);
        });
    }, [refresh2]);

    useEffect(() => {
        if (editContent === '') return;

        setDescription(cornSetups[editContent]?.description ? cornSetups[editContent]?.description : '');

    }, [editContent]);

    const onSubmit = (formData) => {
        setSaving(true);
        formData.cornSetups = JSON.stringify(cornSetups);
        callFetch("cron", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
            setRefresh2(refresh2 + 1)
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-md-8">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('Rechnungen Mahnstufe')}</h6>
                        </div>
                        <div className="card-body">
                            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                {cornSetups.map((corn, i) => (
                                    <div className="row">
                                        <div className="col-3 mt-0">
                                            <label className="pt-2">{t('Mahnstufe ' + (i + 1) + ' nach')} *</label>
                                        </div>
                                        <div className="col-1 mt-0 mb-1">
                                            <input
                                                type="number"
                                                className="form-control form-control-sm"
                                                placeholder={t('')}
                                                value={cornSetups[i].days}
                                                onChange={(e) => {
                                                    var update = cornSetups;
                                                    update[i].days = e.target.value;
                                                    setCornSetups(update);
                                                    setRefresh(refresh + 1)
                                                }}
                                                min={0}
                                                required
                                            />
                                            <div className="invalid-feedback">{errors.eigenverbrauch && errors.eigenverbrauch.message}</div>
                                        </div>

                                        <div className="col-3 mt-0">
                                            <label className="pt-2">{t('Tagen mit Textbaustein')} *</label>
                                        </div>
                                        <div className="col-3 mt-0 mb-1">
                                            <select
                                                className="form-control form-control-sm"
                                                placeholder={t('')}
                                                value={cornSetups[i].module}
                                                onChange={(e) => {
                                                    var update = cornSetups;
                                                    update[i].module = e.target.value;
                                                    setCornSetups(update);
                                                    setRefresh(refresh + 1)
                                                }}
                                                required
                                            >
                                                <option value={''}>{t('Select')}</option>
                                                {Object.keys(modules).map(function (key) {
                                                    return <option value={key}>{t(modules[key])}</option>
                                                })}
                                            </select>
                                            <div className="invalid-feedback">{errors.eigenverbrauch && errors.eigenverbrauch.message}</div>
                                        </div>
                                        <div className="col-2 mt-0 text-end">

                                            <Switch
                                                checked={cornSetups[i].status == 'active' ? true : false}
                                                onChange={(val) => {

                                                    var update = cornSetups;
                                                    if(cornSetups[i].status === 'active'){
                                                        update[i].status = 'deactive';
                                                    }else{
                                                        update[i].status = 'active';
                                                    }

                                                    setCornSetups(update);
                                                    setRefresh(refresh + 1)

                                                }}
                                            />

                                            <i
                                                className="fa-solid fa-edit text-primary ms-3"
                                                style={{ cursor: 'pointer' }}
                                                data-key={i}
                                                data-bs-toggle="modal"
                                                data-bs-target="#emailContent"
                                                onClick={() => {
                                                    setEditContent(i);
                                                    setRefresh(refresh + 1);
                                                }}
                                            ></i>

                                            <i
                                                className="fa-solid fa-circle-xmark text-danger  ms-3"
                                                style={{ cursor: 'pointer' }}
                                                data-key={i}
                                                onClick={() => {

                                                    //console.log(cornSetups[i].id)

                                                    if (cornSetups[i]?.id && cornSetups[i].id > 0) {
                                                        callFetch("cron/" + cornSetups[i].id, "POST", { '_method': 'DELETE' }, null).then((res) => {
                                                            if (res?.message === 'success') {
                                                                setEditContent('');
                                                                delete cornSetups[i];
                                                                setRefresh(refresh + 1)
                                                            }
                                                        });
                                                    } else {
                                                        setEditContent('');
                                                        delete cornSetups[i];
                                                        setRefresh(refresh + 1)
                                                    }
                                                }}
                                            ></i>
                                        </div>
                                    </div>
                                ))}

                                <div className="row">
                                    <div className="col-12">
                                        <button
                                            type="button"
                                            className="btn mb-2 mt-3"
                                            style={{
                                                background: '#2684FF',
                                                color: '#ffffff',
                                                textTransform: 'capitalize',
                                                fontWeight: 'normal'
                                            }}
                                            onClick={() => {

                                                var items = cornSetups;
                                                items.push({
                                                    days: 0,
                                                    module: '',
                                                    status: 'deactive',
                                                    subject: '',
                                                    description: '',
                                                });

                                                setCornSetups(items);
                                                setRefresh(refresh + 1)
                                            }}
                                        >{t('Add')}</button>
                                    </div>
                                </div>

                                <div className="row g-3 mt-3">
                                    <div className="col-12">
                                        {!saving && (
                                            <button type="submit" className="btn btn-primary">
                                                {t('Save')}
                                            </button>
                                        )}
                                        {saving && (
                                            <button type="submit" className="btn btn-disabled" disabled>
                                                {t('Saving ...')}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="emailContent" tabindex="-1" role="dialog" aria-labelledby="emailContentLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header border-1">
                            <h6 className="modal-title" id="emailContentLabel" style={{ fontWeight: 'bold' }}>{t(modules[cornSetups[editContent]?.module])}</h6>
                            <button
                                type="button"
                                className="btn-close text-dark"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {editContent !== '' ? (
                                <div className="row">
                                    <div className="col-12">
                                        <label>
                                            {t('Subject')} <b>*</b>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('Subject')}
                                            value={cornSetups[editContent]?.subject}
                                            onChange={(e) => {
                                                var update = cornSetups;
                                                update[editContent].subject = e.target.value;
                                                setCornSetups(update);
                                                setRefresh(refresh + 1)
                                            }}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.subject && errors.subject.message}</div>
                                    </div>
                                    <div className="col-12 mb-4">
                                        <label>
                                            {t('Description')} <b>*</b>
                                        </label>
                                        <SoftEditor
                                            onChange={setDescription}
                                            value={description}
                                        />
                                        <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                    </div>
                                </div>
                            ) : ''}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    var update = cornSetups;
                                    update[editContent].description = description;
                                    setCornSetups(update);
                                    setRefresh(refresh + 1)
                                }}
                            >
                                {t('Save')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;
