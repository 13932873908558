import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CustomerIndexTable from "./CustomerIndexTable";
import Cookies from 'js-cookie';

function CustomerIndex() {
    const { t } = useTranslation();
    // useEffect(() => {
    //     document.title = "INGTEC . Marketing & Vertrieb";
    // }, []);
    return (
        <>
            <div className="d-sm-flex justify-content-between">
                <div>
                    {JSON.parse(Cookies.get('permissions')).indexOf("CusC") !== -1 ? (
                        <NavLink to="/customers/create" className="btn btn-icon btn-primary">
                            {t('Add Customer')}
                        </NavLink>
                    ) : <></>}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('All Customers')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <CustomerIndexTable />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CustomerIndex;
