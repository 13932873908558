import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import deleteAlert from 'helpers/deleteAlert';

import ImportModal from 'pages/importer/ImportModal';
import { Switch } from '@mui/material'
import SoftSnackbar from "components/SoftSnackbar";

const IndexTable = () => {
    const { t } = useTranslation();
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);

    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            //content="Invoice successfully created"
            //dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgSuccess
        />
    );

    const handleSetStatus = (status, id) => {

        var formData = {};
        formData.id = id;
        formData.status = status ? 1 : 0;

        callFetch("bundle/status", "POST", formData, {}).then((res) => {

            if (res?.ok) {
                openSuccessSB();
            }

            if (!res.ok) return;
            setRefresh(refresh + 1)
        });

    };

    const tableHeadings = [
        {
            name: t('Bundle Nr.'),
            selector: row => <NavLink to={`/bundle-preis/${row.id}/edit`} className="text-primary font-weight-bold">{row.bundle_nummer}</NavLink>,
            width: '180px',
            sortable: true,
        },
        {
            name: t('Bundle'),
            selector: row => <NavLink to={`/bundle-preis/${row.id}/edit`} >{row.bundle_group_name}</NavLink>,
        },
        {
            name: t('Create Date'),
            selector: row => dateFormat(row.created_at, "dd.mm.yyyy"),
        },

        {
            name: t('Text'),
            selector: row => row.description,
        },

        {
            name: t('Status'),
            selector: row => (
                <>
                    <Switch
                        defaultChecked={(row.status == 1 ? true : false)}
                        onChange={() => {
                            handleSetStatus((row.status == 1 ? false : true), row.id)
                        }}
                    />
                </>
            ),
        },

        {
            name: t('Actions'),
            cell: row => (
                <>
                    {JSON.parse(Cookies.get('permissions')).indexOf("EU") !== -1 ||
                        JSON.parse(Cookies.get('permissions')).indexOf("ED") !== -1 ? (
                        <div className="text-center dropstart">
                            <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v text-xs"></i>
                            </a>
                            <ul className="dropdown-menu">
                                {JSON.parse(Cookies.get('permissions')).indexOf("EU") !== -1 ? (
                                    <>
                                        <li>
                                            <NavLink to={`/bundle-preis/${row.id}/edit`} className="dropdown-item">
                                                {t('Edit')}
                                            </NavLink>
                                        </li>
                                        <li><hr className="dropdown-divider" /></li>
                                    </>
                                ) : <></>}
                                {JSON.parse(Cookies.get('permissions')).indexOf("ED") !== -1 ? (
                                    <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'bundle', row.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                                ) : <></>}
                            </ul>
                        </div>
                    ) : <></>}
                </>
            )
        }
    ];

    useEffect(() => {
        callFetch("bundle?page=" + pageNumber, "GET", []).then((res) => {
            setTableData(res.data?.bundle);
        });
    }, [pageNumber, refresh]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {pageItems.map((page) => {
                            const className =
                                page === currentPage ? "page-item active" : "page-item";

                            return (
                                <li key={page} className={className}>
                                    <button
                                        className="page-link"
                                        onClick={handlePageNumber}
                                        value={page}
                                    >
                                        {page}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return <>
        <DataTable
            columns={tableHeadings}
            data={tabledata?.data}
            noDataComponent={t('There are no records to display')}
            className='data-table'
            pagination
            highlightOnHover
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationServer
            paginationTotalRows={tabledata?.total}
            onChangePage={handlePageChange}
            paginationComponent={BootyPagination}
        />

        <ImportModal type={'bundle'} refreshParent={() => setRefresh(refresh + 1)} />

        {renderSuccessSB}
    </>;
}

export default IndexTable 