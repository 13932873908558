import { useMemo, useEffect, useState, useRef } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftSelect from "components/SoftSelect";
import PieChart from "examples/Charts/PieChart";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import SoftBadgeDot from "components/SoftBadgeDot";
import { useTranslation } from 'react-i18next';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

import PagesHeaderCell from "layouts/applications/analytics/components/PagesHeaderCell";
import PagesBodyCell from "layouts/applications/analytics/components/PagesBodyCell";

import SoftAvatar from "components/SoftAvatar";

import callFetch from "../../../helpers/callFetch";
import { useForm } from "react-hook-form";

import { NumericFormat } from 'react-number-format';

import LoadingCard from "components/LoadingCard";
import borders from "assets/theme/base/borders";
import Flatpickr from "react-flatpickr";
import dateFormat from "dateformat";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

function TechnicianSalesStatusPieChart(props) {
    const { borderWidth, borderColor } = borders;
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const fp = useRef(null);
    const [loading, setLoading] = useState(false);
    const [pieChartData, setPieChartData] = useState({});
    const [refresh, setRefresh] = useState(0);
    const [users, setUsers] = useState([]);
    const [data, SetData] = useState([]);
    const [verkaufer, setVerkaufer] = useState();
    const [teams, setTeams] = useState();
    const [allTeams, setAllTeams] = useState([]);
    const [filterData, setFilterData] = useState({
        verkaufer: 0,
        created_at: '',
    });

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleDateChange = (selectedDates) => {
        // Handle selected date range 
        if (Object.keys(selectedDates).length <= 0) {
            setStartDate('');
            setEndDate('');
        } else {
            const [startDate, endDate] = selectedDates;
            setStartDate(dateFormat(startDate, "yyyy-mm-dd"));
            setEndDate(dateFormat(endDate, "yyyy-mm-dd"));
        }

        setRefresh(refresh + 1);
    };

    useEffect(() => {
        setLoading(true);
        if (!filterData?.created_at) return;

        filterData.startDate = startDate;
        filterData.endDate = endDate;

        callFetch("statistics/technician-sales-status-pie-chart", "POST", filterData, setError).then((res) => {
            SetData(res?.data);
            setPieChartData(res?.data?.pie_data);
            setUsers(res?.data?.users);
            setAllTeams(res?.data?.salesTeam);
            setLoading(false);
            if (!res.ok) return;

        });
    }, [refresh]);

    useEffect(() => {
        if (!props?.filterData?.created_at) return;

        if (props?.filterData?.created_at != filterData?.created_at) {
            var data = filterData;

            data.verkaufer = 0;
            data.team = JSON.stringify([]);

            data.created_at = props?.filterData?.created_at;


            setStartDate('')
            setEndDate('')
            setVerkaufer([]);
            setTeams([]);

            setFilterData(data);
            setRefresh(refresh + 1)
        }
    }, [props?.filterData?.created_at]);

    return (
        <>

            <Menu
                anchorEl={props?.technicianSalesFilterMenu}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                open={Boolean(props?.technicianSalesFilterMenu)}
                onClose={props?.TechnicianSalesFiltercloseMenu}
                keepMounted
                PaperProps={{
                    style: {
                        //maxHeight: 120,
                        //width: '20ch',
                        overflow: 'unset'
                    },
                }}
            >
                <MenuItem>
                    <SoftSelect
                        className="mt-1"
                        size="small"
                        placeholder="VKB Name"
                        options={users}
                        value={verkaufer}
                        onChange={(e) => {

                            var data = filterData;

                            var verkaufer_ids = [];
                            for (let [key, value] of Object.entries(e)) {
                                verkaufer_ids.push(value.value)
                            }
                            data.verkaufer = JSON.stringify(verkaufer_ids);

                            setFilterData(data);
                            setVerkaufer(e);
                            setRefresh(refresh + 1)
                        }}
                        isMulti={true}
                    />
                </MenuItem>

                <MenuItem>
                    <SoftSelect
                        className="mt-1"
                        size="small"
                        placeholder="Sales Team"
                        options={allTeams}
                        value={teams}
                        getOptionLabel={(option) => ((option.team_name))}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => {
                            var data = filterData;

                            var team_ids = [];
                            for (let [key, value] of Object.entries(e)) {
                                team_ids.push(value.id)
                            }
                            data.team = JSON.stringify(team_ids);

                            setFilterData(data);
                            setTeams(e);
                            setRefresh(refresh + 1)
                        }}
                        isMulti={true}
                    />
                </MenuItem>
                {props?.title == 'All' ? (
                    <MenuItem>
                        <Flatpickr
                            ref={fp}
                            options={{
                                mode: 'range',
                                dateFormat: 'Y-m-d',
                            }}
                            onChange={handleDateChange}
                            placeholder="Selec Date"
                            style={{
                                height: '32px',
                                color: '#344767',
                                opacity: 0.5,
                                fontSize: '0.75rem',
                                border: '0.0625rem solid #d2d6da'
                            }}
                        />
                    </MenuItem>
                ) : ''}
            </Menu>

            {/*
            <Grid container alignItems="end" display="flex" px={2}>
                <Grid item xs={12} sm={12} lg={12}>
                    <SoftTypography
                        className="mt-3"
                        variant="caption"
                        color="text-dark"
                    >
                        {props?.title == 'Today' || props?.title == 'All' ? '' : <><span style={{ fontWeight: "bold" }}>Date:</span> <span style={{ color: '#718096' }}>Kw12/2 <span style={{ fontWeight: 'bold' }}>06.02.2024- 12.02.2024</span></span></>}

                    </SoftTypography>
                </Grid>
            </Grid>
            */}
            <SoftBox mt={0}>
                <Grid container>
                    <Grid item xs={12} sm={4} lg={4} style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>

                        <SoftTypography
                            fontWeight="bold"
                            variant="h6"
                            color="text-dark"
                            className="total-ammunt"
                            textAlign="center"
                            style={{ position: 'absolute', display: 'block', width: '100%', fontSize: '12px' }}
                        >
                            {'Umsatz in EUR'} <br />
                            <NumericFormat
                                value={data?.total_auftrage_ammount ? data?.total_auftrage_ammount : 0}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale
                                suffix={' € '}
                            />
                        </SoftTypography>
                        <DefaultDoughnutChart height="180px" chart={pieChartData} />
                        {/* {<PieChart
                                    className={"p-0"}
                                    chart={pieChartData}
                                    height="150px"
                                />}  */}
                    </Grid>
                    <Grid item xs={12} sm={8} lg={8} mt={1.6}>
                        <SoftBox px={0}>
                            <SoftTypography variant="caption" style={{ borderBottom: '1px solid #E7EAEE', padding: '0px 0px 12px 25px', display: 'block', marginBottom: '12px', color: '#667085', fontSize: '16px', fontWeight: 'bold' }}>Status</SoftTypography>
                            {pieChartData?.labels ?
                                pieChartData?.labels?.map((status, index) =>
                                    <div className="d-flex mb-1" key={index}>
                                        <div className="d-flex" style={{ width: '60%', textAlign: 'left' }}><SoftBadgeDot style={{ padding: '0px' }} size="lg" className="soft-badge-dot" color={pieChartData?.datasets?.backgroundColors[index]} /> <span className="status-text">{status}</span></div>
                                        <div className="d-flex" style={{ width: '28%', textAlign: 'left' }}><span className="font-size-14-rem">{pieChartData?.datasets?.data[index] + ' Stck.'}</span></div>
                                        <div className="d-flex" style={{ width: '12%', textAlign: 'right' }}>
                                            <span className="font-size-14-rem">
                                                {pieChartData?.datasets?.data[index]
                                                    ? ((pieChartData?.datasets?.data[index] / data?.totalProjects) * 100) === 100
                                                        ? '100 %'
                                                        : ((pieChartData?.datasets?.data[index] / data?.totalProjects) * 100).toFixed(2) + ' %'
                                                    : '0 %'}
                                            </span>
                                        </div>
                                    </div>
                                    // <SoftBox mb={1} key={index}>
                                    //     <SoftBadgeDot
                                    //         className="soft-badge-dot"
                                    //         color={pieChartData?.datasets?.backgroundColors[index]}
                                    //         size="sm"
                                    //         //badgeContent={status+' - '+pieChartData?.datasets?.data[index]}
                                    //         badgeContent={'Note ' + (index + 1) + ' = '}
                                    //         boldBadgeContent={pieChartData?.datasets?.data[index] + ' Stck. | ' + (pieChartData?.datasets?.data[index] ? ((pieChartData?.datasets?.data[index] / data?.totalProjects) * 100).toFixed(2) : 0) + ' %'}
                                    //     />
                                    // </SoftBox>
                                ) : <></>}
                        </SoftBox>
                    </Grid>
                    {/* <Grid item xs={12} mt={1.6}  >
                        <table className="table mt-5">
                            <thead>
                                <tr style={{ backgroundColor: '#F8F9FA', borderRadius: '5px 5px 0px 0px' }}>
                                    <th style={{ padding: '10px', borderBottom: 'none' }}>Instance</th>
                                    <th style={{ padding: '10px', borderBottom: 'none' }}>Value</th>
                                    <th style={{ textAlign: 'right', padding: '10px', borderBottom: 'none' }}>Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Stornoquote Gesamt</td>
                                    <td>$0.00</td>
                                    <td style={{ textAlign: 'right' }}><span style={{ color: '#12B76A' }}>{((data?.stornoquote_gesamt ? data?.stornoquote_gesamt : '---') + '%')}</span></td>
                                </tr>
                                <tr>
                                    <td>Stornoquote nach DC</td>
                                    <td>$0.00</td>
                                    <td style={{ textAlign: 'right' }}><span style={{ color: '#12B76A' }}>{((data?.stornoquote_nach_DC ? data?.stornoquote_nach_DC : '---') + '%')}</span></td>
                                </tr>
                                <tr>
                                    <td>Schreibquote</td>
                                    <td>$0.00</td>
                                    <td style={{ textAlign: 'right' }}><span style={{ color: '#12B76A' }}>{((data?.schreibquote ? data?.schreibquote : '---') + '%')}</span></td>
                                </tr>
                                <tr>
                                    <td>Schreibquote aus 1-4</td>
                                    <td>$0.00</td>
                                    <td style={{ textAlign: 'right' }}><span style={{ color: '#12B76A' }}>{((data?.schreibquote_aus_1_4 ? data?.schreibquote_aus_1_4 : '---') + '%')}</span></td>
                                </tr>
                                <tr>
                                    <td>Aufträge vor Storno</td>
                                    <td>$0.00</td>
                                    <td style={{ textAlign: 'right' }}><span style={{ color: '#12B76A' }}>{(data?.auftrage_vor_storno >= 0 ? data?.auftrage_vor_storno : '---')}</span></td>
                                </tr>
                                <tr>
                                    <td>Aufträge nach Storno</td>
                                    <td>$0.00</td>
                                    <td style={{ textAlign: 'right' }}><span style={{ color: '#12B76A' }}>{(data?.auftrage_nach_storno >= 0 ? data?.auftrage_nach_storno : '---')}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid> */}

                    <Grid item xs={12} mt={1.6}>
                        <SoftBox
                            border={'1px solid #F3F4F6'}
                            borderRadius="8px"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"

                            p={2}
                            my={2}
                        >
                            <SoftBox>
                                <SoftTypography sx={{ fontSize: "0.68rem" }} variant="button" fontWeight="medium" color="secondary">
                                    <SoftTypography fontWeight="bold" fontSize="0.875rem" variant="caption" style={{ color: '#12B76A' }}>{(data?.total_DC_mit_montagetermin >= 0 ? data?.total_DC_mit_montagetermin : '---')}</SoftTypography>
                                    <br />
                                    <SoftTypography variant="caption" style={{ color: '#667085' }} fontWeight="bold" className="status-bottom-text">DC mit Montagetermin</SoftTypography>
                                </SoftTypography>
                                <br />
                                <br />
                                <SoftTypography sx={{ fontSize: "0.68rem" }} variant="button" fontWeight="medium" color="secondary">
                                    <SoftTypography fontWeight="bold" fontSize="0.875rem" variant="caption" style={{ color: '#F04438' }}>{(data?.DCOhneMontagetermin >= 0 ? data?.DCOhneMontagetermin : '---')}</SoftTypography>
                                    <br />
                                    <SoftTypography variant="caption" style={{ color: '#667085' }} fontWeight="bold" className="status-bottom-text">DC ohne Montagetermin</SoftTypography>
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox>
                                <SoftTypography sx={{ fontSize: "0.68rem" }} variant="button" fontWeight="medium" color="secondary">
                                    <SoftTypography fontWeight="bold" fontSize="0.875rem" variant="caption" style={{ color: '#F04438' }}>{(data?.total_AC_mit_montagetermin >= 0 ? data?.total_AC_mit_montagetermin : '---')}</SoftTypography>
                                    <br />
                                    <SoftTypography variant="caption" style={{ color: '#667085' }} fontWeight="bold" className="status-bottom-text">AC mit Montagetermin</SoftTypography>
                                </SoftTypography>
                                <br />
                                <br />
                                <SoftTypography sx={{ fontSize: "0.68rem" }} variant="button" fontWeight="medium" color="secondary">
                                    <SoftTypography fontWeight="bold" fontSize="0.875rem" variant="caption" style={{ color: '#12B76A' }}>{(data?.AC_OhneMontagetermin >= 0 ? data?.AC_OhneMontagetermin : '---')}</SoftTypography>
                                    <br />
                                    <SoftTypography variant="caption" style={{ color: '#667085' }} fontWeight="bold" className="status-bottom-text">AC ohne Montagetermin</SoftTypography>
                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>
                    </Grid>


                    {data && data?.sallerOfTheDay?.data ? data?.sallerOfTheDay?.data.map((sallerOfTheDay, index) => (
                        <Grid item xs={12} mt={1.6} style={{ backgroundColor: '#F8F9FA', borderRadius: '8px', padding: '12px 0px' }}>
                            <SoftTypography
                                fontWeight="bold"
                                variant="h6"
                                color="text-dark"
                                pl={2}
                                pb={2}
                            >
                                Nr. #{index + 1} Verkaufer des Tages
                            </SoftTypography>
                            <SoftBox display="flex" alignItems="center" pl={2}>
                                <SoftBox mr={1}>
                                    <SoftAvatar src={sallerOfTheDay?.verkaufer_photo ? process.env.REACT_APP_STORAGE_URL + sallerOfTheDay?.verkaufer_photo : '/assets/img/placeholder.png'} style={{ height: '35px', width: '35px', padding: '5px', borderRadius: '5px', backgroundColor: '#fff' }} alt={sallerOfTheDay?.verkaufer_name} variant="rounded" />
                                </SoftBox>
                                <SoftBox display="flex" flexDirection="column">
                                    <SoftTypography variant="h6" color="text-dark" fontWeight="medium">
                                        {sallerOfTheDay?.verkaufer_name}
                                    </SoftTypography>
                                    <SoftTypography variant="button" fontWeight="medium" color="secondary" sx={{ fontSize: "0.74rem" }}>
                                        <SoftTypography component="span" variant="button" color="success" sx={{ fontSize: "0.74rem" }}>
                                            {sallerOfTheDay?.total_order}
                                        </SoftTypography>{" "}
                                        Aufträge in Höhe von
                                        &nbsp;
                                        <SoftTypography component="span" variant="button" color="success" sx={{ fontSize: "0.74rem" }}>
                                            <NumericFormat
                                                value={Number(sallerOfTheDay?.total_netto)}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale
                                                suffix={' € '}
                                            />
                                        </SoftTypography>
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                        </Grid>
                    )) : <></>}





                </Grid>
            </SoftBox>
            {/*</>
                    )}*/}
        </>
    )
}

export default TechnicianSalesStatusPieChart;