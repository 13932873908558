import React from "react";
import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import callFetch from "../../helpers/callFetch";
import Cookies from 'js-cookie';
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftDropzone from "components/SoftDropzone";
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import dateFormat from "dateformat";

function Create(props) {
    const { t } = useTranslation();
    const [items, setItems] = useState([{ id: 0, title: '', quantity: 1 }]);
    const [refresh, setRefresh] = useState(0);
    const [data, setData] = useState([]);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reclamation, setReclamation] = useState('Projektbezogene');
    const [showproduct, setShowProduct] = useState('no');
    const [projects, setProjects] = useState([]);
    const [module, setModule] = useState([]);
    const [speicher, setSpeicher] = useState([]);
    const [wechselrichter, setWechselrichter] = useState([]);
    const [startDate, setStartDate] = useState(dateFormat(new Date(), "yyyy-mm-dd"),);
    const [endDate, setEndDate] = useState('');
    const [attachments, setAttachment] = useState([]);
    const [editForm, setEditForm] = useState(false);
    const [selectedProjectId, setSelectedProjectId] = useState({ id: '' });

    const handleProject = (value) => {
        setData([])
        if (value) {
            callFetch("project/timelines/" + value, "GET", []).then((res) => {
                setData(res.data);
            });
        }
    }

    const handleCheckboxChange = (event) => {
        if (event.target.checked === false) {
            setReclamation('Projektbezogene');
        } else {
            setReclamation('Allgemeine');
        }
    };

    const handleCheckboxChangeProduct = (event) => {
        if (event.target.checked === false) {
            setShowProduct('no');
        } else {
            setShowProduct('yes');
        }
    };

    const handleCheckboxChangeProjekt = (event) => {
        if (event.target.checked === false) {
            setReclamation('Allgemeine');
        } else {
            setReclamation('Projektbezogene');
        }
    };

    function deleteProduct() {
        var titems = [];
        items.map((t) => {
            if (!t)
                return;
            titems.push(t);
        });
        setItems(titems);
        setRefresh(refresh + 1);
    }

    const {
        register,
        handleSubmit,
        reset,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    const handeleAttachment = (newData) => {
        let data = attachments;
        data[attachments.length] = newData;
        setAttachment(data);
        setValue('attachments', JSON.stringify(data));
    }

    const removeAttachment = (id) => {
        const beforeRemove = attachments;
        const afterRemove = beforeRemove.filter(value => {
            return value.id != id;
        });

        setAttachment(afterRemove);
    }

    const onSubmit = (formData) => {
        formData.products = JSON.stringify(items);
        formData.is_product_related_complain = (formData.is_product_related_complain ? 1 : 0);

        if (reclamation === 'Allgemeine' || reclamation === '') {
            formData.project_id = 0;
            formData.type = 'Allgemeine';
        }

        setSaving(true);
        callFetch("store-reclamation", "POST", formData, setError).then((res) => {
            setSaving(false);
            setRefresh(refresh + 1);
            props?.setrefresh(refresh + Math.floor(Math.random() * 100));
            setAttachment([]);
            setValue('attachments', '');
            if (!res.ok) return;
            document.getElementById('createReclamationModalClose'+(props?.popupPrefix ? props?.popupPrefix : '')).click();
            clearForm();
        });
    };

    useEffect(() => {
        callFetch("get-artikels-projects-employees", "GET", []).then((res) => {
            setProjects(res.projects);
            setModule(res.module);
            setSpeicher(res.speicher);
            setWechselrichter(res.wechselrichter);
        });
    }, []);

    useEffect(() => {
        if (!props?.projectid) return;

        for (let [key, project] of Object.entries(projects)) {
            if (project?.id == props?.projectid) {
                setValue('project_id', props?.projectid);
                setSelectedProjectId({
                    id: project?.id,
                    vorname: project?.vorname,
                    name: project?.name,
                    telefonnummer: project?.telefonnummer,
                });
                console.log('projectid')
            }
        }

        //setValue('project_id', props?.projectid);
        //setSelectedProjectId({ id: props?.projectid });
        //handleProject(props?.projectid);
    }, [props?.projectid, projects]);

    useEffect(() => {
        if (!props?.ticket?.id) return; 
        setValue('tickets_id', props.ticket.id);
        for (let [key, value] of Object.entries(props?.ticket)) {
            //console.log(key+': '+value);
            if (key == 'id') {
                setValue('ticket_id', (value == null || value == 'null' ? "" : value));
            } else if (key == 'type') {

            } else if (key == 'project_id') {
                setValue('project_nr', value);
                setSelectedProjectId({ id: value });
                //handleProject(value);
            } else if (key == 'user') {

            } else if (key == 'project') {

            } else if (key == 'checklists') {

            } else if (key == 'attachments') {
                setAttachment(JSON.parse(value));
                setValue(key, (value == null || value == 'null' ? "" : value));
            } else {
                setValue(key, (value == null || value == 'null' ? "" : value));
            }
        }



    }, [props?.ticket]);

    const clearForm = () => {

        reset();
        setAttachment([]);
        setItems([{ id: 0, title: '', quantity: 0 }]);
        setShowProduct('no');
        setReclamation('Projektbezogene');

        if (!props?.projectid) {
            setSelectedProjectId({ id: '' });
        } else {
            setValue('project_id', props?.projectid)
        }

        return;

        reset({
            id: 0,
            title: '',
            status: '',
            description: '',
            attachments: JSON.stringify([]),
        });

        setEditForm(false);
        setAttachment([]);
        console.log('Cancel');
    }

    const dropZoneInitialize = () => {

        return (
            <SoftDropzone
                options={{
                    dictDefaultMessage: t('Drop files here to upload'),
                    //addRemoveLinks: true,
                    acceptedFiles: ".jpeg,.jpg,.png,.pdf",
                    action: Cookies.get('api_url') + 'attachment',
                    headers: {
                        "Accept": "application/json",
                        "Authorization": "Bearer " + Cookies.get('token')
                    },
                    processing: function () {
                        document.body.classList.add('loading');
                    },
                    success: (file, response) => {
                        document.body.classList.remove('loading');
                        if (response.message == 'success') {
                            handeleAttachment(response.data);
                        }
                    },
                    maxfilesexceeded: function (file) {
                        this.removeAllFiles();
                        this.addFile(file);
                    },
                    error: function (file, response) {
                        document.body.classList.remove('loading');
                    }
                }}
            />
        )
    }

    return (
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <div class="modal fade" id={"createReclamationModalOpen"+(props?.popupPrefix ? props?.popupPrefix : '')}  tabindex="-1" role="dialog" aria-labelledby={"createReclamationModalOpenLabel"+(props?.popupPrefix ? props?.popupPrefix : '')} aria-hidden="true">
                    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <button
                                    onClick={() => {
                                        clearForm();
                                    }}
                                    type="button" data-bs-dismiss="modal" aria-label="Close" id={"createReclamationModalClose"+(props?.popupPrefix ? props?.popupPrefix : '')} className="close btn btn-icon-only btn-rounded btn-outline-dark mb-0 me-3 btn-sm d-flex align-items-center justify-content-center" style={{ fontSize: '12px', position: 'absolute', right: '0px' }}><i class="fa-sharp fa-solid fa-times icon icon-shape icon-sm  text-center d-flex align-items-center justify-content-center"></i></button>

                                <SoftBox p={2} lineHeight={1}>
                                    <SoftTypography variant="h6" fontWeight="medium">
                                        {t('Create Reclamation')}
                                    </SoftTypography>

                                    <SoftBox mt={3} mb={2}>
                                        <Grid container spacing={3}>

                                            <Grid item xs={6} md={6}>
                                                <Grid container>
                                                    <Grid item xs={2}>
                                                        <SoftBox ml={0.5} mb={0.25}>
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input" {...register("type")} value="Projektbezogene" onChange={handleCheckboxChangeProjekt} checked={reclamation === 'Projektbezogene' ? 'checked' : ''} type="checkbox" />
                                                            </div>
                                                        </SoftBox>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Projektbezogene Reklamation
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftBox pl={0.5} pb={1.5}>
                                                            <SoftTypography
                                                                component="label"
                                                                variant="caption"
                                                                fontWeight="regular"
                                                                color="text"
                                                            >
                                                                Auswahl Projekt
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={6} md={6}>
                                                <Grid container>
                                                    <Grid item xs={2}>
                                                        <SoftBox ml={0.5} mb={0.25}>
                                                            <div className="form-check form-switch">
                                                                <input  {...register("type")} disabled value="Allgemeine" className="form-check-input" onChange={handleCheckboxChange} checked={reclamation === 'Allgemeine' ? 'checked' : ''} type="checkbox" />
                                                            </div>
                                                        </SoftBox>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Allgemeine Reklamation
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftBox pl={0.5} pb={1.5}>
                                                            <SoftTypography
                                                                component="label"
                                                                variant="caption"
                                                                fontWeight="regular"
                                                                color="text"
                                                            >
                                                                Projektnr., Lieferant, Produkt
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </Grid>
                                                </Grid>
                                            </Grid>


                                        </Grid>
                                    </SoftBox>
                                    <SoftBox
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="flex-end"
                                        height="100%"
                                    >

                                        <SoftBox
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="flex-end"
                                            height="100%"
                                        >
                                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Title
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftInput placeholder="Enter title" {...register("title", { required: true, })} />
                                        </SoftBox>

                                    </SoftBox>


                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >
                                                <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Abteilung
                                                    </SoftTypography>
                                                </SoftBox>
                                                <select
                                                    className="form-control"
                                                    {...register("department", {
                                                        required: true
                                                    })}
                                                    required
                                                >
                                                    <option value={''}>{t('Select')}</option>
                                                    {JSON.parse(Cookies.get('permissions')).indexOf("OnlyDacRec") !== -1 ? (
                                                        <option value={'Dachmontage'}>Dachmontage</option>
                                                    ) : <></>}
                                                    {JSON.parse(Cookies.get('permissions')).indexOf("OnlyElkRec") !== -1 ? (
                                                        <option value={'Elektromontage'}>Elektromontage</option>
                                                    ) : <></>}

                                                    {JSON.parse(Cookies.get('permissions')).indexOf("OnlyDacRec") !== -1 || JSON.parse(Cookies.get('permissions')).indexOf("OnlyElkRec") !== -1 ? (
                                                        <></>
                                                    ) : <>
                                                        <option value={'Dachmontage'}>Dachmontage</option>
                                                        <option value={'Elektromontage'}>Elektromontage</option>
                                                        <option value={'Einkauf'}>Einkauf</option>
                                                        <option value={'Buchhaltung'}>Buchhaltung</option>
                                                        <option value={'Vertrieb'}>Vertrieb</option>
                                                        <option value={'Fertigmeldung'}>Fertigmeldung</option>
                                                    </>}
                                                </select>
                                                <div className="invalid-feedback">{errors.department && errors.department.message}</div>
                                            </SoftBox>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                                mt={2}>
                                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        {t('Prioritat')}
                                                    </SoftTypography>
                                                </SoftBox>
                                                <div className="form-group ">
                                                    <select
                                                        className="form-control"
                                                        {...register("priority", {
                                                            required: true,
                                                        })}
                                                        required>
                                                        <option value="">{t('Select')}</option>
                                                        <option value="Urgend">{t('Urgend')}</option>
                                                        <option value="Normal">{t('Normal')}</option>
                                                        <option value="Not Important">{t('Not Important')}</option>
                                                    </select>
                                                    <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                                                </div>
                                            </SoftBox>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                                mt={2}>
                                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Projekt Nr.
                                                    </SoftTypography>
                                                </SoftBox>
                                                <div className="form-group ">
                                                    <Select
                                                        placeholder={t('Select')}
                                                        options={projects ? projects : {}}
                                                        //value={selectedTeams}
                                                        getOptionLabel={(option) => ((option.id ? option.id : '') + (option.vorname ? ' - ' + option.vorname : '') + ' ' + (option?.name ? option?.name : ''))}
                                                        getOptionValue={(option) => option.id}
                                                        value={selectedProjectId}
                                                        onChange={(e) => {
                                                            setValue('project_nr', e.id);
                                                            setSelectedProjectId({
                                                                id: e?.id,
                                                                vorname: e?.vorname,
                                                                name: e?.name,
                                                                telefonnummer: e?.telefonnummer,
                                                            });
                                                        }}
                                                        required
                                                    />
                                                    {selectedProjectId?.id ? (
                                                        <p className="mt-1" style={{ fontSize: '12px', textDecoration: 'underline', fontWeight: '500' }}>{((selectedProjectId?.id ? selectedProjectId?.id : '') + (selectedProjectId?.vorname ? ' - ' + selectedProjectId?.vorname : '') + ' ' + (selectedProjectId?.name ? selectedProjectId?.name : '') + ', ' + (selectedProjectId?.telefonnummer ? selectedProjectId?.telefonnummer : ''))}</p>
                                                    ) : ''}

                                                    {/*
                                                    <select
                                                        className="form-control"
                                                        {...register("project_nr", {
                                                            required: true,
                                                        })}
                                                        onChange={handleProject}>
                                                        <option value="">{t('Select')}</option>
                                                        {projects && projects.map(project => (
                                                            <option value={project.id}>{project.id}</option>
                                                        ))}
                                                    </select>
                                                    */}
                                                    <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                                                </div>
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                    {reclamation === 'Allgemeine' ? (
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <SoftBox
                                                    display="flex"
                                                    flexDirection="column"
                                                    justifyContent="flex-end"
                                                    height="100%"
                                                    mt={2}>
                                                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Partner-/ Lieferanten Nr.
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <div className="form-group ">
                                                        <select
                                                            className="form-control"
                                                            {...register("partner", {
                                                                required: true,
                                                            })}
                                                            required>
                                                            <option value="">{t('Select')}</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                        </select>
                                                        <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                                                    </div>
                                                </SoftBox>
                                            </Grid>
                                        </Grid>
                                    ) : ''}

                                    {reclamation === 'Projektbezogene' ? (
                                        <div className="row mt-3">
                                            <div className="col-sm-12">
                                                <div class="form-check">
                                                    <input class="form-check-input" {...register("is_product_related_complain")} type="checkbox" onClick={handleCheckboxChangeProduct} />
                                                    <label class="custom-control-label m-0 mt-2">Produktbezogene Reklamation</label>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''}

                                    {reclamation === 'Allgemeine' || showproduct === 'yes' ? (
                                        <div className="mt-3">
                                            {items && items.map((item, i) => (
                                                <div key={i} className="">
                                                    {i > 0 && <hr />}
                                                    <div className="row mt-2">
                                                        <div className="col-md-9">
                                                            <div>
                                                                <label>{t('Product')}</label>
                                                                <select value={items[i].id} className="form-control" placeholder={t('Select Product')} onChange={(e) => {
                                                                    items[i].id = e.target.value;
                                                                    items[i].title = e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text;
                                                                    setItems(items);
                                                                    setRefresh(refresh + 1);
                                                                }}>
                                                                    <option value="">--</option>
                                                                    {/*artikels && artikels.map((product, i) => (
                                                                        <option key={i} value={product.id}>{product.artikel_title}</option>
                                                                    ))*/}

                                                                    {module && module.map((modul, i) => (
                                                                        <option key={i} value={'module-' + modul.id}>{modul.name}</option>
                                                                    ))}
                                                                    {speicher && speicher.map((speiche, i) => (
                                                                        <option key={i} value={'speicher-' + speiche.id}>{speiche.name}</option>
                                                                    ))}
                                                                    {wechselrichter && wechselrichter.map((wechselrichte, i) => (
                                                                        <option key={i} value={'wechselrichter-' + wechselrichte.id}>{wechselrichte.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <div>
                                                                <label>{t('Qty/Hrs')}</label>
                                                                <input type="number" style={{ width: '84%' }} className="form-control d-inline" placeholder="eg. 0.00" defaultValue={items[i].quantity} onChange={(e) => {
                                                                    let value = 0;
                                                                    if (e.target.value.length && !isNaN(e.target.value))
                                                                        value = e.target.value;

                                                                    items[i].quantity = parseFloat(value);
                                                                    setItems(items);
                                                                    setRefresh(refresh + 1);
                                                                }} />
                                                                &nbsp;
                                                                <i className="fa-solid fa-circle-xmark text-danger" data-key={i} onClick={() => { delete items[i]; deleteProduct(); }}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                            <div className="row mt-3 ">
                                                <div className="col-md-12">
                                                    <p className="btn btn-dark" style={{ cursor: 'pointer' }} onClick={() => { setItems([...items, { id: 0, title: '', quantity: 0 }]); setRefresh(refresh + 1); }}><i className="fa-solid fa-circle-plus"></i>&nbsp;{t('Add Item')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''}

                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >
                                                <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Start Date
                                                    </SoftTypography>
                                                </SoftBox>
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    {...register("start_date", { required: true })}
                                                    defaultValue={startDate}
                                                />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >
                                                <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        End Date
                                                    </SoftTypography>
                                                </SoftBox>
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    {...register("end_date", { required: true, })}
                                                    defaultValue={endDate}
                                                />
                                            </SoftBox>
                                        </Grid>
                                    </Grid>


                                    <SoftBox
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="flex-end"
                                        height="100%"
                                    >
                                        <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                {t('Description')}
                                            </SoftTypography>
                                        </SoftBox>

                                        <textarea  {...register("description", { required: true, })} className="form-control"></textarea>
                                    </SoftBox>

                                    <div className="col-sm-12 mt-3">
                                        <div className="form-group">
                                            <label htmlFor="reclamation_upload_files" className="text-primary" style={{ cursor: 'pointer' }}><i className="fa-solid fa-link"></i> {t('Upload Files')}</label>
                                            <input
                                                type="file"
                                                onChange={(e) => {
                                                    setLoading(true);
                                                    var formData = {};
                                                    for (let [key, val] of Object.entries(e.target.files)) {
                                                        formData['files[' + key + ']'] = e.target.files[key];
                                                    }

                                                    callFetch('attachment', "POST", formData, setError).then((res) => {
                                                        setLoading(false);
                                                        if (res.message == 'success') {
                                                            let data = attachments;
                                                            for (let [key, val] of Object.entries(res.data)) {
                                                                data[data.length] = val;
                                                            }
                                                            setAttachment(data);
                                                            setValue('attachments', JSON.stringify(data));
                                                        }
                                                    });
                                                }}
                                                id="reclamation_upload_files"
                                                className="d-none"
                                                multiple
                                                accept=".jpeg,.jpg,.png,.pdf,.doc,.docx"
                                            />
                                        </div>
                                    </div>

                                    <SoftBox p={0} className="order-processing">
                                        <Grid container spacing={3}>
                                            {attachments && attachments.map(function (data, i) {
                                                return (
                                                    <Grid item key={i} className="text-center">
                                                        <div>
                                                            <a target="_blank" download href={Cookies.get('backend_url') + 'storage/attachments/' + data?.attachment}>
                                                                {['jpeg', 'jpg', 'png'].indexOf(data?.extension) !== -1 ? (
                                                                    <img src={Cookies.get('backend_url') + 'storage/attachments/' + data?.attachment} />
                                                                ) : (
                                                                    <div className="placeholder">{data?.extension}</div>
                                                                )}

                                                            </a>
                                                        </div>
                                                        <a
                                                            onClick={() => {
                                                                removeAttachment(data?.id);
                                                            }}
                                                        >{t('Remove')}</a>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </SoftBox>

                                    <SoftBox className="d-none">
                                        <SoftBox
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="flex-end"
                                            height="100%"
                                        >
                                            <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                                <SoftTypography mb={2} component="label" variant="caption" fontWeight="bold">
                                                    Attachments
                                                </SoftTypography>

                                                <div className="row mb-2">
                                                    <div className="col-lg-12 mt-lg-0">
                                                        <div className="emb-4">
                                                            {editForm == false ? (
                                                                <>
                                                                    <SoftBox lineHeight={1}>
                                                                        <SoftBox>
                                                                            <SoftBox
                                                                                display="flex"
                                                                                flexDirection="column"
                                                                                justifyContent="flex-end"
                                                                                height="100%"
                                                                            >

                                                                                {/*dropZoneInitialize()*/}

                                                                            </SoftBox>
                                                                        </SoftBox>
                                                                    </SoftBox>
                                                                </>
                                                            ) : <></>}
                                                        </div>
                                                        <br />
                                                    </div>
                                                </div>
                                            </SoftBox>

                                        </SoftBox>
                                    </SoftBox>
                                    <SoftBox display="flex" justifyContent="flex-end" mt={3}>
                                        <SoftBox p={2} display="flex" justifyContent="flex-end">
                                            <a href="#" className="btn btn-danger mx-2"
                                                onClick={() => {
                                                    clearForm();
                                                    document.getElementById('createReclamationModalClose'+(props?.popupPrefix ? props?.popupPrefix : '')).click();
                                                }}
                                            >
                                                {t('Cancel')}
                                            </a>

                                            {!saving && (
                                                <button type="submit" className="btn btn-primary">
                                                    {t('Create Reclamation')}
                                                </button>
                                            )}
                                            {saving && (
                                                <button type="button" className="btn btn-disabled" disabled>
                                                    {t('Creating ...')}
                                                </button>
                                            )}
                                        </SoftBox>
                                    </SoftBox>
                                </SoftBox>

                            </div>
                        </div>
                    </div>
                </div >
            </form >

        </>
    );
}

export default Create;
