import { Cancel } from '@mui/icons-material';
import CropIcon from '@mui/icons-material/Crop';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Icon,
  Slider,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './utils/cropImage';
import SoftButton from 'components/SoftButton';
import SoftBox from 'components/SoftBox';



const CropEasy = ({ photoURL, setOpenCrop, setPhotoURL, setFile, filePhoto }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };


  const cropImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        photoURL,
        croppedAreaPixels,
        rotation
      );
      setPhotoURL(croppedImage);
      setFile(croppedImage);
      setOpenCrop(false);
    } catch (error) {
      
    }

   
  };
  return (
    <>
      <DialogContent
        dividers
        sx={{
          background: '#333',
          position: 'relative',
          height: 400,
          width: 'auto',
          minWidth: { sm: 500 },
        }}
      >
        <Cropper
          image={photoURL}
          crop={crop}
          zoom={zoom}
          cropShape="round"
          showGrid={false}
          rotation={rotation}
          aspect={1}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={cropComplete}
        />
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'column', mx: 3, my: 2 }}>
        <Box sx={{ width: '100%', mb: 1 }}>
          <Box>
            <Typography>Zoom: {zoomPercent(zoom)}</Typography>
            <Slider
              sx={{
                '& .MuiSlider-track': {
                  backgroundImage: 'none', // Remove background image
                  backgroundColor: 'transparent', // Set background color to transparent
                },
              }}
              valueLabelDisplay="auto"
              aria-labelledby="discrete-slider"
              valueLabelFormat={zoomPercent(zoom)}
              min={1}
              max={3}
              step={0.01}
              value={zoom}
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </Box>
          <Box>
            <Typography>Rotation: {rotation + '°'}</Typography>
            <Slider
             sx={{
                '& .MuiSlider-track': {
                  backgroundImage: 'none', // Remove background image
                  backgroundColor: 'transparent', // Set background color to transparent
                },
              }}
              valueLabelDisplay="auto"
              min={0}
              max={360}
              value={rotation}
              onChange={(e, rotation) => setRotation(rotation)}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexWrap: 'wrap',
          }}
        >
          {/* <Button
            variant="outlined"
            startIcon={<Cancel />}
            onClick={() => setOpenCrop(false)}
          >
            Cancel
          </Button>
          <></>
          <Button 
            variant="contained"
            startIcon={<CropIcon className='me-2' />}
            onClick={cropImage}
          >
            Crop
          </Button> */}
         <SoftBox display="flex" justifyContent="flex-end" mb={0} ml={2}>
          <SoftBox mr={3}>
            <button type='button' onClick={() => setOpenCrop(false)} className="btn btn-icon btn-outline-danger">
              <Icon>cancel</Icon>&nbsp;&nbsp;
              Cancel
            </button>
          </SoftBox>
          <button type='button' onClick={cropImage} className="btn btn-icon btn-primary" >
            <Icon>crop</Icon>&nbsp;
            Crop
          </button>
        </SoftBox>
        </Box>
      </DialogActions>
    </>
  );
};

export default CropEasy;

const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};
