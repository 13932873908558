import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import SoftBox from "components/SoftBox";
import { Grid } from "@mui/material";
import Sidenav from './components/Sidenav';
import BasicInfo from './components/BasicInfo';
import DeviceManagment from './components/DeviceManagemt/DeviceManagment';
import callFetch from 'helpers/callFetch';

const WireHouseAdd = () => {
    const { t } = useTranslation();
    const params = useParams();
    const [refresh, setRefresh] = useState(0);
    const location = useLocation()
    const [componentShwo, setComponentShwo] = useState("Lagerdaten")
    const [lager, setLager] = useState({});
    useEffect(()=>{
      if(params?.id){
        callFetch("lager/"+params?.id+"/edit", "GET", []).then((res)=>{
          setLager(res?.data)
        })
      }
    },[refresh,params?.id])
    const handleRefresh = () =>{
        setRefresh(refresh +1)
    }
    const componentSwitch = (value) => { 
        setComponentShwo (value)
    }
  return (
    <SoftBox mt={4}> 
    <Grid container spacing={3}>
      <Grid item xs={12} lg={3}>
        <Sidenav switchComponent={componentSwitch} />
      </Grid>
      <Grid item xs={12} lg={9}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12}>
              <Header imageUrl={selectedImageUrl} data={showData} />
            </Grid> */}
            <Grid item xs={12}>
              { 
                location?.hash === "#lagerdaten" && <BasicInfo />
              }

              {
                !location?.hash && componentShwo === "Lagerdaten" && <BasicInfo title={componentShwo} />
              }         
              {
                location?.hash === "#lagerbereiche" && <DeviceManagment lager={lager} refreshData={handleRefresh} title={componentShwo}/>
              }
              
            </Grid>
          </Grid>
        </SoftBox>
      </Grid>
    </Grid>
  </SoftBox>
  )
}

export default WireHouseAdd