import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";

function IndexTable(props) {
    const { t } = useTranslation();
    const [teams, setTeams] = useState([]);
    const [roles, setRoles] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [searchKey, setSearchKey] = useState("");
    const [searchData, setSearchData] = useState([]);
    const [filterData, setFilterData] = useState([]);

    const avatarStyles = {
        border: ({ borders: { borderWidth }, palette: { white } }) =>
            `${borderWidth[2]} solid ${white.main}`,
        cursor: "pointer",
        position: "relative",
        ml: -1.5,

        "&:hover, &:focus": {
            zIndex: "10",
        },
    };

    const tableHeadings = [
        {
            name: t('Team ID'),
            width: '180px',
            sortable: true,
            selector: row => <NavLink to={'/teams/' + row.id + '/edit'} className={"text-primary font-weight-bold"}>{row.team_id}</NavLink>
        },
        {
            name: t('Team Name'),
            width: '180px',
            sortable: true,
            selector: row => <NavLink to={'/teams/' + row.id + '/edit'}>{row.team_name}</NavLink>
        },
        {
            name: t('Technical Manager'),
            sortable: true,
            cell: row => (
                <SoftBox display="flex" alignItems="center">
                    <SoftBox mt={0.5} pl={1}>
                        <SoftBox className="team-member-avator" display="flex">
                            {row?.head ? row?.head?.map((item) => (
                                <Tooltip title={item?.user?.name} placement="top">
                                    <SoftAvatar src={item?.user?.photo ? process.env.REACT_APP_STORAGE_URL + item?.user?.photo : '/assets/img/placeholder.png'} alt="" size="sm" sx={avatarStyles} style={{ height: 'auto !important' }} />
                                </Tooltip>
                            )) : ''}
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            )
        },
        {
            name: t('Team Manager'),
            sortable: true,
            cell: row => (
                <SoftBox display="flex" alignItems="center">
                    <SoftBox mt={0.5} pl={1}>
                        <SoftBox className="team-member-avator" display="flex">
                            {row?.manager ? row?.manager?.map((item) => (
                                <Tooltip title={item?.user?.name} placement="top">
                                    <SoftAvatar src={item?.user?.photo ? process.env.REACT_APP_STORAGE_URL + item?.user?.photo : '/assets/img/placeholder.png'} alt="" size="sm" sx={avatarStyles} style={{ height: 'auto !important' }} />
                                </Tooltip>
                            )) : ''}
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            )
        },
        {
            name: t('Team Members'),
            sortable: true,
            cell: row => (
                <SoftBox display="flex" alignItems="center">
                    <SoftBox mt={0.5} pl={1}>
                        <SoftBox className="team-member-avator" display="flex">
                            {row?.teammember ? row?.teammember?.map((item) => (
                                <Tooltip title={item?.employe?.name} placement="top">
                                    <SoftAvatar src={item?.employe?.photo ? process.env.REACT_APP_STORAGE_URL + item?.employe?.photo : '/assets/img/placeholder.png'} alt="" size="sm" sx={avatarStyles} style={{ height: 'auto !important' }} />
                                </Tooltip>
                            )) : ''}
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            )
        },
        {
            name: t('Actions'),
            sortable: true,
            width: '120px',
            cell: row => (
                <>
                    {JSON.parse(Cookies.get('permissions')).indexOf("EU") !== -1 ||
                        JSON.parse(Cookies.get('permissions')).indexOf("ED") !== -1 ? (
                        <div className="text-center dropstart">
                            <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v text-xs"></i>
                            </a>
                            <ul className="dropdown-menu">
                                {JSON.parse(Cookies.get('permissions')).indexOf("EU") !== -1 ? (
                                    <li>
                                        <NavLink to={'/teams/' + row.id + '/edit'} className="dropdown-item">
                                            {t('Edit')}
                                        </NavLink>
                                    </li>
                                ) : <></>}
                                <li><hr className="dropdown-divider" /></li>
                                {JSON.parse(Cookies.get('permissions')).indexOf("ED") !== -1 ? (
                                    <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'teams/delete', row.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                                ) : <></>}
                            </ul>
                        </div>
                    ) : <></>}
                </>
            )
        }
    ];

    useEffect(() => {
        callFetch("teams/" + props.type + "?page=" + pageNumber + '&search=' + searchKey, "GET", []).then((res) => {
            setTeams(res.data);
            setFilterData(res.data.data);
        });
    }, [pageNumber, props.type, refresh, searchKey]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = teams.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === teams.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {teams.from} {t('to')} {teams.to} {t('of')} {teams.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {pageItems.map((page) => {
                            const className =
                                page === currentPage ? "page-item active" : "page-item";

                            return (
                                <li key={page} className={className}>
                                    <button
                                        className="page-link"
                                        onClick={handlePageNumber}
                                        value={page}
                                    >
                                        {page}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return <>
        <DataTable
            columns={tableHeadings}
            data={filterData}
            noDataComponent={t('There are no records to display')}
            className='data-table'
            pagination
            highlightOnHover
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationServer
            paginationTotalRows={teams.total}
            onChangePage={handlePageChange}
            paginationComponent={BootyPagination}
            subHeader
            subHeaderComponent={<input type="text" className=' form-control w-sm-50 w-md-25 w-lg-15' value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />}
        />

    </>
}

export default IndexTable;
