import { React, useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import OperationalPlan from "./OperationalPlan";
import OrdersToPlan from "./OrdersToPlan";

function Index() {
  const [refresh, setRefresh] = useState(0);
  const [eventTechnicianLocation, setEventTechnicianLocation] = useState({});

  const fetchRefresh = (refresh) => {
    console.log('fetchRefresh')
    console.log(refresh)
    setRefresh(refresh);
  }

  const EventTechnicianLocationSet = (data) => {
    setEventTechnicianLocation(data);
  }

  return (
    <>
      <Grid container spacing={2} py={0}>
        <Grid item xs={12}>
          <OperationalPlan refresh={refresh} setEventTechnicianLocation={EventTechnicianLocationSet}/>
        </Grid>
      </Grid>

      <OrdersToPlan setRefresh={fetchRefresh} eventTechnicianLocation={eventTechnicianLocation}/>
    </>
  );
}

export default Index;
