import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import SpeicherTypeModal from '../speicher/TypeModal';
import { jsonGroupData } from "pages/Article/data";

function Create() {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [product_type, setProduct_type] = useState('module');
    const [lieferanten, setlieferanten] = useState([]);
    const [refresh2, setRefresh2] = useState(0);
    const [speicherTypes, setSpeicherTypes] = useState([]);


    const [selectedFile, setSelectedFile] = useState(null);

    const [category, setCategory] = useState('');
    const [articalCategory, setArticalCategory] = useState('');
    const subcategories =
        jsonGroupData?.categories
            .find((category) => category.name === articalCategory)
            ?.group || [];

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        setValue,
        formState: { errors },
    } = useForm();

    const Product_type = (e) => {
        const value = e.target.value;
        if (value != 'module') {
            setValue('description', '')
        }

        setProduct_type(value);
    }

    useEffect(() => {
        callFetch(product_type + "/create", "GET", [], setError).then((res) => {
            if (!res.ok) return;

            if (res?.data?.identity) {
                setValue('identity', res?.data?.identity);
            }

            setlieferanten(res?.data?.lieferanten)
        });
    }, [product_type]);

    useEffect(() => {
        callFetch("speicherTypes", "GET", []).then((res) => {
            setSpeicherTypes(res?.data);
        });
    }, [refresh2])

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            clearErrors('file');
        }
        e.target.value = '';
    }

    const handleFileRemove = () => {
        setSelectedFile(null)
    }

    const onSubmit = (formData) => {
        if (product_type == 'module') {
            formData.description = JSON.stringify(formData.description);
        }

        if (product_type == 'product') {
            formData.status = (formData?.status ? 1 : 0);
        }

        if (selectedFile) {
            formData.file = selectedFile;
        }

        setSaving(true);
        callFetch(product_type, "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to={product_type == 'product' ? '/zusatzprodukte' : ('/products/' +  product_type)} /> :
        <>
            <div className="row">
                <div className="col-md-9">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t((
                                product_type == 'product' ? 'Zusatzprodukte'
                                    : product_type == 'wechselrichter' ? "Create Wechselrichter"
                                        : product_type == 'module' ? "Create Module"
                                            : product_type == 'speicher' ? "Create Speicher"
                                                : "Create Product"
                            ))}</h6>
                        </div>
                        <div className="card-body">
                            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                <div className="row g-3">
                                    <div className="col-md-4">
                                        <label>{t((
                                            product_type == 'product' ? 'Produktnummber'
                                                : product_type == 'wechselrichter' ? "Nummber"
                                                    : product_type == 'module' ? "Modulnr."
                                                        : product_type == 'speicher' ? "Speichernr."
                                                            : "Nummber."
                                        ))} *</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("identity", {
                                                required: true,
                                            })}
                                            required
                                            readOnly
                                        />
                                        <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                    </div>

                                    <div className="col-md-4">
                                        <label>{t('Product Type')} *</label>
                                        <select className="form-control mb-4" name="product_type" onChange={Product_type} required>
                                            <option value="module">{t('Module')}</option>
                                            <option value="speicher">{t('Speicher')}</option>
                                            <option value="wechselrichter">{t('Wechselrichter')}</option>
                                            <option value="product">{t('Zusatzprodukte')}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.product_type && errors.product_type.message}</div>
                                    </div>
                                </div>

                                <div className="row g-3">
                                    <div className="col-md-4">
                                        <label>{t('Hersteller / Lieferanten')} *</label>
                                        <select
                                            className="form-control mb-4"
                                            {...register("lieferanten_id", {
                                                required: true,
                                            })}
                                            required
                                        >
                                            <option value={''}>{t("Select Lieferanten")}</option>
                                            {lieferanten.map((lief) => (
                                                <option value={lief?.id}>{lief?.lieferanten_nummer + (lief?.firma_name ? ' - ' + lief?.firma_name : '')}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">{errors.lieferanten_id && errors.lieferanten_id.message}</div>
                                    </div>

                                    {product_type == 'module' ? (
                                        <div className="col-md-4">
                                            <label>{t('Markenhersteller')} *</label>
                                            <input
                                                type="text"
                                                className="form-control mb-4"
                                                placeholder={t('')}
                                                {...register("description[Markenhersteller]", {
                                                    required: true,
                                                })}
                                                required
                                            />
                                        </div>
                                    ) : <></>}

                                    {product_type == 'product' ? (
                                        <div className="col-md-4" style={{ paddingTop: '14px' }}>
                                            <div className="form-check pt-4">
                                                <label>{t('Active')}</label>
                                                <input className="form-check-input" defaultChecked type="checkbox" {...register("status")} />
                                            </div>
                                            <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                                        </div>
                                    ) : <></>}
                                </div>

                                <div className="row g-3">
                                    <div className={
                                        product_type == 'wechselrichter' ? "col-md-6"
                                            : product_type == 'module' ? "col-md-6"
                                                : product_type == 'speicher' ? "col-md-9"
                                                    : "col-md-12"
                                    }
                                    >
                                        <label>{t((
                                            product_type == 'product' ? 'Produktname'
                                                : product_type == 'wechselrichter' ? "Title"
                                                    : product_type == 'module' ? "Modulbezeichnung"
                                                        : product_type == 'speicher' ? "Speicher Bezeichnung"
                                                            : "Title"
                                        ))} *</label>
                                        <input
                                            type="text"
                                            className={"form-control mb-4"}
                                            placeholder={t('')}
                                            {...register("name", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                    </div>

                                    {product_type == 'module' ? (
                                        <>
                                            <div className="col-md-3">
                                                <label>{t('Short Title')} *</label>
                                                <input
                                                    type="text"
                                                    className="form-control mb-4"
                                                    placeholder={t('')}
                                                    {...register("short_itle", {
                                                        required: true,
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.short_itle && errors.short_itle.message}</div>
                                            </div>

                                            <div className="col-md-3">
                                                <label>{t('Typ')} *</label>
                                                <input
                                                    type="text"
                                                    className="form-control mb-4"
                                                    placeholder={t('')}
                                                    {...register("description[Typ]", {
                                                        required: true,
                                                    })}
                                                    required
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label>{t('Watt')} *</label>
                                                <input
                                                    type="number"
                                                    className="form-control mb-4"
                                                    placeholder={t('')}
                                                    {...register("watt", {
                                                        required: true,
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.watt && errors.watt.message}</div>
                                            </div>

                                            <div className="col-md-3">
                                                <label>{t('Zelltyp')} *</label>
                                                <input
                                                    type="text"
                                                    className="form-control mb-4"
                                                    placeholder={t('')}
                                                    {...register("description[Zelltyp]", {
                                                        required: true,
                                                    })}
                                                    required
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label>{t('Modulabmessung') + ' (' + t('Width') + ')'} *</label>
                                                <input
                                                    type="number"
                                                    step={0.01}
                                                    className="form-control mb-4"
                                                    placeholder={t('')}
                                                    {...register("description[Modulabmessung_width]", {
                                                        required: true,
                                                    })}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label>{t('Modulabmessung') + ' (' + t('Height') + ')'} *</label>
                                                <input
                                                    type="number"
                                                    step={0.01}
                                                    className="form-control mb-4"
                                                    placeholder={t('')}
                                                    {...register("description[Modulabmessung_height]", {
                                                        required: true,
                                                    })}
                                                    required
                                                />
                                            </div>


                                            <div className="col-md-12">
                                                <label>{t('Description')}</label>
                                                <textarea
                                                    className="form-control"
                                                    rows="4"
                                                    placeholder={t('')}
                                                    {...register("description[description]", {
                                                        required: false,
                                                    })}
                                                ></textarea>
                                            </div>
                                        </>
                                    ) : <></>}

                                    {product_type == 'speicher' ? (
                                        <>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>
                                                        {t('Typ')} *
                                                    </label>
                                                    <br />
                                                    <select
                                                        className="form-control"
                                                        {...register("type", {
                                                            required: true,
                                                        })}
                                                        style={{ float: 'left', width: '65%' }}
                                                        required
                                                    //onChange={handleInputChange}
                                                    >
                                                        <option value="">--</option>
                                                        {speicherTypes && speicherTypes.map((speicherType) => (
                                                            <option key={speicherType.id} value={speicherType.id}>{speicherType.name}</option>
                                                        ))}
                                                    </select>
                                                    &nbsp;
                                                    <button className="btn btn-outline-secondary" type="button" data-bs-toggle="modal" data-bs-target="#speicherTypeModal" style={{ padding: '11px 25px', width: '32%', float: 'inline-end' }}>{t('Typ Hinzufügen')}</button>
                                                    <div className="invalid-feedback">{errors.type && errors.type.message}</div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <label>{t('Watt')} *</label>
                                                <input
                                                    type="number"
                                                    className="form-control mb-4"
                                                    placeholder={t('')}
                                                    {...register("watt", {
                                                        required: true,
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.watt && errors.watt.message}</div>
                                            </div>

                                            <div className="col-md-12">
                                                <label>{t('Description')}</label>
                                                <textarea
                                                    className="form-control"
                                                    rows="4"
                                                    placeholder={t('')}
                                                    {...register("description")}></textarea>
                                                <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-check">
                                                    <label>{t('Ohne Energiespeicher')}</label>
                                                    <input className="form-check-input" type="checkbox" value={"Yes"} {...register("without_energiespeichersystem")} />
                                                </div>
                                                <div className="invalid-feedback">{errors.without_energiespeichersystem && errors.without_energiespeichersystem.message}</div>
                                            </div>
                                        </>
                                    ) : <></>}

                                    {product_type == 'wechselrichter' ? (
                                        <>
                                            <div className="col-md-6">
                                                <label>{t('Angebotstitel')} *</label>
                                                <input
                                                    type="text"
                                                    className="form-control mb-4"
                                                    placeholder={t('')}
                                                    {...register("angebotstitel", {
                                                        required: true,
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.angebotstitel && errors.angebotstitel.message}</div>
                                            </div>

                                            <div className="col-md-12">
                                                <label>{t('Description')}</label>
                                                <textarea
                                                    className="form-control"
                                                    rows="4"
                                                    placeholder={t('')}
                                                    {...register("description")}></textarea>
                                                <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                            </div>
                                        </>
                                    ) : <></>}

                                    {product_type == 'product' ? (
                                        <>
                                            <div className="col-md-3">
                                                <label>{t('DC- oder AC Produkt')} *</label>
                                                <select
                                                    className="form-control"
                                                    {...register("category", {
                                                        required: true,
                                                        onChange: (e) => {
                                                            setCategory(e.target.value);

                                                            if (e.target.value === 'Dienstleistung') {
                                                                setValue('article_category', '');
                                                                setArticalCategory('');
                                                            }
                                                        }
                                                    })}
                                                    required
                                                >
                                                    <option value="">{t('auswählen')}</option>
                                                    <option value="ac">{t('AC Produkt')}</option>
                                                    <option value="dc">{t('DC Produkt')}</option>
                                                    <option value="Dienstleistung">{t('Dienstleistung')}</option>
                                                </select>
                                                <div className="invalid-feedback">{errors.category && errors.category.message}</div>
                                            </div>

                                            {category !== 'Dienstleistung' ? (
                                                <>
                                                    <div className="col-md-3">
                                                        <label>{t('Kategorie')}</label>
                                                        <select
                                                            className="form-control"
                                                            {...register('article_category', {
                                                                required: false,
                                                                onChange: (e) => {
                                                                    setArticalCategory(e.target.value);
                                                                }
                                                            })}
                                                            required={false}
                                                        >
                                                            <option value="">{t('----')}</option>
                                                            {
                                                                jsonGroupData && jsonGroupData?.categories?.map((category, index) => (
                                                                    <option key={index} value={category?.name}>{t(category?.name)}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <div className="invalid-feedback">{errors.article_category && errors.article_category.message}</div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <label>{t('Gruppe')}</label>
                                                        <select className="form-control"
                                                            {...register('article_group', {
                                                                required: false,
                                                            })}
                                                            required={false}
                                                        >
                                                            <option value="">{t("----")}</option>
                                                            {
                                                                subcategories && subcategories?.map((group, index) => (
                                                                    <option key={index} value={group}>{t(group)}</option>
                                                                ))
                                                            }

                                                        </select>
                                                        <div className="invalid-feedback">{errors.article_group && errors.article_group.message}</div>
                                                    </div>
                                                </>
                                            ) : ''}



                                            <div className="col-md-3">
                                                <label>{t('Einheit')} *</label>
                                                <select
                                                    className="form-control"
                                                    {...register('unit', { required: true })}
                                                    required
                                                >
                                                    <option value="">{t("----")}</option>
                                                    <option value="Meter">{t("Meter")}</option>
                                                    <option value="Stück">{t("Stück")}</option>
                                                    <option value="Kg">{t("Kg")}</option>
                                                    <option value="Liter">{t("Liter")}</option>
                                                    <option value="m2">{t("m2")}</option>
                                                    <option value="%">%</option>
                                                    <option value="watt">Watt (W)</option>

                                                </select>
                                                <div className="invalid-feedback">{errors.unit && errors.unit.message}</div>
                                            </div>

                                            <div className="col-12 p-0 m-0"></div>

                                            <div className="col-md-3">
                                                <label>{t('Einkaufspreis in €')} *</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    step={0.01}
                                                    placeholder={t('00,00 €')}
                                                    {...register("einkaufs_price", {
                                                        required: true,
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.einkaufs_price && errors.einkaufs_price.message}</div>
                                            </div>
                                            <div className="col-md-3">
                                                <label>{t('Verkaufspreis in €')} *</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    step={0.01}
                                                    placeholder={t('00,00 €')}
                                                    {...register("verkauf_price", {
                                                        required: true,
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.verkauf_price && errors.verkauf_price.message}</div>
                                            </div>


                                            <div className="col-md-12">
                                                <label>{t('Description')}</label>
                                                <textarea
                                                    className="form-control"
                                                    rows="4"
                                                    placeholder={t('')}
                                                    {...register("description")}></textarea>
                                                <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                            </div>
                                        </>
                                    ) : <></>}

                                    <div className="col-12 mb-4">
                                        {!saving && (
                                            <button type="submit" className="btn btn-primary">
                                                {t('Save')}
                                            </button>
                                        )}
                                        {saving && (
                                            <button type="submit" className="btn btn-disabled" disabled>
                                                {t('Saving ...')}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >

                <div className="col-3">

                    {/*
                        product_type == 'product' ? <ProductFileUpload id={id} file={false} />
                            : product_type == 'wechselrichter' ? <WechselrchterFileUpload id={id} file={false} />
                                : product_type == 'module' ? <ModuleFileUploadCreate id={id} />
                                    : product_type == 'speicher' ? <SpeicherFileUploadCreate id={id} />
                                        : ""

                    */}

                    <div className="card">
                        <div className="card-header">
                            <label>{t('Datenblätter etc.')}</label>
                            <input
                                type="file"
                                className="form-control"
                                onChange={(e) => { handleFileChange(e); }}
                            />
                            <div className="invalid-feedback d-block">{errors.file && errors.file.message}</div>
                        </div>
                        <div className="card-body pt-0 position-relative">
                            {selectedFile?.type.startsWith('image/') ? (
                                <>
                                    <img
                                        src={selectedFile && URL.createObjectURL(selectedFile)}
                                        alt="Selected"
                                        width="100%"
                                        height="100%"
                                        className="border-radius-md w-100 h-100"
                                    />
                                    &nbsp;
                                    <i style={{ top: "-5px" }} className="fa-solid fa-circle-xmark text-danger cursor-pointer position-absolute end-4" onClick={handleFileRemove}></i>
                                </>
                            ) : selectedFile?.type.startsWith('application/') ? (
                                <>
                                    <iframe
                                        src={URL.createObjectURL(selectedFile)}
                                        title="PDF Viewer"
                                        width="100%"
                                        height="350px" // Adjust the height as needed
                                        className="border-radius-md"
                                    ></iframe>
                                    &nbsp;
                                    <i style={{ top: "-5px" }} className="fa-solid fa-circle-xmark text-danger cursor-pointer position-absolute end-4" onClick={handleFileRemove}></i>
                                </>
                            ) : <></>}
                        </div>
                    </div>

                </div>
            </div>

            <SpeicherTypeModal refreshParent={() => setRefresh2(refresh2 + 1)} />
        </>;
}

export default Create;