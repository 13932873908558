import React from "react";
import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../helpers/callFetch";
import Cookies from 'js-cookie';
// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftDropzone from "components/SoftDropzone";
import { ToastContainer, toast } from 'react-toastify';

function Edit(props) {
    const { t } = useTranslation();
    const [items, setItems] = useState([{ id: 0, title: '', quantity: 0 }]);
    const [refresh, setRefresh] = useState(0);
    const [data, setData] = useState([]);
    const [saving, setSaving] = useState(false);
    const [reclamation, setReclamation] = useState('Allgemeine');
    const [reclamationData, setReclamationData] = useState([]);
    const [showproduct, setShowProduct] = useState('now');
    const [projects, setProjects] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [artikels, setArtikels] = useState([]);
    const [module, setModule] = useState([]);
    const [speicher, setSpeicher] = useState([]);
    const [wechselrichter, setWechselrichter] = useState([]);
    const [projectId, setProjectId] = useState(0);

    const {
        register,
        handleSubmit,
        reset,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [editorValue, setEditorValue] = useState(
        "<p>Hello World!</p><p>Some initial <strong>bold</strong> text</p><br><br>"
    );

    const notify = () => toast("Success");
    const user = JSON.parse(Cookies.get('user'));
    const submitBtn = useRef();
    const [attachments, setAttachment] = useState([]);
    const [newDropzone, setNewDropzone] = useState(true);
    const [editForm, setEditForm] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);

    const handleProject = (event) => {
        setData([])
        if (event.target.value) {
            setProjectId(event.target.value);
        }
    }

    useEffect(() => {
        if (!projectId) return;

        callFetch("project/timelines/" + projectId, "GET", []).then((res) => {
            setData(res.data);
        });
    }, [projectId]);


    const handleCheckboxChange = (event) => {
        if (event.target.checked === false) {
            setReclamation('Projektbezogene');
        } else {
            setReclamation('Allgemeine');
        }
    };

    const handleCheckboxChangeProduct = (event) => {
        if (event.target.checked === false) {
            setShowProduct('no');
        } else {
            setShowProduct('yes');
        }
    };

    const handleCheckboxChangeProjekt = (event) => {
        if (event.target.checked === false) {
            setReclamation('Allgemeine');
        } else {
            setReclamation('Projektbezogene');
        }
    };

    const removeAttachment = (id) => {
        const beforeRemove = attachments;
        const afterRemove = beforeRemove.filter(value => {
            return value.id != id;
        });

        setAttachment(afterRemove);
    }


    function deleteProduct() {
        var titems = [];
        items.map((t) => {
            if (!t)
                return;
            titems.push(t);
        });
        setItems(titems);
        setRefresh(refresh + 1);
    }

    const handeleAttachment = (newData) => {
        let data = attachments;
        data[attachments.length] = newData;
        setAttachment(data);
        setValue('attachments', JSON.stringify(data));
    }


    const onSubmit = (formData) => {
        formData.products = JSON.stringify(items);
        formData.attachments = JSON.stringify(attachments);
        formData.is_product_related_complain = (formData.is_product_related_complain ? 1 : 0);

        setSaving(true);
        callFetch("reclamation/update/" + props.id, "POST", formData, setError).then((res) => {
            setSaving(false);
            setRefresh(refresh + 1);
            props.refresh((refresh + 1) * Math.floor(Math.random() * 100));
            document.getElementById('closeEditReclamation').click();


            /*
            
            
            setAttachment([]);
            setValue('attachments', '');
            if (!res.ok) return;
            setSubmitSuccess(true);
            clearForm();
            notify();
            */
        });
    };

    useEffect(() => {
        callFetch("get-artikels-projects-employees", "GET", []).then((res) => {
            //setProjects(res.projects);
            setEmployees(res.employees);
            setArtikels(res.artikels);

            setModule(res.module);
            setSpeicher(res.speicher);
            setWechselrichter(res.wechselrichter);
        });
    }, []);


    useEffect(() => {
        if (!props.id) return;

        callFetch("edit-reclamation/" + props.id, "GET", []).then((res) => {
            setReclamationData(res.data);
            setProjectId(res?.data?.project_nr);
            setProjects([res?.data?.project]);
            //console.log(res.data)
        });
    }, [props.id]);

    useEffect(() => {
        if (!reclamationData?.project_nr) return;

        for (let [key, value] of Object.entries(reclamationData)) {

            if (key == 'dc_freigeben') {

            } else if (key == 'status') {

            } else if (key == 'attachments') {
                if (value) {
                    setAttachment(JSON.parse(value));
                }
            } else if (key == 'products') {
                setItems(JSON.parse(value));
            } else if (key == 'is_product_related_complain') {

                setValue(key, (value == null || value == 'null' ? "" : value));

                if (value == 1) {
                    setShowProduct('yes');
                } else {
                    setShowProduct('no');
                }

            } else if (key == 'type') {
                if (value == 'Allgemeine') {
                    setReclamation('Allgemeine');
                } else {
                    setReclamation('Projektbezogene');
                }
            } else {
                setValue(key, (value == null || value == 'null' ? "" : value));
            }
        }
    }, [reclamationData, projects, projectId]);


    const clearForm = () => {
        reset({
            id: 0,
            title: '',
            status: '',
            description: '',
            attachments: JSON.stringify([]),
        });

        setNewDropzone(true);
        setEditForm(false);
        setAttachment([]);
        console.log('Cancel');
    }

    const dropZoneInitialize = (name = 'dachansicht') => {

        return (
            <SoftDropzone
                key={'dfdf'}
                options={{
                    dictDefaultMessage: t('Drop files here to upload'),
                    addRemoveLinks: true,
                    acceptedFiles: ".jpeg,.jpg,.png,.pdf",
                    action: Cookies.get('api_url') + 'attachment',
                    headers: {
                        "Accept": "application/json",
                        "Authorization": "Bearer " + Cookies.get('token')
                    },
                    dictCancelUpload: (e) => {
                        console.log('dictCancelUpload')

                    },
                    processing: function () {
                        document.body.classList.add('loading');
                    },
                    success: (file, response) => {
                        document.body.classList.remove('loading');
                        if (response.message == 'success') {
                            handeleAttachment(response.data);
                        }
                    },
                    maxfilesexceeded: function (file) {
                        this.removeAllFiles();
                        this.addFile(file);
                    },
                    error: function (file, response) {
                        document.body.classList.remove('loading');
                    }
                }}
            />
        )
    }

    return (
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <div class="modal fade" id="exampleModalEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <button type="button" id="closeEditReclamation" data-bs-dismiss="modal" aria-label="Close" className="close btn btn-icon-only btn-rounded btn-outline-dark mb-0 me-3 btn-sm d-flex align-items-center justify-content-center" style={{ fontSize: '12px', position: 'absolute', right: '0px' }}><i class="fa-sharp fa-solid fa-times icon icon-shape icon-sm  text-center d-flex align-items-center justify-content-center"></i></button>

                                <SoftBox p={2} lineHeight={1}>
                                    <SoftTypography variant="h6" fontWeight="medium">
                                        Edit Reclamation
                                    </SoftTypography>


                                    <SoftBox mt={3} mb={2}>
                                        <Grid container spacing={3}>

                                            <Grid item xs={6} md={6}>
                                                <Grid container>
                                                    <Grid item xs={2}>
                                                        <SoftBox ml={0.5} mb={0.25}>
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input" {...register("type")} value="Projektbezogene" onChange={handleCheckboxChangeProjekt} checked={reclamation === 'Projektbezogene' ? 'checked' : ''} type="checkbox" />
                                                            </div>
                                                        </SoftBox>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Projektbezogene Reklamation
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftBox pl={0.5} pb={1.5}>
                                                            <SoftTypography
                                                                component="label"
                                                                variant="caption"
                                                                fontWeight="regular"
                                                                color="text"
                                                            >
                                                                Auswahl Projekt
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={6} md={6}>
                                                <Grid container>
                                                    <Grid item xs={2}>
                                                        <SoftBox ml={0.5} mb={0.25}>
                                                            <div className="form-check form-switch">
                                                                <input  {...register("type")} disabled value="Allgemeine" className="form-check-input" onChange={handleCheckboxChange} checked={reclamation === 'Allgemeine' ? 'checked' : ''} type="checkbox" />
                                                            </div>
                                                        </SoftBox>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Allgemeine Reklamation
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftBox pl={0.5} pb={1.5}>
                                                            <SoftTypography
                                                                component="label"
                                                                variant="caption"
                                                                fontWeight="regular"
                                                                color="text"
                                                            >
                                                                Projektnr., Lieferant, Produkt
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </Grid>
                                                </Grid>
                                            </Grid>


                                        </Grid>
                                    </SoftBox>
                                    <SoftBox
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="flex-end"
                                        height="100%"
                                    >

                                        <SoftBox
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="flex-end"
                                            height="100%"
                                        >
                                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Title
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftInput placeholder="Enter title" {...register("title", { required: true, })} />
                                        </SoftBox>

                                    </SoftBox>


                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >
                                                <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Abteilung
                                                    </SoftTypography>
                                                </SoftBox>
                                                <select
                                                    className="form-control"
                                                    {...register("department")}
                                                    readOnly
                                                >
                                                    {JSON.parse(Cookies.get('permissions')).indexOf("OnlyDacRec") !== -1 ? (
                                                        <option value={'Dachmontage'}>Dachmontage</option>
                                                    ) : <></>}
                                                    {JSON.parse(Cookies.get('permissions')).indexOf("OnlyElkRec") !== -1 ? (
                                                        <option value={'Elektromontage'}>Elektromontage</option>
                                                    ) : <></>}

                                                    {JSON.parse(Cookies.get('permissions')).indexOf("OnlyDacRec") !== -1 || JSON.parse(Cookies.get('permissions')).indexOf("OnlyElkRec") !== -1 ? (
                                                        <></>
                                                    ) : <>
                                                        <option value={'Dachmontage'}>Dachmontage</option>
                                                        <option value={'Elektromontage'}>Elektromontage</option>
                                                        <option value={'Einkauf'}>Einkauf</option>
                                                        <option value={'Buchhaltung'}>Buchhaltung</option>
                                                        <option value={'Vertrieb'}>Vertrieb</option>
                                                        <option value={'Fertigmeldung'}>Fertigmeldung</option>
                                                    </>}
                                                </select>
                                                <div className="invalid-feedback">{errors.department && errors.department.message}</div>
                                            </SoftBox>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                                mt={2}>
                                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        {t('Prioritat')}
                                                    </SoftTypography>
                                                </SoftBox>
                                                <div className="form-group ">
                                                    <select
                                                        className="form-control"
                                                        {...register("priority", {
                                                            required: true,
                                                        })}
                                                        required
                                                    >
                                                        <option value="">{t('Select')}</option>
                                                        <option value="Urgend">{t('Urgend')}</option>
                                                        <option value="Normal">{t('Normal')}</option>
                                                        <option value="Not Important">{t('Not Important')}</option>
                                                    </select>
                                                    <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                                                </div>
                                            </SoftBox>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                                mt={2}>
                                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Projekt Nr.
                                                    </SoftTypography>
                                                </SoftBox>
                                                <div className="form-group ">
                                                    <select
                                                        className="form-control"
                                                        {...register("project_nr", {
                                                            required: true,
                                                        })}
                                                        readOnly
                                                    >
                                                        <option value="">{t('Select')}</option>
                                                        {projects && projects.map(project => (
                                                            <option value={project.id}>{(project.id + ' - ' + project.vorname + ' ' + (project?.name ? project?.name : ''))}</option>
                                                        ))}
                                                    </select>
                                                    <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                                                </div>
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                    {reclamation === 'Allgemeine' ? (
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <SoftBox
                                                    display="flex"
                                                    flexDirection="column"
                                                    justifyContent="flex-end"
                                                    height="100%"
                                                    mt={2}>
                                                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Partner-/ Lieferanten Nr.
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <div className="form-group ">
                                                        <select
                                                            className="form-control"
                                                            {...register("partner", {
                                                                required: true,
                                                            })}
                                                            required>
                                                            <option value="">{t('Select')}</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                        </select>
                                                        <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                                                    </div>
                                                </SoftBox>
                                            </Grid>
                                        </Grid>
                                    ) : ''}

                                    {reclamation === 'Projektbezogene' ? (
                                        <div className="row mt-3">
                                            <div className="col-sm-12">
                                                <div class="form-check">
                                                    <input class="form-check-input" {...register("is_product_related_complain")} type="checkbox" id="customCheck1" onChange={handleCheckboxChangeProduct} />
                                                    <label class="custom-control-label m-0 mt-2" for="customCheck1">Produktbezogene Reklamation</label>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''}

                                    {reclamation === 'Allgemeine' || showproduct === 'yes' ? (
                                        <div className="mt-3">
                                            {items && items.map((item, i) => (
                                                <div key={i} className="">
                                                    {i > 0 && <hr />}
                                                    <div className="row mt-2">
                                                        <div className="col-md-9">
                                                            <div>
                                                                <label>{t('Product')}</label>
                                                                <select value={items[i].id} className="form-control" placeholder={t('Select Product')} onChange={(e) => {
                                                                    items[i].id = e.target.value;
                                                                    items[i].title = e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text;

                                                                    setItems(items);
                                                                    setRefresh(refresh + 1);
                                                                }}>
                                                                    <option value="">--</option>
                                                                    {/*artikels && artikels.map((product, i) => (
                                                                        <option key={i} value={product.id}>{product.artikel_title}</option>
                                                                    ))*/}

                                                                    {module && module.map((modul, i) => (
                                                                        <option key={i} value={'module-' + modul.id}>{modul.name}</option>
                                                                    ))}
                                                                    {speicher && speicher.map((speiche, i) => (
                                                                        <option key={i} value={'speicher-' + speiche.id}>{speiche.name}</option>
                                                                    ))}
                                                                    {wechselrichter && wechselrichter.map((wechselrichte, i) => (
                                                                        <option key={i} value={'wechselrichter-' + wechselrichte.id}>{wechselrichte.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <div>
                                                                <label>{t('Qty/Hrs')}</label>
                                                                <input
                                                                    type="number"
                                                                    style={{ width: '84%' }}
                                                                    className="form-control d-inline"
                                                                    placeholder="eg. 0.00"
                                                                    defaultValue={items[i].quantity}
                                                                    onChange={(e) => {
                                                                        let value = 0;
                                                                        if (e.target.value.length && !isNaN(e.target.value))
                                                                            value = e.target.value;

                                                                        items[i].quantity = parseFloat(value);
                                                                        setItems(items);
                                                                        setRefresh(refresh + 1);
                                                                    }}
                                                                />
                                                                &nbsp;
                                                                <i className="fa-solid fa-circle-xmark text-danger" data-key={i} onClick={() => { delete items[i]; deleteProduct(); }}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                            <div className="row mt-3 ">
                                                <div className="col-md-12">
                                                    <p className="btn btn-dark" style={{ cursor: 'pointer' }} onClick={() => { setItems([...items, { id: 0, title: '', quantity: 0 }]); setRefresh(refresh + 1); }}><i className="fa-solid fa-circle-plus"></i>&nbsp;{t('Add Item')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''}

                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >
                                                <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Start Date
                                                    </SoftTypography>
                                                </SoftBox>
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    {...register("start_date", { required: true })}
                                                    defaultValue={startDate}
                                                />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >
                                                <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        End Date
                                                    </SoftTypography>
                                                </SoftBox>
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    {...register("end_date", { required: true, })}
                                                    defaultValue={endDate}
                                                />
                                            </SoftBox>
                                        </Grid>
                                    </Grid>


                                    <SoftBox
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="flex-end"
                                        height="100%"
                                    >
                                        <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                {t('Description')}
                                            </SoftTypography>
                                        </SoftBox>

                                        <textarea  {...register("description", { required: true, })} className="form-control"></textarea>
                                    </SoftBox>

                                    <SoftBox>
                                        <SoftBox
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="flex-end"
                                            height="100%"
                                        >
                                            <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                                <SoftTypography mb={2} component="label" variant="caption" fontWeight="bold">
                                                    Attachments
                                                </SoftTypography>

                                                <div className="row mb-2">
                                                    <div className="col-lg-12 mt-lg-0">
                                                        <div className="emb-4">
                                                            {editForm == false ? (
                                                                <>
                                                                    <SoftBox lineHeight={1}>
                                                                        <SoftBox>
                                                                            <SoftBox
                                                                                display="flex"
                                                                                flexDirection="column"
                                                                                justifyContent="flex-end"
                                                                                height="100%"
                                                                            >

                                                                               

                                                                            </SoftBox>
                                                                        </SoftBox>
                                                                    </SoftBox>
                                                                </>
                                                            ) : <></>}
                                                        </div>
                                                        <br />
                                                    </div>
                                                </div>
                                            </SoftBox>


                                            <SoftBox p={3} className="order-processing">
                                                <Grid container spacing={3}>
                                                    {attachments.map(function (data, i) {
                                                        return (
                                                            <Grid item key={i} className="text-center">
                                                                <div>
                                                                    <a target="_blank" download href={Cookies.get('backend_url') + 'storage/attachments/' + data?.attachment}>
                                                                        {['jpeg', 'jpg', 'png'].indexOf(data?.extension) !== -1 ? (
                                                                            <img src={Cookies.get('backend_url') + 'storage/attachments/' + data?.attachment} />
                                                                        ) : (
                                                                            <div className="placeholder">{data?.extension}</div>
                                                                        )}
                                                                    </a>
                                                                </div>
                                                                <a
                                                                    onClick={() => {
                                                                        removeAttachment(data?.id);
                                                                    }}
                                                                >{t('Remove')}</a>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </SoftBox>

                                        </SoftBox>
                                    </SoftBox>
                                    <SoftBox display="flex" justifyContent="flex-end" mt={3}>
                                        <SoftBox p={2} display="flex" justifyContent="flex-end">
                                            <a href="#" className="btn btn-danger mx-2" onClick={() => {

                                            }}>
                                                {t('Cancel')}
                                            </a>

                                            {!saving && (
                                                <button type="submit" className="btn btn-primary">
                                                    {t('Save')}
                                                </button>
                                            )}
                                            {saving && (
                                                <button type="button" className="btn btn-disabled" disabled>
                                                    {t('Saving ...')}
                                                </button>
                                            )}
                                        </SoftBox>
                                    </SoftBox>
                                </SoftBox>

                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </>
    );
}

export default Edit;
