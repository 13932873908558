import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Card from "@mui/material/Card";
import Sidenav from "./components/SideNav";
import { useTranslation } from 'react-i18next';
import PlaceholderCard from "examples/Cards/PlaceholderCard";

function CallCenterIndex() {
  const { t } = useTranslation();
  return (
    <>
      <SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} sm={6} lg={3}>
            <Sidenav />
          </Grid>
          <Grid container spacing={2} lg={9} xs={12} mt={3} pl={2}>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <SoftBox pl={4} width="100%">
                <SoftTypography fontSize="20px" fontWeight="medium">
                  {t('Call Center')}
                </SoftTypography>
              </SoftBox>

              <SoftBox mt={3} height="18.5rem">
                <PlaceholderCard
                  icon={""}
                  title={{ variant: "h6", text: t("Videos coming soon.") }}
                  hasBorder
                />
              </SoftBox>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
}

export default CallCenterIndex;
