import { Card, Grid } from '@mui/material'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import callFetch from 'helpers/callFetch';
import Cookies from 'js-cookie';
import React, { useState, useRef, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import SignaturePad from 'react-signature-pad-wrapper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Timeline from "../../create/Timeline";
import DropZoneTabs from 'components/DropZoneTabs/Index';
import Overview from '../../Overview';
import ACabnahmeSign from '../ACabnahmeSign';
import ComisioningModal from "../ComisioningModal";
import SoftEditor from "components/SoftEditor";

const ACAbnahmeprotokoll = (props) => {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const notify = () => toast("Success");
    const signaturePadRef = useRef(null);
    const [link, setLink] = useState(null)
    const [modules, setModules] = useState([]);
    const [id, setID] = useState(0);
    const [tabFiles, setTabFiles] = useState({});
    const params = useParams();
    const [strgItemRefresh, setStrgItemRefresh] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const [description, setDescription] = useState("");
    const [message, setMessage] = useState('Success');
    const [color, setColor] = useState('success');
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [data, setData] = useState([]);
    const [successSB, setSuccessSB] = useState(false);
    const [strgItem, setStrgItem] = useState({ strg_1: '' });
    const [voltItem, setVoltItem] = useState({ strg_1: '' });
    const [displayTab, setDisplayTab] = useState('form');


    const [dropZoneTabs, setDropZoneTabs] = useState([
        {
            name: 'wechselrichter_und_umgebung',
            titel: 'Wechselrichter und Umgebung',
            require: false
        }, {
            name: 'anschlussbereich_wechselrichter',
            titel: 'Anschlussbereich Wechselrichter',
            require: false
        }, {
            name: 'installierte_batterien_und_umgebung',
            titel: 'Installierte Batterien und Umgebung',
            require: false
        }, {
            name: 'zahlerschrank_geoffneter_zustand',
            titel: 'Zählerschrank (geöffneter Zustand)',
            require: false
        }, {
            name: 'verbraucher_sls_schalter_nh_sicherung',
            titel: 'Verbraucher SLS Schalter (NH Sicherung)',
            require: false
        }, {
            name: 'ac_uberspannungsschutz_ac_uss',
            titel: 'AC Überspannungsschutz 1&2 (AC USS)',
            require: false
        }, {
            name: 'leitungsschutz_des_wechselrichter',
            titel: 'Leitungsschutz des Wechselrichter',
            require: false
        }, {
            name: 'smart_meter',
            titel: 'Smart Meter',
            require: false
        }, {
            name: 'pv_Anlage_zahlerschrank',
            titel: 'PV Anlage Zählerschrank',
            require: false
        },
        {
            name: 'werte',
            titel: 'Eingestellte Werte',
            require: false
        },
        {
            name: 'foto_chos_phi',
            titel: 'Foto Chos Phi / Länderspezifische Einstellung',
            require: false
        },
        {
            name: 'foto_einsp',
            titel: 'Foto Einspeiserregelung',
            require: false
        },
        {
            name: 'foto_zahlerstand',
            titel: 'Foto Zählerstand zum Zeitpunkt der Anlagenerrichtung',
            require: false
        },
        {
            name: 'foto_von_der',
            titel: 'Foto von der laufenden Anlage',
            require: false
        },
        {
            name: 'zahlerschrank_mit',
            titel: 'Zählerschrank mit Berührungsschutz',
            require: false
        },
        {
            name: 'zahlerschrank_ohne',
            titel: 'Zählerschrank ohne Berührungsschutz',
            require: false
        },
    ]);
    const [dropZonValidationError, setDropZonValidationError] = useState('');

    const openSuccessSB = () => setSuccessSB(true);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("module/all", "GET", []).then((res) => {
            setModules(res.data);
        });
    }, [0]);

    useEffect(() => {
        if (props.project_id !== undefined) {
            callFetch("projectdistribution/" + props.project_id + "/edit?project_id=" + props.project_id, "GET", []).then((res) => {
                setValue('modultyp', res?.data?.quations?.modul);
                setValue('modulanzahl', res?.data?.quations?.anzahl_module);
                //setValue('wechselrichter', res?.data?.quations?.anzahl_module);

                if (res?.data?.quations?.wechselrichter_eingeben == 'no') {
                    setValue('wechselrichter', res?.data?.quations?.wechselrichter?.name);
                } else {
                    setValue('wechselrichter', res?.data?.quations?.wechselrichter_titel);
                }
            });

            callFetch("ac_abnahmeprotokoll/" + props.project_id + "/edit", "GET", []).then((res) => {

                //setStrgItem();

                if (res?.data?.strg) {
                    setStrgItem(JSON.parse(res?.data?.strg));
                    setVoltItem(JSON.parse(res?.data?.volt));
                }

                if (res?.data?.dcabname) {
                    var payload = res?.data?.dcabname?.payload ? (typeof res?.data?.dcabname?.payload === 'string' ? JSON.parse(res?.data?.dcabname?.payload) : res?.data?.dcabname?.payload) : {};

                    setTabFiles(JSON.parse(res?.data?.dcabname?.filesdata));
                    setID(res?.data?.dcabname?.id);
                    setDescription(res?.data?.dcabname.description && res?.data?.dcabname.description != 'null' ? res?.data?.dcabname.description : '')

                    var dc = res.data.dcabname;
                    setLink(res?.data?.pdf)
                    for (let [key, value] of Object.entries(res?.data?.dcabname)) {
                        if (key == 'payload') {
                            for (let [key, value] of Object.entries(payload)) {
                                setValue(key, (value == null || value == 'null' ? "" : (value === 'false' || value === '0' ? 0 : value === 'true' || value === '1' ? 1 : value)));
                            }
                        } else if (key == 'filesdata') {
                            setTabFiles(JSON.parse(value));
                        } else {
                            setValue(key, (value == null || value == 'null' ? "" : value));
                        }
                    }
                    setStrgItemRefresh(strgItemRefresh + 1);
                }
            })
        }
    }, [props.project_id, refresh]);

    const setCallBackData = (data) => {
        setTabFiles(data);

        setDropZonValidationError('');
        for (let [key, dropZoneTab] of Object.entries(dropZoneTabs)) {
            if (dropZoneTab?.require === true && (!data[dropZoneTab?.name] || data[dropZoneTab?.name]?.length <= 0)) {
                setDropZonValidationError(dropZoneTab?.titel + ' is required')
                return;
            }
        }
    }

    const [verbaut, setVerbaut] = useState();
    const handleVerbaut = (e) => {
        setVerbaut(e.target.checked);
    }

    const [smarter, setSmarter] = useState();
    const handleSmarter = (e) => {
        setSmarter(e.target.checked);
    }

    const [sls, setSls] = useState();
    const handleChangeSLS = (e) => {
        setSls(e.target.checked);
    }

    const [ausgebaut, seAusgebaut] = useState();
    const handleAusgebaut = (e) => {
        seAusgebaut(e.target.checked);
    }

    const onSubmit = (formData) => {
        setDropZonValidationError('');
        for (let [key, dropZoneTab] of Object.entries(dropZoneTabs)) {
            if (dropZoneTab?.require === true && (!tabFiles[dropZoneTab?.name] || tabFiles[dropZoneTab?.name]?.length <= 0)) {
                setDropZonValidationError(dropZoneTab?.titel + ' is required')
                return;
            }
        }

        setSaving(true);

        var loadingElement = document.querySelector('.res-options .btn-primary-save');
        loadingElement.classList.add("btn-secondary");
        loadingElement.innerHTML = t('wird geladen...');
        // formData.sign = signaturePadRef.current.toDataURL('image/png');
        formData.filesdata = JSON.stringify(tabFiles);
        formData.description = description;

        if (id >= 1) {

        } else {
            formData.project_id = props.project_id;
            formData.eidt_id = Cookies.get('ac_pdf');
        }

        callFetch((id > 0 ? "ac_abnahmeprotokoll/" + id : "ac_abnahmeprotokoll"), "POST", formData, setError).then((res) => {

            //setData(res);
            //setLink(res?.data?.pdf);

            if (!id) {
                setID(res?.data?.id);
                /*
                setTimeout(() => {
                    document.getElementById('ComisioningModalOpen').click();
                }, 2000);
                */
            }

            setColor('success')
            setMessage('Success');

            setTimeout(() => {

                setSaving(false);
                openSuccessSB();

                loadingElement.classList.remove("btn-secondary");
                loadingElement.innerHTML = t('Save');

                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                document.getElementById("main-content").scrollTop = 0;

                if (!res.ok) return;
                setSubmitSuccess(true);
                setRefresh(refresh + 1)
                clearForm();

            }, 2000);

        });
    }
    const clearForm = () => {
        reset({
            id: 0,
            title: '',
            status: '',
            description: '',
            attachments: JSON.stringify([]),
        });
    }
    return (
        <>
            <div className='row mt-3'>
                <div className='col-lg-9 inner-main'>
                    <Overview className="mb-3" project_id={params?.id} title={t('AC Abnahmeprotokoll')} ></Overview>
                    {displayTab === 'form' ? (
                        <form id="createOrderProcesing" className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            {
                                link !== null && <a className="opacity-0" target={"_blank"} id="dc_abnahmeprotokoll" href={link && link} ></a>
                            }

                            <input type="hidden" id="acid" value={id} />

                            {id > 0 ? <input type="hidden" defaultValue="PUT" {...register("_method")} /> : ''}
                            <SoftBox>
                                <Card sx={{ overflow: "visible" }}>
                                    <SoftBox p={3}>
                                        <SoftTypography variant="h5">{t('AC Abnahmeprotokoll')}</SoftTypography>
                                    </SoftBox>

                                    <SoftBox p={2} lineHeight={1}>

                                        <Grid my={2} container spacing={2}>
                                            {
                                                Object.keys(strgItem).map(function (keyName, index) {
                                                    return (
                                                        <>
                                                            <Grid item xs={6} sm={6} md={2} lg={2}>
                                                                <label>Strg {(index + 1)}</label>
                                                                <input
                                                                    type="text" className="form-control"
                                                                    placeholder={"Strg " + (index + 1)}
                                                                    /*
                                                                    {...register('strg[' + keyName + ']', {
                                                                        required: true
                                                                    })}
                                                                    */
                                                                    value={strgItem[keyName]}
                                                                    required
                                                                    readOnly
                                                                />
                                                            </Grid>

                                                            <Grid item xs={6} sm={6} md={2} lg={2}>
                                                                <label>Volt {(index + 1)}</label>
                                                                <input
                                                                    type="text" className="form-control"
                                                                    placeholder={"Volt " + (index + 1)}
                                                                    /*
                                                                    {...register('volt[' + keyName + ']', {
                                                                        required: true
                                                                    })}
                                                                    */
                                                                    value={voltItem[keyName]}
                                                                    required
                                                                    readOnly
                                                                />
                                                            </Grid>
                                                        </>
                                                    )
                                                })
                                            }
                                        </Grid>

                                        {/* <Grid my={2} container spacing={2}>
                                        <Grid item xs={12} sm={12} md={4} lg={4} >
                                            <label>Modultyp (hersteller, typ)</label>
                                            <select className="form-control" {...register('modultyp', { required: true })} required>
                                                <option>--</option>
                                                {
                                                    modules && modules.map((modules, index) => {
                                                        return <option key={index} value={modules.id}>{modules.name}</option>
                                                    })
                                                }
                                            </select>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} >
                                            <label>Modulanzahl (parallel × reihe)</label>
                                            <input type="number" className=" form-control" placeholder="Modulanzahl" {...register('modulanzahl', { required: true })} required />
                                        </Grid>      

                                        <Grid item xs={12} sm={12} md={4} lg={4} >
                                            <label>Wechselrichter (Hersteller, typ, nennleistung)</label>
                                            <input type="text" className=" form-control" placeholder="Wechselrichter" {...register('wechselrichter', { required: false })} />
                                        </Grid>     
                                       
                                    </Grid> */}


                                        <Grid my={2} container spacing={2} >

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={8} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">Zählerkonzept und Potentialausgleich</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3}>
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">JA</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3}>
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">NEIN</SoftTypography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={8} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Wurde das geplante Zählerkonzept umgesetzt?</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3}>
                                                        <div className="d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input

                                                                    type="checkbox"
                                                                    {...register("planned_meter_concept_implemented")}
                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3}>
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input

                                                                    type="checkbox"
                                                                    {...register("planned_meter_concept_implemented_no")}
                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={8} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Einbau eines zusätzlichen Zählerschranks notwendig</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input

                                                                    type="checkbox"
                                                                    {...register("additional_meter_cabinet_necessary")}

                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input

                                                                    type="checkbox"
                                                                    {...register("additional_meter_cabinet_necessary_no")}

                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={8} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">APZ Erweiterung notwendig</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input

                                                                    type="checkbox"
                                                                    {...register("apz_extension_necessary")}

                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input

                                                                    type="checkbox"
                                                                    {...register("apz_extension_necessary_no")}

                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={8} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Potentialausgleich mit Erder vorhanden?</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className="d-flex">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("potential_equalization_with_earthing_present")}

                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                    onChange={(e) => handleVerbaut(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input

                                                                    type="checkbox"
                                                                    {...register("potential_equalization_with_earthing_present_no")}

                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} mt={3} mb={3} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px" style={{ marginTop: '15px', display: 'block' }}>Netzform</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <input type="text" className='form-control' {...register("net_shape")} style={{ width: '80%' }} />
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px" style={{ marginTop: '15px', display: 'block' }}>Erdungswiderstand</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <input type="text" className='form-control' {...register("grounding_resistance")} />
                                                    </Grid>

                                                </Grid>
                                            </Grid>



                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={8} lg={8} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto des Zählerkonzeptes / der APZ Erweiterung</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("photo_meter_concept_apz_extension")} className="form-check-input  ms-1" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={8} lg={8} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto Überspannungsschutz</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("photo_surge_protection")} className="form-check-input  ms-1" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={8} lg={8} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto Sicherungselemente mit „PV-Sticker“</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("photo_security_elements_pv_sticker")} className="form-check-input  ms-1" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={8} lg={8} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto von EnFluRi</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("photo_enfluri")} className="form-check-input  ms-1" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={8} lg={8} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto SLS mit Beschriftung</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("photo_sls_label")} className="form-check-input  ms-1" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={8} lg={8} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">PV Aufkleber auf HAK</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("pv_sticker_hak")} className="form-check-input  ms-1" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={8} lg={8} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto Potentialausgleichsschiene mit Erdungswiderstand</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("photo_bonding_bar_earthing_resistance")} className="form-check-input  ms-1" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} mt={4} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={8} lg={8} >
                                                        <h5>Dokumentation PV-Module</h5>
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="16px">Verwendetes Messgerät </SoftTypography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={7} lg={7} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Hersteller und Typ: </SoftTypography>
                                                        <input type="text" className='form-control mt-2' {...register('manufacturer_type')} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4} lg={4} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Gewählte Prüfspannung: </SoftTypography>
                                                        <input type="text" className='form-control mt-2' {...register('selected_test_voltage')} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} mt={4} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={5} lg={5}>
                                                        <h5>MPP1</h5>
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Bezeichnung laut Strangplan / Dachfächenausrichtung</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <input type="text" className='form-control mt-4' {...register("bezeichnung_strangplan_dachausrichtung_mpp1")} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px" className="d-block mt-3">Anzahl der Module</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <input type="text" className='form-control' {...register("anzahl_module_mpp1")} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px" className="d-block mt-3">Neigung in Grad</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <input type="text" className='form-control' {...register("neigung_grad_mpp1")} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px" className="d-block mt-3">Leerlaufspannung</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <input type="text" className='form-control' {...register("leerlaufspannung_mpp1")} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px" className="d-block mt-3">Isolationswiderstand</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <input type="text" className='form-control' {...register("isolationswiderstand_mpp1")} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <h6 className='mt-2'>Fotodokumentation MPP1</h6>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto von Leerlaufspannung (Werte müssen mit Eintragung übereinstimmen)</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input type="checkbox" {...register("foto_leerlaufspannung_mpp1")} className="form-check-input" value={1} />
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto von Isolationswiderstand (Werte müssen mit Eintragung übereinstimmen)</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input type="checkbox" {...register("foto_isolationswiderstand_mpp1")} className="form-check-input" value={1} />
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {/* MPP2 */}
                                            <Grid item xs={12} sm={12} md={12} lg={12} mt={4} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={5} lg={5}>
                                                        <h5>MPP2</h5>
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Bezeichnung laut Strangplan / Dachfächenausrichtung</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <input type="text" className='form-control mt-4' {...register('bezeichnung_strangplan_dachausrichtung_mpp2')} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px" className="d-block mt-3">Anzahl der Module</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <input type="number" className='form-control' {...register('anzahl_module_mpp2')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px" className="d-block mt-3">Neigung in Grad</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <input type="number" step="0.01" className='form-control' {...register('neigung_grad_mpp2')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px" className="d-block mt-3">Leerlaufspannung</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <input type="number" step="0.01" className='form-control' {...register('leerlaufspannung_mpp2')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px" className="d-block mt-3">Isolationswiderstand</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <input type="number" step="0.01" className='form-control' {...register('isolationswiderstand_mpp2')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <h6 className='mt-2'>Fotodokumentation MPP2</h6>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto von Leerlaufspannung (Werte müssen mit Eintragung übereinstimmen)</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input type="checkbox" {...register("foto_leerlaufspannung_mpp2")} className="form-check-input" value={1} />
                                                                    </div>
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto von Isolationswiderstand (Werte müssen mit Eintragung übereinstimmen)</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input type="checkbox" {...register("foto_isolationswiderstand_mpp2")} className="form-check-input" value={1} />
                                                                    </div>
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {/* MPP3 */}
                                            <Grid item xs={12} sm={12} md={12} lg={12} mt={4} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={5} lg={5}>
                                                        <h5>MPP3</h5>
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Bezeichnung laut Strangplan / Dachfächenausrichtung</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <input type="text" className='form-control mt-4' {...register('bezeichnung_strangplan_dachausrichtung_mpp3')} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px" className="d-block mt-3">Anzahl der Module</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <input type="number" className='form-control' {...register('anzahl_module_mpp3')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px" className="d-block mt-3">Neigung in Grad</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <input type="number" step="0.01" className='form-control' {...register('neigung_grad_mpp3')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px" className="d-block mt-3">Leerlaufspannung</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <input type="number" step="0.01" className='form-control' {...register('leerlaufspannung_mpp3')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px" className="d-block mt-3">Isolationswiderstand</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <input type="number" step="0.01" className='form-control' {...register('isolationswiderstand_mpp3')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <h6 className='mt-2'>Fotodokumentation MPP3</h6>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto von Leerlaufspannung (Werte müssen mit Eintragung übereinstimmen)</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input type="checkbox" {...register("foto_leerlaufspannung_mpp3")} className="form-check-input" value={1} />
                                                                    </div>
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto von Isolationswiderstand (Werte müssen mit Eintragung übereinstimmen)</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input type="checkbox" {...register("foto_isolationswiderstand_mpp3")} className="form-check-input" value={1} />
                                                                    </div>
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {/* MPP4 Inputs */}
                                            <Grid item xs={12} sm={12} md={12} lg={12} mt={4} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={5} lg={5}>
                                                        <h5>MPP4</h5>
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Bezeichnung laut Strangplan / Dachfächenausrichtung</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <input type="text" className='form-control mt-4' {...register("bezeichnung_strangplan_dachausrichtung_mpp4")} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px" className="d-block mt-3">Anzahl der Module</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <input type="text" className='form-control' {...register("anzahl_module_mpp4")} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px" className="d-block mt-3">Neigung in Grad</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <input type="text" className='form-control' {...register("neigung_grad_mpp4")} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px" className="d-block mt-3">Leerlaufspannung</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <input type="text" className='form-control' {...register("leerlaufspannung_mpp4")} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px" className="d-block mt-3">Isolationswiderstand</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <input type="text" className='form-control' {...register("isolationswiderstand_mpp4")} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <h6 className='mt-2'>Fotodokumentation MPP4</h6>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto von Leerlaufspannung (Werte müssen mit Eintragung übereinstimmen)</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input type="checkbox" {...register("foto_leerlaufspannung_mpp4")} className="form-check-input" value={1} />
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto von Isolationswiderstand (Werte müssen mit Eintragung übereinstimmen)</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input type="checkbox" {...register("foto_isolationswiderstand_mpp4")} className="form-check-input" value={1} />
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {/* MPP5 Inputs */}
                                            <Grid item xs={12} sm={12} md={12} lg={12} mt={4} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={5} lg={5}>
                                                        <h5>MPP5</h5>
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Bezeichnung laut Strangplan / Dachfächenausrichtung</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <input type="text" className='form-control mt-4' {...register("bezeichnung_strangplan_dachausrichtung_mpp5")} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px" className="d-block mt-3">Anzahl der Module</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <input type="text" className='form-control' {...register("anzahl_module_mpp5")} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px" className="d-block mt-3">Neigung in Grad</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <input type="text" className='form-control' {...register("neigung_grad_mpp5")} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px" className="d-block mt-3">Leerlaufspannung</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <input type="text" className='form-control' {...register("leerlaufspannung_mpp5")} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px" className="d-block mt-3">Isolationswiderstand</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <input type="text" className='form-control' {...register("isolationswiderstand_mpp5")} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <h6 className='mt-2'>Fotodokumentation MPP5</h6>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto von Leerlaufspannung (Werte müssen mit Eintragung übereinstimmen)</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input type="checkbox" {...register("foto_leerlaufspannung_mpp5")} className="form-check-input" value={1} />
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                                                <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto von Isolationswiderstand (Werte müssen mit Eintragung übereinstimmen)</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input type="checkbox" {...register("foto_isolationswiderstand_mpp5")} className="form-check-input" value={1} />
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>


                                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                                <h6 className='mt-2'>Dokumentation Speicher</h6>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={8} lg={8} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Wie würde eine Internetverbindung zum Speicher hergestellt?</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type='text' className='form-control' {...register('internet_connection_to_storage')} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px"> </SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;JA</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  NEIN</SoftTypography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Alle Netwerkverbindungen mit Ethernet-Kabel nach Netzwerkschema erstellt?</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                                        <div className="d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input

                                                                    type="checkbox"
                                                                    {...register("network_connections_created_ethernet")}
                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input

                                                                    type="checkbox"
                                                                    {...register("network_connections_created_ethernet_no")}
                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">WLAN Adapter verwendet?</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input

                                                                    type="checkbox"
                                                                    {...register("wlan_adapter_used")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input

                                                                    type="checkbox"
                                                                    {...register("wlan_adapter_used_no")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Ist die Internetverbindung stabil?</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input

                                                                    type="checkbox"
                                                                    {...register("internet_connection_stable")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input


                                                                    type="checkbox"
                                                                    {...register("internet_connection_stable_no")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={3} md={3} lg={3} >
                                                        <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Verwendeter Adapter</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                                        <input type='text' {...register('adapter_used')} className='form-control form-control-sm' style={{ width: '100%' }} />
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={3} md={3} lg={3} >
                                                        <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Verwendetes Netzwerk</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                                        <input type='text' {...register('network_used')} className='form-control form-control-sm' style={{ width: '100%' }} />
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={3} md={3} lg={3} >
                                                        <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">IP-Adresse des Speichers</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                                        <input type='text' {...register('ip_address_storage')} className='form-control form-control-sm' style={{ width: '100%' }} />
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={3} md={3} lg={3} >
                                                        <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Seriennnummer Speicher</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                                        <input type='text' {...register('serial_number_memory')} className='form-control form-control-sm' style={{ width: '100%' }} />
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={3} md={3} lg={3} >
                                                        <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Seriennnummern verbaute Batterien</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                                        <input type='text' {...register('serial_numbers_batteries')} className='form-control form-control-sm' style={{ width: '100%' }} />
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                                <h6 className='mt-2'>Fotodokumentation Speicher</h6>
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={8} lg={8} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto Standort des Speichers</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("photo_storage_facility_location")} className="form-check-input" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={8} lg={8} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto der Blindleistung</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("photo_reactive_power")} className="form-check-input" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={8} lg={8} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto der IP Adresse des Speichers</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("photo_ip_address_storage")} className="form-check-input" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={8} lg={8} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto der Seriennummer des Speichers</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("photo_memory_serial_number")} className="form-check-input" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={8} lg={8} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto aller Seriennummern der Batterien</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("photo_battery_serial_numbers")} className="form-check-input" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                                <h6>Dokumentation Wechselrichter</h6>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={3} md={5} lg={5} >
                                                        <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Verwendete Wechselrichter (Nur bei externen Geräten)</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                                        <input type='text' className='form-control form-control-sm' {...register('inverters_used')} style={{ width: '100%' }} />
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} md={5} lg={5} >
                                                        <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Anzahl Wechselrichter (Nur bei externen Geräten)</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                                        <input type='text' className='form-control form-control-sm' {...register('number_of_inverters')} style={{ width: '100%' }} />
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} md={5} lg={5} >
                                                        <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Seriennnummern Wechselrichter (Nur bei externen Geräten)</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                                        <input type='text' className='form-control form-control-sm' {...register('inverter_serial_numbers')} style={{ width: '100%' }} />
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px"> </SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={1}>
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;JA</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2}>
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  NEIN</SoftTypography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Die Funktionsweise wurde geprüft und ist in Ordnung?</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={1} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input


                                                                    type="checkbox"
                                                                    {...register("functionality_checked_ok")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("functionality_checked_ok_no")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>


                                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                                <h6 className='mt-2'>Fotodokumentation Wechselrichter</h6>
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={8} lg={8} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto der Seriennummern</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("photo_serial_numbers")} className="form-check-input" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={8} lg={8} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto Standort der Wechselrichter</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("photo_inverter_location")} className="form-check-input" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>



                                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                                <h6>AC-Messung (Diese Seite für jede AC Messung ausfüllen)</h6>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={3} md={6} lg={6} >
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Nummer der AC Messung</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <input type='text' className='form-control form-control-sm' {...register('ac_measurement_number')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} md={6} lg={6} >
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Zielbezeichnung</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <input type='text' className='form-control form-control-sm' {...register('target_designation')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} md={6} lg={6} >
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Typ</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <input type='text' className='form-control form-control-sm' {...register('type')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} md={6} lg={6} >
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Anzahl</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <input type='text' className='form-control form-control-sm' {...register('number')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} md={6} lg={6} >
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Querschnitt [mm]</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <input type='text' className='form-control form-control-sm' {...register('cross_section_mm')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} md={6} lg={6} >
                                                        {/* <Grid container spacing={2}>
                                                                <Grid item xs={12} sm={6} md={6} lg={6}> 
                                                                    <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Nummer der AC Messung</SoftTypography>
                                                                </Grid> 
                                                                <Grid item xs={12} sm={6} md={6} lg={6}> 
                                                                    <input type='text' className='form-control form-control-sm'/>
                                                                </Grid>
                                                            </Grid> */}
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} md={6} lg={6} >
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Charakteristik</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <input type='text' className='form-control form-control-sm' {...register('characteristics')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} md={6} lg={6} >
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Bemessungsstrom [Iₙ]</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <input type='text' className='form-control form-control-sm' {...register('rated_current_in')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} md={6} lg={6} >
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Kurzschlusstrom [Ik]</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <input type='text' className='form-control form-control-sm' {...register('short_circuit_current_ik')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} md={6} lg={6} >
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Impedanz [Zₛ]</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <input type='text' className='form-control form-control-sm' {...register('impedance_zs')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} md={6} lg={6} >
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Bemessungsdifferenzstrom [IΔₙ]</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <input type='text' className='form-control form-control-sm' {...register('rated_residual_current_idn')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} md={6} lg={6} >
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Widerstand ohne Verbraucher</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <input type='text' className='form-control form-control-sm' {...register('resistance_without_consumers')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} md={6} lg={6} >
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Auslösestrom [Imess]</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <input type='text' className='form-control form-control-sm' {...register('tripping_current_imess')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} md={6} lg={6} >
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Auslösezeit [IF]</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <input type='text' className='form-control form-control-sm' {...register('tripping_time_if')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} md={6} lg={6} >
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Berührungsspannung [Umess]</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <input type='text' className='form-control form-control-sm' {...register('contact_voltage_umess')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} md={6} lg={6} >
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <SoftTypography variant="p" fontWeight="normal" className="d-block mt-2" fontSize="14px">Schutzleiterwiderstand [RPE low]</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <input type='text' className='form-control form-control-sm' {...register('schutzleiterwiderstand_rpe_low')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>


                                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                                <h6 className='mt-2'>Fotodokumentation AC-Messung</h6>
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={8} lg={8} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto von Ziel</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("photo_ziel")} className="form-check-input" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={8} lg={8} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto der Seriennummer des Zieles</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("photo_target_serial_number")} className="form-check-input" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={8} lg={8} >
                                                        <SoftTypography variant="p" fontWeight="normal" fontSize="14px">Foto Typenbezeichnung</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("photo_type_designation")} className="form-check-input" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>


                                            {/* <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                            <Grid container spacing={2} >
                                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                                    <SoftTypography variant="p" fontWeight="bold" fontSize="14px">Kunden eingewiesen</SoftTypography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3} lg={3} >
                                                    <div className=" d-flex align-items-center">
                                                        <div className="form-check d-inline-block">
                                                            <input
                                                                checked={ 4 == 'Yes'}
                                                                onClick={() => handleOptionChange4('Yes')}
                                                                type="checkbox"
                                                                {...register("kunden_ja")}

                                                                className="form-check-input  ms-1"
                                                                value={1}
                                                            />
                                                        </div> 
                                                    </div>
                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3} lg={3} >
                                                    <div className=" d-flex align-items-center">
                                                        <div className="form-check d-inline-block">
                                                            <input
                                                                 checked={ 4 == 'No'}
                                                            onClick={() => handleOptionChange4('No')}
                                                                type="checkbox"
                                                                {...register("kunden_nein")}

                                                                className="form-check-input  ms-1"
                                                                value={1}
                                                            />
                                                        </div> 
                                                    </div>
                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                            <Grid container spacing={2} >
                                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                                    <SoftTypography variant="p" fontWeight="bold" fontSize="14px">Anlage auf 0% Prozent Eingespeisung eingestellt</SoftTypography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3} lg={3} >
                                                    <div className=" d-flex align-items-center">
                                                        <div className="form-check d-inline-block">
                                                            <input
                                                                checked={ 5 == 'Yes'}
                                                                onClick={() => handleOptionChange5('Yes')}
                                                                type="checkbox"
                                                                {...register("anlage_ja")}

                                                                className="form-check-input  ms-1"
                                                                value={1}
                                                            />
                                                        </div> 
                                                    </div>
                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3} lg={3} >
                                                    <div className=" d-flex align-items-center">
                                                        <div className="form-check d-inline-block">
                                                            <input
                                                                 checked={ 5 == 'No'}
                                                                onClick={() => handleOptionChange5('No')}
                                                                type="checkbox"
                                                                {...register("anlage_nein")}

                                                                className="form-check-input  ms-1"
                                                                value={1}
                                                            />
                                                        </div> 
                                                    </div>
                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                            <Grid container spacing={2} >
                                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                                    <SoftTypography variant="p" fontWeight="bold" fontSize="14px" >Smarter verbaut?</SoftTypography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3} lg={3} >
                                                    <div className=" d-flex align-items-center">
                                                        <div className="form-check d-inline-block">
                                                            <input 
                                                                 checked={ 6 == 'Yes'}
                                                                onClick={() => handleOptionChange6('Yes')}
                                                                type="checkbox"
                                                                {...register("smarter_ja")}

                                                                className="form-check-input  ms-1"
                                                                value={1}
                                                                onChange={(e) => handleSmarter(e)}
                                                            />
                                                        </div> 
                                                        {smarter && <input  {...register("smarter_text")} className='d-inline-block form-control' style={{ position: 'relative', left: '8px' }} placeholder='Type' />}
                                                    </div>
                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3} lg={3} >
                                                    <div className=" d-flex align-items-center">
                                                        <div className="form-check d-inline-block">
                                                            <input
                                                                  checked={ 6 == 'No'}
                                                            onClick={() => handleOptionChange6('No')}
                                                                type="checkbox"
                                                                {...register("smarter_nein")}

                                                                className="form-check-input  ms-1"
                                                                value={1}
                                                            />
                                                        </div> 
                                                    </div>
                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                            <Grid container spacing={2} >
                                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                                    <SoftTypography variant="p" fontWeight="bold" fontSize="14px" >SLS Schalter verbaut?</SoftTypography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3} lg={3} >
                                                    <div className=" d-flex align-items-center">
                                                        <div className="form-check d-inline-block">
                                                            <input
                                                                checked={ 7 == 'Yes'}
                                                                onClick={() => handleOptionChange7('Yes')}
                                                                type="checkbox"
                                                                {...register("sls_verbaut_ja")}

                                                                className="form-check-input  ms-1"
                                                                value={1}
                                                                onChange={(e) => handleChangeSLS(e)}
                                                            />
                                                        </div> 
                                                        {sls && <input  {...register("sls_text")} className='d-inline-block form-control' style={{ position: 'relative', left: '8px' }} placeholder='Enter Type & AMper' />}
                                                    </div>
                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3} lg={3} >
                                                    <div className=" d-flex align-items-center">
                                                        <div className="form-check d-inline-block">
                                                            <input
                                                                 checked={ 7 == 'No'}
                                                            onClick={() => handleOptionChange7('No')}
                                                                type="checkbox"
                                                                {...register("sls_verbaut_nein")}

                                                                className="form-check-input  ms-1"
                                                                value={1}
                                                            />
                                                        </div> 
                                                    </div>
                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                            <Grid container spacing={2} >
                                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                                    <SoftTypography variant="p" fontWeight="bold" fontSize="14px" >Zählernummer</SoftTypography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3} lg={3} >
                                                    <div className=" d-flex align-items-center">
                                                        <div className="form-check d-inline-block">
                                                            <input
                                                                 checked={ 8 == 'Yes'}
                                                                onClick={() => handleOptionChange8('Yes')}
                                                                type="checkbox"
                                                                {...register("zahlernummer_ja")}

                                                                className="form-check-input  ms-1"
                                                                value={1}
                                                            />
                                                        </div> 
                                                    </div>
                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3} lg={3} >
                                                    <div className=" d-flex align-items-center">
                                                        <div className="form-check d-inline-block">
                                                            <input
                                                                  checked={ 8 == 'No'}
                                                            onClick={() => handleOptionChange8('No')}
                                                                type="checkbox"
                                                                {...register("zahlernummer_nein")}

                                                                className="form-check-input  ms-1"
                                                                value={1}
                                                            />
                                                        </div> 
                                                    </div>
                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                            <Grid container spacing={2} >
                                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                                    <SoftTypography variant="p" fontWeight="bold" fontSize="14px" >Zähler ausgebaut</SoftTypography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3} lg={3} >
                                                    <div className=" d-flex align-items-center">
                                                        <div className="form-check d-inline-block">
                                                            <input
                                                             checked={ 9 == 'Yes'}
                                                                onClick={() => handleOptionChange9('Yes')}
                                                                type="checkbox"
                                                                {...register("ausgebaut_ja")}

                                                                className="form-check-input  ms-1"
                                                                value={1}
                                                                onChange={(e) => handleAusgebaut(e)}
                                                            />
                                                        </div> 
                                                        {ausgebaut && <input  {...register("ausgebaut_text")} className='d-inline-block form-control' style={{ position: 'relative', left: '8px' }} placeholder='Zählernummer' />}
                                                    </div>
                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3} lg={3} >
                                                    <div className=" d-flex align-items-center">
                                                        <div className="form-check d-inline-block">
                                                            <input
                                                              checked={ 9 == 'No'}
                                                            onClick={() => handleOptionChange9('No')}
                                                                type="checkbox"
                                                                {...register("ausgebaut_nein")}

                                                                className="form-check-input  ms-1"
                                                                value={1}
                                                            />
                                                        </div> 
                                                    </div>
                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                </Grid>
                                            </Grid>
                                        </Grid> */}
                                            {/* <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                            <Grid container spacing={2} >
                                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                                    <SoftTypography variant="p" fontWeight="bold" fontSize="14px" >Aktueller Zählerstand</SoftTypography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3} lg={3} >
                                                    <div className=" d-flex align-items-center">
                                                        <div className="form-check d-inline-block">
                                                            <input
                                                             checked={ 10 == 'Yes'}
                                                                onClick={() => handleOptionChange10('Yes')}
                                                                type="checkbox"
                                                                {...register("aktueller_ja")}

                                                                className="form-check-input  ms-1"
                                                                value={1}
                                                            />
                                                        </div> 
                                                    </div>
                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3} lg={3} >
                                                    <div className=" d-flex align-items-center">
                                                        <div className="form-check d-inline-block">
                                                            <input
                                                              checked={ 10 == 'No'}
                                                            onClick={() => handleOptionChange10('No')}
                                                                type="checkbox"
                                                                {...register("aktueller_nein")}

                                                                className="form-check-input  ms-1"
                                                                value={1}
                                                            />
                                                        </div> 
                                                    </div>
                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                </Grid>
                                            </Grid>
                                        </Grid> */}
                                        </Grid>



                                        <SoftBox
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="flex-end"
                                            height="100%"
                                        >
                                            <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    {t('Description')}
                                                </SoftTypography>
                                            </SoftBox>
                                            {false ? (
                                                <>
                                                    {description}
                                                </>
                                            ) : (
                                                <SoftEditor
                                                    value={description}
                                                    onChange={setDescription}
                                                />
                                            )}
                                        </SoftBox>
                                    </SoftBox>
                                </Card>
                            </SoftBox>

                            <SoftBox my={2}>
                                <Card>
                                    <SoftBox p={3}>
                                        <SoftTypography variant="h5">{t('Files')}</SoftTypography>
                                    </SoftBox>

                                    <SoftBox px={3} pb={3}>
                                        <DropZoneTabs
                                            tabs={dropZoneTabs}
                                            defaultFiles={tabFiles}
                                            defaultTab={'wechselrichter_und_umgebung'}
                                            callBackData={setCallBackData}
                                            dropZonValidationError={dropZonValidationError}
                                        />
                                    </SoftBox>
                                </Card>
                            </SoftBox>



                            <SoftBox p={2} display="flex">
                                {JSON.parse(Cookies.get('permissions')).indexOf("acAbhU") !== -1 ? (
                                    <>
                                        {!saving && (
                                            <button type="submit" id="acAbname" className="btn btn-primary">
                                                {t('Save')}
                                            </button>
                                        )}
                                        {saving && (
                                            <button type="submit" className="btn btn-disabled" disabled>
                                                {t('Saving ...')}
                                            </button>
                                        )}
                                    </>
                                ) : <></>}

                                <a target='_blank' className="btn btn-primary mx-2" href={Cookies.get('backend_url') + 'acabname/' + (id ? id : 'empty')}>{t('PDF')}</a>
                            </SoftBox>
                        </form>
                    ) : displayTab === 'sign' ?
                        <>
                            <ACabnahmeSign saveBtnDisplay={true} flashLoader={false} pdfDisplay={true} project_id={params?.id} id={id} />
                        </>
                        : ''}
                </div>
                <div className="col-lg-3 inner-main">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-body p-3">
                                    <div className="d-flex">
                                        <div>
                                            <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="ms-3">
                                            <div className="numbers">
                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Project Nr.')}</p>
                                                <h5 className="font-weight-bolder mb-0">
                                                    {props.project_id ? props.project_id : '---'}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {displayTab === 'form' ? (
                                <>
                                    {params?.id && id ?
                                        <button
                                            className="btn btn-primary"
                                            style={{ width: '100%', backgroundColor: '#CB0C9F', borderColor: '#CB0C9F' }}
                                            onClick={() => { setDisplayTab('sign') }}
                                        >{t('Abnahmeprotokol erstellen')}</button>
                                        : <button
                                            className="btn btn-secondary"
                                            style={{ width: '100%' }}
                                        >{t('Abnahmeprotokol erstellen')}</button>
                                    }
                                </>
                            ) : (
                                <button
                                    className="btn btn-secondary"
                                    style={{ width: '100%' }}
                                    onClick={() => { setDisplayTab('form') }}
                                >{t('Zürück')}</button>
                            )}
                        </div>

                    </div>

                    {/* <Comisioning projectid={props.project_id} id={id} type={'ac'}></Comisioning> */}

                    <ComisioningModal projectid={props.project_id} id={id} type={'ac'} setRefresh={setRefresh} />

                    <Timeline refresh={refresh} acStatusEdit={true} />
                </div>
            </div>


        </>
    )
}

export default ACAbnahmeprotokoll