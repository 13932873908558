import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useParams, NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch";
import flatpickr from "flatpickr";
import SoftSnackbar from "components/SoftSnackbar";
import TimelineNew from "./TimelineNew";
import Cookies from 'js-cookie';
import { NumericFormat } from 'react-number-format';
import Swal from 'sweetalert2';
import dateFormat from "dateformat";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import SoftAlert from "components/SoftAlert";
// Images
import mercedesEQC from "assets/images/Mono-S4_HC_WhiteBacksheet_diagonal_hoch_live 2.png";
import wavesWhite from "assets/images/shapes/waves-white.svg";
// @mui material components
import Grid from "@mui/material/Grid";
import SignaturePad from 'react-signature-pad-wrapper'

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import OtpInput from 'react-otp-input';
import Group_1000004630 from "assets/images/Group_1000004630.png";

function OrdersOverviewEmailConfirm(props) {
    let params = useParams();
    const location = useLocation()
    const [otp, setOtp] = useState('');
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [saving, setSaving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [refesh3, setRefesh3] = useState(0);
    const [invoices, setInvoices] = useState([]);
    const [successSB, setSuccessSB] = useState(false);
    const [confirmInfo, setConfirmInfo] = useState([]);
    const [invalidCode, setInvalidCode] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [steps, setSteps] = useState(1);
    const [resend, setResend] = useState(false);
    const [sending, setSending] = useState(false);
    const [openPDF, setOpenPDF] = useState(false);
    const signaturePadRefClient = useRef(null);
    const [showPadClient, setShowPadClient] = useState(true);
    const [playAnimation, setPlayAnimation] = useState(true);
    const [settings, setSettings] = useState([]);
    const [showButton, setShowButton] = useState(false);
    const [backend_url, setBackendUrl] = useState('');

    const openThePDF = () => {
        setOpenPDF(true);
        var url = "get-client-sign-order-overview/" + params?.id
        callFetch(url, "GET", [], {}, backend_url + '/api/v1/').then((res) => {
            if (res.quation?.client_sign_order_overview) {
                signaturePadRefClient.current.fromDataURL(res.quation?.client_sign_order_overview);
            }

        })
    }


    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams?.get('url')) {
            setBackendUrl(searchParams?.get('url'));
        } else {
            setBackendUrl('');
        }
    }, [location])


    useEffect(() => {
        if (!backend_url) return;

        callFetch("get-application-settings", "GET", [], setError, backend_url + '/api/v1/').then((res) => {
            setSettings(res);
            setShowButton(true);
        });
    }, [backend_url])

    useEffect(() => { // useEffect hook
        if (playAnimation) {
            //document.body.classList.add('loading');
        }
        setTimeout(() => { // simulate a delay
            setPlayAnimation(false)
            //document.body.classList.remove('loading');
        }, 10000);
    }, [refresh]);

    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title={t('Success')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={() => setSuccessSB(false)}
            close={() => setSuccessSB(false)}
            bgSuccess
        />
    );
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const hideSignClient = () => {
        setShowPadClient(true);
    }

    //Edit Data load
    useEffect(() => {
        if (!backend_url) return;

        flatpickr(".flat-pickr");
        if (params?.id) {
            callFetch("order-overview-info/" + params.id, "GET", [], {}, backend_url + '/api/v1/').then((res) => {
                setConfirmInfo(res);
                // console.log(res); 
            });
        }
    }, [params?.id, refesh3, backend_url]);

    const accept = () => {
        if (!backend_url) return;

        callFetch("order-overview-information-for-accept/" + params.id, "GET", [], {}, backend_url + '/api/v1/').then((res) => {
            setData(res);
            if (res.message == 'success') {
                setSteps(3);
                //openThePDF();
                setInvalidCode(false);
            }
        });
    }

    const sendVerificationCode = () => {
        setSending(true);
        callFetch("order-overview-send-verification-code/" + params.id, "GET", [], {}, backend_url + '/api/v1/').then((res) => {
            setTimeout(() => { setShowNotification(true); setSteps(2); setSending(false) }, 1000);
        });
    }

    const resendNewCode = () => {
        setShowNotification(false);
        callFetch("order-overview-resend-verification-code/" + params.id, "GET", [], {}, backend_url + '/api/v1/').then((res) => {
            setTimeout(() => { setShowNotification(true) }, 1000);
            setResend(true);
            setSteps(2);
            // document.getElementById("emailModalOneClose").click();
            // document.getElementById("emailModal2").click(); 
        });
    }

    const onSubmit = (formData) => {
        if (Number(otp) >= 1111) {
            var code = otp;
            callFetch("order-overview-verify-code/" + code + '/' + params.id, "GET", [], {}, backend_url + '/api/v1/').then((res) => {
                setData(res);
                setOtp('');
                if (res.message == 'success') {
                    let timerInterval;
                    Swal.fire({
                        //title: "Code prüfung...",
                        html: "Bitte warten Sie einen Moment, während wir Ihren Zugang überprüfen.",
                        timer: 4000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer();
                                if (content) {
                                    const b = content.querySelector("b");
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft();
                                    }
                                }
                            }, 100);
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            setSteps(3);
                        }
                    });
                    //openThePDF();
                    setInvalidCode(false);
                } else {
                    setInvalidCode(true);
                }

                // console.log(res); 
            });
        } else {

            if (signaturePadRefClient.current.isEmpty()) {
                // alert('Sign please');
                setRefresh(refresh + 1);
                return false;
            }

            setSaving(true);

            formData.client_sign = signaturePadRefClient.current.toDataURL('image/png');
            formData.id = data?.data?.quations?.id;

            var url = "save-client-sign-order-overview";
            callFetch(url, "POST", formData, setError, backend_url + '/api/v1/').then((res) => {
                setRefresh(refresh + 1);
                setPlayAnimation(true);
                setSaving(false);

                if (res.status == 'success') {
                    document.getElementById('successSignModalBtn').click()
                }
            });
        }

    }

    return (
        <>
            <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3  bg-white" id="sidenav-main" style={{ boxShadow: 'none' }}>
                <div className="sidenav-header">
                    <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav" />
                    <a className="navbar-brand m-0" href="#0">
                        <img src="/assets/img/logonew.png" className="navbar-brand-img h-100" style={{ maxHeight: '50px', marginTop: '16px' }} alt="main_logo" />
                    </a>
                </div>
                <hr className="horizontal dark mt-0" />
                <div className="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink to="#0" className="nav-link active" style={{ background: '#CB0C9FA6', color: '#fff' }}>
                                <i aria-hidden="true" style={{ background: '#fff', color: '#CB0C9FA6', padding: '8px' }} className="border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_4276_16989)">
                                        <path d="M7.66289 2.96736C7.61927 2.92563 7.56124 2.90234 7.50088 2.90234C7.44052 2.90234 7.38248 2.92563 7.33887 2.96736L1.94531 8.11979C1.92241 8.14171 1.90418 8.16804 1.89175 8.19719C1.87931 8.22635 1.87291 8.25773 1.87295 8.28942L1.87207 13.1252C1.87207 13.3738 1.97084 13.6123 2.14666 13.7881C2.32247 13.9639 2.56093 14.0627 2.80957 14.0627H5.625C5.74932 14.0627 5.86855 14.0133 5.95645 13.9254C6.04436 13.8375 6.09375 13.7182 6.09375 13.5939V9.60954C6.09375 9.54738 6.11844 9.48777 6.1624 9.44381C6.20635 9.39986 6.26596 9.37517 6.32812 9.37517H8.67187C8.73403 9.37517 8.79365 9.39986 8.8376 9.44381C8.88155 9.48777 8.90625 9.54738 8.90625 9.60954V13.5939C8.90625 13.7182 8.95563 13.8375 9.04354 13.9254C9.13145 14.0133 9.25068 14.0627 9.375 14.0627H12.1893C12.4379 14.0627 12.6764 13.9639 12.8522 13.7881C13.028 13.6123 13.1268 13.3738 13.1268 13.1252V8.28942C13.1268 8.25773 13.1204 8.22635 13.108 8.19719C13.0955 8.16804 13.0773 8.14171 13.0544 8.11979L7.66289 2.96736Z" fill="#CB0C9F" fill-opacity="0.65" />
                                        <path d="M14.3821 7.15283L12.1907 5.05635V1.875C12.1907 1.75068 12.1413 1.63145 12.0534 1.54354C11.9655 1.45564 11.8463 1.40625 11.722 1.40625H10.3157C10.1914 1.40625 10.0722 1.45564 9.98427 1.54354C9.89637 1.63145 9.84698 1.75068 9.84698 1.875V2.8125L8.15011 1.19004C7.99132 1.02949 7.75518 0.9375 7.50001 0.9375C7.24571 0.9375 7.01016 1.02949 6.85138 1.19033L0.619933 7.15225C0.437706 7.32803 0.414855 7.61719 0.580675 7.80762C0.622315 7.85568 0.6733 7.89477 0.730528 7.92251C0.787756 7.95025 0.850029 7.96605 0.913558 7.96896C0.977087 7.97186 1.04054 7.96181 1.10006 7.93941C1.15958 7.91701 1.21392 7.88274 1.25978 7.83867L7.33888 2.02969C7.38249 1.98796 7.44053 1.96468 7.50089 1.96468C7.56125 1.96468 7.61928 1.98796 7.6629 2.02969L13.7426 7.83867C13.8321 7.92455 13.9521 7.97142 14.0762 7.96901C14.2002 7.96659 14.3183 7.91509 14.4044 7.82578C14.5843 7.63945 14.5693 7.33183 14.3821 7.15283Z" fill="#CB0C9F" fill-opacity="0.65" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4276_16989">
                                            <rect width="15" height="15" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                </i>
                                <span className="sidenav-normal">{t('Ihre Bestellübersicht')}</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </aside>
            <main className="main-content position-relative h-100 mt-1 border-radius-lg" id="main-content" style={{ backgroundColor: '#fff' }}>
                <div className="container-fluid py-4 min-height-500">
                    <div className="row mb-5">
                        <div className="col-lg-12 mt-lg-0 pt-lg-4">

                            {params.id > 0 && (
                                <>
                                    <h4 className="mt-lg-5 pt-lg-5">Ihre Bestellübersicht</h4>
                                    {data?.modules ? data?.modules.map((module, key) => (
                                        <div className="py-3 px-5" style={{ background: 'linear-gradient(263.4deg, #130744 14.32%, #208DFC 77.34%)', borderRadius: '1rem' }}>
                                            <Grid container alignItems="center" position="relative">
                                                <Grid item xs={12} lg={4}>
                                                    <SoftBox px={{ xs: 0, md: 1.5 }}>
                                                        <SoftTypography variant="h4" color="white" textTransform="capitalize" opacity={0.9}>
                                                            {module?.name} | {module?.description ? JSON.parse(module?.description).Markenhersteller : ''}
                                                        </SoftTypography>
                                                        <Divider light />
                                                        <SoftBox display="flex">
                                                            <SoftBox>
                                                                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                                                                    power
                                                                </SoftTypography>
                                                                <SoftTypography variant="h3" fontWeight="bold" color="white">
                                                                    {module?.watt}
                                                                    <SoftTypography variant="button" color="white" verticalAlign="top">W</SoftTypography>
                                                                </SoftTypography>
                                                            </SoftBox>
                                                            <SoftBox ml={{ xs: 3, md: 8 }} mb={{ xs: 1, md: 0 }}>
                                                                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                                                                    Abmessung
                                                                </SoftTypography>
                                                                <SoftTypography variant="h3" fontWeight="bold" color="white">
                                                                    {module?.description ? JSON.parse(module?.description).Modulabmessung_width : ''} - {module?.description ? JSON.parse(module?.description).Modulabmessung_height : ''}
                                                                    <SoftTypography variant="button" color="white" verticalAlign="top">mm</SoftTypography>
                                                                </SoftTypography>
                                                            </SoftBox>
                                                        </SoftBox>
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} lg={5}>
                                                    <SoftBox textAlign="center">
                                                        <SoftBox
                                                            component="img"
                                                            src={mercedesEQC}
                                                            alt="car image"
                                                            width="auto"
                                                            justifyContent={{ xs: "flex-center", md: "center" }}
                                                            mt={{ xs: 0, lg: -30 }}
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} lg={3}>
                                                    <SoftBox px={{ xs: 0, md: 1.5 }}>
                                                        <SoftTypography variant="h5" color="white" textTransform="capitalize" opacity={0.9}>
                                                            {module?.short_itle}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )) : ''}

                                    {!data?.modules || !data?.modules.length ? (
                                        <div className="py-3 px-5" style={{ background: 'linear-gradient(263.4deg, #130744 14.32%, #208DFC 77.34%)', borderRadius: '1rem' }}>
                                            <Grid container alignItems="center" position="relative">
                                                <Grid item xs={12} lg={4}>
                                                    <SoftBox px={{ xs: 0, md: 1.5 }}>
                                                        <SoftTypography variant="h4" color="white" textTransform="capitalize" opacity={0.9}>
                                                            --
                                                        </SoftTypography>
                                                        <Divider light />
                                                        <SoftBox display="flex">
                                                            <SoftBox>
                                                                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                                                                    power
                                                                </SoftTypography>
                                                                <SoftTypography variant="h3" fontWeight="bold" color="white">
                                                                    --
                                                                    <SoftTypography variant="button" color="white" verticalAlign="top">W</SoftTypography>
                                                                </SoftTypography>
                                                            </SoftBox>
                                                            <SoftBox ml={{ xs: 3, md: 8 }} mb={{ xs: 1, md: 0 }}>
                                                                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                                                                    Abmessung
                                                                </SoftTypography>
                                                                <SoftTypography variant="h3" fontWeight="bold" color="white">
                                                                    -- - --
                                                                    <SoftTypography variant="button" color="white" verticalAlign="top">mm</SoftTypography>
                                                                </SoftTypography>
                                                            </SoftBox>
                                                        </SoftBox>
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} lg={5}>
                                                    <SoftBox textAlign="center">
                                                        <SoftBox
                                                            component="img"
                                                            src={mercedesEQC}
                                                            alt="car image"
                                                            width="auto"
                                                            justifyContent={{ xs: "flex-center", md: "center" }}
                                                            mt={{ xs: 0, lg: -30 }}
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} lg={3}>
                                                    <SoftBox px={{ xs: 0, md: 1.5 }}>
                                                        <SoftTypography variant="h5" color="white" textTransform="capitalize" opacity={0.9}>
                                                            {module?.short_itle}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    ) : ''}

                                    <div className="row mt-4">
                                        {openPDF ?
                                            <>
                                                <div className="col-sm-6">
                                                    <a onClick={() => setOpenPDF(false)} style={{ display: 'inline-block', position: 'relative', top: '-12px', borderBottom: '1px solid rgb(0, 84, 152)', fontSize: '15px', color: 'rgb(0, 84, 152)', margin: '0px', padding: '0px', fontWeight: '700' }}><i className="fa-solid fa-arrow-left-long"></i> {t('Zurück')}</a>
                                                    {saving === false ? (
                                                        <iframe src={backend_url + '/quation/' + data?.data?.quations?.id + '#page=5'} style={{ width: '100%', height: '1123px' }}></iframe>
                                                    ) : ''}
                                                </div>

                                                <div className="col-sm-6" style={{ paddingLeft: '0px' }}>
                                                    <div className="row">
                                                        <div className="col-lg-12 mt-lg-0" style={{ position: 'relative' }}>
                                                            {/* {playAnimation ? <div className="loader-pdf"><p>Loading...</p></div> : ''} */}
                                                            <div className={playAnimation ? 'mb-4 loading' : "mb-4"}>

                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        {!showPadClient ? <div><div className="sign-pad">
                                                                            <i className="fa fa-pencil" onClick={() => hideSignClient()} style={{ position: 'relative', top: '140px' }}></i>
                                                                            <img style={{ width: '100%' }} src={data && data.order ? process.env.REACT_APP_STORAGE_URL + 'signs/' + data.order.client_sign : ''} alt="" />
                                                                            <p style={{ borderTop: '1px solid #000', textAlign: 'center' }}>{t('Unterschrift Auftraggeber')}</p>
                                                                        </div> <button type="button" className="btn btn-outline-dark" onClick={() => { signaturePadRefClient.current.clear(); }}>
                                                                                <i className="fa fa-eraser"></i> Radieren
                                                                            </button></div> : ' '}
                                                                        {showPadClient ? <div><div className="sign-pad-new">
                                                                            <SignaturePad options={{ minWidth: 1, maxWidth: 1, penColor: 'rgb(0, 0, 0)', backgroundColor: '#b9d1ff5e' }} ref={signaturePadRefClient} />
                                                                            <p style={{ borderTop: '1px solid #000', textAlign: 'center', marginTop: '18px' }}>{t('Unterschrift Auftraggeber')}</p>
                                                                        </div>
                                                                        </div> : ''}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className={"row mt-4" + (props?.saveBtnDisplay == false ? 'd-none' : '')}>
                                                                <div className="col-sm-7 mt-3">
                                                                    {!saving && (
                                                                        <button type="button" id="saveSign" onClick={() => onSubmit({})} className="btn btn-primary mr-2" style={{ fontSize: '0.875rem', background: 'rgb(38, 132, 255)'}}>
                                                                            {t('Akzeptieren und Unterschreiben')}
                                                                        </button>

                                                                    )}
                                                                    {saving && (
                                                                        <button type="button" className="btn btn-disabled mr-2" style={{ fontSize: '0.875rem' }} disabled>
                                                                            {t('Saving ...')}
                                                                        </button>
                                                                    )}
                                                                    &nbsp;&nbsp; <button type="button" style={{ fontSize: '0.875rem'}} className="btn btn-outline-dark me-2" onClick={() => { signaturePadRefClient.current.clear(); }}>
                                                                        <i className="fa fa-eraser"></i> Radieren
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="col-md-4">
                                                    <TimelineNew refresh={refresh2} api_url={backend_url ? backend_url + '/api/v1/' : ''} />
                                                    <div className="mt-3" style={{ padding: '15px', position: 'relative', color: '#fff', borderRadius: '15px', background: 'linear-gradient(360deg, #0962CA 0%, #CB0C9F 100%)', height: '150px', width: '100%' }}>
                                                        <SoftTypography color="#fff" variant="h4">750 € Prämie sichern</SoftTypography>
                                                        <SoftTypography color="#fff" variant="caption" fontSize="17px">Empfehlen Sie Ihren Kunden {settings?.angebot?.company_name ? settings?.angebot?.company_name : '--'} weiter und erhalten Sie eine Sonderprämie i.h.v 750 EURO </SoftTypography>
                                                        <a className="text-white" style={{ position: 'absolute', bottom: '15px', right: '28px', fontSize: '12px' }}>Erfahre mehr <i className="fa fa-arrow-right"></i> </a>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 mt-5 pt-5 pt-md-0 mt-md-0">
                                                    <SoftTypography variant="h6" fontWeight="bold">Lieferadresse <span style={{ color: 'red', fontSize: '10px' }}>(Kundendaten löschen)</span></SoftTypography>
                                                    <div className="card mt-2 mb-3" style={{ backgroundColor: '#F8F9FA', boxShadow: 'none' }}>
                                                        <div className="card-body" style={{ padding: '15px' }}>
                                                            <SoftBox>
                                                                <div className="row" style={{ lineHeight: '16px' }}>
                                                                    <div className="col-sm-12"><SoftTypography variant="caption" fontSize="12px" fontSizeXS style={{ fontWeight: '400' }}>Name:</SoftTypography> <SoftTypography variant="caption" fontSize="12px" fontSizeXS fontWeight="bold"> {data?.data?.vorname}  {data?.data?.name}</SoftTypography> </div>
                                                                </div>
                                                                <div className="row" style={{ lineHeight: '16px' }}>
                                                                    <div className="col-sm-12"><SoftTypography variant="caption" fontSize="12px" style={{ fontWeight: '400' }}>Tel:</SoftTypography> <SoftTypography variant="caption" fontSize="12px" fontWeight="bold"> {(data?.data?.telefonnummer ? data?.data?.telefonnummer : '') + (data?.data?.mobilnummer ? (data?.data?.telefonnummer ? ', ' : '') + data?.data?.mobilnummer : '')} </SoftTypography> </div>
                                                                </div>
                                                                <div className="row" style={{ lineHeight: '16px' }}>
                                                                    <div className="col-sm-12"><SoftTypography variant="caption" fontSize="12px" style={{ fontWeight: '400' }}>E-mail:</SoftTypography> <SoftTypography variant="caption" fontSize="12px" fontWeight="bold"> {data?.data?.email} </SoftTypography> </div>
                                                                </div>
                                                                <div className="row" style={{ lineHeight: '16px' }}>
                                                                    <div className="col-sm-12"><SoftTypography variant="caption" fontSize="12px" style={{ fontWeight: '400' }}>Straße, Nr.:</SoftTypography> <SoftTypography variant="caption" fontSize="12px" fontWeight="bold">{data?.data?.street} {data?.data?.nr}, {data?.data?.plz}, {data?.data?.ort} </SoftTypography> </div>
                                                                </div>
                                                            </SoftBox>
                                                        </div>
                                                    </div>

                                                    <SoftTypography variant="h6" fontWeight="bold">Auftrag Details</SoftTypography>
                                                    <div className="card mt-2 mb-3" style={{ backgroundColor: '#F8F9FA', boxShadow: 'none' }}>
                                                        <div className="card-body" style={{ padding: '15px', minHeight: '130px' }}>
                                                            <SoftBox>
                                                                <table style={{ width: '100%' }}>
                                                                    {data?.data?.quations?.id && (
                                                                        <tr>
                                                                            <td style={{ width: '25%' }}><SoftTypography variant="caption" fontSize="12px" fontSizeXS style={{ fontWeight: '400' }}>Angebot:</SoftTypography></td>
                                                                            <td style={{ width: '40%' }}><SoftTypography variant="caption" fontSize="12px" fontSizeXS style={{ fontWeight: '700' }}>{data?.data?.quations?.id} vom {dateFormat(data?.data?.quations?.created_at, "dd.mm.yyyy")}</SoftTypography></td>
                                                                            <td style={{ width: '35%', textAlign: 'center', color: '#5E25FF' }}> <a target={"_blank"} rel="noreferrer" style={{ color: '#5E25FF' }} href={settings?.backend_url + 'quation/' + data?.data?.quations?.id}> <SoftTypography variant="caption" color="#5E25FF" fontSize="12px" fontSizeXS style={{ fontWeight: '700' }}><i className="fa fa-file-lines"></i> PDF Herunterladen</SoftTypography> </a></td>
                                                                        </tr>
                                                                    )}
                                                                    {data?.data?.quations?.order?.id && (
                                                                        <tr>
                                                                            <td style={{ width: '25%' }}><SoftTypography variant="caption" fontSize="12px" fontSizeXS style={{ fontWeight: '400' }}>Auftrag:</SoftTypography></td>
                                                                            <td style={{ width: '40%' }}><SoftTypography variant="caption" fontSize="12px" fontSizeXS style={{ fontWeight: '700' }}>{data?.data?.quations?.order?.id} vom {dateFormat(data?.data?.quations?.order?.created_at, "dd.mm.yyyy")}</SoftTypography></td>
                                                                            <td style={{ width: '35%', textAlign: 'center', color: '#5E25FF' }}>   <a target={"_blank"} rel="noreferrer" style={{ color: '#5E25FF' }} href={settings?.backend_url + 'order/' + data?.data?.quations?.order?.id}> <SoftTypography variant="caption" color="#5E25FF" fontSize="12px" fontSizeXS style={{ fontWeight: '700' }}><i className="fa fa-file-lines"></i> PDF Herunterladen</SoftTypography> </a></td>
                                                                        </tr>
                                                                    )}

                                                                    {data?.data?.quations?.order?.invoices && data?.data?.quations?.order?.invoices.map((invoice, key) => (
                                                                        <tr>
                                                                            <td style={{ width: '25%' }}><SoftTypography variant="caption" fontSize="12px" fontSizeXS style={{ fontWeight: '400' }}>{key + 1}. Rechnung:</SoftTypography></td>
                                                                            <td style={{ width: '40%' }}><SoftTypography variant="caption" fontSize="12px" fontSizeXS style={{ fontWeight: '700' }}>{invoice.id} vom {dateFormat(invoice.created_at, "dd.mm.yyyy")}</SoftTypography></td>
                                                                            <td style={{ width: '35%', textAlign: 'center', color: '#5E25FF' }}> <a target={"_blank"} rel="noreferrer" style={{ color: '#5E25FF' }} href={settings?.backend_url + 'invoice/' + invoice.id}> <SoftTypography variant="caption" color="#5E25FF" fontSize="12px" fontSizeXS style={{ fontWeight: '700' }}><i className="fa fa-file-lines"></i> PDF Herunterladen</SoftTypography> </a></td>
                                                                        </tr>
                                                                    ))}
                                                                </table>
                                                            </SoftBox>
                                                        </div>
                                                    </div>

                                                    <SoftTypography variant="h6" fontWeight="bold">Rechnung Details</SoftTypography>
                                                    <div className="card mt-2" style={{ backgroundColor: '#F8F9FA', boxShadow: 'none' }}>
                                                        <div className="card-body" style={{ padding: '15px' }}>
                                                            <SoftBox>
                                                                <div className="row" style={{ lineHeight: '16px' }}>
                                                                    <div className="col-sm-12"><SoftTypography variant="caption" fontSize="12px" fontSizeXS style={{ fontWeight: '400' }}>Rechnungsempfänger:</SoftTypography> <SoftTypography variant="caption" fontSize="12px" fontSizeXS fontWeight="bold"> {data?.data?.vorname} {data?.data?.name}</SoftTypography> </div>
                                                                </div>
                                                                <div className="row" style={{ lineHeight: '16px' }}>
                                                                    <div className="col-sm-12"><SoftTypography variant="caption" fontSize="12px" style={{ fontWeight: '400' }}>Tel:</SoftTypography> <SoftTypography variant="caption" fontSize="12px" fontWeight="bold"> {(data?.data?.telefonnummer ? data?.data?.telefonnummer : '') + (data?.data?.mobilnummer ? (data?.data?.telefonnummer ? ', ' : '') + data?.data?.mobilnummer : '')}</SoftTypography> </div>
                                                                </div>
                                                                <div className="row" style={{ lineHeight: '16px' }}>
                                                                    <div className="col-sm-12"><SoftTypography variant="caption" fontSize="12px" style={{ fontWeight: '400' }}>E-Mail:</SoftTypography> <SoftTypography variant="caption" fontSize="12px" fontWeight="bold">{data?.data?.email}</SoftTypography> </div>
                                                                </div>
                                                                <div className="row" style={{ lineHeight: '16px' }}>
                                                                    <div className="col-sm-12"><SoftTypography variant="caption" fontSize="12px" style={{ fontWeight: '400' }}>Straße, Nr.:</SoftTypography> <SoftTypography variant="caption" fontSize="12px" fontWeight="bold">{data?.data?.street} {data?.data?.nr}, {data?.data?.plz}, {data?.data?.ort}</SoftTypography> </div>
                                                                </div>
                                                            </SoftBox>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-4">
                                                    <SoftTypography variant="h6" fontWeight="bold">Ihr Lieferant</SoftTypography>
                                                    <img src={settings?.backend_url + 'storage/attachments/' + settings?.angebot?.logo} alt="" className="mt-2" style={{ height: '60px' }} />
                                                    <div className="row">
                                                        <div className="col-sm-7 pt-4">
                                                            <SoftTypography variant="h6" fontWeight="bold">{settings?.angebot?.company_name ? settings?.angebot?.company_name : '--'}</SoftTypography>
                                                            <SoftTypography variant="caption" style={{ display: 'inline-block', lineHeight: '16px' }}>
                                                                {settings?.angebot?.address ? settings?.angebot?.address : '--'} {settings?.angebot?.nr ? settings?.angebot?.nr : '--'} <br />
                                                                {settings?.angebot?.plz ? settings?.angebot?.plz : '--'} {settings?.angebot?.ort ? settings?.angebot?.ort : '--'} <br />
                                                                {settings?.angebot?.email ? settings?.angebot?.email : '--'}<br />
                                                                T {settings?.angebot?.telephone ? settings?.angebot?.telephone : '--'}<br />
                                                            </SoftTypography>
                                                        </div>
                                                        {/*
                                                    <div className="col-sm-5 pt-4 " style={{ textAlign: 'right' }}>
                                                        <button className="btn btn-primary" style={{ background: ('linear-gradient(121.78deg, #0048B1 9.06%, #2152FF 88.15%'), fontSize: '0.875rem', }}>Kontaktieren</button>
                                                    </div>
                                                    */}
                                                    </div>

                                                    <div className="row mt-4">
                                                        <div className="col-sm-12 pt-3">
                                                            <SoftTypography variant="h6" fontWeight="bold">Zahlungsmodalitäten</SoftTypography>

                                                        </div>
                                                    </div>
                                                    <div className="row ">
                                                        <div className="col-8" style={{ lineHeight: '2.5' }}>
                                                            <SoftTypography variant="caption" fontWeight="regular">Summe: </SoftTypography>
                                                        </div>
                                                        <div className="col-4" style={{ textAlign: 'right' }}>
                                                            <SoftTypography variant="caption" fontWeight="bold" style={{ fontSize: '1.25rem' }}><NumericFormat value={data?.data?.quations?.gesami_netto} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} /> € </SoftTypography>
                                                        </div>
                                                    </div>
                                                    {invoices && invoices.map((invoice, key) => (
                                                        <div className="row">
                                                            <div className="col-8" style={{ lineHeight: '2.5' }}>
                                                                <SoftTypography variant="caption" fontWeight="regular">{key + 1}.Abschlag {invoice.amountPerchantage}% </SoftTypography> &nbsp;&nbsp;&nbsp;&nbsp; {invoice.status == 0 ? <span className="badge badge-danger">Zahlung offen</span> : <span className="badge badge-success">Zahlung erhalten</span>}
                                                            </div>
                                                            <div className="col-4" style={{ textAlign: 'right' }}>
                                                                <SoftTypography variant="caption" fontWeight="bold" style={{ fontSize: '1.25rem' }}><NumericFormat value={invoice.brutto} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} /> €</SoftTypography>
                                                            </div>
                                                        </div>
                                                    ))}

                                                    <div className="row">
                                                        <div className="col-8" style={{ lineHeight: '2.5' }}>
                                                            <SoftTypography variant="caption" fontWeight="regular">Gesamtsumme Netto: </SoftTypography>
                                                        </div>
                                                        <div className="col-4" style={{ textAlign: 'right' }}>
                                                            <SoftTypography variant="caption" fontWeight="bold" style={{ fontSize: '1.25rem' }}><NumericFormat value={data?.data?.quations?.gesami_netto} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} /> €</SoftTypography>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-sm-12" style={{ textAlign: 'right' }}>
                                                            <button onClick={() => openThePDF(true)} className="btn btn-primary" style={{ backgroundColor: '#CB0C9F', borderColor: '#CB0C9F', fontSize: '0.875rem' }}>Angebot Unterschreiben</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </>
                            )}

                        </div>
                        {renderSuccessSB}
                    </div>

                    <button className="d-none" id="callcenterRefesh3" onClick={() => { setRefesh3(refesh3 + 1); }}>refesh3</button>
                </div>

                {steps == 1 ? <div className="modal show custommodal" style={{ display: 'block' }} id="" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" style={{ maxWidth: '752px', minHeight: '656px' }}>
                        <div className="modal-content" style={{ borderRadius: '30px', minHeight: '656px' }}>
                            <button type="button" id="emailModalOneClose" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className="modal-body" style={{ padding: '3.5rem' }}>
                                <SoftTypography variant="h4" color="#313860" fontSize="20px" fontWeight="bold">Herzlich Willkommen auf Solacloud! </SoftTypography>
                                <br />
                                <SoftTypography variant="caption" color="#313860" style={{ lineHeight: '20px', fontSize: '0.875rem', display: 'inline-block' }}>
                                    Wir nehmen den Datenschutz sehr ernst und möchten Sie darüber informieren, dass alle personenbezogenen Daten, die im Rahmen dieses Projekts erfasst oder verarbeitet wurden, nach erfolgreichem Abschluss des Projekts umgehend und dauerhaft gelöscht werden. Es ist zu beachten, dass Kunden, die unser CRM-System nutzen, die Daten autonom in ihrem eigenen CRM-System speichern. Für diese Datenspeicherung ist der Kunde eigenverantwortlich.
                                </SoftTypography>
                                <br />
                                <br />
                                <SoftTypography variant="button" style={{ display: 'block', marginBottom: '12px', color: '#0048B1', fontSize: '16px' }} fontWeight="bold">
                                    Ich bestätige hiermit, dass die angegebene E-Mail-Adresse mir gehört.
                                </SoftTypography>
                                <SoftTypography color="#313860" variant="caption" style={{ lineHeight: '20px', fontSize: '0.875rem', display: 'inline-block' }}>
                                    Bestätigung des Empfängers: Ich bestätige, dass ich der vorgesehene Empfänger dieser Informationen bin und dass der Zugriff auf diese Daten im Einklang mit dem Zweck meiner Beziehung zum Hauptkunden steht.
                                </SoftTypography>
                                <br />
                                <br />
                                <SoftTypography style={{ display: 'block', color: '#0048B1', fontSize: '20px', textAlign: 'center' }} fontWeight="">{confirmInfo?.project?.email}</SoftTypography>
                                <br />
                                <SoftTypography color="#313860" variant="caption" style={{ lineHeight: '20px', fontSize: '0.875rem', display: 'inline-block' }}>
                                    Für Anfragen und Anregungen können sie uns jederzeit und folgenden Kontaktdaten erreichen: support@solacloud.de
                                </SoftTypography>

                                <div className="row mt-5">
                                    <div className="col-sm-12" style={{ textAlign: 'center' }}>
                                        {showButton ? settings?.data?.order_overview_verification == 0 ? sending ? <button className="btn btn-primary" style={{ backgroundColor: '#ddd', borderColor: '#ddd', marginBottom: '0px', color: '#000', fontSize: '0.875rem', }}>Verifizieren und Fortfahren...</button> : <button onClick={() => accept()} className="btn btn-primary" style={{ backgroundColor: '#CB0C9F', borderColor: '#CB0C9F', marginBottom: '0px', fontSize: '0.875rem' }}>Akzeptieren und fortfahren</button> : <button onClick={() => sendVerificationCode()} className="btn btn-primary" style={{ backgroundColor: '#CB0C9F', borderColor: '#CB0C9F', marginBottom: '0px', fontSize: '0.875rem' }}>Verifizieren und Fortfahren</button> : ''}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> : steps == 2 ? <div className="modal show custommodal" style={{ display: 'block' }} id="" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" style={{ maxWidth: '752px' }}>
                        <div className="modal-content" style={{ borderRadius: '30px', minHeight: '656px' }}>
                            <button type="button" id="emailModalTwoClose" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className="modal-body" style={{ padding: '3.5rem' }}>
                                <SoftTypography variant="h4" color="#313860" fontSize="20px" fontWeight="bold">Herzlich Willkommen auf Solacloud! </SoftTypography>
                                <br />
                                <SoftTypography variant="caption" color="#313860" style={{ lineHeight: '20px', fontSize: '0.875rem', display: 'inline-block' }}>
                                    Ihre Sicherheit liegt uns am Herzen. Um sicherzustellen, dass nur du Zugriff auf dein Konto und deine Daten hast, haben wir die 2-Schritt-Verifikation eingeführt. Bei diesem Prozess senden wir dir einen eindeutigen Code an deine registrierte E-Mail-Adresse. Durch die Eingabe dieses Codes bestätigst du, dass du tatsächlich der rechtmäßige Inhaber des Kontos und der E-Mail-Adresse bist. Dieser zusätzliche Schritt hilft uns, dein Konto vor unberechtigtem Zugriff zu schützen.
                                </SoftTypography>
                                <br />
                                <br />
                                {showNotification ? <SoftAlert color="info" style={{ textAlign: 'center' }} textAlign="center"> <Icon fontSize="small">info</Icon>&nbsp; {resend ? t('Code wurde erneut an Ihre E-Mail versendet.') : t('Bitte prüfen Sie Ihren E-Mail Postfach')}  </SoftAlert> : ''}
                                <SoftTypography color="#313860" variant="h2" style={{ display: 'block', textAlign: 'center' }} fontWeight="bold">
                                    2-Step Verifikation
                                </SoftTypography>
                                <br />
                                <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" id="editdst">
                                    <div style={{ textAlign: 'center' }}>
                                        <OtpInput containerStyle={{ display: 'inline-block', textAlign: 'center' }} inputStyle={{ textAlign: 'center', fontSize: '24px', marginRight: '10px', width: '55px', height: '40px', border: '1px solid #ddd', borderRadius: '8px' }} skipDefaultStyles={true} value={otp} onChange={setOtp} numInputs={4} renderSeparator={<span> </span>} renderInput={(props, index) => <input {...register("code" + index)}  {...props} />} />
                                    </div>
                                    {invalidCode ? <span style={{ display: 'inline-block', width: '100%', padding: '8px 0px' }} className="text-danger text-center">{t('Ihr Code ist ungültig!')}</span> : ''}
                                    <br />
                                    <div className="row">
                                        <div className="col-sm-12" style={{ textAlign: 'center' }}>
                                            <button type="submit" className="btn btn-primary" style={{ backgroundColor: '#CB0C9F', borderColor: '#CB0C9F', marginBottom: '0px', width: '300px', fontSize: '0.875rem', }}>Bestätigen</button>
                                        </div>
                                    </div>
                                </form>
                                <br />
                                <SoftTypography color="secondary" style={{ display: 'block', fontSize: '16px', textAlign: 'center' }} fontWeight="">{t('Keine E-Mail erhalten?')}<span style={{ color: '#252F40', cursor: 'pointer' }} onClick={() => resendNewCode()}> {t('Code erneut senden.')}</span> </SoftTypography>

                            </div>

                        </div>
                    </div>
                </div> : steps == 3 ? '' : ''}




                <button
                    type="button"
                    id="successSignModalBtn"
                    className="btn bg-gradient-primary d-none"
                    data-bs-toggle="modal"
                    data-bs-target="#successSignModal"
                >{t('Success Sign Modal')}</button>
                <div className="modal fade" id="successSignModal" tabindex="-1" role="dialog" aria-labelledby="companyProfileChooseLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" style={{ maxWidth: '752px' }}>
                        <div className="modal-content" style={{ borderRadius: '30px' }}>
                            <button type="button" id="successSignModalClose" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className="modal-body" style={{ padding: '3.5rem' }}>

                                <img src={Group_1000004630} className="w-100 mt-3" />

                                <SoftTypography variant="h3" style={{ color: '#0048B1' }} mb={4} >
                                    Herlzichen Glückwunsch!
                                </SoftTypography>
                                <SoftTypography variant="caption" color="#313860" style={{ lineHeight: '20px', fontSize: '0.875rem', display: 'inline-block' }}>
                                    Fantastisch! Ihre Unterschrift markiert den Beginn einer Zukunft mit sauberer Energie. Herzlichen Glückwunsch zur Wahl einer umweltfreundlichen Lösung für Ihr Zuhause.
                                </SoftTypography>

                                <button type="button" onClick={() => setOpenPDF(false)} data-bs-dismiss="modal" aria-label="Close" className="btn btn-primary mt-5 mb-0" style={{ backgroundColor: '#1D80E5', borderColor: '#1D80E5', width: '300px', fontSize: '0.875rem', }}>Zu Ihrer Übersicht</button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default OrdersOverviewEmailConfirm;
