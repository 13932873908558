import { useMemo, useEffect, useState, useRef } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftSelect from "components/SoftSelect";
import PieChart from "examples/Charts/PieChart";
import SoftBadgeDot from "components/SoftBadgeDot";
import { useTranslation } from 'react-i18next';

import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

import PagesHeaderCell from "layouts/applications/analytics/components/PagesHeaderCell";
import PagesBodyCell from "layouts/applications/analytics/components/PagesBodyCell";

import SoftAvatar from "components/SoftAvatar";

import callFetch from "../../../helpers/callFetch";
import { useForm } from "react-hook-form";

import { NumericFormat } from 'react-number-format';

import LoadingCard from "components/LoadingCard";
import borders from "assets/theme/base/borders";
import Flatpickr from "react-flatpickr";
import dateFormat from "dateformat";

function TechnicianSalesStatusPieChartV2(props) {
    const { borderWidth, borderColor } = borders;
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const fp = useRef(null);
    const [loading, setLoading] = useState(false);
    const [pieChartData, setPieChartData] = useState({});
    const [refresh, setRefresh] = useState(0);
    const [users, setUsers] = useState([]);
    const [data, SetData] = useState([]);
    const [verkaufer, setVerkaufer] = useState();
    const [teams, setTeams] = useState();
    const [allTeams, setAllTeams] = useState([]);
    const [filterData, setFilterData] = useState({
        verkaufer: 0,
        created_at: '',
    });

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleDateChange = (selectedDates) => {
        // Handle selected date range 
        if (Object.keys(selectedDates).length <= 0) {
            setStartDate('');
            setEndDate('');
        } else {
            const [startDate, endDate] = selectedDates;
            setStartDate(dateFormat(startDate, "yyyy-mm-dd"));
            setEndDate(dateFormat(endDate, "yyyy-mm-dd"));
        }

        setRefresh(refresh + 1);
    };

    useEffect(() => {
        setLoading(true);
        if (!filterData?.created_at) return;

        filterData.startDate = startDate;
        filterData.endDate = endDate;

        callFetch("statistics/technician-sales-status-pie-chart", "POST", filterData, setError).then((res) => {
            SetData(res?.data);
            setPieChartData(res?.data?.pie_data);
            setUsers(res?.data?.users);
            setAllTeams(res?.data?.salesTeam);
            setLoading(false);
            if (!res.ok) return;

        });
    }, [refresh]);

    useEffect(() => {
        if (!props?.filterData?.created_at) return;

        if (props?.filterData?.created_at != filterData?.created_at) {
            var data = filterData;
            data.created_at = props?.filterData?.created_at;
            setFilterData(data);
            setRefresh(refresh + 1)
        }
    }, [props?.filterData?.created_at]);

    return (
        <>
            <Card sx={{ overflow: "visible" }} className="technical-sales-status-pie-chart">
                <Grid container alignItems="end" display="flex" px={2}>
                    <Grid item xs={12} sm={12} lg={12}>
                        <SoftTypography
                            className="mt-3"
                            fontWeight="bold"
                            variant="h5"
                            color="text-dark"
                        >
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    {t(props?.title)}
                                </div>
                                <div className="col-12 col-md-4">
                                    <SoftSelect
                                        className="mt-1"
                                        size="small"
                                        placeholder="VKB Name"
                                        options={users}
                                        defaultValue={verkaufer}
                                        onChange={(e) => {

                                            var data = filterData;

                                            var verkaufer_ids = [];
                                            for (let [key, value] of Object.entries(e)) {
                                                verkaufer_ids.push(value.value)
                                            }
                                            data.verkaufer = JSON.stringify(verkaufer_ids);

                                            setFilterData(data);
                                            setVerkaufer(e);
                                            setRefresh(refresh + 1)
                                        }}
                                        isMulti={true}
                                    />
                                </div>
                                <div className="col-12 col-md-4">
                                    <SoftSelect
                                        className="mt-1"
                                        size="small"
                                        placeholder="Sales Team"
                                        options={allTeams}
                                        defaultValue={teams}
                                        getOptionLabel={(option) => ((option.team_name))}
                                        getOptionValue={(option) => option.id}
                                        onChange={(e) => {
                                            var data = filterData;

                                            var team_ids = [];
                                            for (let [key, value] of Object.entries(e)) {
                                                team_ids.push(value.id)
                                            }
                                            data.team = JSON.stringify(team_ids);

                                            setFilterData(data);
                                            setTeams(e);
                                            setRefresh(refresh + 1)
                                        }}
                                        isMulti={true}
                                    />
                                </div>

                                {props?.title == 'Flexible' ? (
                                    <div className="col-12 col-md-6">
                                        <div className="mt-1">
                                            <Flatpickr
                                                ref={fp}
                                                options={{
                                                    mode: 'range',
                                                    dateFormat: 'Y-m-d',
                                                }}
                                                onChange={handleDateChange}
                                                placeholder="Selec Date"
                                                style={{
                                                    height: '32px',
                                                    color: '#344767',
                                                    opacity: 0.5,
                                                    fontSize: '0.75rem',
                                                    border: '0.0625rem solid #d2d6da'
                                                }}
                                            />
                                        </div>
                                    </div>
                                ) : ''}
                            </div>
                        </SoftTypography>
                    </Grid>
                </Grid>
                <SoftBox p={2} mt={0}>
                    <Grid container>
                        <Grid item xs={12} sm={4} lg={4}>
                            <SoftTypography
                                variant="button"
                                color={'text'}
                                opacity={'white'}
                                textTransform="capitalize"
                                fontWeight={'medium'}
                                className={'ministatistics-title'}
                            >
                                {'Umsatz in EUR'}
                            </SoftTypography>
                            <SoftTypography
                                fontWeight="bold"
                                variant="h6"
                                color="text-dark"
                                className="total-ammunt"
                            >
                                <NumericFormat
                                    value={data?.total_auftrage_ammount ? data?.total_auftrage_ammount : 0}
                                    displayType="text"
                                    thousandSeparator={"."}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix={' € '}
                                />
                            </SoftTypography>
                            <PieChart
                                className={"p-0"}
                                chart={pieChartData}
                                height="150px"
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} lg={8} mt={1.6}>
                            <SoftBox px={0}>
                                {pieChartData?.labels ?
                                    pieChartData?.labels?.map((status, index) =>
                                        <SoftBox mb={0.5} key={index}>
                                            <SoftBadgeDot
                                                className="soft-badge-dot"
                                                color={pieChartData?.datasets?.backgroundColors[index]}
                                                size="sm"
                                                //badgeContent={status+' - '+pieChartData?.datasets?.data[index]}
                                                badgeContent={'Note ' + (index + 1) + ' = '}
                                                boldBadgeContent={pieChartData?.datasets?.data[index] + ' Stck. | ' + (pieChartData?.datasets?.data[index] ? ((pieChartData?.datasets?.data[index] / data?.totalProjects) * 100).toFixed(2) : 0) + ' %'}
                                            />
                                        </SoftBox>
                                    ) : <></>}
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} mt={1.6}>
                            <TableContainer sx={{ boxShadow: "none" }}>
                                <Table>
                                    <SoftBox component="thead">
                                        <TableRow>
                                            <PagesHeaderCell>Instanz</PagesHeaderCell>
                                            <PagesHeaderCell></PagesHeaderCell>
                                            <PagesHeaderCell>Bounce:</PagesHeaderCell>
                                        </TableRow>
                                    </SoftBox>
                                    <TableBody>
                                        <PagesBodyCell rows={["Stornoquote Gesamt", "", ((data?.stornoquote_gesamt ? data?.stornoquote_gesamt : '---') + '%')]} />
                                        <PagesBodyCell rows={["Stornoquote nach DC", "", ((data?.stornoquote_nach_DC ? data?.stornoquote_nach_DC : '---') + '%')]} />
                                        <PagesBodyCell rows={["Schreibquote", "", ((data?.schreibquote ? data?.schreibquote : '---') + '%')]} />
                                        <PagesBodyCell rows={["Schreibquote aus 1-4", "", ((data?.schreibquote_aus_1_4 ? data?.schreibquote_aus_1_4 : '---') + '%')]} />
                                        <PagesBodyCell rows={["Aufträge vor Storno", "", (data?.auftrage_vor_storno >= 0 ? data?.auftrage_vor_storno : '---')]} />
                                        <PagesBodyCell rows={["Aufträge nach Storno", "", (data?.auftrage_nach_storno >= 0 ? data?.auftrage_nach_storno : '---')]} />
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12} mt={1.6}>
                            <SoftBox
                                border={`${borderWidth[1]} solid ${borderColor}`}
                                borderRadius="lg"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                p={1}
                                my={2}
                            >
                                <SoftBox>
                                    <SoftTypography sx={{ fontSize: "0.68rem" }} variant="button" fontWeight="medium" color="secondary">
                                        DC mit Montagetermin: <SoftTypography component="span" variant="button" color="info">{(data?.total_DC_mit_montagetermin >= 0 ? data?.total_DC_mit_montagetermin : '---')}</SoftTypography>
                                    </SoftTypography>
                                    <br />
                                    <SoftTypography sx={{ fontSize: "0.68rem" }} variant="button" fontWeight="medium" color="secondary">
                                        DC ohne Montagetermin: <SoftTypography component="span" variant="button" color="info">{(data?.DCOhneMontagetermin >= 0 ? data?.DCOhneMontagetermin : '---')}</SoftTypography>
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox>
                                    <SoftTypography sx={{ fontSize: "0.68rem" }} variant="button" fontWeight="medium" color="secondary">
                                        AC mit Montagetermin: <SoftTypography component="span" variant="button" color="success">{(data?.total_AC_mit_montagetermin >= 0 ? data?.total_AC_mit_montagetermin : '---')}</SoftTypography>
                                    </SoftTypography>
                                    <br />
                                    <SoftTypography sx={{ fontSize: "0.68rem" }} variant="button" fontWeight="medium" color="secondary">
                                        AC ohne Montagetermin: <SoftTypography component="span" variant="button" color="success">{(data?.AC_OhneMontagetermin >= 0 ? data?.AC_OhneMontagetermin : '---')}</SoftTypography>
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                        </Grid>


                        {data && data?.sallerOfTheDay?.data ? data?.sallerOfTheDay?.data.map((sallerOfTheDay, index) => (
                            <Grid item xs={12} mt={1.6}>
                                <SoftTypography
                                    fontWeight="bold"
                                    variant="h6"
                                    color="text-dark"
                                    pl={2}
                                    pb={2}
                                >
                                    Nr. #{index + 1} Verkaufer des Tages
                                </SoftTypography>
                                <SoftBox display="flex" alignItems="center" pl={2}>
                                    <SoftBox mr={2}>
                                        <SoftAvatar src={sallerOfTheDay?.verkaufer_photo ? process.env.REACT_APP_STORAGE_URL + sallerOfTheDay?.verkaufer_photo : '/assets/img/placeholder.png'} alt={sallerOfTheDay?.verkaufer_name} variant="rounded" />
                                    </SoftBox>
                                    <SoftBox display="flex" flexDirection="column">
                                        <SoftTypography variant="h6" color="text-dark" fontWeight="medium">
                                            {sallerOfTheDay?.verkaufer_name}
                                        </SoftTypography>
                                        <SoftTypography variant="button" fontWeight="medium" color="secondary" sx={{ fontSize: "0.74rem" }}>
                                            <SoftTypography component="span" variant="button" color="success" sx={{ fontSize: "0.74rem" }}>
                                                {sallerOfTheDay?.total_order}
                                            </SoftTypography>{" "}
                                            Aufträge in Höhe von
                                            &nbsp;
                                            <SoftTypography component="span" variant="button" color="success" sx={{ fontSize: "0.74rem" }}>
                                                <NumericFormat
                                                    value={Number(sallerOfTheDay?.total_netto)}
                                                    displayType="text"
                                                    thousandSeparator={"."}
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    suffix={' € '}
                                                />
                                            </SoftTypography>
                                        </SoftTypography>
                                    </SoftBox>
                                </SoftBox>
                            </Grid>
                        )) : <></>}





                    </Grid>
                </SoftBox>
                {/*</>
                    )}*/}
            </Card>
        </>
    )
}

export default TechnicianSalesStatusPieChartV2;