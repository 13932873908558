import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { callFetch } from "../../helpers/callFetch";
// @mui core components
import Card from "@mui/material/Card";
import { Navigate, useParams, NavLink } from "react-router-dom";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import moment from "moment";
import { Grid, Icon, Menu } from "@mui/material";
import { useTranslation } from "react-i18next";
import Timeline from './Timeline';
import CloseIcon from "@mui/icons-material/Close";
function Overview(props) {
    const { t } = useTranslation()
    const [quationdata, setQuatationData] = useState(false);
    const [salsePerson, setSalsePerson] = useState(false);
    const [project, setProject] = useState(false);
    const [openMenu, setOpenMenu] = useState(null);
    const [data, setData] = useState([]);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(null);
    useEffect(() => {
        if (props.quation_id > 0 || props.project_id > 0 || props.order_id > 0) {
            var fromData = {}
            if (props.quation_id > 0) {
                fromData.quation_id = props.quation_id;
            } else if (props.project_id > 0) {
                fromData.project_id = props.project_id;
            } else if (props.order_id > 0) {
                fromData.order_id = props.order_id;
            } else {
                return;
            }

            callFetch("get-overview", "POST", fromData).then((res) => {
                setQuatationData(res.quation);
                setProject(res?.project)
                setSalsePerson(res.salse_person);
            });
        }
    }, [props?.quation_id, props?.project_id, props?.order_id]);

    const projectData = (id) => {
        setData([])
        if (id) {
            callFetch("project/timelines/" + id, "GET", []).then((res) => {
                setData(res.data);
            });
        }
    }

    const renderMenu = () => (
        data?.project && <Menu
            anchorEl={openMenu}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            keepMounted
        >
            <CloseIcon
                onClick={handleCloseMenu}
                style={{
                    width: "17px",
                    height: "25px",
                    cursor: "pointer",
                    zIndex: "1",
                }}
            />
            <Grid mt={0} item xs={12} lg={12} pl={8}>
                <Timeline datas={data} title="Project Status" />
            </Grid>
        </Menu>
    );

    console.log(project)

    return (
        <Card className={props?.className}>
            <div className="row" style={{ padding: '15px' }}>
                <div className="col-md-6">
                    <SoftBox>
                        <SoftTypography variant="h6" mr={1} >{props?.title}</SoftTypography>
                    </SoftBox>
                    <SoftBox>
                        <table>
                            <tr
                                style={{
                                    height: '20px',
                                    lineHeight: '20px'
                                }}
                            >
                                <td width="110"><SoftTypography variant="caption"><strong>{t('Name')}: </strong></SoftTypography></td>
                                <td><SoftTypography variant="caption">{project.vorname ? project.vorname : ' '} {project.name ? project.name : ' '}</SoftTypography></td>
                            </tr>
                            <tr
                                style={{
                                    height: '20px',
                                    lineHeight: '20px'
                                }}
                            >
                                <td><SoftTypography variant="caption"><strong>{t('Phone')}: </strong></SoftTypography></td>
                                <td>
                                    <SoftTypography variant="caption">
                                        {(project?.telefonnummer ? project?.telefonnummer : '') + (project?.mobilnummer ? (project?.telefonnummer ? ', ' : '') + project?.mobilnummer : '')}
                                    </SoftTypography>
                                </td>
                            </tr>
                            <tr
                                style={{
                                    height: '20px',
                                    lineHeight: '20px'
                                }}
                            >
                                <td><SoftTypography variant="caption"><strong>{t('Address')}: </strong></SoftTypography></td>
                                <td><SoftTypography variant="caption">{project.street ? project.street : ' '} {project.nr ? project.nr : ' '}, {project.plz ? project.plz : ' '}, {project.ort ? project.ort : ' '}</SoftTypography></td>
                            </tr>
                            <tr
                                style={{
                                    height: '20px',
                                    lineHeight: '20px'
                                }}
                            >
                                <td><SoftTypography variant="caption"><strong>{t('VKB Name')}: </strong></SoftTypography></td>
                                <td><SoftTypography variant="caption"><a style={{ textDecoration: 'underline', color: '#005498', cursor: "pointer" }} href="#">{salsePerson ? salsePerson : ' '} </a></SoftTypography></td>
                            </tr>
                        </table>
                    </SoftBox>
                </div>


                <div className={`col-md-6 ${props?.title && "mt-md-4"} `}>
                    <SoftBox>
                        <table>
                            <tr
                                style={{
                                    height: '20px',
                                    lineHeight: '20px'
                                }}
                            >
                                <td width="120"><SoftTypography variant="caption"><strong>{t('Quoation Nr.')}: </strong></SoftTypography></td>
                                <td><SoftTypography variant="caption">
                                    <NavLink to={'/quations/' + quationdata?.id} data-bs-dismiss="modal" style={{ textDecoration: '1px solid' }}><SoftTypography style={{ textDecoration: 'underline', color: '#005498' }} variant="caption" color="text">{quationdata?.id ? quationdata?.id : ' '} </SoftTypography></NavLink>
                                </SoftTypography>
                                </td>
                                <td><SoftTypography variant="caption" color="text" mr={1} ml={1}> /</SoftTypography></td>
                                <td><SoftTypography variant="caption" color="text" >{quationdata && moment(quationdata?.created_at).format("D.M.Y")}</SoftTypography></td>
                                <td
                                    className="text-mobile"
                                >
                                    <span className="badge badge-dot py-0" style={{ marginLeft: "1rem" }}>
                                        {project?.status && project?.sales_status?.id ? (
                                            <><i className={'status-' + project?.sales_status?.color ?? "bg-dark"}></i><span className={"text-dark text-xs"}>{t(project?.sales_status?.name ?? project?.status)}</span></>
                                        ) : <span className="text-dark text-xs">{'--'}</span>}
                                    </span>
                                </td>
                            </tr>
                            <tr
                                style={{
                                    height: '20px',
                                    lineHeight: '20px'
                                }}
                            >
                                <td><SoftTypography variant="caption"><strong>{t('Project Nr.')}: </strong></SoftTypography></td>
                                <td><SoftTypography variant="caption"><NavLink to={'/projects/' + project?.id} data-bs-dismiss="modal" style={{ textDecoration: '1px solid' }}><SoftTypography style={{ textDecoration: 'underline', color: '#005498' }} variant="caption" color="text">{project?.id ? project?.id : ' '}</SoftTypography></NavLink></SoftTypography></td>
                                <td><SoftTypography variant="caption" color="text" mr={1} ml={1}> /</SoftTypography></td>
                                <td><SoftTypography variant="caption" color="text" >{project && moment(project?.created_at).format("D.M.Y")}</SoftTypography></td>
                                <td
                                    className="text-mobile"
                                >
                                    <span className="badge badge-dot py-0" style={{ marginLeft: "1rem" }}>
                                        {project?.status && project?.sales_status?.id ? (
                                            <><i className={'status-' + project?.sales_status?.color ?? "bg-dark"}></i><span className={"text-dark text-xs"}>{t(project?.sales_status?.name ?? project?.status)}</span></>
                                        ) : <span className="text-dark text-xs">{'--'}</span>}
                                    </span>
                                </td>
                            </tr>
                            {props?.Wunschtermin ? (
                                <tr
                                    style={{
                                        height: '20px',
                                        lineHeight: '20px'
                                    }}
                                >
                                    <td><SoftTypography variant="caption"><strong>{t('Wunschtermin')}: </strong></SoftTypography></td>
                                    <td colSpan={4}><SoftTypography variant="caption">{project?.desired_assembly ? moment(project?.desired_assembly).format("D.M.Y") : '--'}</SoftTypography></td>
                                </tr>
                            ) : ''}

                            {props?.Montagetermin ? (
                                <>
                                    <tr
                                        style={{
                                            height: '20px',
                                            lineHeight: '20px'
                                        }}
                                    >
                                        <td><SoftTypography variant="caption"><strong>{t('DC Montagetermin')}: </strong></SoftTypography></td>
                                        <td colSpan={4}><SoftTypography variant="caption">{project?.dachmontag?.date ? moment(project?.dachmontag?.date).format("D.M.Y") : '--'}</SoftTypography></td>
                                    </tr>

                                    <tr
                                        style={{
                                            height: '20px',
                                            lineHeight: '20px'
                                        }}
                                    >
                                        <td><SoftTypography variant="caption"><strong>{t('AC Montagetermin')}: </strong></SoftTypography></td>
                                        <td colSpan={4}><SoftTypography variant="caption">{project?.elektromontage?.date ? moment(project?.elektromontage?.date).format("D.M.Y") : '--'}</SoftTypography></td>
                                    </tr>
                                </>
                            ) : ''}

                            <tr
                                style={{
                                    height: '20px',
                                    lineHeight: '20px'
                                }}
                            >
                                <td>
                                    <SoftTypography
                                        onClick={handleOpenMenu}
                                        style={{ color: '#005498', cursor: "pointer" }}
                                        variant="caption"
                                    >
                                        <strong
                                            onClick={() => projectData(project?.id)}
                                            style={{ display: "flex", alignItems: "center", marginTop: "0.30rem" }}
                                        >
                                            <Icon>info</Icon>&nbsp;{t('Overview')}
                                        </strong>
                                    </SoftTypography>
                                </td>
                                {renderMenu()}
                            </tr>
                        </table>
                    </SoftBox>
                </div>
            </div>
            {props?.customHtaml ? props?.customHtaml : ''}
        </Card>
    )
}

export default Overview;