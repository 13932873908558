export const jsonGroupData = {
    categories: [
        {
            name: 'Solarpanels',
            group:[
                "Monokristalline Solarpanels",
                "Polykristalline Solarpanels",
                "Dünnschicht-Solarpanels",
                "Hochleistungs-Solarpanels",
                "Flexible Solarpanels",
            ]
        },
        {
            name: 'Montagematerialien',
            group:[
                "Montageschienen",
                "Montageklemmen",
                "Solarpanel-Halterungen",
                "Befestigungssets",
                "Verbindungselemente",
            ]
        },
        {
            name: 'Wechselrichter',
            group:[
                "String-Wechselrichter",
                "Zentralwechselrichter",
                "Mikro-Wechselrichter",
                "Hybrid-Wechselrichter",
                "Solarbatteriewechselrichter",
            ]
        },
        {
            name: 'Verkabelung und Kabel',
            group:[
                "Solarkabel",
                "DC-Verbindungskabel",
                "AC-Verbindungskabel",
                "Überspannungsschutz",
            ]
        },
        {
            name: 'Solarbatterien',
            group:[
                "Lithium-Ionen-Batterien",
                "Blei-Säure-Batterien",
                "Salzwasserbatterien",
                "Solarbatterie-Speichersysteme",
            ]
        },
        {
            name: 'Überwachungs- und Steuerungssysteme',
            group:[
                "Solardatenüberwachungssysteme",
                "Fernüberwachungssysteme",
                "Solarladeregler",
                "Solarleistungsoptimierer",
            ]
        },
        {
            name: 'Montage- und Installationszubehör',
            group:[
                "Solarkabelclips",
                "Erdungszubehör",
                "Montagewerkzeuge",
                "Dichtungsmaterialien",
            ]
        },
        {
            name: 'Sicherheitsausrüstung',
            group:[
                "Persönliche Schutzausrüstung (PSA) wie Helme und Sicherheitsgurte",
                "Sicherheitswarnschilder und -markierungen"
            ]
        },
        {
            name: 'Ersatzteile und Komponenten',
            group:[
                "Ersatzteile für Wechselrichter und Solarpanels",
                "Elektronische Komponenten",
                "Mechanische Teile",
            ]
        },
        {
            name: 'Dokumentation und Schulungsmaterialien',
            group:[
                "Installationshandbücher",
                "Schulungsunterlagen für Techniker",
                "Wartungsanleitungen",
            ]
        },
        {
            name: 'Sonstiges Zubehör',
            group:[
                "Solarlampen",
                "Solarladegeräte",
                "Solarpumpen für Wasseranwendungen",
            ]
        },
    ]
}