import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
function Create() {
    const { t } = useTranslation();
    const [departments, setDepartments] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [lieferanten, setlieferanten] = useState([]);
    const [id, setID] = useState('');

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        setValue,
        formState: { errors },
    } = useForm();

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            clearErrors('file');
        }
        e.target.value = '';
    }

    const handleFileRemove = () => {
        setSelectedFile(null)
    }

    useEffect(() => {
        callFetch("module/create", "GET", [], setError).then((res) => {
            if (!res.ok) return;
            setValue('module_identity', res?.data?.identity)
            setValue('id', res?.data?.id);
            setID(res?.data?.id);
            setlieferanten(res?.data?.lieferanten);
        });
    }, [refresh]);

    const onSubmit = (formData) => {
        setSaving(true);
        formData.description = JSON.stringify(formData.description);

        if (selectedFile) {
            formData.file = selectedFile;
        }

        callFetch("module", "POST", formData, setError).then((res) => {
            setSaving(false);
            // console.log(res);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to='/products/module' /> :
        <>
            <div className="row">
                <div className="col-md-9">
                    <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <div className="card mb-4">
                            <div className="card-header pb-0">
                                <h6>{t('Create Module')}</h6>
                            </div>
                            <div className="card-body">
                                <div className="row g-3">
                                    <div className="col-md-4">
                                        <label>{t('Modulnr.')} *</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("module_identity", {
                                                required: true,
                                            })}
                                            required
                                            readOnly
                                        />
                                        <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                    </div>
                                </div>

                                <div className="row g-3">
                                    <div className="col-md-4">
                                        <label>{t('Hersteller / Lieferanten')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("lieferanten_id", {
                                                required: true,
                                            })}
                                            required
                                        >
                                            <option value={''}>{t("Select Lieferanten")}</option>
                                            {lieferanten.map((lief) => (
                                                <option value={lief?.id}>{lief?.lieferanten_nummer + (lief?.firma_name ? ' - ' + lief?.firma_name : '')}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">{errors.lieferanten_id && errors.lieferanten_id.message}</div>
                                    </div>

                                    <div className="col-md-4">
                                        <label>{t('Markenhersteller')} *</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("description[Markenhersteller]", {
                                                required: true,
                                            })}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <label>{t('Modulbezeichnung')} *</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("name", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                    </div>

                                    <div className="col-md-3">
                                        <label>{t('Short Title')} *</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("short_itle", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.short_itle && errors.short_itle.message}</div>
                                    </div>

                                    <div className="col-md-3">
                                        <label>{t('Typ')} *</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("description[Typ]", {
                                                required: true,
                                            })}
                                            required
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label>{t('Watt')} *</label>
                                        <input
                                            type="number"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("watt", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.watt && errors.watt.message}</div>
                                    </div>

                                    <div className="col-md-3">
                                        <label>{t('Zelltyp')} *</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("description[Zelltyp]", {
                                                required: true,
                                            })}
                                            required
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label>{t('Modulabmessung') + ' (' + t('Width') + ')'} *</label>
                                        <input
                                            type="number"
                                            step={0.01}
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("description[Modulabmessung_width]", {
                                                required: true,
                                            })}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <label>{t('Modulabmessung') + ' (' + t('Height') + ')'} *</label>
                                        <input
                                            type="number"
                                            step={0.01}
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("description[Modulabmessung_height]", {
                                                required: true,
                                            })}
                                            required
                                        />
                                    </div>


                                    <div className="col-md-12">
                                        <label>{t('Description')}</label>
                                        <textarea
                                            className="form-control"
                                            rows="4"
                                            placeholder={t('')}
                                            {...register("description[description]", {
                                                required: false,
                                            })}
                                        ></textarea>
                                    </div>

                                    <div className="col-12 mb-4">
                                        {!saving && (
                                            <button type="submit" className="btn btn-primary">
                                                {t('Save')}
                                            </button>
                                        )}
                                        {saving && (
                                            <button type="submit" className="btn btn-disabled" disabled>
                                                {t('Saving ...')}
                                            </button>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>

                <div className="col-3">
                    <div className="card">
                        <div className="card-header">
                            <label>{t('Datenblätter etc.')}</label>
                            <input
                                type="file"
                                className="form-control"
                                onChange={(e) => { handleFileChange(e); }}
                            />
                            <div className="invalid-feedback d-block">{errors.file && errors.file.message}</div>
                        </div>
                        <div className="card-body pt-0 position-relative">
                            {selectedFile?.type.startsWith('image/') ? (
                                <>
                                    <img
                                        src={selectedFile && URL.createObjectURL(selectedFile)}
                                        alt="Selected"
                                        width="100%"
                                        height="100%"
                                        className="border-radius-md w-100 h-100"
                                    />
                                    &nbsp;
                                    <i style={{ top: "-5px" }} className="fa-solid fa-circle-xmark text-danger cursor-pointer position-absolute end-4" onClick={handleFileRemove}></i>
                                </>
                            ) : selectedFile?.type.startsWith('application/') ? (
                                <>
                                    <iframe
                                        src={URL.createObjectURL(selectedFile)}
                                        title="PDF Viewer"
                                        width="100%"
                                        height="350px" // Adjust the height as needed
                                        className="border-radius-md"
                                    ></iframe>
                                    &nbsp;
                                    <i style={{ top: "-5px" }} className="fa-solid fa-circle-xmark text-danger cursor-pointer position-absolute end-4" onClick={handleFileRemove}></i>
                                </>
                            ) : <></>}
                        </div>
                    </div>
                </div>
            </div>;
        </>
}

export default Create;