import { React, useState } from "react";
import { useForm } from "react-hook-form";

// react-router-dom components
import { Navigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import callFetch from "helpers/callFetch";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftAvatar from 'components/SoftAvatar'

function Illustration() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [apps, setApps] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  let tempAuth = Cookies.get('token') ? true : false;
  const [errorMessage, setErrorMessage] = useState('');
  const [signinSuccess, setSigninSuccess] = useState(tempAuth);
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('dashboard');
  const [emailErrorTwo, setEmailErrorTwo] = useState(false);
  const { register, handleSubmit, setError, formState: { errors } } = useForm();
  const { i18n } = useTranslation();


  const setLoginData = (data) => {

    var loginData = data?.login_data;

    if ((!loginData?.data?.token) || (!data?.app_data?.api_url)) {
      console.log('setLoginData')
      setErrorMessage('Something wrong!');
      return;
    } else {
      setErrorMessage('');
    }

    let l = { flag: 'de', lang: 'de', name: 'Deutsch' }
    i18n.changeLanguage(l.lang);
    Cookies.set('lang', JSON.stringify(l));
    Cookies.set('api_url', data?.app_data?.api_url);
    Cookies.set('backend_url', data?.app_data?.backend_url);

    if (loginData.message == 'The entered password is invalid.') {
      setPasswordError(true);
      setEmailError(false);
      setEmailErrorTwo(false);
      return;
    }
    if (loginData.message == 'The email must be a valid email address.') {
      setEmailErrorTwo(true);
      setEmailError(false);
      setPasswordError(false);
      return;
    }
    if (loginData.message == "The email address you entered isn't connected to an account.") {
      setEmailError(true);
      setEmailErrorTwo(false);
      setPasswordError(false);
      return;
    }


    setPasswordError(false);
    setEmailError(false);
    Cookies.set('token', loginData.data.token);
    Cookies.set('user', JSON.stringify({
      id: loginData.data.user.id,
      name: loginData.data.user.name,
      email: loginData.data.user.email,
      mobile: loginData.data.user.mobile,
      gender: loginData.data.user.gender,
      date_of_birth: loginData.data.user.date_of_birth,
      address: loginData.data.user.address,
      photo: loginData.data.user.photo,
      rolename: loginData.data.user.roles[0].name,
      roleid: loginData.data.user.roles[0].id,
    }));

    Cookies.set('permissions', JSON.stringify(loginData.data.permissions));
    Cookies.set('user_cache', loginData.data.user.cache);

    setTimeout(() => {
      window.location.href = process.env.REACT_APP_FRONTEND_URL + redirectUrl;
    }, 1500);
  }

  const onSubmit = (formData) => {
    setApps([]);
    setLoading(true);
    setPasswordError(false);
    setEmailError(false);
    callFetch('client-signin', 'POST', formData, setError, process.env.REACT_APP_API_URL)
      .then(loginData => {

        setLoading(false);

        if (loginData?.message === "success") {
          if (loginData?.data.length === 0) {
            setErrorMessage('Email or Password invalid');
          } else if (loginData?.data.length === 1) {
            setLoginData(loginData?.data[0]);
            setErrorMessage('');
          } else {
            //console.log(loginData?.data.length)
            //console.log(loginData);
            setApps(loginData?.data);
            document.getElementById('companyChooseModalBtn').click();
            setErrorMessage('');
          }

        } else {
          //console.log('onSubmit')
          if (loginData?.errors?.length <= 0) {
            setErrorMessage('Something wrong!');
          }
        }
      });
  }


  return signinSuccess ? <Navigate to={'/' + redirectUrl} /> :
    <>
      <IllustrationLayout
        title="Login"
        description={t('Tragen Sie Ihre E-Mail und Passwort ein')}
      /*
      illustration={{
        image: chat,
        title: '"Attention is the new currency"',
        description:
          "The more effortless the writing looks, the more effort the writer actually put into the process.",
      }}
      */
      >
        <SoftBox component="form" role="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <SoftBox mb={2}>
            <SoftInput
              type="email"
              placeholder="Email"
              size="large"
              {...register('email', {
                required: true
              })}
            />

            {errors.email ?
              <div className="invalid-feedback d-block">{t(errors.email.message)}</div>
              : emailError ? <div className="invalid-feedback d-block">{t("The email address you entered isn't connected to an account.")}</div>
                : emailErrorTwo ? <div className="invalid-feedback d-block">{t('The email must be a valid email address.')}</div>
                  : errorMessage ? <div className="invalid-feedback d-block">{t(errorMessage)}</div>
                    : ""}
          </SoftBox>

          <SoftBox mb={2}>
            <SoftInput
              type="password"
              placeholder="Password"
              size="large"
              {...register('password', {
                required: true
              })}
            />

            {errors.password ? <div className="invalid-feedback d-block">{t(errors.password.message)}</div> : passwordError ? <div className="invalid-feedback d-block">{t('The entered password is invalid.')}</div> : ''}
          </SoftBox>

          <SoftBox display="flex" alignItems="center">
            <Switch checked={rememberMe} onChange={handleSetRememberMe} />
            <SoftTypography
              variant="button"
              fontWeight="regular"
              onClick={handleSetRememberMe}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              &nbsp;&nbsp;{t('Erinnere dich')}
            </SoftTypography>
          </SoftBox>

          <SoftBox mt={4} mb={1}>
            {/*loading ? (
            <SoftButton disabled variant="gradient" color="info" size="large" fullWidth>
              {t('Sign In')}
            </SoftButton>
          ) : (
            <SoftButton type="submit" variant="gradient" color="info" size="large" fullWidth>
              {t('Sign In')}
            </SoftButton>
          )*/}

            {loading ? (
              <button style={{ background: 'linear-gradient(121.78deg, #21D4FD 9.06%, #2152FF 88.15%)', boxShadow: 'none' }} type="button" disabled className="btn btn-primary w-100 mb-0">{t('Sign In')}</button>
            ) : (
              <button style={{ background: 'linear-gradient(121.78deg, #21D4FD 9.06%, #2152FF 88.15%)', boxShadow: 'none' }} type="submit" className="btn btn-primary w-100 mb-0">{t('Sign In')}</button>
            )}

          </SoftBox>
        </SoftBox>
      </IllustrationLayout >


      <button
        type="button"
        id="companyChooseModalBtn"
        className="btn bg-gradient-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#companyProfileChoose"
      >{t('Company Choose')}</button>

      <div className="modal fade" id="companyProfileChoose" tabindex="-1" role="dialog" aria-labelledby="companyProfileChooseLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="companyProfileChooseLabel" style={{ fontWeight: 'bold' }}>{t('Choose Profile')}</h6>
              <button
                type="button"
                className="btn-close text-dark"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body py-0" style={{ background: 'rgb(248, 249, 250)' }}>
              {apps.map((app) => (
                <>
                  <Card
                    className="mt-3"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setLoginData(app);
                      setErrorMessage('');
                    }}
                  >
                    <SoftBox p={2}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>
                          <SoftAvatar
                            src={'/assets/img/placeholder.png'}
                            alt="profile-image"
                            variant="rounded"
                            size="xl"
                            shadow="sm"
                          />
                        </Grid>
                        <Grid item>
                          <SoftBox height="100%" mt={0.5} lineHeight={1}>
                            <SoftTypography variant="h5" fontWeight="medium">
                              {app?.app_data?.company_name}
                            </SoftTypography>
                            <SoftTypography variant="button" color="text" fontWeight="medium">{app?.login_data?.data?.user?.name}</SoftTypography>
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                  </Card>
                </>
              ))}
            </div>
            <div className="modal-footer border-0" style={{ background: 'rgb(248, 249, 250)' }}>

            </div>
          </div>
        </div>
      </div>
    </>;
}

export default Illustration;
