import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DepartmentManageModal from "../../DepartmentManageModal";
import DesignationManageModal from "../../DesignationManageModal";
import ImagePopup from "../../ImagePopup";
import callFetch from "helpers/callFetch";
import SoftSnackbar from "components/SoftSnackbar";

function MonthlySalary({ title,employee,refreshData }) {
    const params = useParams();
    const { t } = useTranslation();
    const [designations, setDesignations] = useState([]);
    const [roles, setRoles] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isModalVisible, setModalVisible] = useState(false);
    const [files,setFile] = useState();
    const [image, setImage] = useState();
    const [provision, setProvision] = useState('')
    const [receivedError, setReceivedError] = useState(null);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();




  const handleBlur = () => {
    if (provision !== '' && !provision.endsWith('%')) {
      setValue(provision + '%');
    }
  };
    
  
   
    

    useEffect(() => {
        if(employee){
            setValue('bruttogehalt_money',employee?.bruttogehalt_money)
            setValue('provision',employee?.provision)
        }
    }, [refresh,employee]);


    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
          color="success"
          icon="check"
          title="Success"
          content="Monthly Salary Successfully Updated"
          dateTime="Just Now"
          open={successSB}
          onClose={closeSuccessSB}
          close={closeSuccessSB}
          bgWhite
        />
      );


    const onSubmit = (formData) => {
        formData.employee_identity_number = params?.id
        formData.name = employee?.name
        formData.email = employee?.email
        setSaving(true);
        callFetch("employees/"+ params?.id , "POST", formData, setError).then((res) => {
            setSaving(false);
            if(receivedError == null && res.message === 'success' && params?.id){
                openSuccessSB();
            }
            refreshData();
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t(`${title}`)}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <input type="hidden" defaultValue="PUT" {...register("_method")} />
                            
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label>
                                        {t('Monatiches Bruttogehalt in EUR')} *
                                    </label>
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder={t('Bruttogehalt')}
                                            {...register("bruttogehalt_money", {
                                                required: true,
                                            })}
                                            required
                                            aria-label="Input group example" 
                                            aria-describedby="btnGroupAddon"
                                        />
                                        <div className="input-group-text" id="btnGroupAddon">€</div>
                                    </div>
                                    <div className="invalid-feedback">{errors.monthly_gross_salary && errors.monthly_gross_salary.message}</div>
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        {t('Provision in %')} *
                                    </label>
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder={t('provision')}
                                            {...register("provision", {
                                                required: true,
                                            })}
                                            required
                                            aria-label="Input group example" 
                                            aria-describedby="btnGroupAddon"
                                        />
                                        <div className="input-group-text" id="btnGroupAddon">%</div>
                                    </div>
                                    <div className="invalid-feedback">{errors.provision && errors.provision.message}</div>
                                </div>
                            </div>
  

                            
                            <div className="col-12 mb-4 text-end mt-5">
                                {!saving && (
                                    <button type="submit" className="btn btn-primary">
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Saving ...')}
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            {renderSuccessSB}
        </div>
    )
}

export default MonthlySalary;
