import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import SoftTypography from "components/SoftTypography";
import dateFormat from "dateformat";
import Cookies from 'js-cookie';

function Replay(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [data, setData] = useState([]);

    useEffect(() => {
        if(!params.id) return;

        callFetch("support-replay/" + params.id, "GET", []).then((res) => {
            setData(res.data);
        });
    }, [params.id, props.refresh]);


    return(
        <div className="row">
            {data ? data.map((replay, index) => (
                 <div className="col-12" key={index}>
                    <div className="card mb-4">                    
                        <div className="card-body py-2">
                            <div className="row">
                                <div className="col-md-4">                             
                                    <SoftTypography variant="caption">{replay?.replay_by?.name}</SoftTypography>
                                </div>    
                                <div className="col-md-8">
                                    <SoftTypography variant="caption">{replay?.description}</SoftTypography>                                
                                </div>    
                            </div>                                  
                        </div>
                        <div className="card-footer border-top py-2">
                            <SoftTypography variant="caption">{t('Replied: ')+ dateFormat(replay?.created_at, "dd.mm.yyyy hh:MM")}</SoftTypography>
                        </div>
                    </div>
                </div>
            )) : ''}
        </div>
    )
}

export default Replay;
