import { useMemo, useEffect, useState, useRef } from "react";
import ProgressChart from "../../analyses/VkbEvulation/ProgressChart";
import callFetch from "../../../helpers/callFetch";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import LoadingCard from "components/LoadingCard";

function ProjectBezahltPieChart(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
} = useForm();

  const [data, setData] = useState({
    labels: ["Wird Bezahlt", "Nicht Bezahlt"],
    datasets: {
      label: "Termine",
      backgroundColors: ["#0048B1", "#CBD5E0"],
      data: [0, 0],
      total: 0,
    },
  });
  const [refresh, setRefresh] = useState(0);
  //const [users, setUsers] = useState([]);
  const [filterData, setFilterData] = useState({
      verkaufer: 0,
      created_at: 'All',
  });



  useEffect(() => {
    if(!props?.filterData?.created_at) return;
    
    /*
    if(props?.filterData?.created_at != filterData?.created_at){
        var data = filterData;
        data.created_at =  props?.filterData?.created_at;
        setFilterData(data);
        setRefresh(refresh+1)
    }
    */
  }, [props?.filterData?.created_at]);


  useEffect(() => {
    setLoading(true);
    callFetch("statistics/project-bezahlt-pie-chart", "POST", filterData, setError).then((res) => {
        setData(res.data);
        setLoading(false);
        //setUsers(res.users);
        if (!res.ok) return;
    });
  }, [refresh]);

  return (
    <>
      {loading ? (
        <LoadingCard
          sx={{height: '256px'}}
        />
      ) : (
        <ProgressChart
          icon="workspace_premium"
          title="projects"
          count={data?.datasets?.total}
          color="dark"
          chart={data}
          height="20rem"
        />
      )}
        
    </>
  );
}

export default ProjectBezahltPieChart;
