import React, { useEffect, useState } from 'react'
import callFetch from 'helpers/callFetch';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Card from "@mui/material/Card";
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import { useForm } from "react-hook-form";
import ModalContentProductStock from './ModalContentProductStock';
import ModalContentProductStockIn from './ModalContentProductStockIn';
import { fontWeight } from '@mui/system';
const ArticleModalContent = ({setShow, id}) => {
    const params = useParams();
    const [item, setItem] = useState([]);
    const [pl, setPl] = useState([]);
    const [result, setResult] = useState([]);
    const [productID, setProductID] = useState(0);
    const [activeTab, setActiveTab] = useState('out');

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => { 
       if(id != 0){
        callFetch("goods-by-id/"+id+'/'+params?.id, "GET", []).then((res) => {
            console.log(res);
            setResult(res);
            setProductID(res.data.product_id)
            setValue('lagerbestand', res?.qty);
            setPl(res.pl);
            callFetch("items-by-prefix/"+res.data.product_id, "GET", []).then((res) => {
                console.log(res); 
                setItem(res.data)  
            });
        }); 
       }
    }, [id]);

    const handleClose = () => setShow(false);

    return <>
        <div className="modal-dialog">
                <div className="modal-content"> 
                    <div className="modal-body">
                     <SoftBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems="baseline"
                            pt={3}
                            pb={0.8}
                            px={2}
                        >
                            <SoftBox>
                                <SoftTypography variant="h6" style={{fontSize: '0.875rem'}} ><span style={{fontWeight: '500'}}>LG-{params?.id}</span> - <NavLink to={ (result?.data?.product_type == 'MDL' ? '/products/module/' : result?.data?.product_type == 'SPC' ? '/products/speicher/' : result?.data?.product_type == 'WR' ? '/products/wechselrichter/' : '') + result?.pl?.id  + '/edit'}><span style={{color:'#005498', fontWeight: '600'}}>{item.name}</span></NavLink><span style={{color:'#005498', fontWeight: '600'}}></span></SoftTypography>
                            </SoftBox>

                            <Icon
                                sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                                    fontSize: `${size.md} !important`,
                                    fontWeight: `${fontWeightBold} !important`,
                                    stroke: dark.main,
                                    strokeWidth: "2px",
                                    cursor: "pointer",
                                    mt: 2,
                                })}
                                onClick={handleClose}
                            >
                                close
                            </Icon>
                        </SoftBox>

                        <Divider /> 

                        <SoftBox    
                            pb={0.8}
                            px={2}> 
                            <SoftTypography variant="h6" style={{fontSize: '0.875rem'}}>Lieferant : <span style={{color:'#005498', fontWeight: '600'}}>{pl?.lieferanten?.firma_name}</span></SoftTypography> 
                            {/* <SoftTypography variant="caption" fontWeight="medium" pt={2}>kunden : </SoftTypography>  */}
                            <div className='row mt-2'>
                                <div className='col-sm-2'>
                                    <SoftTypography style={{display: 'block', marginBottom: '5px'}} variant="caption" fontWeight="medium">Telefon  </SoftTypography> 
                                    <SoftTypography style={{display: 'block', marginBottom: '5px'}} variant="caption" fontWeight="medium">E-mail   </SoftTypography> 
                                    <SoftTypography style={{display: 'block', marginBottom: '5px'}} variant="caption" fontWeight="medium">Address  </SoftTypography>
                                </div> 
                                <div className='col-sm-10'>
                                     <SoftTypography style={{display: 'block', marginBottom: '5px'}} variant="caption" fontWeight="medium"> {pl?.lieferanten?.telephone}  </SoftTypography> 
                                     <SoftTypography style={{display: 'block', marginBottom: '5px'}} variant="caption" fontWeight="medium"> {pl?.lieferanten?.email}    </SoftTypography> 
                                     <SoftTypography style={{display: 'block', marginBottom: '5px'}} variant="caption" fontWeight="medium"> {pl?.lieferanten?.street}, {pl?.lieferanten?.plz}, {pl?.lieferanten?.ort}  </SoftTypography> 
                                </div>
                            </div> 

                            <div className='row mt-4'>
                                <div className='col-sm-6'>
                                    <div>
                                        <SoftTypography variant="caption" fontWeight="bold">Lagerbestand: </SoftTypography>
                                        <input className='form-control' {...register("lagerbestand", {
                                            required: true,
                                        })}/>
                                    </div>

                                    <div className='mt-3'>
                                        <SoftTypography variant="caption" fontWeight="bold">EK-Preis: </SoftTypography>
                                        <input className='form-control'/>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div>
                                        <SoftTypography variant="caption" fontWeight="bold">Lagerwert: </SoftTypography>
                                        <input className='form-control'/>
                                    </div>

                                    <div className='mt-3'>
                                        <SoftTypography variant="caption" fontWeight="bold">VK-Preis: </SoftTypography>
                                        <input className='form-control'/>
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-5'>
                                <div className='col-sm-12 projects-menu'>
                                    <div>
                                        <ul className="nav nav-tabs nav-underline" id="myTab" role="tablist"> 
                                            <li className="nav-item" role="presentation">
                                                <button onClick={() => { setActiveTab('out'); }} className={"nav-link " + (activeTab == 'out' ? 'active' : '')} id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" style={{ color: '#0048B1', fontSize: '0.875rem' }}>Warenausgang</button>
                                            </li>  

                                            <li className="nav-item" role="presentation">
                                                <button onClick={() => { setActiveTab('in'); }} className={"nav-link " + (activeTab == 'in' ? 'active' : '')} id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" style={{ color: '#0048B1', fontSize: '0.875rem' }}>Wareneingang</button>
                                            </li>  
                                        </ul>
                                    </div>
                                    <div className="tab-content" id="myTabContent">
                                        <div className={activeTab == 'out' ? 'tab-pane fade show active p-3' : 'tab-pane fade p-3'} id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                                            <ModalContentProductStock id={productID}></ModalContentProductStock>
                                        </div>
                                        <div className={activeTab == 'in' ? 'tab-pane fade show active p-3' : 'tab-pane fade p-3'} id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                                            <ModalContentProductStockIn id={productID}></ModalContentProductStockIn>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </SoftBox>

                    </div> 
                </div>
            </div>
    </>
}

export default ArticleModalContent