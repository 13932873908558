import { Grid, Popover } from '@mui/material'
import SoftTypography from 'components/SoftTypography'
import React from 'react'
import "./styles.css"
import Car from "assets/images/car.svg"
import dateFormat from "dateformat";
import { NumericFormat } from 'react-number-format';

const RoutenCard = ({ data, distance, schedule, key }) => {

  return (
    <>

      <Grid>
        <Grid width="14rem" mt={1} display="flex" mx={.2}>
          <div className="" style={{ width: "13.65rem" }}>
            {distance != '--' ? (
              <div className="end-0 stan z-index-3 d-flex align-items-center justify-content-center px-1 py-1" style={{ background: "#CB0C9F" }}>
                <div className="d-flex align-items-center justify-content-center">
                  <img src={Car} alt='car' className="me-2" />
                  <SoftTypography fontSize="0.625rem" variant="caption" color="white" fontWeight="medium">
                    {distance >= 0 && distance != null ? (
                      <>
                        <NumericFormat
                          value={Number(distance)}
                          displayType="text"
                          thousandSeparator={"."}
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale
                          suffix={' km'}
                        />{/* | 1:20 h*/}
                      </>
                    ) : 'Invalid Address'+distance}
                  </SoftTypography>
                </div>
              </div>
            ) : (
              <div className="px-1 pb-1 pt-3"></div>
            )}

            <div className="rounded-3" style={{ background: "rgba(206, 237, 255, 0.87)" }}>
              <div className="p-2 m-0">
                <SoftTypography className="" fontSize="11px" sx={{ color: "#000", fontWeight: '600' }} >
                  {(data?.plan_form == 'Reclamation' ? (
                    <a target='_blank' href={'/reclamation/' + data?.id} className='text-primary'>{'R-'+data?.id}</a>
                  ) : (
                    <a target='_blank' href={'/projects/' + (data?.plan_form == 'ACFertigmeldung' ? data.project_id : data.id)} className='text-primary'>{(data?.plan_form == 'ACFertigmeldung' ? data.project_id : data.id)}</a>                   
                  ))}
                   - {data?.street} - {data?.phone}
                </SoftTypography>
                {/*
                <SoftTypography className="" fontSize="10px" sx={{ color: "#000" }} fontWeight={'medium'}>
                  {data?.needHours && data?.needHours >= 12 ? (data?.needHours/12)+'Days' : data?.needHours+' Hours'}
                </SoftTypography>
                */}

                <SoftTypography className="" fontSize="10px" sx={{ color: "#000" }} fontWeight={'medium'}>
                  {'Plz: '+data?.plz+(data?.speicher_eingeben == 'no' ? (data?.speicher_name ? ', '+data?.speicher_name : (data?.wechselrichter_eingeben == 'no' ? (data?.wechselrichter_name ? ', '+data?.wechselrichter_name : '') : (data?.custom_wechselrichter_titel ? ', '+data?.custom_wechselrichter_titel : ''))) : (data?.custom_speicher_name && data?.custom_speicher_name != 'null' ? ', '+data?.custom_speicher_name : ''))+(data?.meter_box_conversion == 'JA' ? ', ZKU' : '')}
                </SoftTypography>

                <SoftTypography className="" fontSize="10px" sx={{ color: "#000" }} fontWeight={'medium'}>
                  {/*schedule?.date && schedule?.start && schedule?.end ? dateFormat(schedule?.date, "dd.mm.yyyy") + ' - ' + schedule?.start + '-' + schedule?.end + ' Uhr' : '--'*/}
                  {data?.startDate && data?.endDate && data?.startTime && data?.endTime ? dateFormat(data?.startDate, "dd.mm.yyyy") + ':' + data?.startTime + ' - ' + dateFormat(data?.endDate, "dd.mm.yyyy") + ':' + data?.endTime + ' Uhr' : '--'}
                </SoftTypography>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default RoutenCard