import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import IndexTable from './IndexTable'
import Cookies from 'js-cookie';

const Index = () => {
    const { t } = useTranslation()
    return (
        <>

            <div className='row'>
                <div className="col-12">
                    <div className="d-sm-flex justify-content-between">
                        <NavLink to="/bundle-preis/create" className="btn btn-icon btn-primary">
                            {t('Bundle-Preis erstellen')}
                        </NavLink>

                        <div className="float-end">
                            {JSON.parse(Cookies.get('permissions')).indexOf("Import") !== -1 ? (
                                <>
                                    <button className="btn btn-outline-dark mx-3" data-bs-toggle="modal" data-bs-target="#bundleimportModal">{t('Import XLSX')}</button>
                                    <button className="btn btn-outline-dark mx-3 d-none" id='bundleoutputModal-show-button' data-bs-toggle="modal" data-bs-target="#bundleoutputModal">{t('Output XLSX')}</button>
                                </>
                            ) : <></>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('All Bundle')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <IndexTable />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index