import { React, useEffect, useState, useRef } from 'react';
import SoftTypography from "components/SoftTypography";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import dateFormat, { masks } from "dateformat";
import { NavLink } from "react-router-dom";

const ExtraInfo = ({ data }) => {
  const [quation, setQuation] = useState(0);
  const [order, setOrder] = useState(0);
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState(0);
  const [reclamations, setReclamations] = useState(0);
  const [project, setProject] = useState({});
  const [user, setUser] = useState({});
  const [assignOrders, setAssignOrders] = useState([]);
  useEffect(() => {
    callFetch("project-details"+"/"+data.id, "GET", []).then((res) => { 
      setQuation(res.quation);
      setOrder(res.order);
      setUser(res.user);
      setReclamations(res.reclamations);
      setProject(res.module_speicher_wech);
      setAssignOrders(res.assign_orders); 
      setInvoices(res.invoices);
    });
  }, []);
  
  return (
    <div className='row p-3'  style={{margin: '0px', backgroundColor: '#F3F6FF'}}>
       <div className='col-sm-3 ps-5'>
            <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Lead Origin:</SoftTypography> <SoftTypography variant="caption">{user?.id ? user?.name : '--'}</SoftTypography>  <br/>
            
            <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Module:</SoftTypography> <SoftTypography variant="caption">
                {project?.module_eingeben ? project.module_eingeben == 'yes' ? project.custom_module_short_title != null ? project.custom_module_short_title + " | LT: " + dateFormat(project.quation_created_at, "dd.mm.yyyy"): '--' : project.module_short_title != null ? project.module_short_title + " | LT: " + dateFormat(project.modules_created_at, "dd.mm.yyyy"): '--' : '--'}
            </SoftTypography> <br/>

            <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Speicher:</SoftTypography> <SoftTypography variant="caption">
              {project?.speicher_eingeben ? project.speicher_eingeben == 'yes' ? project.custom_speicher_name != null ?  project.custom_speicher_name + " | LT: " + dateFormat(project.quation_created_at, "dd.mm.yyyy") : '--' : project.speicher_name != null ? project.speicher_name + " | LT: " + dateFormat(project.speicher_created_at, "dd.mm.yyyy") : '--' : '--'}
            </SoftTypography> <br/> 
            <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Wechselrichter:</SoftTypography> <SoftTypography variant="caption">
              {project?.wechselrichter_eingeben ? project.wechselrichter_eingeben == 'yes' ? project.custom_wechselrichter_titel != null ? project.custom_wechselrichter_titel + " | LT: " + dateFormat(project.quation_created_at, "dd.mm.yyyy") : '--' : project.wechselrichter_name != null ? project.wechselrichter_name + " | LT: " + dateFormat(project.wechselrichter_created_at, "dd.mm.yyyy") : '--' : '--'}
            </SoftTypography>  
       </div>
       <div className='col-sm-3'>
            <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Angebot & AB:</SoftTypography> <SoftTypography variant="caption">{quation?.id ? quation.id : '--' } | {order?.id ? order.id : '--' }</SoftTypography>   <br/>
            
            <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Rechnung:</SoftTypography> <SoftTypography variant="caption">
            {invoices?.length > 0 ? 
               invoices.map((invoice) =>  invoice.id + ", " )
            : '--' }
            </SoftTypography>  <br/>

            <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Einkauf Nr:</SoftTypography> <SoftTypography variant="caption"> {project?.purchase ? 'PO-'+project?.purchase?.id : ''} </SoftTypography>  <br/>
            
            <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Reclamation:</SoftTypography> <SoftTypography variant="caption"> 
            {reclamations?.length > 0 ? 
               reclamations.map((reclamation) => <NavLink to={'/reclamation/' + reclamation?.id}>{"R-"+reclamation?.id + " "}</NavLink>)
            : '--' }
            </SoftTypography>    
            
       </div>
       <div className='col-sm-3'>
        {assignOrders?.length == 0 ? 
          <>
          <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Dachmontage:</SoftTypography> <SoftTypography variant="caption">--</SoftTypography> <br/>
          <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Abnahme:</SoftTypography> <SoftTypography variant="caption">--</SoftTypography>  <br/>
        
          <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Elektromontage:</SoftTypography> <SoftTypography variant="caption">--</SoftTypography> <br/>
            <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Abnahme:</SoftTypography> <SoftTypography variant="caption">--</SoftTypography>  <br/>
          </>  :  ''
      }

      {assignOrders?.length == 1 ? 
         assignOrders && assignOrders.map((assign_order) => (
            <>
            {assign_order.dachmontag_elektromontage == 'Dachmontage' ? 
            <>
              <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Dachmontage:</SoftTypography> <SoftTypography variant="caption">{assign_order.employee.name} {dateFormat(assign_order.created_at, "dd.mm.yyyy")}</SoftTypography> <br/>
              <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Abnahme:</SoftTypography> <SoftTypography variant="caption">{assign_order?.dcabnameprotokoll?.comisioning_status == "1" ? t('Successfull') : t("Not Successfull")}</SoftTypography>  <br/>
              <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Elektromontage:</SoftTypography> <SoftTypography variant="caption">--</SoftTypography> <br/>
              <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Abnahme:</SoftTypography> <SoftTypography variant="caption">--</SoftTypography>  <br/>
          
            </> 
            :
            <>
            <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Dachmontage:</SoftTypography> <SoftTypography variant="caption">--</SoftTypography> <br/>
            <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Abnahme:</SoftTypography> <SoftTypography variant="caption">--</SoftTypography>  <br/>
            <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Elektromontage:</SoftTypography> <SoftTypography variant="caption">{assign_order.employee.name} {dateFormat(assign_order.created_at, "dd.mm.yyyy")}</SoftTypography> <br/>
              <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Abnahme:</SoftTypography> <SoftTypography variant="caption">{assign_order?.acabnameprotokoll?.comisioning_status == "1" ? t('Successfull') : t("Not Successfull")}</SoftTypography>  <br/>
            </> 
            }
            
            </> 
        )) :  ' '
      }

    {assignOrders?.length == 2 ? 
         assignOrders && assignOrders.map((assign_order) => (
            <>
            {assign_order.dachmontag_elektromontage == 'Dachmontage' ? 
            <>
              <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Dachmontage:</SoftTypography> <SoftTypography variant="caption">{assign_order.employee.name} {dateFormat(assign_order.created_at, "dd.mm.yyyy")}</SoftTypography> <br/>
              <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Abnahme:</SoftTypography> <SoftTypography variant="caption">{assign_order?.dcabnameprotokoll?.comisioning_status == "1" ? t('Successfull') : t("Not Successfull")}</SoftTypography>  <br/>
            </> 
            :
            <>
            <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Elektromontage:</SoftTypography> <SoftTypography variant="caption">{assign_order.employee.name} {dateFormat(assign_order.created_at, "dd.mm.yyyy")}</SoftTypography> <br/>
              <SoftTypography variant="button" sx={{ fontSize: '0.75rem'}}>Abnahme:</SoftTypography> <SoftTypography variant="caption">{assign_order?.acabnameprotokoll?.comisioning_status == "1" ? t('Successfull') : t("Not Successfull")}</SoftTypography>  <br/>
            </> 
            }
            
            </> 
        )) :  
           ' '
      }
       
       </div>
    </div>
  );
};

export default ExtraInfo;