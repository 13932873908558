import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie'; 
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography" 
import Tooltip from "@mui/material/Tooltip";
import SoftButton from "components/SoftButton";
import Checkbox from "@mui/material/Checkbox";
import Select from 'react-select';
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import SoftAvatar from "components/SoftAvatar";
function Edit({ setRefresh }, id) {
    const { t } = useTranslation();
    const [filderData, setFilderData] = useState([]);
    const [users, setUsers] = useState([]); 
    const [saving, setSaving] = useState(false); 
   
    
    useEffect(() => { 
        callFetch("get-project-filter-data", "GET", []).then((res) => {
            setFilderData(res.data); 
        });

        callFetch("get-users-todo", "GET", []).then((res) => {
            setUsers(res); 
        });

        // callFetch("get-users-todo", "GET", []).then((res) => {
        //     setUsers(res); 
        // });
        console.log(id)
         
    }, []);

    const {
        register,
        handleSubmit,
        reset,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm(); 


    //Form Submit
    const onSubmit = (formData) => {
        setSaving(true); 
        callFetch("store-to-do", "POST", formData, setError).then((res) => {
           setSaving(false);
           setValue('title', '')
           setValue('date', '')
           setValue('project_id', '')
           setValue('users', '')
           reset();
           document.getElementById('cls-btn').click();
           setRefresh(prev => prev + 1);
        });
    };


    return <>
         <div class="modal fade" id="staticBackdropEdit" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropEditLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
            
                    <div class="modal-body pt-4 pb-3">
                        <button onClick={() => reset()} type="button" id="cls-btn" data-bs-dismiss="modal" aria-label="Close" className=" lose btn btn-icon-only btn-rounded btn-outline-dark mb-0 me-3 btn-sm d-flex align-items-center justify-content-center" style={{ fontSize: '12px', position: 'absolute', right: '0px' }}><i class="fa-sharp fa-solid fa-times icon icon-shape icon-sm  text-center d-flex align-items-center justify-content-center"></i></button>
                        <SoftTypography variant="button" fontSize="18px" fontWeight="bold" style={{color: '#2D3748'}}> Aufgabe bearbeiten </SoftTypography><br/>
                        <SoftTypography variant="button" fontSize="14px" fontWeight="regular" style={{color: '#A0AEC0'}}> Bearbeiten sie eıne Aufgabe  </SoftTypography><br/>
                        <div className="mt-4"></div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <label>Aufgabe Titel </label>
                                <input type="text" {...register('title')} className="form-control" placeholder="bsp. Titel"/>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Datum  </label>
                                        <input type="date" {...register('date')} className="form-control" placeholder="bsp. Titel"/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Projekt </label>
                                        <Select 
                                            placeholder={t('Select')}
                                            options={filderData?.projects ? filderData?.projects : []} 
                                            onChange={(e) => { 
                                                setValue('project_id', e.value);
                                            }}
                                            className='react-select-filter mb-2' 
                                        />
                                    </div>
                                </div>
                            </div>
                        
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>User hinzufügen </label>
                                        <Select
                                            placeholder={t('Select')}
                                            options={users?.data ? users?.data : []} 
                                            onChange={(e) => { 

                                                var users = [];

                                                for (let [option_key, option] of Object.entries(e)) {
                                                    users.push(option?.value)
                                                } 

                                                setValue('users', JSON.stringify(users));
                                                 
                                            }}
                                            className='react-select-filter mb-2' 
                                            isMulti
                                        />
                                    </div>
                                </div> 
                            </div> 
                            
                            {saving ? <button className="btn btn-secondary" disabled style={{float: 'right', width: '110px'}}>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button> : <button className="btn to-do-modal-btn">
                                {t('Erstellen')}
                            </button>}
                            <button className="btn btn-danger" style={{float: 'right', marginRight: '8px'}}>
                                {t('Delete')}
                            </button>
                        </form>

                        
                    </div>
                
                </div>
            </div>
        </div>
    </>
}

export default Edit;
