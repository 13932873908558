import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import flatpickr from "flatpickr";
import SoftAlert from "components/SoftAlert";
import SoftSnackbar from "components/SoftSnackbar";
import callFetch from "helpers/callFetch";
import { useLoadScript, Autocomplete } from '@react-google-maps/api';

const CustomerCreate = () => {
    const { t } = useTranslation();
    const [currency, setCurrency] = useState('EUR');
    const [data, setData] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [receivedError, setReceivedError] = useState(null);
    const [einkaufData, setEinkaufData] = useState([]);

    const [autocomplete, setAutocomplete] = useState(null);
    const libraries = ["places"]
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries
    })

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    const onLoad = (autocomplete) => {
        console.log('autocomplete: ', autocomplete);
        setAutocomplete(autocomplete);
    }

    const onPlaceChanged = () => {
        if (autocomplete !== null) {

            var place = autocomplete.getPlace();
            //console.log('address_components');
            setValue('plz', '');
            setValue('nr', '');
            setValue('ort', '');
            setValue('street', '');

            if (place?.address_components) {
                for (let [key, value] of Object.entries(place?.address_components)) {
                    if (value?.types) {
                        if (value?.types.indexOf("postal_code") !== -1) {
                            setValue('plz', value?.long_name);
                        } else if (value?.types.indexOf("street_number") !== -1) {
                            setValue('nr', value?.long_name);
                        } else if (value?.types.indexOf("locality") !== -1) {
                            setValue('ort', value?.long_name);
                        } else if (value?.types.indexOf("route") !== -1) {
                            setValue('street', value?.long_name);
                        }
                    }
                }
            }
            setValue('lat', place.geometry.location.lat());
            setValue('lng', place.geometry.location.lng());
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }


    useEffect(() => { }, [refresh2]);



    useEffect(() => {
        flatpickr(".flat-pickr");
        callFetch("customers/create", "GET", []).then((res) => {
            setValue('customer_nummer', res.customer_nummer);
            setRefresh(refresh + 1)
        });
    }, []);




    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            content="Customer successfully created"
            dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
        />
    );

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("customers", "POST", formData, setError).then((res) => {
            if (receivedError == null && res.message === "success") {
                openSuccessSB();
            }
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    if (loadError) return "Error loading maps"
    if (!isLoaded) return;// "Loading Maps"

    return submitSuccess ? <Navigate to={'/customers'} /> :
        <div className="row">
            <div className="col-8">
                <div className="card">
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>{t('Customer Nr.')} *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("customer_nummer", {
                                                required: false,
                                            })}
                                            readOnly
                                        />
                                        <div className="invalid-feedback">{errors.customer_nummer && errors.customer_nummer.message}</div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>{t('Von wo kommt der Kunde')} *</label>
                                        <select className="form-control" {...register('von_wo_der_kunde', { required: true })} required>
                                            <option value={''}>---</option>
                                            <option value="Physisches Marketing">{t('Physisches Marketing')}</option>
                                            <option value="Online Marketing">{t('Online Marketing')}</option>
                                            <option value="Gekaufte Leads">{t('Gekaufte Leads')}</option>
                                            <option value="Empfehlung">{t('Empfehlung')}</option>
                                            <option value="Werbung">{t('Werbung')}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.von_wo_der_kunde && errors.von_wo_der_kunde.message}</div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>{t('Kundenstatus.')} *</label>
                                        <select className="form-control" {...register('kundenstatus', { required: true })} required>
                                            <option value={''}>---</option>
                                            <option value="Lead">{t('Lead')}</option>
                                            <option value="Angebot">{t('Angebot')}</option>
                                            <option value="Bestandskunde">{t('Bestandskunde')}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.kundenstatus && errors.kundenstatus.message}</div>
                                    </div>
                                </div>



                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>{t('Anrede')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("anrede", {
                                                required: true,

                                            })}
                                            required>
                                            <option value="">--</option>
                                            <option value="Herr">{t('Herr')}</option>
                                            <option value="Frau">{t('Frau')}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.anrede && errors.anrede.message}</div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>{t('Vorname')} *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("vorname", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.vorname && errors.vorname.message}</div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>{t('Name')} *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("name", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-md-9">
                                    <Autocomplete
                                        options={{
                                            componentRestrictions: { country: "de" },
                                            //fields: ["address_components", "geometry", "icon", "name"],
                                            //types: ["establishment"]
                                        }}
                                        onLoad={onLoad}
                                        onPlaceChanged={onPlaceChanged}
                                    >
                                        <div className="form-group">
                                            <label>{t('Straße')} *</label>
                                            <input
                                                type="text"
                                                placeholder={t('')}
                                                className="form-control"
                                                //defaultValue={getValues('street') ? getValues('street') + ' ' + getValues('nr') : ''}
                                                {...register("full_address", {
                                                    required: false,
                                                })}

                                            />
                                            <div className="invalid-feedback d-block">{errors.street ? errors.street.message : (errors.nr ? t('Please add a street nr.') : '')}</div>
                                        </div>
                                    </Autocomplete>
                                    <div className="form-group d-none">
                                        <label>{t('Straße')} *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("street", {
                                                required: false,
                                            })}
                                        />
                                        <div className="invalid-feedback">{errors.street && errors.street.message}</div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>{t('Nr')} *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("nr", {
                                                required: false,
                                            })}
                                            readOnly
                                        />
                                        <div className="invalid-feedback">{errors.nr && errors.nr.message}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('PLZ')} *</label>

                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("plz", {
                                                required: false,
                                            })}
                                            readOnly
                                        />
                                        <div className="invalid-feedback">{errors.plz && errors.plz.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('Ort')} *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("ort", {
                                                required: false,
                                            })}
                                            readOnly
                                        />
                                        <div className="invalid-feedback">{errors.ort && errors.ort.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('Firma')} *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("firma")} />
                                        <div className="invalid-feedback">{errors.firma && errors.firma.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('E-mail Adresse')} *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("email", {
                                                required: false,
                                                onChange: (e) => {
                                                    //setCustomEmail(e.target.value);
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('Telefonnummer')} *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("telefonnummer", {
                                                required: false,
                                            })}
                                        />
                                        <div className="invalid-feedback">{errors.telefonnummer && errors.telefonnummer.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('Mobilnummer')} *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("mobilnummer", {
                                                required: false,
                                            })}
                                        />
                                        <div className="invalid-feedback">{errors.mobilnummer && errors.mobilnummer.message}</div>
                                    </div>
                                </div>


                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <label>{t('Description')}</label>
                                    <textarea className="form-control" rows="3"
                                        defaultValue=""
                                        placeholder="eg. Description"
                                        {...register("description")}>

                                    </textarea>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-5">{receivedError && <SoftAlert color="error" dismissible> {receivedError} </SoftAlert>}</div>
                                <div className="col-12 mb-4 text-end">
                                    {!saving && (
                                        <button type="submit" className="btn btn-primary">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

}

export default CustomerCreate;
