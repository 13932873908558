import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { callFetch } from "helpers/callFetch";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import { NumericFormat } from 'react-number-format';
import SoftAlert from "components/SoftAlert";
import SoftSnackbar from "components/SoftSnackbar";
import Select from 'react-select';
import SoftEditor from "components/SoftEditor";

function Create(props) {
    const { t } = useTranslation();
    const [currency, setCurrency] = useState('EUR');
    const [data, setData] = useState([]);
    const [items, setItems] = useState([{ title: '', quantity: 1, price: 0 }]);
    const [itemSummery, setItemSummery] = useState({ items: [], subTotal: 0, discountPercentage: 0, discount: 0, taxPercentage: 0, tax: 0, total: 0 });
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [refresh3, setRefresh3] = useState(0);
    const [receivedError, setReceivedError] = useState(null);
    const [invoice_id, setInvoiceId] = useState(0)
    const [selectedOrderId, setselectedOrderId] = useState({ id: '' });
    const [description, setDescription] = useState('');

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            content="Invoice successfully created"
            dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
        />
    );

    useEffect(() => {


    }, [props?.orderId]);



    useEffect(() => {
        var subTotal = 0;
        items.map((item) => {
            subTotal += Number(item.quantity) * Number(item.price);
        });
        itemSummery.subTotal = subTotal;
        itemSummery.amount = parseFloat(itemSummery.subTotal);
        // setSubtotal(subTotal);

        itemSummery.tax = parseFloat((itemSummery.amount * (itemSummery.taxPercentage / 100)).toFixed(2));
        itemSummery.discount = parseFloat((itemSummery.amount * (itemSummery.discountPercentage / 100)).toFixed(2));
        itemSummery.total = ((itemSummery.amount + itemSummery.tax) - itemSummery.discount);
        itemSummery.items = items;
        setItemSummery(itemSummery);
        setRefresh2(refresh2 + 1);
    }, [refresh]);

    useEffect(() => {
        if (!(props?.orderId && props?.parrentId)) return;
        console.log('props?.orderId');
        console.log(props?.orderId)

        setValue('auftrag_id', props?.orderId);
        setValue('parrent_id', props?.parrentId);
        setselectedOrderId({
            id: props?.orderId,
        });

    }, [props?.orderId, props?.parrentId]);

    useEffect(() => {
        flatpickr(".flat-pickr");

        callFetch("invoices/create", "GET", []).then((res) => {
            setData(res?.data);
            setValue('id', res?.data?.id);
            setRefresh(refresh + 1)
        });
    }, []);

    function deleteProduct() {
        var titems = [];
        items.map((t) => {
            if (!t)
                return;
            titems.push(t);
        });
        setItems(titems);
        setRefresh(refresh + 1);
    }

    const onSubmit = (formData) => {

        setSaving(true);

        formData.subTotal = itemSummery.subTotal;
        formData.netto = itemSummery.amount;
        formData.brutto = itemSummery.total;
        formData.tax = itemSummery.taxPercentage;

        formData.product_details = JSON.stringify(itemSummery);
        formData.description = description;
        formData.assembly_cost = 0;
        formData.gutschrift = 1;

        callFetch("invoices", "POST", formData, setError).then((res) => {
            if (receivedError == null) {
                openSuccessSB();
            }
            setSaving(false);
            if (!res.ok) return;
            setInvoiceId(res.id);
            setRefresh3(refresh3 + 1);

            if (document.getElementById('ZahlungseingangeListRefresh')) {
                document.getElementById('ZahlungseingangeListRefresh').click();
            }

            if (document.getElementById('InvoiceoverviewRefresh')) {
                document.getElementById('InvoiceoverviewRefresh').click();
            }

            if (props?.redirect == false) {
                if (props?.popupClose == true) {
                    document.getElementById('createGutschriftClose').click();
                }
                if (props?.invoiceListRefresh == true) {
                    document.getElementById('refreshInvoiceList').click();
                }
            } else {
                setSubmitSuccess(true);
            }

        });
    };

    return submitSuccess ? <Navigate to={`/invoices/${invoice_id}`} /> :
        <div className="row">
            <div className={props?.col ? props?.col : "col-md-9"}>
                <div className="card">
                    <div className="card-header pb-0">
                        <h6>{t('Gutschrift hinzufügen')}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <div className="row g-3">
                                <div className="col-md-3">
                                    <label>{t('Invoice Nr.')} *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('eg: 700001')}
                                        {...register("id", {
                                            required: true,
                                        })}
                                        readOnly
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.identity_number && errors.identity_number.message}</div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>
                                            {t('Auftrag')} *
                                        </label>
                                        <br />
                                        <Select
                                            placeholder={t('Select')}
                                            options={data?.orders ? data?.orders : {}}
                                            getOptionLabel={(option) => ((option.id ? option.id : '') + (option?.quation?.project?.id ? ' - ' + option?.quation?.project?.id : '') + (option?.quation?.project?.vorname ? ' - ' + option?.quation?.project?.vorname : '') + (option?.quation?.project?.name ? ' ' + option?.quation?.project?.name : ''))}
                                            getOptionValue={(option) => option.id}
                                            value={selectedOrderId}
                                            onChange={(e) => {
                                                setValue('auftrag_id', e.id);
                                                setselectedOrderId(e);
                                            }}
                                            required
                                            isDisabled={props?.orderId ? true : false}
                                        />
                                        <div className="invalid-feedback">{errors.auftrag_id && errors.auftrag_id.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>
                                            {t('Currency')} *
                                        </label>
                                        <br />
                                        <select
                                            className="form-control"
                                            {...register("currency", {
                                                required: true,
                                            })}
                                            onChange={(e) => {
                                                setCurrency(e.target.value)
                                            }}
                                            required
                                        >
                                            <option value="EUR">EUR (€)</option>
                                            <option value="USD">USD ($)</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.currency && errors.currency.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <label>
                                        {t('Invoice Date')} *
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control mb-4 flat-pickr"
                                        placeholder={t('eg. 2001-03-20')}
                                        defaultValue={dateFormat(new Date(), "yyyy-mm-dd")}
                                        {...register("date", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.date && errors.date.message}</div>
                                </div>
                            </div>

                            <div className="mt-3">
                                {items && items.map((item, i) => (
                                    <div key={i} className="">
                                        {i > 0 && <hr />}
                                        <div className="row mt-2">
                                            <div className="col-md-8">
                                                <div>
                                                    <label>{t('Product')}</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={items[i].title}
                                                        onChange={(e) => {
                                                            items[i].title = e.target.value;
                                                            setItems(items);
                                                            setRefresh(refresh + 1);
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div>
                                                    <label>{t('Qty/Hrs')}</label>
                                                    <input
                                                        type="number"
                                                        className="form-control d-inline"
                                                        placeholder="eg. 0.00"
                                                        value={items[i].quantity}
                                                        onChange={(e) => {
                                                            let value = 0;
                                                            if (e.target.value.length && !isNaN(e.target.value))
                                                                value = e.target.value;

                                                            items[i].quantity = value;
                                                            setItems(items);
                                                            setRefresh(refresh + 1);
                                                        }} />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div>
                                                    <label>{t('Price')}</label>
                                                    <input
                                                        type="number"
                                                        style={{ width: '84%' }}
                                                        className="form-control d-inline"
                                                        placeholder="eg. 0.00"
                                                        value={items[i].price}
                                                        step={0.01}
                                                        onChange={(e) => {
                                                            let value = 0;
                                                            if (e.target.value.length && !isNaN(e.target.value))
                                                                value = e.target.value;

                                                            items[i].price = value;
                                                            setItems(items);
                                                            setRefresh(refresh + 1);
                                                        }}
                                                    />
                                                    &nbsp;
                                                    <i className="fa-solid fa-circle-xmark text-danger" data-key={i} onClick={() => { delete items[i]; deleteProduct(); }}></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div className="row mt-3 ">
                                    <div className="col-md-12">
                                        <p className="btn btn-dark" style={{ cursor: 'pointer' }} onClick={() => { setItems([...items, { title: '', quantity: 1, price: 0 }]); setRefresh(refresh + 1); }}><i className="fa-solid fa-circle-plus"></i>&nbsp;{t('Add Item')}</p>
                                    </div>
                                </div>

                                <hr />

                                <div className="row">
                                    <div className="col-md-6">
                                        <SoftEditor
                                            onChange={setDescription}
                                            value={description}
                                            style={{
                                                height: '120px',
                                                overFlowY: 'auto'
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6 border">
                                        <div className="row">
                                            <div className="col-md-7 border-end">
                                                <label className="d-block text-end">{t('Sub Total')}</label>
                                            </div>
                                            <div className="col-md-5">
                                                <p className="text-end mb-0">
                                                    <NumericFormat
                                                        value={itemSummery.amount}
                                                        displayType="text"
                                                        thousandSeparator={"."}
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row border-top d-none">
                                            <div className="col-md-3 border-end">
                                                <label className="d-block text-end">{t('Discount')}</label>
                                            </div>
                                            <div className="col-md-3 border-end">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        let value = 0;
                                                        if (e.target.value.length && !isNaN(e.target.value))
                                                            value = e.target.value;
                                                        itemSummery.discountPercentage = parseFloat(value);
                                                        setItemSummery(itemSummery);
                                                        setRefresh(refresh + 1);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-1 border-end">
                                                <p className="text-end">%</p>
                                            </div>
                                            <div className="col-md-5">
                                                <p className="text-end">
                                                    <NumericFormat
                                                        value={itemSummery.discount}
                                                        displayType="text"
                                                        thousandSeparator={"."}
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row border-top">
                                            <div className="col-md-3 border-end">
                                                <label className="d-block text-end">{t('Tax')}</label>
                                            </div>
                                            <div className="col-md-4 border-end">
                                                <select
                                                    className="form-control"
                                                    value={itemSummery.taxPercentage}
                                                    onChange={(e) => {
                                                        let value = 0;
                                                        if (e.target.value.length && !isNaN(e.target.value))
                                                            value = e.target.value;

                                                        itemSummery.taxPercentage = parseFloat(value);
                                                        setItemSummery(itemSummery);
                                                        setRefresh(refresh + 1);
                                                    }}>
                                                    <option value="0">0%</option>
                                                    <option value="19">19%</option>
                                                </select>
                                            </div>
                                            <div className="col-md-5">
                                                <p className="text-end">
                                                    <NumericFormat
                                                        value={itemSummery.tax}
                                                        displayType="text"
                                                        thousandSeparator={"."}
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row bg-gray-200">
                                            <div className="col-md-7">
                                                <label className="d-block text-end">{t('Total')}</label>
                                            </div>
                                            <div className="col-md-5">
                                                <p className="text-end">
                                                    <NumericFormat
                                                        value={((itemSummery.amount + itemSummery.tax))}
                                                        displayType="text"
                                                        thousandSeparator={"."}
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-6">{receivedError && <SoftAlert color="error" dismissible> {receivedError} </SoftAlert>}</div>
                                <div className="col-md-12 mt-3">
                                    {saving ? (
                                        <button type="button" className="btn btn-disabled mt-5" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    ) : (
                                        <button type="submit" className="btn btn-primary mt-5">{t('Save')}</button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {renderSuccessSB}
        </div>;
}

export default Create;