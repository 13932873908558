import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { NumericFormat } from 'react-number-format';

function IndexTable(props) {
    const { t } = useTranslation();
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [invoiceId, setInvoiceId] = useState(0);
    const [searchKey, setSearchKey] = useState("")
    const [searchData, setSearchData] = useState([])
    const [filterData, setFilterData] = useState([])

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    const createInvoice = (order) => {
        setSaving(true);
        callFetch("invoices", "POST", {
            auftrag_id: order.id,
            currency: order.currency,
            netto: order.netto,
            tax: order.tax,
            brutto: order.brutto,
            product_details: JSON.stringify({ items: [{ id: order.quation.speicher, title: '', quantity: 1, price: order.netto, currentPrice: order.netto, total: order.brutto }], emails: [], subTotal: order.netto, discountPercentage: 0, discount: 0, taxPercentage: 19, tax: 0, total: order.brutto }),
        }, setError).then((res) => {
            setSaving(false);
            //if (!res.ok) return;
            if (res.message == 'success') {
                setInvoiceId(res.id);
                setSubmitSuccess(true);
            }
        });
    };

    const tableHeadings = [
        {
            name: t('Order Nr.'),
            selector: row => (
                <NavLink to={'/orders/' + row.id} className={"text-primary font-weight-bold"}>
                    {row.id}
                </NavLink>
            ),
            sortable: true,
        },
        {
            name: t('Kunde'),
            cell: row => (row?.quation.project?.vorname ? row?.quation?.project?.vorname + ' ' + row?.quation?.project?.name : ''),
        },
        {
            name: t('Projektnr.'),
            cell: row => (row?.quation?.project ? <NavLink to={'/projects/' + row?.quation?.project?.id} className={"text-primary font-weight-bold"}>{row?.quation?.project?.id}</NavLink> : ''),
        },
        {
            name: t('Angebotsnr.'),
            cell: row => row?.quation?.id,
        },
        {
            name: t('Angebotsdatum'),
            cell: row => dateFormat(row?.quation?.created_at, "dd.mm.yyyy"),
        },
        {
            name: t('Verkaufer'),
            cell: row => (row?.quation?.project?.salse_person ? row?.quation?.project?.salse_person?.name : ''),
        },
        {
            name: t('Auftragsdatum '),
            selector: row => dateFormat(row.order_date, "dd.mm.yyyy"),
        },
        {
            name: t('Status'),
            cell: row => (
                <>

                    <span className="badge badge-dot me-4">
                        {row?.quation?.project?.stornieren == 1 ? (
                            <>
                                <i className="bg-danger"></i>
                                <span className="text-dark text-xs">{'Storno'}</span>
                            </>
                        ) : (
                            <>
                                {row?.quation?.project?.status && row?.quation?.project?.sales_status?.id ? (
                                    <><i className={'status-' + row?.quation?.project?.sales_status?.color ?? "bg-dark"}></i><span className={"text-dark text-xs"}>{t(row?.quation?.project?.sales_status?.name ?? row?.quation?.project?.status)}</span></>
                                ) : row?.quation?.project?.termine_qoute?.sales_status?.name && row?.quation?.project?.termine_qoute?.status && row?.quation?.project?.termine_qoute?.status != 'null' ? (
                                    <><i className={'status-' + row?.quation?.project?.termine_qoute?.sales_status?.color ?? "bg-dark"}></i><span className={"text-dark text-xs "}>{t(row?.quation?.project?.termine_qoute?.sales_status?.name ?? row?.quation?.project?.termine_qoute?.status)}</span></>
                                ) : <span className="text-dark text-xs">{'--'}</span>}
                            </>
                        )}
                    </span>
                </>
            ),
        },
        {
            name: t('Statusdatum'),
            cell: row => (row?.activity_created_at ? dateFormat(row.activity_created_at, "dd.mm.yyyy") : ''),
        },
        {
            name: t('DC Datum'),
            cell: row => (row?.quation?.project?.dachmontag?.date ? dateFormat(row?.quation?.project?.dachmontag?.date, "dd.mm.yyyy") : ''),
        },
        {
            name: t('Summe'),
            width: '120px',
            cell: row => (
                <>
                    <NumericFormat
                        value={Number(row.brutto)}
                        displayType="text"
                        thousandSeparator={"."}
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        suffix={row.currency == 'EUR' ? ' € ' : ' $ '}
                    />
                </>
            ),
        },
        {
            name: t('Actions'),
            cell: row => (
                <>
                    {
                        JSON.parse(Cookies.get('permissions')).indexOf("OU") !== -1 ||
                            JSON.parse(Cookies.get('permissions')).indexOf("OD") !== -1 ? (
                            <div className="text-center dropstart">
                                <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa fa-ellipsis-v text-xs"></i>
                                </a>
                                <ul className="dropdown-menu">
                                    {JSON.parse(Cookies.get('permissions')).indexOf("IC") !== -1 ? (
                                        <>
                                            <li>
                                                <NavLink to={"/invoices/add/" + row.id} className="dropdown-item">
                                                    {t('Create Invoice')}
                                                </NavLink>
                                            </li>
                                            <li><hr className="dropdown-divider" /></li>
                                        </>
                                    ) : <></>}

                                    {JSON.parse(Cookies.get('permissions')).indexOf("OU") !== -1 ? (
                                        <>
                                            <li>
                                                <NavLink to={'/orders/' + row.id} className="dropdown-item">
                                                    {t('Edit')}
                                                </NavLink>
                                            </li>
                                            <li><hr className="dropdown-divider" /></li>
                                        </>
                                    ) : <></>}
                                    {JSON.parse(Cookies.get('permissions')).indexOf("OD") !== -1 ? (
                                        <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'order', row.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                                    ) : <></>}
                                </ul>
                            </div>
                        ) : <></>}
                </>
            )
        }
    ];

    useEffect(() => {
        callFetch("order?page=" + pageNumber + "&search=" + searchKey + "&status=" + props?.status, "GET", []).then((res) => {
            setTableData(res.data);
        });
    }, [pageNumber, refresh, searchKey, props?.status]);

    /*
    useEffect(() => {
        if (searchKey.length > 0) {
            callFetch("order/search/" + searchKey, "GET", []).then((res) => {
                setTableData(res.data);
            })
        } else {
            setRefresh(refresh + 1)
        }
    }, [searchKey])
    */


    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return submitSuccess ? <Navigate to={'/invoices/' + invoiceId} /> :
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {tabledata?.links.map((page) => {
                            //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                            const className = (page.active === true ? "page-item active" : "page-item");
                            return (
                                <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                    <button
                                        className="page-link"
                                        onClick={page.label != '...' ? handlePageNumber : ''}
                                        value={page.label}
                                        disabled={page.label == '...' ? true : false}
                                    >
                                        {page.label}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
            ;
    };

    return <DataTable
        columns={tableHeadings}
        data={tabledata?.data}
        noDataComponent={t('There are no records to display')}
        className='data-table'
        pagination
        highlightOnHover
        paginationComponentOptions={{ noRowsPerPage: true }}
        paginationServer
        paginationTotalRows={tabledata?.total}
        onChangePage={handlePageChange}
        paginationComponent={BootyPagination}
        subHeader
        subHeaderComponent={<input type="text" placeholder={t("Search...")} className=' form-control w-sm-50 w-md-25 w-lg-15' value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />}
    />;
}

export default IndexTable;