import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import { NumericFormat } from 'react-number-format';
import SoftAlert from "components/SoftAlert";
import SoftSnackbar from "components/SoftSnackbar";
import { useCallFetchv2 } from 'helpers/callFetchv2';
function DeviceManagment({ title, lager, refreshData }) {
    const params = useParams();
    const callFetch = useCallFetchv2();
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [items, setItems] = useState([{ id: 0, zone_name: "", regal_nr: 0 }]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [receivedError, setReceivedError] = useState(null);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();



    useEffect(() => { }, [refresh2]);


    function deleteProduct() {
        var titems = [];
        items.map((t) => {
            if (!t)
                return;
            titems.push(t);
        });
        setItems(titems);
        setRefresh(refresh + 1);
    }
    useEffect(() => {
        if (lager) {
            if (!lager.warehouse_zone) return;
            let warehouse_zone = JSON.parse(lager?.warehouse_zone);
            setItems(warehouse_zone);
        }
    }, [0]);

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            content="Warehouse zone added successfully"
            dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
        />
    );

    const onSubmit = (formData) => {
        setSaving(true);
        formData.warehouse_zone = JSON.stringify(items)
        callFetch("lager/" + params?.id, "POST", formData, setError).then((res) => {
            /*
            if (res.message === 'Successfully updated' && params?.id) {
                openSuccessSB();
            }
            */
            setSaving(false);
            setRefresh(refresh + 1)
            refreshData();
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return (
        <div className="">
            <div className="col-12">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t(`${title}`)}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <input type="hidden" defaultValue="PUT" {...register("_method")} />

                            <div className="mt-3">
                                {items && items.map((item, i) => (
                                    <div key={i} className="row mt-2">
                                        <div className="col-12 col-md-4">
                                            <div>
                                                <label>{t('Zone Name')}</label>
                                                <input type="text" className="form-control" defaultValue={items[i].zone_name} placeholder={t('e.B Zone Name')} onChange={(e) => {
                                                    items[i].id = items[i].id + 1;
                                                    items[i].zone_name = e.target.value;
                                                    setItems(items);
                                                    setRefresh(refresh + 1);
                                                }} />

                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div>
                                                <label>{t('Regal Nr.')}</label>
                                                <input type="number" style={{ width: '84%' }} value={parseInt(items[i].regal_nr)} className="form-control d-inline" placeholder="eg. 0.00" onChange={(e) => {
                                                    items[i].regal_nr = parseFloat(e.target.value);
                                                    setItems(items);
                                                    setRefresh(refresh + 1);
                                                }} />
                                                &nbsp;
                                                <i className="fa-solid fa-circle-xmark text-danger" data-key={i} onClick={() => { delete items[i]; deleteProduct(); }}></i>
                                            </div>
                                        </div>

                                    </div>
                                ))}

                                <div className="row mt-3 ">
                                    <div className="col-md-12">
                                        <p className="btn btn-dark" style={{ cursor: 'pointer' }} onClick={() => { setItems([...items, { id: 0, zone_name: "", regal_nr: 0 }]); setRefresh(refresh + 1); }}><i className="fa-solid fa-circle-plus"></i>&nbsp;{t('Add Item')}</p>
                                    </div>
                                </div>



                            </div>
                            <hr />

                            <div className="row mt-3">
                                <div className="col-md-5">{receivedError && <SoftAlert color="error" dismissible> {receivedError} </SoftAlert>}</div>
                                <div className="col-md-12 mt-3">
                                    <div className="col-12">
                                        {!saving && (
                                            <button type="submit" className="btn mb-0"
                                            style={{
                                                background: '#2684FF',
                                                color: '#ffffff',
                                                textTransform: 'capitalize',
                                                fontWeight: 'normal'
                                            }}>
                                                {t('Save')}
                                            </button>
                                        )}
                                        {saving && (
                                            <button type="submit" className="btn btn-disabled" disabled>
                                                {t('Saving ...')}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {renderSuccessSB}
        </div>
    )

}

export default DeviceManagment