import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import Swal from 'sweetalert2';
import React, { useMemo, useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import callFetch from "helpers/callFetch";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import SoftTypography from "components/SoftTypography";
import SoftEditor from "components/SoftEditor";
import FindNearestUsersByProject from "pages/FindNearest/UsersByProject"

import ProjectPost from "pages/project/create/ProjectPost";

import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import {
  useSoftUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setMiniSidenav,
  setFixedNavbar,
  setSidenavColor,
} from "context";

const EditPlan = (props) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState("Sehr geehrte Damen und Herren, <br/><br/>hiermit bestätigen wir Ihnen, Ihren Termin mit unserem Techniker. <br/><br/>Sollten Sie diesen Termin nicht wahrnehmen können, bitten wir Sie uns unter der info@solacloud.de zu informieren. <br/><br/>Bitte zögern Sie nicht, im Falle von Unklarheiten oder Rückfragen, mit uns Kontakt aufzunehmen. <br/><br/>Gerne steht Ihnen unser Team zu unseren Geschäftszeiten Mo. – Do. von 09:00 – bis 18:00 Uhr und Fr. von 09:00 bis 15:00 Uhr unter +49 89 904220890 oder jederzeit per eMail unter info@solacloud.de zur Verfügung.<br/><br/>Ihr Solacloud Team<br/>Solacloud GmbH<br/>+49 89 904220890 info@solacloud.de");
  const [refresh, setRefresh] = useState(0);
  const [saving, setSaving] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [backToPlan, setBackToPlan] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [data, setData] = useState([]);
  const [isVisible, setIsVisible] = useState(0);
  const [miltarbeiterId, setMiltarbeiterId] = useState(0);
  const [project, setProject] = useState(null);
  const [miltarbeiter, setMiltarbeiter] = useState([]);


  const fp = useRef(null);
  const fpt = useRef(null);
  //const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, true);
  const [controller, dispatch] = useSoftUIController();
  const { openConfigurator, transparentSidenav, miniSidenav, fixedNavbar, sidenavColor } = controller;
  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
  const handleClearButton = () => {
    document.getElementById('ortInput').value = '';
    document.getElementById('min').value = '';
    document.getElementById('max').value = '';
    document.getElementById('projectidInput').value = '';
    document.getElementById('kundenInput').value = '';
    document.getElementById('vkbInput').value = '';
    fp.current.flatpickr.clear();
    fpt.current.flatpickr.clear();
  }


  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();


  useEffect(() => {
    if (!props?.data?.id) return;

    setSendingEmail(false);

    var url = '';

    if (props?.data?.planform == "Reclamation") {
      url = "reclamation-plan/" + props?.data?.id + "/edit";
    } else if (props?.data?.planform == "ACFertigmeldung") {
      url = "zahlertermin-plan/" + props?.data?.id + "/edit";
    } else {
      url = "assign-order-processing/" + props?.data?.id + "/edit";
    }

    callFetch(url, "GET", []).then((res) => {
      setEmployees(res?.data?.employees);
      setData(res?.data?.data);
    });

  }, [props?.data?.id, props?.refresh]);

  useEffect(() => {
    if (!data?.id) return;

    for (let [key, value] of Object.entries(data)) {
      setValue(key, (value == null || value == 'null' ? "" : value));
    }
  }, [data, props?.refresh]);

  const sendEmail = () => {
    if (!data?.id) return;
    //e.preventDefault();

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary me-2',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    });

    return swalWithBootstrapButtons.fire({
      text: t("Are you sure to send this E-mail?"),
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('Yes, do it!'),
      cancelButtonText: t('Cancel')
    }).then((result) => {
      if (result.isConfirmed) {

        //console.log(props?.data);


        var formData = new FormData();
        formData.event_id = props?.data?.id;
        formData.planform = props?.data?.planform;
        formData.description = description;
        formData.project_id = data.project_id;
        formData.miltarbeiter = getValues('miltarbeiter');
        formData.time = getValues('time');
        formData.date = getValues('date');
        formData.dachmontag_elektromontage = getValues('dachmontag_elektromontage');
        callFetch("send-new-plan-email", "POST", formData, setError).then((res) => {
          // console.log(res.data);
          setSaving(false);
          if (!res.ok) return;
          setSendingEmail(true);
          setSubmitSuccess(true);
        });
      }
    });
  }

  const sendBackToPlan = () => {
    if (!data?.id) return;

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary me-2',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    });

    return swalWithBootstrapButtons.fire({
      text: t("Are you sure to send back to plan?"),
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('Yes, do it!'),
      cancelButtonText: t('Cancel')
    }).then((result) => {
      if (result.isConfirmed) {

        //console.log('Back to plan');
        //console.log(data);

        setSaving(false);

        var url = '';
        if (props?.data?.planform == "Reclamation") {
          url = "reclamation-plan/" + data.id;
        } else {
          url = "assign-order-processing/" + data.id;
        }

        var formData = new FormData();
        callFetch(url, "POST", { '_method': 'DELETE' }, null).then((res) => {

          setSaving(false);
          if (!res.ok) return;
          setSubmitSuccess(true);
          props.setRefresh((Math.random() * 1000) + 1000);
          handleCloseConfigurator();
        });


      }
    });
  }

  const onSubmit = (formData) => {
    if (!data?.id) { alert('id not found'); return; }
    setSaving(true);
    formData.project_id = data.project_id;
    formData.description = description;

    var url = '';
    if (props?.data?.planform == "Reclamation") {
      url = "reclamation-plan/" + data.id;
    } else if (props?.data?.planform == "ACFertigmeldung") {
      url = "zahlertermin-plan/" + props?.data?.id;
    } else {
      url = "assign-order-processing/" + data.id;
    }

    callFetch(url, "POST", formData, setError).then((res) => {
      //console.log('edit plan submit')

      setSaving(false);
      if (!res.ok) return;
      setSubmitSuccess(true);
      props.setRefresh((Math.random() * 1000) + 1000);
      //var projectInfoModalClose = document.getElementById('triggerEditPlanClose');
      //projectInfoModalClose.click();
      //setValue('date', '')
      //setValue('time', '')
      //setValue('miltarbeiter', '')
      //setValue('dachmontag_elektromontage', '')
      //setValue('description', '')
    });
  };

  const setNearestMiltarbeiter = (id) => {
    if (props?.data?.id) return;

    setValue('miltarbeiter', id);
    setMiltarbeiterId({
      id: id,
      name: '',
    });
  }

  const setDirectionDistance = (data) => {
    console.log('setDirectionDistance');
  }



  return (
    <>
      <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }} configWidth={500} padding={0}>
        <SoftBox
          sx={{ background: '#ffffff' }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
            pt={3}
            pb={0.8}
            px={3}
          >
            <SoftBox>
              <SoftTypography variant="h5">{t("Edit Plan")}</SoftTypography>
            </SoftBox>

            <Icon
              sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
              })}
              onClick={handleCloseConfigurator}
            >
              close
            </Icon>
          </SoftBox>

          <Divider />

          <SoftBox pt={1.25} pb={3} px={3} >
            <div className="row">

              <div className="col-lg-12">
                <div className="nearestUsersCard">
                  <SoftBox p={1}>
                    <SoftTypography className="mb-1" variant="h6">{t('Project Nr')}: <NavLink to={'/projects/' + data?.project_id} className="text-primary">{data?.project_id}</NavLink></SoftTypography>
                    <SoftTypography variant="caption"><strong>{t('Customer')}</strong>:</SoftTypography>
                    <table>
                      <tr>
                        <td width="70"><SoftTypography variant="caption">{t('Name')}:</SoftTypography></td>
                        <td><SoftTypography variant="caption">{data?.project?.vorname} {data?.project?.name}</SoftTypography></td>
                      </tr>
                      <tr>
                        <td width="70"><SoftTypography variant="caption">{t('Adresse')}:</SoftTypography></td>
                        <td><SoftTypography variant="caption"><a style={{ textDecoration: 'underline', color: '#005498', cursor: "pointer" }} href="#">{data?.project?.street}, {data?.project?.nr}</a></SoftTypography></td>
                      </tr>
                      <tr>
                        <td width="70"><SoftTypography variant="caption">{t('PLZ, Ort')}:</SoftTypography></td>
                        <td><SoftTypography variant="caption">{data?.project?.plz}, {data?.project?.ort}</SoftTypography></td>
                      </tr>
                      <tr>
                        <td width="70"><SoftTypography variant="caption">{t('Telefon')}:</SoftTypography></td>
                        <td><SoftTypography variant="caption"><a style={{ textDecoration: 'underline', color: '#005498', cursor: "pointer" }} href="#">{data?.project?.telefonnummer} </a></SoftTypography></td>
                      </tr>
                    </table>
                  </SoftBox>
                </div>
              </div>

              {/*
              <div className="col-lg-6">
                <div className="nearestUsersCard">
                  <SoftBox p={1}>
                    <img src="https://placehold.co/150x150" className="rounded-circle mb-2" style={{ width: '45px', border: '1px solid #0048B1', padding: '3px' }} />
                    <SoftTypography variant="caption" className="d-block"><strong>{t('Eingeplanter Techniker')}</strong>:</SoftTypography>
                    <table>
                      <tr>
                        <td width="70"><SoftTypography variant="caption">{t('Name')}:</SoftTypography></td>
                        <td>
                          <SoftTypography variant="caption">
                            {miltarbeiter?.name ?
                              miltarbeiter?.name
                              : props?.dachmontag_elektromontage == 'Dachmontage' ?
                                miltarbeiter?.dachmontag?.employee?.name
                                : props?.dachmontag_elektromontage == 'Dachmontage' ?
                                  miltarbeiter?.elektromontage?.employee?.name
                                  : '--'}
                          </SoftTypography>
                        </td>
                      </tr>
                      <tr>
                        <td width="70"><SoftTypography variant="caption">{t('Adresse')}:</SoftTypography></td>
                        <td>
                          <SoftTypography variant="caption">
                            <a style={{ textDecoration: 'underline', color: '#005498', cursor: "pointer" }} href="#">
                              {miltarbeiter?.street ? miltarbeiter?.street : '--'}, {miltarbeiter?.nr ? miltarbeiter?.nr : '--'}
                            </a>
                          </SoftTypography>
                        </td>
                      </tr>
                      <tr>
                        <td width="70"><SoftTypography variant="caption">{t('PLZ, Ort')}:</SoftTypography></td>
                        <td>
                          <SoftTypography variant="caption">
                            {miltarbeiter?.plz ? miltarbeiter?.plz : '--'}, {miltarbeiter?.ort ? miltarbeiter?.ort : '--'}
                          </SoftTypography>
                        </td>
                      </tr>
                      <tr>
                        <td width="70"><SoftTypography variant="caption">{t('Telefon')}:</SoftTypography></td>
                        <td>
                          <SoftTypography variant="caption">
                            <a style={{ textDecoration: 'underline', color: '#005498', cursor: "pointer" }} href="#">
                              {miltarbeiter?.telefonnummer ? miltarbeiter.telefonnummer : '--'}
                            </a>
                          </SoftTypography>
                        </td>
                      </tr>
                      <tr>
                        <td width="70"><SoftTypography variant="caption">{t('Project Nr')}:</SoftTypography></td>
                        <td>
                          <SoftTypography variant="caption">
                            <a style={{ textDecoration: 'underline', color: '#005498', cursor: "pointer" }} href="#">
                              {miltarbeiter?.project_id ? miltarbeiter.project_id : '--'}
                            </a>
                          </SoftTypography>
                        </td>
                      </tr>
                    </table>
                  </SoftBox>
                </div>
              </div>
              */}

              <div className="col-md-12">
                <form
                  className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""
                    }`}
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                  autoComplete="off"
                >
                  <input type="hidden" defaultValue="PUT" {...register("_method")} />

                  <div className="row">
                    <div className="col-sm-6">
                      <label>{t("Start Datum")} *</label>
                      <input
                        type="date"
                        className="form-control form-control-sm mb-2 flatpickr"
                        placeholder={t("eg. 16-04-2022")}
                        {...register("date", {
                          required: true,
                        })}
                        required
                      />
                      <div className="invalid-feedback">
                        {errors.date && errors.date.message}
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <label>{t("Start Uhrzeit")} *</label>
                      <input
                        type="time"
                        className="form-control form-control-sm mb-2 flatpickr"
                        placeholder={t("eg. 16-04-2022")}
                        {...register("time", {
                          required: true,
                        })}
                        required
                      />
                      <div className="invalid-feedback">
                        {errors.date && errors.date.message}
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <label>{t("End Datum")} *</label>
                      <input
                        type="date"
                        className="form-control form-control-sm mb-2 flatpickr"
                        placeholder={t("eg. 16-04-2022")}
                        {...register("end_date", {
                          required: true,
                        })}
                        required
                      />
                      <div className="invalid-feedback">
                        {errors.date && errors.date.message}
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <label>{t("End Uhrzeit")} *</label>
                      <input
                        type="time"
                        className="form-control form-control-sm mb-2 flatpickr"
                        placeholder={t("eg. 16-04-2022")}
                        {...register("end_time", {
                          required: true,
                        })}
                        required
                      />
                      <div className="invalid-feedback">
                        {errors.date && errors.date.message}
                      </div>
                    </div>


                    <div className="col-sm-6">
                      <label>{t("Employees")} *</label>
                      <select
                        className="form-control form-control-sm mb-2"
                        {...register("miltarbeiter", {
                          required: true,
                          onChange: (e) => {
                            setMiltarbeiterId({
                              id: e.target.value,
                              name: e.target.selectedOptions[0].text
                            });
                          }
                        })}
                        required
                      >
                        <option>----</option>
                        {employees?.map((technician, index) => (
                          <option value={technician.id}>
                            {technician.name}
                          </option>
                        ))}
                      </select>
                      <div className="invalid-feedback">
                        {errors.employees && errors.employees.message}
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <label>{t("Dachmontage/Elektromontage")} *</label>
                      <select
                        className="form-control form-control-sm mb-2"
                        {...register("dachmontag_elektromontage", {
                          required: true,
                        })}
                        required
                        readOnly={true}
                      >
                        <option value="Dachmontage">Dachmontage</option>
                        <option value="Elektromontage">Elektromontage</option>
                      </select>
                      <div className="invalid-feedback">
                        {errors.date && errors.date.message}
                      </div>
                    </div>

                    {/*
                    <div className="col-md-12 mt-5 d-none">
                      <FindNearestUsersByProject
                        miltarbeiter={miltarbeiterId}
                        setNearestMiltarbeiter={setNearestMiltarbeiter}
                        projectId={data?.project_id}
                        dachmontag_elektromontage={data?.dachmontag_elektromontage}
                        setDirectionDistance={setDirectionDistance}
                        rightSidePopup={true}
                        detalsDisplay={false}
                        setProject={setProject}
                        setMiltarbeiter={setMiltarbeiter}
                        height={'175px'}
                      />
                    </div>
                    */}

                    <div className="col-12 mt-4">

                      {!saving ? (
                        <button type="submit" className="btn btn-primary">
                          {t("Save")}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-disabled"
                          disabled
                        >
                          {t("Saving ...")}
                        </button>
                      )}

                      {!saving ? (
                        <button
                          //type="submit"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#planEmailSendModal"
                          className={sendingEmail ? "btn btn-success mx-2" : "btn btn-secondary mx-2"}
                          style={{ marginRight: '15px' }}>
                          {t("Send E-mail")}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-disabled"
                          disabled
                          style={{ marginRight: '15px' }}>
                          {t("Sending ...")}
                        </button>
                      )}


                      {!backToPlan ? (
                        <button
                          type="button"
                          className="btn btn-danger"
                          style={{ marginRight: '15px' }}
                          onClick={() => {
                            sendBackToPlan()
                          }}
                        >
                          {t("Back To Plan")}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-disabled"
                          style={{ marginRight: '15px' }}
                          disabled
                        >
                          {t("Back To Plan")} ...
                        </button>
                      )}

                    </div>

                    <div className="col-md-12 mt-3 scroll-project-notizen">
                      <ProjectPost
                        id={data?.project_id}
                        create={false}
                      />
                    </div>

                  </div>
                </form>
              </div>
            </div>
            {/*<button type='button' className='btn btn-outline-dark mt-4' onClick={() => handleClearButton()}>Clear</button>*/}
          </SoftBox>
        </SoftBox>
      </ConfiguratorRoot>


      <div className="modal fade" id="planEmailSendModal" tabIndex={-1} role="dialog" aria-labelledby="planEmailSendModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered  modal-lg" role="document">
          <div className="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="planEmailSendModalLongTitle">{t("Send E-mail")}</h5>
              <button type="button" className="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <SoftTypography component="label" variant="caption" fontWeight="bold">{t('Termin Verschieben')}</SoftTypography>
              <SoftEditor
                value={description}
                onChange={setDescription}
              />
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" onClick={(e) => sendEmail()}>{t("Send E-mail")}</button>
            </div>
          </div>
        </div>
      </div>



      <button type="button" id="triggerEditPlanBtn" onClick={handleConfiguratorOpen} className="btn bg-gradient-primary d-none">Edit Plan</button>
    </>
  );
};

export default EditPlan;
