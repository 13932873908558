import { React } from "react";

import Card from "@mui/material/Card";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import PropTypes from "prop-types";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import typography from "assets/theme/base/typography";

function BigDataTable() {
  function PagesBodyCell({ rows, noBorder }) {
    const { light, error } = colors;
    const { borderWidth } = borders;

    const renderRows = rows.map((row, index) => {
      if (row === "Nach Storno") {
        return (
          <SoftBox
            key={row}
            component="td"
            width="100%"
            xs={{ alignItems: "center", textAlign: "center" }}
            borderBottom={
              noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`
            }
            textAlign="center"
            alignItems="center"
            pr={1}
          >
            <SoftTypography
              display="block"
              variant="button"
              fontWeight="medium"
              sx={{ width: "max-content", color: "#0048B1" }}
            >
              {row}
            </SoftTypography>
          </SoftBox>
        );
      } else if (row === 1967) {
        return (
          <SoftBox
            key={row}
            component="td"
            width="100%"
            xs={{ alignItems: "center", textAlign: "center" }}
            borderBottom={
              noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`
            }
            textAlign="center"
            alignItems="center"
            pr={1}
          >
            <SoftTypography
              display="block"
              variant="button"
              fontWeight="medium"
              sx={{ width: "max-content", color: "#48BB78" }}
            >
              {row}
            </SoftTypography>
          </SoftBox>
        );
      } else if (index < rows.length - 2) {
        return (
          <SoftBox
            key={row}
            component="td"
            width="100%"
            xs={{ alignItems: "center", textAlign: "center" }}
            borderBottom={
              noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`
            }
            textAlign="center"
            alignItems="center"
          >
            <SoftTypography
              display="block"
              variant="button"
              fontWeight="medium"
              color="text"
              sx={{ width: "max-content" }}
            >
              {row}
            </SoftTypography>
          </SoftBox>
        );
      } else {
        return (
          <SoftBox
            key={row}
            component="td"
            width="100%"
            xs={{ alignItems: "center", textAlign: "center" }}
            borderBottom={
              noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`
            }
            textAlign="center"
            alignItems="center"
          >
            <SoftTypography
              display="block"
              variant="button"
              fontWeight="medium"
              sx={{
                justifyContent: "center",
                color: "#E50303",
              }}
            >
              {row}
            </SoftTypography>
          </SoftBox>
        );
      }
    });

    return <TableRow>{renderRows}</TableRow>;
  }

  // Setting default values for the props for PagesBodyCell
  PagesBodyCell.defaultProps = {
    noBorder: false,
  };

  // Typechecking props for the PagesBodyCell
  PagesBodyCell.propTypes = {
    rows: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ).isRequired,
    noBorder: PropTypes.bool,
  };
  function PagesHeaderCell({ children }) {
    const { light } = colors;
    const { size, fontWeightBold } = typography;
    const { borderWidth } = borders;

    return (
      <SoftBox
        component="th"
        float="center"
        textAlign="center"
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        py={1.5}
        pr={3}
      >
        <SoftBox
          width="max-content"
          textAlign="center"
          fontSize={size.xs}
          fontWeight={fontWeightBold}
          color="secondary"
          opacity={0.7}
          sx={{ textAlign: "center" }}
        >
          {children}
        </SoftBox>
      </SoftBox>
    );
  }

  // Typechecking props for the PagesHeaderCell
  PagesHeaderCell.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return (
    <Card>
      <SoftBox py={3} mb={6} px={2}>
        <TableContainer
          sx={{
            boxShadow: "none",
            overflowY: "scroll",
            height: "43rem",
            textAlign: "center",
          }}
        >
          <Table>
            <SoftBox component="thead">
              <TableRow>
                <PagesHeaderCell>Gesamt Übersicht</PagesHeaderCell>
                <PagesHeaderCell>Note 1</PagesHeaderCell>
                <PagesHeaderCell>Note 2</PagesHeaderCell>
                <PagesHeaderCell>Note 3</PagesHeaderCell>
                <PagesHeaderCell>Note 4</PagesHeaderCell>
                <PagesHeaderCell>Note 5</PagesHeaderCell>
                <PagesHeaderCell>Note 6</PagesHeaderCell>
                <PagesHeaderCell>Note 7</PagesHeaderCell>
                <PagesHeaderCell>Storno</PagesHeaderCell>
                <PagesHeaderCell>Storno - Q in %</PagesHeaderCell>
              </TableRow>
            </SoftBox>
            <TableBody>
              <PagesBodyCell
                rows={[
                  "Vor Storno",
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  "  50 %",
                ]}
                noBorder
              />
              <PagesBodyCell
                rows={["Nach Storno", 1967, "", "", "", "", "", "", "", ""]}
              />
            </TableBody>
          </Table>
          <Table>
            <SoftBox component="thead">
              <TableRow>
                <PagesHeaderCell>Januar</PagesHeaderCell>
                <PagesHeaderCell>Note 1</PagesHeaderCell>
                <PagesHeaderCell>Note 2</PagesHeaderCell>
                <PagesHeaderCell>Note 3</PagesHeaderCell>
                <PagesHeaderCell>Note 4</PagesHeaderCell>
                <PagesHeaderCell>Note 5</PagesHeaderCell>
                <PagesHeaderCell>Note 6</PagesHeaderCell>
                <PagesHeaderCell>Note 7</PagesHeaderCell>
                <PagesHeaderCell>Storno</PagesHeaderCell>
                <PagesHeaderCell>Storno - Q in %</PagesHeaderCell>
              </TableRow>
            </SoftBox>
            <TableBody>
              <PagesBodyCell
                rows={[
                  "Vor Storno",
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  "  50 %",
                ]}
                noBorder
              />
              <PagesBodyCell
                rows={["Nach Storno", 1967, "", "", "", "", "", "", "", ""]}
              />
            </TableBody>
          </Table>
          <Table>
            <SoftBox component="thead">
              <TableRow>
                <PagesHeaderCell>Februar</PagesHeaderCell>
                <PagesHeaderCell>Note 1</PagesHeaderCell>
                <PagesHeaderCell>Note 2</PagesHeaderCell>
                <PagesHeaderCell>Note 3</PagesHeaderCell>
                <PagesHeaderCell>Note 4</PagesHeaderCell>
                <PagesHeaderCell>Note 5</PagesHeaderCell>
                <PagesHeaderCell>Note 6</PagesHeaderCell>
                <PagesHeaderCell>Note 7</PagesHeaderCell>
                <PagesHeaderCell>Storno</PagesHeaderCell>
                <PagesHeaderCell>Storno - Q in %</PagesHeaderCell>
              </TableRow>
            </SoftBox>
            <TableBody>
              <PagesBodyCell
                rows={[
                  "Vor Storno",
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  "  50 %",
                ]}
                noBorder
              />
              <PagesBodyCell
                rows={["Nach Storno", 1967, "", "", "", "", "", "", "", ""]}
              />
            </TableBody>
          </Table>
          <Table>
            <SoftBox component="thead">
              <TableRow>
                <PagesHeaderCell>Marz</PagesHeaderCell>
                <PagesHeaderCell>Note 1</PagesHeaderCell>
                <PagesHeaderCell>Note 2</PagesHeaderCell>
                <PagesHeaderCell>Note 3</PagesHeaderCell>
                <PagesHeaderCell>Note 4</PagesHeaderCell>
                <PagesHeaderCell>Note 5</PagesHeaderCell>
                <PagesHeaderCell>Note 6</PagesHeaderCell>
                <PagesHeaderCell>Note 7</PagesHeaderCell>
                <PagesHeaderCell>Storno</PagesHeaderCell>
                <PagesHeaderCell>Storno - Q in %</PagesHeaderCell>
              </TableRow>
            </SoftBox>
            <TableBody>
              <PagesBodyCell
                rows={[
                  "Vor Storno",
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  "  50 %",
                ]}
                noBorder
              />
              <PagesBodyCell
                rows={["Nach Storno", 1967, "", "", "", "", "", "", "", ""]}
              />
            </TableBody>
          </Table>
          <Table>
            <SoftBox component="thead">
              <TableRow>
                <PagesHeaderCell>April</PagesHeaderCell>
                <PagesHeaderCell>Note 1</PagesHeaderCell>
                <PagesHeaderCell>Note 2</PagesHeaderCell>
                <PagesHeaderCell>Note 3</PagesHeaderCell>
                <PagesHeaderCell>Note 4</PagesHeaderCell>
                <PagesHeaderCell>Note 5</PagesHeaderCell>
                <PagesHeaderCell>Note 6</PagesHeaderCell>
                <PagesHeaderCell>Note 7</PagesHeaderCell>
                <PagesHeaderCell>Storno</PagesHeaderCell>
                <PagesHeaderCell>Storno - Q in %</PagesHeaderCell>
              </TableRow>
            </SoftBox>
            <TableBody>
              <PagesBodyCell
                rows={[
                  "Vor Storno",
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  "  50 %",
                ]}
                noBorder
              />
              <PagesBodyCell
                rows={["Nach Storno", 1967, "", "", "", "", "", "", "", ""]}
              />
            </TableBody>
          </Table>
          <Table>
            <SoftBox component="thead">
              <TableRow>
                <PagesHeaderCell>Mail</PagesHeaderCell>
                <PagesHeaderCell>Note 1</PagesHeaderCell>
                <PagesHeaderCell>Note 2</PagesHeaderCell>
                <PagesHeaderCell>Note 3</PagesHeaderCell>
                <PagesHeaderCell>Note 4</PagesHeaderCell>
                <PagesHeaderCell>Note 5</PagesHeaderCell>
                <PagesHeaderCell>Note 6</PagesHeaderCell>
                <PagesHeaderCell>Note 7</PagesHeaderCell>
                <PagesHeaderCell>Storno</PagesHeaderCell>
                <PagesHeaderCell>Storno - Q in %</PagesHeaderCell>
              </TableRow>
            </SoftBox>
            <TableBody>
              <PagesBodyCell
                rows={[
                  "Vor Storno",
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  "  50 %",
                ]}
                noBorder
              />
              <PagesBodyCell
                rows={["Nach Storno", 1967, "", "", "", "", "", "", "", ""]}
              />
            </TableBody>
          </Table>
          <Table>
            <SoftBox component="thead">
              <TableRow>
                <PagesHeaderCell>Juni</PagesHeaderCell>
                <PagesHeaderCell>Note 1</PagesHeaderCell>
                <PagesHeaderCell>Note 2</PagesHeaderCell>
                <PagesHeaderCell>Note 3</PagesHeaderCell>
                <PagesHeaderCell>Note 4</PagesHeaderCell>
                <PagesHeaderCell>Note 5</PagesHeaderCell>
                <PagesHeaderCell>Note 6</PagesHeaderCell>
                <PagesHeaderCell>Note 7</PagesHeaderCell>
                <PagesHeaderCell>Storno</PagesHeaderCell>
                <PagesHeaderCell>Storno - Q in %</PagesHeaderCell>
              </TableRow>
            </SoftBox>
            <TableBody>
              <PagesBodyCell
                rows={[
                  "Vor Storno",
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  "  50 %",
                ]}
                noBorder
              />
              <PagesBodyCell
                rows={["Nach Storno", 1967, "", "", "", "", "", "", "", ""]}
              />
            </TableBody>
          </Table>
          <Table>
            <SoftBox component="thead">
              <TableRow>
                <PagesHeaderCell>juli</PagesHeaderCell>
                <PagesHeaderCell>Note 1</PagesHeaderCell>
                <PagesHeaderCell>Note 2</PagesHeaderCell>
                <PagesHeaderCell>Note 3</PagesHeaderCell>
                <PagesHeaderCell>Note 4</PagesHeaderCell>
                <PagesHeaderCell>Note 5</PagesHeaderCell>
                <PagesHeaderCell>Note 6</PagesHeaderCell>
                <PagesHeaderCell>Note 7</PagesHeaderCell>
                <PagesHeaderCell>Storno</PagesHeaderCell>
                <PagesHeaderCell>Storno - Q in %</PagesHeaderCell>
              </TableRow>
            </SoftBox>
            <TableBody>
              <PagesBodyCell
                rows={[
                  "Vor Storno",
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  "  50 %",
                ]}
                noBorder
              />
              <PagesBodyCell
                rows={["Nach Storno", 1967, "", "", "", "", "", "", "", ""]}
              />
            </TableBody>
          </Table>
          <Table>
            <SoftBox component="thead">
              <TableRow>
                <PagesHeaderCell>august</PagesHeaderCell>
                <PagesHeaderCell>Note 1</PagesHeaderCell>
                <PagesHeaderCell>Note 2</PagesHeaderCell>
                <PagesHeaderCell>Note 3</PagesHeaderCell>
                <PagesHeaderCell>Note 4</PagesHeaderCell>
                <PagesHeaderCell>Note 5</PagesHeaderCell>
                <PagesHeaderCell>Note 6</PagesHeaderCell>
                <PagesHeaderCell>Note 7</PagesHeaderCell>
                <PagesHeaderCell>Storno</PagesHeaderCell>
                <PagesHeaderCell>Storno - Q in %</PagesHeaderCell>
              </TableRow>
            </SoftBox>
            <TableBody>
              <PagesBodyCell
                rows={[
                  "Vor Storno",
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  "  50 %",
                ]}
                noBorder
              />
              <PagesBodyCell
                rows={["Nach Storno", 1967, "", "", "", "", "", "", "", ""]}
              />
            </TableBody>
          </Table>
          <Table>
            <SoftBox component="thead">
              <TableRow>
                <PagesHeaderCell>september</PagesHeaderCell>
                <PagesHeaderCell>Note 1</PagesHeaderCell>
                <PagesHeaderCell>Note 2</PagesHeaderCell>
                <PagesHeaderCell>Note 3</PagesHeaderCell>
                <PagesHeaderCell>Note 4</PagesHeaderCell>
                <PagesHeaderCell>Note 5</PagesHeaderCell>
                <PagesHeaderCell>Note 6</PagesHeaderCell>
                <PagesHeaderCell>Note 7</PagesHeaderCell>
                <PagesHeaderCell>Storno</PagesHeaderCell>
                <PagesHeaderCell>Storno - Q in %</PagesHeaderCell>
              </TableRow>
            </SoftBox>
            <TableBody>
              <PagesBodyCell
                rows={[
                  "Vor Storno",
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  "  50 %",
                ]}
                noBorder
              />
              <PagesBodyCell
                rows={["Nach Storno", 1967, "", "", "", "", "", "", "", ""]}
              />
            </TableBody>
          </Table>
          <Table>
            <SoftBox component="thead">
              <TableRow>
                <PagesHeaderCell>oktober</PagesHeaderCell>
                <PagesHeaderCell>Note 1</PagesHeaderCell>
                <PagesHeaderCell>Note 2</PagesHeaderCell>
                <PagesHeaderCell>Note 3</PagesHeaderCell>
                <PagesHeaderCell>Note 4</PagesHeaderCell>
                <PagesHeaderCell>Note 5</PagesHeaderCell>
                <PagesHeaderCell>Note 6</PagesHeaderCell>
                <PagesHeaderCell>Note 7</PagesHeaderCell>
                <PagesHeaderCell>Storno</PagesHeaderCell>
                <PagesHeaderCell>Storno - Q in %</PagesHeaderCell>
              </TableRow>
            </SoftBox>
            <TableBody>
              <PagesBodyCell
                rows={[
                  "Vor Storno",
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  "  50 %",
                ]}
                noBorder
              />
              <PagesBodyCell
                rows={["Nach Storno", 1967, "", "", "", "", "", "", "", ""]}
              />
            </TableBody>
          </Table>
          <Table>
            <SoftBox component="thead">
              <TableRow>
                <PagesHeaderCell>november</PagesHeaderCell>
                <PagesHeaderCell>Note 1</PagesHeaderCell>
                <PagesHeaderCell>Note 2</PagesHeaderCell>
                <PagesHeaderCell>Note 3</PagesHeaderCell>
                <PagesHeaderCell>Note 4</PagesHeaderCell>
                <PagesHeaderCell>Note 5</PagesHeaderCell>
                <PagesHeaderCell>Note 6</PagesHeaderCell>
                <PagesHeaderCell>Note 7</PagesHeaderCell>
                <PagesHeaderCell>Storno</PagesHeaderCell>
                <PagesHeaderCell>Storno - Q in %</PagesHeaderCell>
              </TableRow>
            </SoftBox>
            <TableBody>
              <PagesBodyCell
                rows={[
                  "Vor Storno",
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  "  50 %",
                ]}
                noBorder
              />
              <PagesBodyCell
                rows={["Nach Storno", 1967, "", "", "", "", "", "", "", ""]}
              />
            </TableBody>
          </Table>
          <Table>
            <SoftBox component="thead">
              <TableRow>
                <PagesHeaderCell>december</PagesHeaderCell>
                <PagesHeaderCell>Note 1</PagesHeaderCell>
                <PagesHeaderCell>Note 2</PagesHeaderCell>
                <PagesHeaderCell>Note 3</PagesHeaderCell>
                <PagesHeaderCell>Note 4</PagesHeaderCell>
                <PagesHeaderCell>Note 5</PagesHeaderCell>
                <PagesHeaderCell>Note 6</PagesHeaderCell>
                <PagesHeaderCell>Note 7</PagesHeaderCell>
                <PagesHeaderCell>Storno</PagesHeaderCell>
                <PagesHeaderCell>Storno - Q in %</PagesHeaderCell>
              </TableRow>
            </SoftBox>
            <TableBody>
              <PagesBodyCell
                rows={[
                  "Vor Storno",
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  11111,
                  "  50 %",
                ]}
                noBorder
              />
              <PagesBodyCell
                rows={["Nach Storno", 1967, "", "", "", "", "", "", "", ""]}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </SoftBox>
    </Card>
  );
}

export default BigDataTable;
