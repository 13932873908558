import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import SoftSnackbar from "components/SoftSnackbar";
import { jsonGroupData } from "./data";

const ArticleEdit = () => {
  const params = useParams();
  const { t } = useTranslation();
  const [lager, setLager] = useState([]);
  const [articleData, setArticleData] = useState({});
  const [saving, setSaving] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [refresh2, setRefresh2] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedGroupItem, setSelectedGroupItem] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [unit, setUnit] = useState(0);
  const [storageAreas, setStorageAreas] = useState([]);
  const {
      register,
      handleSubmit,
      setError,
      setValue,
      formState: { errors },
  } = useForm();


  // Filter subcategories based on the selected category
  const subcategories =
    jsonGroupData?.categories
      .find((category) => category.name === selectedCategory)
      ?.group || [];

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedGroupItem(subcategories); // Reset subcategory when category changes
  };

  const handleFileChange = (e) => {
    
    const file = e.target.files[0];
    if (file) {
      setArticleData({});
      setSelectedFile(file);
    }
  };
  const handleFileRemove = () =>{
        setValue("upload_area","")
        setSelectedFile(null)
        setArticleData({})
    }




  useEffect(() => {
    callFetch("article/create", "GET", []).then((res) => {
        //setValue('article_nr',res?.article_number)
        setLager(res?.lagers)
    });
  }, [0]);

 

  useEffect(() => {
      if(params?.id > 0){
          callFetch("article/"+params?.id+"/edit", "GET", []).then((res) => {
            setArticleData(res.data)
            setSelectedCategory(res?.data?.article_category); 
              for (let [key, value] of Object.entries(res.data)) {
                  setValue(key, (value == null || value == 'null' ? "" : value ));
              }
              setTimeout(()=>{
                setValue('article_group', res?.data?.article_group)
              },100)
              
          });
      }
  }, [refresh]);

  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const renderSuccessSB = (
      <SoftSnackbar
        color="success"
        icon="check"
        title="Success"
        content="Successfully updated"
        dateTime="Just Now"
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
      />
    );


  const onSubmit = (formData) => {
      setSaving(true);
      callFetch("article/"+params?.id, "POST", formData, setError).then((res) => {
          setSaving(false);
          if (!res.ok) return;
          if(res.message === 'success' && params?.id){
              openSuccessSB();
          }
          setSubmitSuccess(true);
      });
  };

return submitSuccess ? <Navigate to={'/ware-houses/articles'} /> :
<>
    <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <div className="row">
          <div className="col-lg-9">
              <div className="card mb-4">
                  <div className="card-header pb-0">
                      <h6>{t('Artikel hinzufügen')}</h6>
                  </div>
                  <div className="card-body">
                        <input type="hidden" defaultValue="PUT" {...register("_method")} />
                          
                          <div className="row g-3">

                              <div className="col-md-4">
                                  <label>{t('Artikelnummer')} *</label>
                                  <input type="text" className="form-control" {...register('article_nr',{required:true})} required readOnly/>
                                  <div className="invalid-feedback">{errors.article_nr && errors.article_nr.message}</div>
                              </div>
                              <div className="col-md-4">
                                  <label>{t('Artikelbeschreibung')} *</label>
                                  <input
                                      type="text"
                                      className="form-control mb-4"
                                      placeholder={t('e.B Jhon Doe')}
                                      {...register("article_name", {
                                          required: true,
                                      })}
                                      required
                                  />
                                  <div className="invalid-feedback">{errors.article_name && errors.article_name.message}</div>
                              </div>
                              <div className="col-md-4">
                                  <label>{t('Lieferant/Hersteller')} *</label>
                                  <select
                                      className="form-control mb-4"
                                      placeholder={t('e.B Jhon Doe')}
                                      {...register("supplier_name", {
                                          required: true,
                                      })}
                                      required
                                  >
                                    <option value="">{t('----')}</option>
                                    <option value="Test Supplier 1">Test Supplier 1</option>
                                    <option value="Test Supplier 2">Test Supplier 2</option>
                                  </select>
                                  <div className="invalid-feedback">{errors.supplier_name && errors.supplier_name.message}</div>
                              </div>

                              <div className="col-md-4">
                                  <label>{t('Einkaufspreis')} *</label>
                                  <input
                                      type="number"
                                      className="form-control mb-4"
                                      placeholder={t('00')}
                                      {...register("purchase_price", {
                                          required: true,
                                      })}
                                      required
                                  />
                                  <div className="invalid-feedback">{errors.purchase_price && errors.purchase_price.message}</div>
                              </div>
                              
                              <div className="col-md-4">
                                  <label>{t('Kategorie')} *</label>
                                  <select className="form-control" name="product_type" {...register('article_category')} onChange={handleCategoryChange} required>
                                      <option value="">{t('----')}</option>
                                      {
                                        jsonGroupData && jsonGroupData?.categories?.map((category,index)=>(
                                          <option key={index} value={category?.name}>{t(category?.name)}</option>
                                        ))
                                      }
                                      
                                  </select>
                                  <div className="invalid-feedback">{errors.article_category && errors.article_category.message}</div>
                              </div>

                              <div className="col-md-4">
                                  <label>{t('Gruppe')} *</label>
                                  <select className="form-control" name="product_type" {...register('article_group')} required disabled={subcategories.length === 0 ? true:false}>
                                      <option value="">{t("----")}</option>
                                      {
                                        subcategories && subcategories?.map((group,index) => (
                                            <option key={index} value={group}>{t(group)}</option>
                                        ))
                                      }
                                      
                                  </select>
                                  <div className="invalid-feedback">{errors.article_group && errors.article_group.message}</div>
                              </div>

                              

                              <div className="row g-3">
                                  <div className="col-md-4">
                                      <label>{t('Einheit')} *</label>
                                      <select className="form-control" name="product_type" {...register('unit',{required:true})} required onChange={(e)=>setUnit(e.target.value)}>
                                        <option value="">{t("----")}</option> 
                                        <option value="Meter">{t("Meter")}</option> 
                                        <option value="Stück">{t("Stück")}</option> 
                                        <option value="Kg">{t("Kg")}</option> 
                                        <option value="Liter">{t("Liter")}</option> 
                                        <option value="m2">{t("m2")}</option> 
                                        <option value="%">%</option> 
                                        <option value="watt">Watt (W)</option> 
                                        
                                      </select>
                                      <div className="invalid-feedback">{errors.unit && errors.unit.message}</div>
                                  </div>  
                              </div>

                              {/* <div className="row g-3">
                                  <div className="col-md-12">
                                      <label>{t('Upload Area (Pictures & PDF Documents)')} *</label>
                                      <input
                                          type="file"
                                          className="form-control mb-4"
                                          placeholder={t('')}
                                          {...register("upload_area")}
                                          onChange={handleFileChange}
                                      />
                                      <div className="invalid-feedback">{errors.upload_area && errors.upload_area.message}</div>
                                  </div>
                              </div> */}

                              <div className="row g-2">
                                  <div className="col-md-12">
                                      <label>{t('Artikelbeschreibung')}</label>
                                      <textarea
                                          className="form-control"
                                          rows="4"
                                          placeholder={t('')}
                                          {...register("description")}></textarea>
                                      <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                  </div>
                              </div>
                              
                              <div className="col-12 mb-4">
                                  {!saving && (
                                      <button type="submit" className="btn btn-primary">
                                          {t('Save')}
                                      </button>
                                  )}
                                  {saving && (
                                      <button type="submit" className="btn btn-disabled" disabled>
                                          {t('Saving ...')}
                                      </button>
                                  )}
                              </div>
                          </div>
                      
                  </div>
              </div>
          </div>
        {
            articleData && <div className="col-3">
            <div className="card">
              <div className="card-header">
                    <label>{t('Datenblätter etc.')} *</label>
                    <input
                        type="file"
                        id="upload"
                        className="form-control "
                        placeholder={t('')}
                        {...register("upload_area")}
                        onChange={handleFileChange}
                        />
              </div>
              <div className="card-body pt-0 position-relative">
              {selectedFile?.type.startsWith('image/') || (articleData?.attachment_ext && ['jpg', 'jpeg', 'png'].includes(articleData?.attachment_ext?.toLowerCase())) ? (
                // Display the image
                    <>
                        <img
                            src={selectedFile ? URL.createObjectURL(selectedFile) : process.env.REACT_APP_STORAGE_URL + articleData?.attachment}
                            alt="Selected"
                            width="100%"
                            height="100%"
                            className="border-radius-md"
                        />
                        &nbsp;
                        <i style={{ top:"-5px" }} className="fa-solid fa-circle-xmark text-danger cursor-pointer position-absolute end-4" onClick={handleFileRemove}></i>
                    </>
                ) : (selectedFile?.type.startsWith('application/') || (articleData?.attachment_ext && ["pdf"].includes(articleData?.attachment_ext?.toLowerCase())) ? (
                    // Display a link to download the PDF file
                    <>
                        <iframe
                        src={
                            selectedFile ?
                            URL.createObjectURL(selectedFile)
                            :
                            process.env.REACT_APP_STORAGE_URL + articleData?.attachment
                        }
                        title="PDF Viewer"
                        width="100%"
                        height="350px" // Adjust the height as needed
                        className="border-radius-md"
                        ></iframe>
                        &nbsp;
                        <i style={{ top:"-5px" }} className="fa-solid fa-circle-xmark text-danger cursor-pointer position-absolute end-4" onClick={handleFileRemove}></i>
                    </>
                ) : null
                )}
              </div>
            </div>
        </div>
        }
      </div>
      </form>
      {renderSuccessSB}
</>
}
export default ArticleEdit