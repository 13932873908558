import React from "react";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { Navigate, useParams, NavLink } from "react-router-dom";
import Cookies from 'js-cookie';
import { callFetch } from "../../../helpers/callFetch";
import Overview from "../../project/Overview";
import SoftTypography from 'components/SoftTypography';
import { useForm } from "react-hook-form";
import SoftSnackbar from "components/SoftSnackbar";
import SoftBox from "components/SoftBox";
import { color } from "@mui/system";
import IndexTable from "./IndexTable";

function Index(props) {
    const { t } = useTranslation();
    const [ready, setReady] = useState(false);


    useEffect(() => {
        setReady(false)
        
        setTimeout(()=>{
            setReady(true)
        }, 500)

    }, [props?.projectid]);

    return (
        <>
            <div className='row'>
                <div className="col-12">
                    <Overview project_id={(props?.projectid ? props?.projectid : 0)} title={t('Materialliste')} Wunschtermin={true} Montagetermin={true}></Overview>
                </div>
                <div className="col-sm-12 mt-3">
                    <SoftBox>
                        <div className="card">
                            <div className="card-header pb-0">
                                <SoftTypography variant="h6">{t('Beschaffungsliste')}</SoftTypography>
                            </div>
                            <div className="card-body px-1">
                                {ready ? (
                                    <IndexTable project_id={(props?.projectid ? props?.projectid : 0)} />
                                ) : ''}                                
                            </div>
                        </div>
                    </SoftBox>
                </div>
            </div>
        </>
    );
}

export default Index;
