import { useEffect, useState, React } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Setting pages components
import TableCell from "layouts/pages/account/settings/components/TableCell";

// Soft UI Dashboard PRO React components
import { useTranslation } from "react-i18next";
import { useCallFetchv2 } from 'helpers/callFetchv2';
import SoftSelect from "components/SoftSelect";

function Status() {
    const { t } = useTranslation();
    const callFetch = useCallFetchv2();
    const [saving, setSaving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [data, setData] = useState({});
    const [openId, setOpenId] = useState(0);
    const [statusTab, setStatusTab] = useState('sale');

    const handleColorPickerToggle = (id) => {
        if (openId == id) {
            setOpenId(0);
        } else {
            setOpenId(id);
        }
    };

    const updateTeamColor = (id, code, bgcode, old) => {
        onSubmit({
            sales_status_id: id,
            color: code
        })
        setOpenId(0);
    };

    const checkColorCodeExists = (color) => {
        return data?.sales_statuses.some(sales_statuse => sales_statuse.color === color);
    }

    useEffect(() => {
        callFetch("status-setting?type=" + statusTab, "GET", []).then((res) => {
            if (res?.message === 'success') {
                setData(res?.data);
            }
        });
    }, [refresh, statusTab]);

    const addBertriebsstatuse = () => {
        var formData = {}
        formData.type = statusTab;

        callFetch("create-sales-status", "POST", formData, {}).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setRefresh(refresh + 1)
        });
    }

    const removeVertriebsstatuse = (id) => {
        callFetch("delete-sales-status/" + id, "POST", {}, {}).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setRefresh(refresh + 1)
        });
    }

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("status-setting-update", "POST", formData, {}).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setRefresh(refresh + 1)
        });
    };

    return (
        <>
            <Card>
                <SoftBox p={3} lineHeight={1}>
                    <SoftBox mb={1}>
                        <SoftTypography variant="h5">{t('Status Einstellungen')}</SoftTypography>
                    </SoftBox>
                    <SoftTypography variant="button" color="text" fontWeight="regular">Choose how you receive notifications. These notification settings apply to the things you’re watching.</SoftTypography>
                </SoftBox>
                <SoftBox pb={3} px={3}>
                    <SoftBox>
                        <Table sx={{ minWidth: "36rem" }}>
                            <SoftBox component="thead">
                                <TableRow>
                                    <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                                        Status
                                    </TableCell>
                                    <TableCell align="right" padding={[1.5, 3, 1.5, 3]}>
                                        <span style={{ position: 'relative', right: '18px' }}>Push</span>
                                    </TableCell>
                                </TableRow>
                            </SoftBox>
                            <TableBody>
                                <TableRow>
                                    <TableCell padding={[1, 1, 1, 0.5]}>
                                        <SoftBox lineHeight={1.4}>
                                            <SoftTypography display="block" variant="button" fontWeight="regular" style={{ color: '#2D3748' }}>
                                                {t('Status Bewertung verpflichtend aktiviert')}
                                            </SoftTypography>
                                            <SoftTypography variant="caption" color="text" fontWeight="regular">
                                                Jeder Verkäufer wird verpflichtet einen Status abzugeben. Falls der Verkäufer einen Status nicht abgibt ist Nutzung des Systems
                                                solange der Status nicht abgegeben wird deaktiv.
                                            </SoftTypography>
                                        </SoftBox>
                                    </TableCell>
                                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                        <div className="d-flex">
                                            <SoftTypography variant="button" fontWeight="regular" style={{ color: '#2D3748' }}>
                                                {data?.applicationStatusSetting?.status_rating_mandatory == 1 ? 'aktiviert' : ''}
                                            </SoftTypography>
                                            <div className="form-check form-switch float-end ms-3">
                                                <input
                                                    className="form-check-input"
                                                    checked={data?.applicationStatusSetting?.status_rating_mandatory == 1 ? true : false}
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                        onSubmit({
                                                            status_rating_mandatory: (e.target.checked ? 1 : 0)
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>

                                {data?.applicationStatusSetting?.status_rating_mandatory == 1 ? (
                                    <TableRow>
                                        <TableCell padding={[1, 1, 1, 0.5]}>
                                            <SoftBox
                                                bgColor="grey-100"
                                                borderRadius="lg"
                                                display="flex"
                                                //justifyContent="space-between"
                                                alignItems={{ xs: "flex-start", sm: "center" }}
                                                flexDirection={{ xs: "column", sm: "row" }}
                                                my={3}
                                                py={1}
                                                pl={{ xs: 1, sm: 2 }}
                                                pl={1}
                                            >
                                                <SoftTypography variant="button" fontWeight="medium" color="text">
                                                    Ab
                                                </SoftTypography>
                                                <SoftBox className="mx-3">
                                                    <SoftSelect
                                                        defaultValue={[
                                                            { value: "24", label: "24 Stunden" },
                                                        ]}
                                                        options={[
                                                            { value: "24", label: "24 Stunden" },
                                                        ]}
                                                        size="small"
                                                    />
                                                </SoftBox>
                                                <SoftBox
                                                    display="flex"
                                                    alignItems={{ xs: "flex-start", sm: "center" }}
                                                    flexDirection={{ xs: "column", sm: "row" }}
                                                >
                                                    <SoftBox mr={2} mb={{ xs: 1, sm: 0 }} lineHeight={0}>
                                                        <SoftTypography variant="button" fontWeight="medium">
                                                            ist der Verkäufer dazu verpflichtet einen Status abzugeben.
                                                        </SoftTypography>
                                                    </SoftBox>
                                                </SoftBox>
                                            </SoftBox>
                                        </TableCell>
                                        <TableCell align="center" padding={[1, 1, 1, 0.5]}>

                                        </TableCell>
                                    </TableRow>
                                ) : ''}

                                <TableRow>
                                    <TableCell padding={[1, 1, 1, 0.5]}>
                                        <SoftBox lineHeight={1.4}>
                                            <SoftTypography display="block" variant="button" fontWeight="regular">
                                                {t('Nur erster Status wird an Lead Owner weitergeleitet')}
                                            </SoftTypography>
                                            <SoftTypography variant="caption" color="text" fontWeight="regular">
                                                Wenn diese Funktion aktiviert wird, wird nur der erste Status den der Verkäufer angibt and Ihren Call Center oder Lead Owner
                                                weitergeleitet. Alle weiteren Statusänderungen -aktualisierungen werden nicht weitergeleitet.
                                            </SoftTypography>
                                        </SoftBox>
                                    </TableCell>
                                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                        <div className="form-check form-switch float-end">
                                            <input
                                                className="form-check-input"
                                                checked={data?.applicationStatusSetting?.first_status_forwarded_to_lead_owner == 1 ? true : false}
                                                type="checkbox"
                                                onChange={(e) => {
                                                    onSubmit({
                                                        first_status_forwarded_to_lead_owner: (e.target.checked ? 1 : 0)
                                                    })
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </SoftBox>
                </SoftBox>
            </Card>

            <div className="row">
                <div className="col-md-7">
                    <Card className="mt-3">
                        <SoftBox p={3} pb={2} lineHeight={1}>
                            <SoftBox mb={1}>
                                <SoftTypography variant="h5">{t('Vertriebsstatuse')}</SoftTypography>
                            </SoftBox>
                            <SoftTypography variant="button" color="text" fontWeight="regular">Folgende Statuse werden im System angezeigt und sind vom Verkaufer auswahlbar. </SoftTypography>

                            <ul className="nav nav-tabs nav-underline mt-3" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button onClick={() => setStatusTab('sale')} className={"nav-link " + (statusTab === 'sale' ? 'active' : '')} id="sales-status" data-bs-toggle="tab" data-bs-target="#sales-status-pane" type="button" role="tab" aria-controls="sales-status-pane" aria-selected="true" style={{ color: '#0048B1', fontSize: '0.875rem' }}>Sales Status</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button onClick={() => setStatusTab('resale')} className={"nav-link " + (statusTab === 'resale' ? 'active' : '')} id="resales-status" data-bs-toggle="tab" data-bs-target="#resales-status-pane" type="button" role="tab" aria-controls="resales-status-pane" aria-selected="false" style={{ color: '#0048B1', fontSize: '0.875rem' }}>Resale Status</button>
                                </li>
                            </ul>
                        </SoftBox>
                        <SoftBox pb={3} px={3}>
                            {data?.sales_statuses?.map((sales_status, index) => (
                                <SoftBox
                                    borderRadius="lg"
                                    display="flex"
                                    justifyContent="flex-start"
                                    alignItems={"center"}
                                    flexDirection={'row'}
                                    my={0}
                                    py={1}
                                    pl={1}
                                >
                                    <SoftTypography variant="button" fontWeight="bold" color="dark" style={{ width: '8%' }}>
                                        {sales_status?.order_number}-
                                    </SoftTypography>

                                    <SoftBox style={{ position: 'relative', width: '8%' }}>
                                        <div
                                            className="team-color-picker"
                                            style={{
                                                width: 'auto',
                                                margin: '0px',
                                                padding: '0px',
                                                top: 'unset',
                                                position: 'unset',
                                                lineHeight: '0px'
                                            }}
                                        >
                                            <span className={"color " + sales_status?.color} style={{ backgroundColor: '#FFFFFF', border: '1px solid #c5c5c5', margin: '0px' }} onClick={() => handleColorPickerToggle(sales_status.id)}></span>
                                        </div>

                                        <div className="team-color-picker" style={{ display: sales_status.id == openId ? 'block' : 'none', right: 'unset' }}>
                                            <span onClick={() => updateTeamColor(sales_status.id, 'team-color-1', 'team-bg-color-1', sales_status.color)} className="color" style={{ position: 'relative', backgroundColor: '#D68B00' }}> {checkColorCodeExists('team-color-1') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''} </span>
                                            <span onClick={() => updateTeamColor(sales_status.id, 'team-color-2', 'team-bg-color-2', sales_status.color)} className="color" style={{ position: 'relative', backgroundColor: '#48BB78' }}> {checkColorCodeExists('team-color-2') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''} </span>
                                            <span onClick={() => updateTeamColor(sales_status.id, 'team-color-3', 'team-bg-color-3', sales_status.color)} className="color" style={{ position: 'relative', backgroundColor: '#CB0C9F' }}> {checkColorCodeExists('team-color-3') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''}  </span>
                                            <span onClick={() => updateTeamColor(sales_status.id, 'team-color-4', 'team-bg-color-4', sales_status.color)} className="color" style={{ position: 'relative', backgroundColor: '#668650', marginRight: '0px' }}> {checkColorCodeExists('team-color-4') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''}  </span>
                                            <span onClick={() => updateTeamColor(sales_status.id, 'team-color-5', 'team-bg-color-5', sales_status.color)} className="color" style={{ position: 'relative', backgroundColor: '#2424B2' }}> {checkColorCodeExists('team-color-5') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''}  </span>
                                            <span onClick={() => updateTeamColor(sales_status.id, 'team-color-6', 'team-bg-color-6', sales_status.color)} className="color" style={{ position: 'relative', backgroundColor: '#7E5A59' }}> {checkColorCodeExists('team-color-6') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''}  </span>
                                            <span onClick={() => updateTeamColor(sales_status.id, 'team-color-7', 'team-bg-color-7', sales_status.color)} className="color" style={{ position: 'relative', backgroundColor: '#ABB521' }}> {checkColorCodeExists('team-color-7') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''}  </span>
                                            <span onClick={() => updateTeamColor(sales_status.id, 'team-color-8', 'team-bg-color-8', sales_status.color)} className="color" style={{ position: 'relative', backgroundColor: '#2D3748', marginRight: '0px' }}> {checkColorCodeExists('team-color-8') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''}  </span>
                                            <span onClick={() => updateTeamColor(sales_status.id, 'team-color-9', 'team-bg-color-9', sales_status.color)} className="color" style={{ position: 'relative', backgroundColor: '#00C6D6', marginBottom: '0px' }}>  {checkColorCodeExists('team-color-9') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''}  </span>
                                            <span onClick={() => updateTeamColor(sales_status.id, 'team-color-10', 'team-bg-color-10', sales_status.color)} className="color" style={{ position: 'relative', backgroundColor: '#D1063D', marginBottom: '0px' }}>  {checkColorCodeExists('team-color-10') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''}  </span>
                                            <span onClick={() => updateTeamColor(sales_status.id, 'team-color-11', 'team-bg-color-11', sales_status.color)} className="color" style={{ position: 'relative', backgroundColor: '#5F00CC', marginBottom: '0px' }}>  {checkColorCodeExists('team-color-11') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''}  </span>
                                            <span onClick={() => updateTeamColor(sales_status.id, 'team-color-12', 'team-bg-color-12', sales_status.color)} className="color" style={{ marginRight: '0px', position: 'relative', backgroundColor: '#DBBA00', marginBottom: '0px' }}>  {checkColorCodeExists('team-color-12') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''}  </span>
                                        </div>
                                    </SoftBox>

                                    <SoftBox className="mx-3" style={{ width: '74%' }}>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            value={sales_status?.name}
                                            readOnly={sales_status?.can_change === 1 ? false : true}
                                            onBlur={(e) => {
                                                if (!sales_status?.can_change) return;
                                                onSubmit({
                                                    sales_status_id: sales_status?.id,
                                                    name: e.target.value
                                                })
                                            }}
                                            onChange={(e) => {
                                                const newData = { ...data }; // Create a shallow copy of the `data` object
                                                newData.sales_statuses[index] = {
                                                    ...sales_status, // Copy the current sales_status
                                                    name: e.target.value // Update the name
                                                };
                                                setData(newData); // Set the updated state
                                            }}
                                        />

                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-check mt-2 mb-0">
                                                    <label className="mb-0">Feedback</label>
                                                    <input
                                                        className="form-check-input"
                                                        checked={sales_status?.feedback == 1 ? true : false}
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            onSubmit({
                                                                sales_status_id: sales_status?.id,
                                                                feedback: (e.target.checked ? 1 : 0)
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-check mt-2 mb-0">
                                                    <label className="mb-0">Lead Owner wird bezahlt</label>
                                                    <input
                                                        className="form-check-input"
                                                        checked={sales_status?.lead_owner_wird_bezhlt == 1 ? true : false}
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            onSubmit({
                                                                sales_status_id: sales_status?.id,
                                                                lead_owner_wird_bezhlt: (e.target.checked ? 1 : 0)
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-check mt-2 mb-0">
                                                    <label className="mb-0">Bild verpflichtend</label>
                                                    <input
                                                        className="form-check-input"
                                                        checked={sales_status?.picture_required == 1 ? true : false}
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            onSubmit({
                                                                sales_status_id: sales_status?.id,
                                                                picture_required: (e.target.checked ? 1 : 0)
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-check mt-2 mb-0">
                                                    <label className="mb-0">Erststatus</label>
                                                    <input
                                                        className="form-check-input"
                                                        checked={sales_status?.erststatus == 1 ? true : false}
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            onSubmit({
                                                                sales_status_id: sales_status?.id,
                                                                erststatus: (e.target.checked ? 1 : 0)
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </SoftBox>
                                    <SoftBox style={{ width: '10%' }}>
                                        {sales_status?.can_change === 1 ? (
                                            <button type="button" onClick={() => removeVertriebsstatuse(sales_status?.id)} className="btn btn-link text-danger m-0">x</button>
                                        ) : ''}
                                    </SoftBox>
                                </SoftBox>
                            ))}

                            <button className="btn btn-primary mt-3" onClick={(e) => { addBertriebsstatuse() }}>Add Vertriebsstatuse</button>
                        </SoftBox>
                    </Card>
                </div>

                <div className="col-md-5">
                    <Card className="mt-3">
                        <SoftBox p={3} pb={1} lineHeight={1}>
                            <SoftBox mb={1}>
                                <SoftTypography variant="h5">{t('Status')}</SoftTypography>
                            </SoftBox>
                            <SoftTypography variant="button" color="text" fontWeight="regular">Bitte beachten Sie folgendes:</SoftTypography>
                        </SoftBox>
                        <SoftBox pb={3} px={3}>
                            <ul className="m-0">
                                <li><SoftTypography variant="caption" color="text" fontWeight="regular">Graue Statuse können nicht verändert werden.</SoftTypography></li>
                                <li><SoftTypography variant="caption" color="text" fontWeight="regular">Statuse die hier angelegt werden sind im Home-Dashboard ersichtlich</SoftTypography></li>
                                <li><SoftTypography variant="caption" color="text" fontWeight="regular">Falls Sie einen Status Löschen gehen alle früheren Daten verloren.</SoftTypography></li>
                            </ul>
                        </SoftBox>
                    </Card>

                    <Card className="mt-3">
                        <SoftBox p={3} pb={1} lineHeight={1}>
                            <SoftBox mb={1}>
                                <SoftTypography variant="h5">{t('Mitarbeiterkennung')}</SoftTypography>
                            </SoftBox>
                        </SoftBox>
                        <SoftBox pb={3} px={3}>
                            <label>Mitarbeiterkennung deaktivieren</label>
                            <div className="form-check form-switch float-end">
                                <input
                                    className="form-check-input"
                                    checked={data?.applicationStatusSetting?.mitarbeiterkennung_optional == 1 ? true : false}
                                    type="checkbox"
                                    onChange={(e) => {
                                        onSubmit({
                                            mitarbeiterkennung_optional: (e.target.checked ? 1 : 0)
                                        })
                                    }}
                                />
                            </div>
                        </SoftBox>
                    </Card>
                </div>
            </div>
        </>
    );
}

export default Status;