import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import { NumericFormat } from 'react-number-format';
import SoftAlert from "components/SoftAlert";
import SoftSnackbar from "components/SoftSnackbar";
import Select from 'react-select';
import SoftEditor from "components/SoftEditor";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import LieferantentCreateModel from 'pages/hr/Lieferanten/CreateModel';
import SoftButton from 'components/SoftButton';
import { Divider, Icon, Menu, MenuItem } from '@mui/material';

function Create(props) {
    const { t } = useTranslation();
    const [quationId, setQuationId] = useState(0);
    const [auftragId, setAftragId] = useState(0);
    const [currency, setCurrency] = useState('EUR');
    const [contactPersons, setContactPersons] = useState([]);
    const [data, setData] = useState([]);
    const [projectData, setProjectData] = useState({});
    const [emails, setEmails] = useState([{ name: '', email: '', currentEmail: '' }]);
    const [items, setItems] = useState([{ id: 0, title: '', quantity: 1, price: 0, currentPrice: 0, total: 0, artikel_id: 0 }]);
    const [itemSummery, setItemSummery] = useState({ items: [], emails: [], subTotal: 0, amountPerchantage: 30, discountPercentage: 0, discount: 0, taxPercentage: 0, tax: 0, total: 0 });
    const [quotation, setQuotation] = useState(0);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [refresh3, setRefresh3] = useState(0);
    const [refresh4, setRefresh4] = useState(0);
    const [refresh5, setRefresh5] = useState(0);
    const [receivedError, setReceivedError] = useState(null);
    const [invoice_id, setInvoiceId] = useState(0)
    const [selectedOrderId, setselectedOrderId] = useState({ id: '' });
    const [description, setDescription] = useState('');
    const [assemblyCost, setAssemblyCost] = useState(false);
    const [assemblyCostAmount, setAssemblyCostAmount] = useState(2500.00);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const [relatedToProject, setRelatedToProject] = useState(true);
    const [menu, setMenu] = useState(null);
    const [status, setStatus] = useState('');
    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = (statu = '') => {
        setMenu(null);
        setStatus(statu);
    };

    const renderMenu = (
        <Menu
            anchorEl={menu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(menu)}
            onClose={() => { closeMenu('') }}
            keepMounted
        >

            {!relatedToProject ? (
                <MenuItem onClick={() => {
                    closeMenu('');
                    if (document.getElementById('createLieferantentModalBtn')) {
                        document.getElementById('createLieferantentModalBtn').click();
                    }
                }}>{t('Lieferanten Hinzufugen')}</MenuItem>
            ) : ''}
        </Menu>
    );

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            content="Invoice successfully created"
            dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
        />
    );

    const [eroorSB, setErrorSB] = useState(false);
    const [eroorMessage, setErrorMessage] = useState('');
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const renderEeeorSB = (
        <SoftSnackbar
            color="error"
            icon="close"
            title="Failed"
            content={eroorMessage ? eroorMessage : "Invoice created failed"}
            dateTime="Just Now"
            open={eroorSB}
            onClose={closeErrorSB}
            close={closeErrorSB}
            bgWhite
        />
    );

    useEffect(() => {
        var subTotal = 0;
        items.map((item) => {
            subTotal += Number(item.quantity) * Number(item.price);
        });
        itemSummery.subTotal = subTotal;

        if (relatedToProject) {
            itemSummery.amount = parseFloat((itemSummery.amountPerchantage / 100) * itemSummery.subTotal);
        } else {
            itemSummery.amount = itemSummery.subTotal;
        }

        // setSubtotal(subTotal);

        setValue('amountPerchantage', itemSummery.amountPerchantage);
        setValue('tax', itemSummery.taxPercentage);
        itemSummery.tax = parseFloat((itemSummery.amount * (itemSummery.taxPercentage / 100)).toFixed(2));
        itemSummery.discount = parseFloat((itemSummery.amount * (itemSummery.discountPercentage / 100)).toFixed(2));
        itemSummery.total = ((itemSummery.amount + itemSummery.tax) - itemSummery.discount);
        itemSummery.items = items;
        setItemSummery(itemSummery);
        setRefresh2(refresh2 + 1);
    }, [refresh]);

    useEffect(() => {
        if (!props?.orderId) return;
        console.log('props?.orderId');
        console.log(props?.orderId)


        setAftragId(props?.orderId);
        setQuationId(0);
        setValue('auftrag_id', props?.orderId);
        setselectedOrderId({
            id: props?.orderId,
        });

    }, [props?.orderId]);

    useEffect(() => {
        if (relatedToProject && auftragId > 0) {
            callFetch("order-add-invoice/" + auftragId, "GET", []).then((res) => {

                if (res?.data?.order?.quation?.project) {
                    setProjectData(res?.data?.order?.quation?.project);

                    setValue('name', (res?.data?.order?.quation?.project?.vorname + ' ' + (res?.data?.order?.quation?.project?.name ? res?.data?.order?.quation?.project?.name : '')))
                    setValue('street', res?.data?.order?.quation?.project?.street)
                    setValue('plz', res?.data?.order?.quation?.project?.plz)
                    setValue('ort', res?.data?.order?.quation?.project?.ort)
                }

                if (res.error == '100') {
                    setReceivedError(t('Inovice has created for receivable amount'));
                    if (props?.canCreateInvoice) {
                        props?.canCreateInvoice(false);
                        console.log('can not create')
                    }
                    console.log('can not create')
                }
                if (res?.data?.receivable) {
                    items[0].id = 1;
                    items[0].price = Number(res.data.order.netto);
                    items[0].quantity = 1;
                    items[0].currentPrice = Number(res.data.order.netto);
                    items[0].total = items[0].currentPrice * items[0].quantity;

                    if (res?.data?.receivable == 30) {
                        if ((res?.data?.order?.quation?.project?.ac_product == 1 && res?.data?.order?.quation?.project?.dc_product == 0) || (res?.data?.order?.quation?.project?.ac_product == 0 && res?.data?.order?.quation?.project?.dc_product == 1)) {
                            setAssemblyCost(false); //if only ac product
                        } else {
                            setAssemblyCost(true)
                        }
                    } else {
                        setAssemblyCost(false)
                    }

                    setItems(items);
                    setRefresh(refresh + 1);
                    setRefresh2(refresh2 + 1);
                    setValue('amountPerchantage', res?.data?.receivable);
                    itemSummery.amountPerchantage = res?.data?.receivable;
                    setItemSummery(itemSummery);
                    setReceivedError(null);

                    setRefresh(refresh + 1);

                } else {
                    setAssemblyCost(false)
                    setValue('amountPerchantage', 0);
                    itemSummery.amountPerchantage = 0;
                    setItemSummery(itemSummery);
                }
            });
        }
    }, [auftragId, refresh3]);

    useEffect(() => {
        flatpickr(".flat-pickr");

        callFetch("invoices/create", "GET", []).then((res) => {
            setData(res?.data);
            setValue('id', res?.data?.id);
            setRefresh(refresh + 1)
        });
    }, [refresh5]);

    function deleteProduct() {
        var titems = [];
        items.map((t) => {
            if (!t)
                return;
            titems.push(t);
        });
        setItems(titems);
        setRefresh(refresh + 1);
    }

    const onSubmit = (formData) => {
        setSaving(true);
        formData.subTotal = itemSummery.subTotal;
        formData.netto = itemSummery.amount;
        formData.brutto = itemSummery.total;
        formData.product_details = JSON.stringify(itemSummery);
        formData.description = description;
        formData.assembly_cost = (assemblyCost ? assemblyCostAmount : 0);

        callFetch("invoices", "POST", formData, setError).then((res) => {



            if (res?.status === 'success') {
                openSuccessSB();
            } else {
                if (res?.message) {
                    setErrorMessage(res?.message);
                }
                openErrorSB();
            }



            setSaving(false);
            if (!res.ok) return;
            setQuotation(res.data);
            setInvoiceId(res.id);
            setRefresh3(refresh3 + 1);

            if (document.getElementById('ZahlungseingangeListRefresh')) {
                document.getElementById('ZahlungseingangeListRefresh').click();
            }

            if (document.getElementById('InvoiceoverviewRefresh')) {
                document.getElementById('InvoiceoverviewRefresh').click();
            }

            if (props?.redirect == false) {
                if (props?.popupClose == true) {
                    console.log('createBuchhaltungClose')
                    document.getElementById('createBuchhaltungClose').click();
                }
                if (props?.invoiceListRefresh == true) {
                    document.getElementById('refreshInvoiceList').click();
                }
            } else {
                setSubmitSuccess(true);
            }

        });
    };

    return submitSuccess ? <Navigate to={`/invoices/${invoice_id}`} /> : (
        <>
            <div className="row">
                <div className={props?.col ? props?.col : "col-md-9"}>
                    <div className="card">
                        <div className="card-header pb-0">
                            <h6>{t('Add Invoices')}</h6>
                        </div>
                        <div className="card-body">
                            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">

                                <div className="row g-3">
                                    <div className="col-md-3">
                                        <label>{t('Invoice Nr.')} *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('eg: 700001')}
                                            {...register("id", {
                                                required: true,
                                            })}
                                            readOnly
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.identity_number && errors.identity_number.message}</div>
                                    </div>

                                    <div className="col-md-3">
                                        {relatedToProject ? (
                                            <div className="form-group">
                                                <label>
                                                    {t('Auftrag')} *
                                                </label>
                                                <br />
                                                <Select
                                                    placeholder={t('Select')}
                                                    options={data?.orders ? data?.orders : {}}
                                                    getOptionLabel={(option) => ((option.id ? option.id : '') + (option?.quation?.project?.id ? ' - ' + option?.quation?.project?.id : '') + (option?.quation?.project?.vorname ? ' - ' + option?.quation?.project?.vorname : '') + (option?.quation?.project?.name ? ' ' + option?.quation?.project?.name : ''))}
                                                    getOptionValue={(option) => option.id}
                                                    value={selectedOrderId}
                                                    onChange={(e) => {

                                                        setAftragId(e.id);
                                                        setQuationId(0);

                                                        //setValue('auftrag_id', e?.quation?.project?.id);
                                                        setValue('auftrag_id', e.id);
                                                        setselectedOrderId(e);
                                                    }}
                                                    required
                                                    isDisabled={props?.orderId ? true : false}
                                                />
                                                <div className="invalid-feedback">{errors.auftrag_id && errors.auftrag_id.message}</div>
                                            </div>
                                        ) : (
                                            <>
                                                <div className="form-group">
                                                    <label>
                                                        {t('Lieferant & Partner')} *
                                                    </label>
                                                    <br />
                                                    <select
                                                        className="form-control"
                                                        {...register("lieferanten_id", {
                                                            required: true,
                                                            onChange: (e) => {
                                                                data?.lieferantan.map((lieferantan) => {
                                                                    if (lieferantan?.id == e.target.value) {
                                                                        setValue('name', (lieferantan?.firma_name == null || lieferantan?.firma_name == 'null' ? "" : lieferantan?.firma_name));
                                                                        setValue('street', (lieferantan?.street == null || lieferantan?.street == 'null' ? "" : lieferantan?.street));
                                                                        setValue('plz', (lieferantan?.plz == null || lieferantan?.plz == 'null' ? "" : lieferantan?.plz));
                                                                        setValue('ort', (lieferantan?.ort == null || lieferantan?.ort == 'null' ? "" : lieferantan?.ort));

                                                                        setRefresh(refresh + 1)
                                                                    }
                                                                });
                                                            }
                                                        })}
                                                        required
                                                    >
                                                        <option value="">--</option>
                                                        {data?.lieferantan && data?.lieferantan?.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.lieferanten_nummer + ' - ' + (item?.firma_name ? item?.firma_name : '')}</option>
                                                        ))}

                                                    </select>
                                                    <div className="invalid-feedback">{errors.lieferanten_id && errors.lieferanten_id.message}</div>
                                                </div>
                                            </>
                                        )}

                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>
                                                {t('Currency')} *
                                            </label>
                                            <br />
                                            <select
                                                className="form-control"
                                                {...register("currency", {
                                                    required: true,
                                                })}
                                                onChange={(e) => {
                                                    setCurrency(e.target.value)
                                                }}
                                                required
                                            >
                                                <option value="EUR">EUR (€)</option>
                                                <option value="USD">USD ($)</option>
                                            </select>
                                            <div className="invalid-feedback">{errors.currency && errors.currency.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <label>
                                            {t('Invoice Date')} *
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control mb-4 flat-pickr"
                                            placeholder={t('eg. 2001-03-20')}
                                            defaultValue={dateFormat(new Date(), "yyyy-mm-dd")}
                                            {...register("date", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.start_date && errors.start_date.message}</div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                {...register("related_to_project", {
                                                    required: false,
                                                })}
                                                className="form-check-input"
                                                id="related_to_project"
                                                onChange={(e) => {
                                                    if (props?.projektbezogene !== false) {
                                                        setReceivedError(null);
                                                        setAssemblyCost(false);

                                                        setAftragId(0);
                                                        setQuationId(0);
                                                        setselectedOrderId({ id: '' });

                                                        setValue('auftrag_id', 0);
                                                        setValue('lieferanten_id', 0);


                                                        itemSummery.amountPerchantage = 0;
                                                        setItemSummery(itemSummery);

                                                        setRelatedToProject(!relatedToProject);

                                                        setRefresh(refresh + 1)
                                                    }
                                                }}
                                                checked={relatedToProject}
                                                disabled={props?.projektbezogene !== false ? false : true}
                                            />
                                            <label htmlFor="related_to_project" >
                                                {t('Projektbezogene Rechnung buchen')}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <SoftBox p={0} mb={2}>
                                            <SoftTypography variant="caption">
                                                {getValues('name')} <br />
                                                {getValues('street')} <br />
                                                {getValues('plz')} {getValues('ort')}
                                            </SoftTypography>
                                            <br />
                                            <SoftTypography variant="caption" data-bs-toggle="modal" data-bs-target={"#invoiceBillingInfo"}><strong style={{ textDecoration: 'underline', cursor: 'pointer' }}>{t('Rechnungsempfanger andern')}</strong></SoftTypography>
                                        </SoftBox>
                                        <div className="modal fade" id={"invoiceBillingInfo"} tabIndex="-1" role="dialog" aria-labelledby={"invoiceBillingInfo"} aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered" role="document">
                                                <div className="modal-content" >
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="invoiceBillingInfoLabel">{t('Billing Address')}</h5>
                                                        <button type="button" className="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <label>{t('Name')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm mb-2 flatpickr"
                                                                    {...register('name', {
                                                                        onChange: () => {

                                                                        },
                                                                        required: true,
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback"> </div>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <label>{t('Straße')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm mb-2 flatpickr"
                                                                    {...register('street', {
                                                                        onChange: () => {

                                                                        },
                                                                        required: false,
                                                                    })}
                                                                />
                                                                <div className="invalid-feedback"> </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label>{t('Plz')} *</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control form-control-sm mb-2 flatpickr"
                                                                    {...register('plz', {
                                                                        onChange: () => {

                                                                        },
                                                                        required: false,
                                                                    })}
                                                                />
                                                                <div className="invalid-feedback"> </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>{t('Ort')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm mb-2 flatpickr"
                                                                    {...register('ort', {
                                                                        onChange: () => {

                                                                        },
                                                                        required: false,
                                                                    })}
                                                                />
                                                                <div className="invalid-feedback"> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button
                                                            onClick={() => {
                                                                setRefresh4(refresh4 + 1);
                                                            }}
                                                            type="button" className="btn btn-primary mb-0" data-bs-dismiss="modal" aria-label="Close">
                                                            {t('Save')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {relatedToProject ? (
                                        <div className="col-12">
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    {...register("assembly_cost", {
                                                        required: false,
                                                    })}
                                                    className="form-check-input"
                                                    onClick={(e) => setAssemblyCost(e.target.checked)}
                                                    checked={assemblyCost}
                                                />
                                                <label>
                                                    {t('Abzüglich Montagekosten (i.h.v. ')}
                                                    &nbsp;
                                                    <NumericFormat
                                                        value={assemblyCostAmount}
                                                        displayType="text"
                                                        thousandSeparator={"."}
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix={' ' + currency}
                                                    />
                                                    {t(')')}
                                                </label>
                                            </div>
                                        </div>
                                    ) : ''}
                                </div>

                                <div className="mt-3">
                                    {!relatedToProject ? (
                                        <>
                                            {items && items.map((item, i) => (
                                                <div key={i} className="">
                                                    {i > 0 && <hr />}
                                                    <div className="row mt-2">
                                                        <div className="col-md-8">
                                                            <div>
                                                                <label>{t('Product')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={items[i].title}
                                                                    onChange={(e) => {
                                                                        items[i].title = e.target.value;
                                                                        setItems(items);
                                                                        setRefresh(refresh + 1);
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>


                                                        <div className="col-md-2">
                                                            <div>
                                                                <label>{t('Qty/Hrs')}</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control d-inline"
                                                                    placeholder="eg. 0.00"
                                                                    value={items[i].quantity}
                                                                    onChange={(e) => {
                                                                        let value = 0;
                                                                        if (e.target.value.length && !isNaN(e.target.value))
                                                                            value = e.target.value;

                                                                        items[i].quantity = value;
                                                                        setItems(items);
                                                                        setRefresh(refresh + 1);
                                                                    }} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div>
                                                                <label>{t('Preis in €')}</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control d-inline"
                                                                    style={{ width: '84%' }}
                                                                    placeholder="eg. 0.00"
                                                                    value={items[i].price}
                                                                    onChange={(e) => {
                                                                        let value = 0;
                                                                        if (e.target.value.length && !isNaN(e.target.value))
                                                                            value = e.target.value;

                                                                        items[i].price = value;
                                                                        items[i].currentPrice = value;
                                                                        setItems(items);
                                                                        setRefresh(refresh + 1);
                                                                    }}
                                                                />
                                                                &nbsp;
                                                                <i className="fa-solid fa-circle-xmark text-danger" data-key={i} onClick={() => { delete items[i]; deleteProduct(); }}></i>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            ))}

                                            <div className="row mt-3 ">
                                                <div className="col-md-12">
                                                    <p className="btn btn-dark" style={{ cursor: 'pointer' }} onClick={() => { setItems([...items, { id: 0, title: '', quantity: 0, price: 0, currentPrice: 0, total: 0 }]); setRefresh(refresh + 1); }}><i className="fa-solid fa-circle-plus"></i>&nbsp;{t('Add Item')}</p>
                                                </div>
                                            </div>
                                        </>
                                    ) : ''}


                                    <hr />

                                    <div
                                        className="row"
                                        style={{
                                            minHeight: '165px',
                                        }}
                                    >
                                        <div className="col-md-6">
                                            {/*
                                        <textarea className="form-control" rows="5"
                                            placeholder="eg. Description"
                                            {...register("description")}
                                            defaultValue={"ABC.."}
                                        ></textarea>
                                        */}
                                            <SoftEditor
                                                onChange={setDescription}
                                                value={description}
                                                style={{
                                                    height: '120px',
                                                    overFlowY: 'auto'
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="border">
                                                <div className="row m-0">
                                                    <div className="col-md-7 border-end">
                                                        <label className="d-block text-end">{t('Sub Total')}</label>
                                                        {relatedToProject ? (
                                                            <select
                                                                {...register(
                                                                    'amountPerchantage', {
                                                                    required: true,
                                                                })}
                                                                onChange={(e) => {
                                                                    itemSummery.amountPerchantage = parseFloat(e.target.value);
                                                                    setItemSummery(itemSummery);
                                                                    setRefresh(refresh + 1)
                                                                }}
                                                                className="form-control mb-2 w-75 float-end"
                                                                required
                                                            >
                                                                <option value="0">--</option>

                                                                {/*itemSummery.amountPerchantage == 100 ? (
                                                                    <option value="100">100%</option>
                                                                ) : itemSummery.amountPerchantage == 70 ? (
                                                                    <>
                                                                        <option value="70">70%</option>
                                                                    </>
                                                                ) : itemSummery.amountPerchantage == 30 ? (
                                                                    <option value="30">30%</option>
                                                                ) : <></>*/}
                                                                <option value={itemSummery.amountPerchantage}>{itemSummery.amountPerchantage}%</option>
                                                            </select>
                                                        ) : ''}
                                                    </div>
                                                    <div className="col-md-5">
                                                        <p className="text-end mb-0">
                                                            <NumericFormat
                                                                value={itemSummery.amount}
                                                                displayType="text"
                                                                thousandSeparator={"."}
                                                                decimalSeparator=","
                                                                decimalScale={2}
                                                                fixedDecimalScale
                                                                suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                            />
                                                        </p>
                                                        {assemblyCost ? (
                                                            <p className="text-end text-danger mb-0">
                                                                <NumericFormat
                                                                    value={assemblyCostAmount}
                                                                    displayType="text"
                                                                    thousandSeparator={"."}
                                                                    decimalSeparator=","
                                                                    decimalScale={2}
                                                                    fixedDecimalScale
                                                                    suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                                    prefix="- "
                                                                    allowNegative={false}
                                                                />
                                                            </p>
                                                        ) : ''}
                                                    </div>
                                                </div>
                                                <div className="row border-top d-none">
                                                    <div className="col-md-3 border-end">
                                                        <label className="d-block text-end">{t('Discount')}</label>
                                                    </div>
                                                    <div className="col-md-3 border-end">
                                                        <input type="number" className="form-control" onChange={(e) => {
                                                            let value = 0;
                                                            if (e.target.value.length && !isNaN(e.target.value))
                                                                value = e.target.value;
                                                            itemSummery.discountPercentage = parseFloat(value);
                                                            setItemSummery(itemSummery);
                                                            setRefresh(refresh + 1);
                                                        }} />
                                                    </div>
                                                    <div className="col-md-1 border-end">
                                                        <p className="text-end">%</p>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <p className="text-end">
                                                            <NumericFormat
                                                                value={itemSummery.discount}
                                                                displayType="text"
                                                                thousandSeparator={"."}
                                                                decimalSeparator=","
                                                                decimalScale={2}
                                                                fixedDecimalScale
                                                                suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                            />
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row border-top m-0">
                                                    <div className="col-md-3 border-end">
                                                        <label className="d-block text-end">{t('Tax')}</label>
                                                    </div>
                                                    <div className="col-md-4 border-end">
                                                        <select
                                                            {...register('tax')}
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                let value = 0;
                                                                if (e.target.value.length && !isNaN(e.target.value))
                                                                    value = e.target.value;
                                                                itemSummery.taxPercentage = parseFloat(value);
                                                                setItemSummery(itemSummery);
                                                                setRefresh(refresh + 1);
                                                            }}>
                                                            <option value="0">0%</option>
                                                            <option value="19">19%</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <p className="text-end">
                                                            <NumericFormat
                                                                value={itemSummery.tax}
                                                                displayType="text"
                                                                thousandSeparator={"."}
                                                                decimalSeparator=","
                                                                decimalScale={2}
                                                                fixedDecimalScale
                                                                suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                            />
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row m-0 bg-gray-200">
                                                    <div className="col-md-7">
                                                        <label className="d-block text-end">{t('Total')}</label>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <p className="text-end">
                                                            <NumericFormat
                                                                value={((itemSummery.amount + itemSummery.tax) - (assemblyCost ? assemblyCostAmount : 0))}
                                                                displayType="text"
                                                                thousandSeparator={"."}
                                                                decimalSeparator=","
                                                                decimalScale={2}
                                                                fixedDecimalScale
                                                                suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                            />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-6">{receivedError && <SoftAlert color="error" dismissible> {receivedError} </SoftAlert>}</div>
                                    <div className="col-md-12 mt-3">
                                        {!saving && (
                                            <>
                                                {receivedError ? (
                                                    <button type="button" className="btn btn-secondary" disabled>{t('Save')}</button>
                                                ) : <button type="submit" className="btn btn-primary">{t('Save')}</button>}
                                            </>
                                        )}
                                        {saving && (
                                            <button type="button" className="btn btn-disabled" disabled>
                                                {t('Saving ...')}
                                            </button>
                                        )}

                                        {!relatedToProject ? (
                                            <>
                                                <SoftButton className={'mb-3 mx-3'} variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
                                                    {status ? status : t('Action')}&nbsp;
                                                    <Icon>keyboard_arrow_down</Icon>
                                                </SoftButton>
                                                {renderMenu}
                                            </>
                                        ) : ''}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {renderSuccessSB}
                {renderEeeorSB}
            </div>

            <LieferantentCreateModel setRefresh={setRefresh5} />
        </>
    );
}

export default Create;