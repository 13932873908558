import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { useCallFetchv2 } from 'helpers/callFetchv2';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import SoftAlert from "components/SoftAlert";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { NavLink } from "react-router-dom";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import Distribution from "./create/Distribution";
import Order from "./create/Distribution/Order";
import Swal from "sweetalert2";
import SoftSnackbar from "components/SoftSnackbar";

function ProjectStatus(props) {
    let params = useParams();
    const { t } = useTranslation();
    const callFetch = useCallFetchv2();
    const [errormessage, setErrorMessage] = useState('');
    const [status, setStatus] = useState('');
    const [resaleStatus, setResaleStatus] = useState('');
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [projectStatus, setProjectStatus] = useState('');
    const [attachments, setAttachment] = useState([]);
    const [selectedSalesStatus, setSelectedSalesStatus] = useState({});
    const [salesStatus, setSalesStatus] = useState([]);
    const [loading, setLoading] = useState(false);

    const [isDisabled, setIsDisabled] = useState(true);

    const [activeStep, setActiveStep] = useState('');
    const isLastStep = activeStep === 1;

    const [resaleStatusChange, setResaleStatusChange] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const removeAttachment = (id) => {
        const beforeRemove = attachments;
        const afterRemove = beforeRemove.filter(value => {
            return value.id != id;
        });

        setAttachment(afterRemove);
    }

    useEffect(() => {
        const selectedPropStatus = salesStatus.find(status => status.id == props?.status);
        const selectedprojectStatus = salesStatus.find(status => status.id == projectStatus);

        if (
            (props?.status && selectedPropStatus && selectedPropStatus?.erststatus == 1) ||
            (projectStatus && selectedprojectStatus && selectedprojectStatus?.erststatus == 1)
        ) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [props?.status, projectStatus, refresh]);

    useEffect(() => {
        callFetch("get-sales-status", "GET", []).then((res) => {
            setSalesStatus(res?.data)
        });
    }, [props?.salesStatus]);

    useEffect(() => {
        if (props?.projectid) {
            setValue('projectid', props.projectid);
        }

        if (props?.quations_id) {
            setValue('quations_id', props.quations_id);
        }

        if (props?.status) {
            console.log('Line 96')
            setValue('status', props.status);
            setProjectStatus(props.status);
        }

        setErrorMessage('')
    }, [props?.quations_id, props?.projectid, props?.status, salesStatus.length]);

    const currentStatusConfirmMessage = () => {

        callFetch("project/related/id/get/" + (props?.projectid ? props?.projectid : getValues('projectid') ? getValues('projectid') : params.id), "GET", []).then((res) => {
            if ((res?.data?.status ? res?.data?.status : res?.data?.termine_qoute?.status ? res?.data?.termine_qoute?.status : '') && (res?.data?.status ? res?.data?.status : res?.data?.termine_qoute?.status ? res?.data?.termine_qoute?.status : '') != '1' && status == '1') {
                const newSwal = Swal.mixin({
                    customClass: {
                        confirmButton: "button button-success",
                        cancelButton: "button button-error",
                    },
                    buttonsStyling: false,
                });

                newSwal
                    .fire({
                        title: "If you close the popup the status will be " + (res?.data?.status ? res?.data?.status : res?.data?.termine_qoute?.status ? res?.data?.termine_qoute?.status : ''),
                        //icon: "warning",
                        showCancelButton: true,
                        cancelButtonText: "no",
                        confirmButtonText: "Yes",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            //Swal.fire("Order Not created yet!", "success");
                            popupCLose();
                        }
                    });
            } else {
                popupCLose();
            }
        });
    };

    const popupCLose = () => {
        if (document.getElementById("projectStatusAngebotOrderClose" + (params?.id ? params?.id : props?.projectid))) {
            document.getElementById("projectStatusAngebotOrderClose" + (params?.id ? params?.id : props?.projectid)).click();
        }

        if (document.getElementById("dashboardProjectCardListRefresh")) {
            document.getElementById("dashboardProjectCardListRefresh").click();
        }

        document.querySelectorAll('.div.todays-card').forEach(function (a) {
            a.classList.remove('visibility-hidden')
        });

        document.querySelectorAll('div.todays-Unbewertet-tab').forEach(function (a) {
            // a.classList.remove('visibility-hidden')
            a.style.zIndex = 9999;
        });

        document.getElementById('projectStatusSales' + (params?.id ? params?.id : props?.projectid)).classList.remove('visibility-visible');
    }

    useEffect(() => {
        if (!(props?.projectid ? props?.projectid : params?.id)) return;
        if (props?.todayCard === true) {
            //console.log(props?.status);
            setValue('projectid', (props?.projectid ? props?.projectid : params?.id));
            console.log('Line 162')
            setValue('status', (props?.status ? props?.status : ''));
            setProjectStatus((props?.status ? props?.status : ''));
        } else {
            callFetch("project/related/id/get/" + (props?.projectid ? props?.projectid : params?.id), "GET", []).then((res) => {
                setValue('projectid', res?.data?.id);
                console.log('Line 168')
                setValue('status', (res?.data?.status ? res?.data?.status : res?.data?.termine_qoute?.status));
                setProjectStatus((res?.data?.status ? res?.data?.status : res?.data?.termine_qoute?.status));
                setResaleStatus((res?.data?.resalestatus?.title ? res?.data?.resalestatus?.title : ''))
            });
        }
    }, [(props?.projectid ? props?.projectid : params?.id)]);

    useEffect(() => {
        if (props?.refresh && props?.todayCard === true) {
            console.log('Line 178')
            setValue('status', (props?.status ? props?.status : ''));
            setProjectStatus((props?.status ? props?.status : ''));
        }
    }, [props?.refresh, props?.status]);

    const onSubmitResaleStatus = (status) => {

        setResaleStatusChange(false);
        setResaleStatus(status);

        var formData = getValues();
        formData.status = status;

        callFetch("project/resaleStatus", "POST", formData, setError).then((res) => {
            if (res?.message === 'success') {
                setResaleStatusChange(true);
            }
        });
    }

    const onSubmit = () => {
        var formData = getValues();

       // console.log('onSubmit formData')
        //console.log(formData)

        for (let [key, value] of Object.entries(salesStatus)) {

            setSelectedSalesStatus(value);

            if ((formData.status == value.id && value.feedback == 1) || (formData.status == value.id && value.picture_required == 1)) {
                document.getElementById('projectStatusWithFileUploadOpenBtn' + (params?.id ? params?.id : props?.projectid)).click();
                return;
            }
        }

        setSaving(true);
        setErrorMessage('');
        callFetch("project/attribute/update", "POST", formData, setError).then((res) => {
            setSaving(false);
            setRefresh(refresh + 1)
            if (res?.ok) {
                setProjectStatus(formData.status);
                setSubmitSuccess(true);

                if (document.getElementById('timeline-refresh-btn')) {
                    document.getElementById('timeline-refresh-btn').click();
                }

                if (document.getElementById('dashboardProjectCardListRefresh')) {
                    document.getElementById('dashboardProjectCardListRefresh').click();
                }
            } else {
                setErrorMessage(res?.message);

                if (props?.salesPopup === true) {

                    if (formData.status == '1') {
                        setActiveStep(1);
                    } else {
                        setActiveStep(0);
                    }

                    document.getElementById('projectSalesStatus' + (params?.id ? params?.id : props?.projectid)).click();

                    document.querySelectorAll('.div.todays-card').forEach(function (a) {
                        a.classList.add('visibility-hidden')
                    });

                    document.querySelectorAll('div.todays-Unbewertet-tab').forEach(function (a) {
                        //a.classList.add('visibility-hidden')
                        a.style.zIndex = 'unset';
                    });

                    document.getElementById('projectStatusSales' + (params?.id ? params?.id : props?.projectid)).classList.add('visibility-visible');
                }
            }
        });
    };

    const onSubmit2 = () => {

        var formData = getValues();
        formData.attachments = (attachments.length ? JSON.stringify(attachments) : '');

        console.log('onSubmit2 formData')
        console.log(formData)

        setSaving(true);
        setErrorMessage('');
        setSubmitSuccess(false);
        callFetch("project/attribute/update", "POST", formData, setError).then((res) => {
            setSaving(false);
            setRefresh(refresh + 1)
            if (res?.ok) {
                setSubmitSuccess(true);
                setAttachment([]);
                setValue('description', '');
                setValue('attachments', '');
                setProjectStatus(formData.status);

                if (document.getElementById('timeline-refresh-btn')) {
                    document.getElementById('timeline-refresh-btn').click();
                }

                if (document.getElementById('dashboardProjectCardListRefresh')) {
                    document.getElementById('dashboardProjectCardListRefresh').click();
                }

                if (document.getElementById("projectStatusWithFileUploadClose" + (params?.id ? params?.id : props?.projectid))) {
                    document.getElementById("projectStatusWithFileUploadClose" + (params?.id ? params?.id : props?.projectid)).click();
                }
            } else {
                setErrorMessage(res?.message);
            }
        });
    }

    return (
        <>
            {props?.salesStatus != false ? (
                <>
                    {JSON.parse(Cookies.get('permissions')).indexOf("QU") !== -1 ? (
                        <select
                            className="form-control"
                            {...register("status", {
                                onChange: (e) => {
                                    setStatus(e.target.value)
                                    clearErrors('description')
                                    clearErrors('attachments')
                                    onSubmit();
                                }
                            })}
                            //disabled={props?.status == '1' || projectStatus == '1' ? true : false}
                            disabled={isDisabled}
                        >
                            <option value="">--</option>
                            {salesStatus && salesStatus
                                .filter(status => status.type === 'sale') // Filter for sale types
                                .map((status) => (
                                    <option key={status.id} value={status.id}>
                                        {status.order_number + '-' + t(status.name)}
                                    </option>
                                ))}
                        </select>
                    ) : (
                        <select
                            className="form-control"
                            {...register("status", {})}
                            disabled
                        >
                            <option value="">--</option>
                            {salesStatus && salesStatus
                                .filter(status => status.type === 'sale') // Filter for sale types
                                .map((status) => (
                                    <option key={status.id} value={status.id}>
                                        {status.order_number + '-' + t(status.name)}
                                    </option>
                                ))}
                        </select>
                    )}
                </>
            ) : ''}

            {props?.resallerStatus === true ? (
                <select
                    className="form-control"
                    value={resaleStatus}
                    onChange={(e) => {
                        onSubmitResaleStatus(e.target.value);
                    }}
                    //disabled={resaleStatus == '1-Verkauft' ? true : false}
                    disabled={salesStatus.find(status => status.id == resaleStatus)?.erststatus === 1}
                >
                    <option value="">--</option>
                    {salesStatus && salesStatus
                        .filter(status => status.type === 'resale') // Filter for resale types
                        .map((status) => (
                            <option key={status.id} value={status.id}>
                                {status.order_number + '-' + t(status.name)}
                            </option>
                        ))}
                </select>
            ) : ''}


            {props?.errorDisplay !== false ? (
                <>
                    {errormessage ? (
                        <SoftAlert color={'dark'} dismissible className="mt-2 dark-alert" style={{ fontWeight: '700', fontSize: '0.75rem' }}>
                            {errormessage == 'Kein Auftrag vorhanden. Bitte erstellen Sie hier ein Auftrag' ? (
                                <>
                                    <SoftTypography variant="body2" color="white">
                                        Kein Auftrag vorhanden. Bitte erstellen Sie{" "}
                                        <SoftTypography component="a" style={{}} href={'/projects/' + (params?.id ? params?.id : props?.projectid) + '#auftrag'} variant="plain" fontWeight="medium" color="white">hier</SoftTypography>{" "}ein Auftrag
                                    </SoftTypography>
                                </>
                            ) : errormessage == 'Kein Angebot vorhanden. Bitte erstellen Sie hier ein Angebot' ? (
                                <>
                                    <SoftTypography variant="body2" color="white">
                                        Kein Angebot vorhanden. Bitte erstellen Sie{" "}
                                        <SoftTypography component="a" href={'/projects/' + (params?.id ? params?.id : props?.projectid) + '#distribution'} variant="plain" fontWeight="medium" color="white">hier</SoftTypography>{" "}ein Angebot
                                    </SoftTypography>
                                </>
                            ) : t(errormessage === 'The description field is required.' || errormessage === 'The description must be at least 10 characters.' ?
                                'Bitte schreiben Sie einen Text mit min. 10 Buchstaben.'
                                : errormessage === 'The attachments field is required.' ?
                                    'Bitte laden Sie ein Bild hoch.'
                                    : errormessage)}
                        </SoftAlert>
                    ) : submitSuccess ? (
                        <SoftAlert color={'success'} dismissible className="mt-2 dark-alert" style={{ fontWeight: '700', fontSize: '0.75rem' }}>
                            <SoftTypography variant="body2" color="white">
                                {t('Speichern erfolgreich')}
                            </SoftTypography>
                        </SoftAlert>
                    ) : ''}
                </>
            ) : ""}

            {resaleStatusChange ? (
                <SoftAlert color={'success'} dismissible className="mt-2 dark-alert" style={{ fontWeight: '700', fontSize: '0.75rem' }}>
                    <SoftTypography variant="body2" color="white">
                        {t('Speichern erfolgreich')}
                    </SoftTypography>
                </SoftAlert>
            ) : ''}



            <a className="d-none" id={"projectStatusWithFileUploadOpenBtn" + (params?.id ? params?.id : props?.projectid)} data-bs-toggle="modal" data-bs-target={"#projectStatusWithFileUpload" + (params?.id ? params?.id : props?.projectid)}></a>
            <div className="modal fade" id={"projectStatusWithFileUpload" + (params?.id ? params?.id : props?.projectid)} tabIndex="-1" role="dialog" aria-labelledby={"projectStatusWithFileUpload" + (params?.id ? params?.id : props?.projectid)} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" >
                        <div className="modal-header">
                            <h5 className="modal-title" id="projectStatusWithFileUploadLabel">{t(selectedSalesStatus?.order_number + '-' + selectedSalesStatus?.name)}</h5>
                            <button type="button" className="btn-close text-dark" id={"projectStatusWithFileUploadClose" + (params?.id ? params?.id : props?.projectid)} data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            {errormessage ? (
                                <SoftAlert color={'error'} sm={{ fontWeight: '700', fontSize: '0.75rem' }}>
                                    {t(errormessage === 'The description field is required.' || errormessage === 'The description must be at least 10 characters.' ?
                                        'Bitte schreiben Sie einen Text mit min. 10 Buchstaben.'
                                        : errormessage === 'The attachments field is required.' ?
                                            'Bitte laden Sie ein Bild hoch.'
                                            : errormessage)}
                                </SoftAlert>
                            ) : ''}



                            <SoftBox
                                display="flex"
                                flexDirection="column"
                                justifyContent="flex-end"
                                height="100%"
                            >
                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        {t('Description')}
                                    </SoftTypography>
                                </SoftBox>

                                <textarea  {...register("description", { required: true, })} className="form-control"></textarea>
                            </SoftBox>

                            <div className="col-sm-12 mt-3">
                                <div className="form-group">
                                    <label htmlFor={"status_upload_files" + (params?.id ? params?.id : props?.projectid)} className="text-primary" style={{ cursor: 'pointer' }}><i className="fa-solid fa-link"></i> {t('Upload Files')}</label>
                                    <input
                                        type="file"
                                        onChange={(e) => {
                                            setLoading(true);
                                            var formData = {};
                                            for (let [key, val] of Object.entries(e.target.files)) {
                                                formData['files[' + key + ']'] = e.target.files[key];
                                            }

                                            callFetch('attachment', "POST", formData, setError, '', false).then((res) => {
                                                setLoading(false);
                                                if (res.message == 'success') {
                                                    let data = attachments;
                                                    for (let [key, val] of Object.entries(res.data)) {
                                                        data[data.length] = val;
                                                    }
                                                    console.log(data)
                                                    setAttachment(data);
                                                    setValue('attachments', JSON.stringify(data));
                                                }
                                            });
                                        }}
                                        id={"status_upload_files" + (params?.id ? params?.id : props?.projectid)}
                                        className="d-none"
                                        multiple
                                        accept=".jpeg,.jpg,.png,.pdf,.doc,.docx"
                                    />
                                </div>
                            </div>

                            <SoftBox p={0} className="order-processing">
                                <Grid container spacing={3}>
                                    {attachments && attachments.map(function (data, i) {
                                        return (
                                            <Grid item key={i} className="text-center">
                                                <div>
                                                    <a target="_blank" download href={Cookies.get('backend_url') + 'storage/attachments/' + data?.attachment}>
                                                        {['jpeg', 'jpg', 'png'].indexOf(data?.extension) !== -1 ? (
                                                            <img src={Cookies.get('backend_url') + 'storage/attachments/' + data?.attachment} />
                                                        ) : (
                                                            <div className="placeholder">{data?.extension}</div>
                                                        )}

                                                    </a>
                                                </div>
                                                <a
                                                    onClick={() => {
                                                        removeAttachment(data?.id);
                                                    }}
                                                >{t('Remove')}</a>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </SoftBox>

                        </div>
                        <div className="modal-footer">
                            {!saving && (
                                <>
                                    <button type="button"
                                        onClick={(e) => {
                                            onSubmit2();
                                        }}
                                        className={"btn btn-primary mb-0"}
                                    >
                                        {t('Save')}
                                    </button>
                                </>
                            )}
                            {saving && (
                                <button type="button" className="btn btn-disabled mb-0">
                                    {t('Save...')}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <a className="d-none" id={"projectSalesStatus" + (params?.id ? params?.id : props?.projectid)} data-bs-toggle="modal" data-bs-target={"#projectStatusSales" + (params?.id ? params?.id : props?.projectid)}></a>
            <div className="modal fade" id={"projectStatusSales" + (params?.id ? params?.id : props?.projectid)} tabIndex="-1" role="dialog" aria-labelledby={"projectStatusSales" + (params?.id ? params?.id : props?.projectid)} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content" >
                        <div className="modal-header">
                            <h5 className="modal-title" id="projectStatusWithFileUploadLabel">{t('Project Sales')}</h5>
                            <button onClick={() => {
                                //activeStep === 1
                                currentStatusConfirmMessage();

                            }} type="button" className="btn-close text-dark">
                                <span aria-hidden="true">&times;</span>
                            </button>

                            <button type="button" className="btn-close text-dark d-none" id={"projectStatusAngebotOrderClose" + (params?.id ? params?.id : props?.projectid)} data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <SoftBox mt={1} mb={1}>
                                <Grid justifyContent="center">
                                    <Grid item xs={12} lg={8}>
                                        <Stepper activeStep={activeStep} alternativeLabel className="my-3">
                                            <Step id="AngeboteSalesTab" onClick={() => setActiveStep(0)}>
                                                <StepLabel>1. {t('Angebote')}</StepLabel>
                                            </Step>
                                            <Step id="OrderSalesTab" onClick={() => setActiveStep(1)}>
                                                <StepLabel>2. {t('Order')}</StepLabel>
                                            </Step>
                                        </Stepper>
                                    </Grid>
                                </Grid>
                            </SoftBox>

                            {activeStep === 0 ? (
                                <Distribution projectid={props?.projectid} overview={true} menu={false} sidebar={false} fullwidth={false} />
                            ) : activeStep === 1 ? (
                                <Order projectid={props?.projectid} overview={true} menu={false} sidebar={false} fullwidth={false} />
                            ) : ''}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProjectStatus;
