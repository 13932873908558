import { React, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import dateFormat, { masks } from "dateformat";
import { NumericFormat } from 'react-number-format';
import callFetch from "../../../helpers/callFetch";
import CorrectionRequestView from "./CorrectionRequestView";

import SoftTypography from "components/SoftTypography";
import { NavLink } from "react-router-dom";

function TimelineNew(props) {
    const { t } = useTranslation();
    let params = useParams();

    const [refresh, setRefresh] = useState(1);
    const [data, setData] = useState([]);
    var AppointmentSerial = 1;
    var QuotationSerial = 1;
    var Orderserial = 1;
    var serial = 1;


    useEffect(() => {
        if (!params.id) return;
        if (!props?.api_url) return;

        callFetch("project/timelines-overview/" + (props?.projectid ? props?.projectid : params.id), "GET", [], {}, props?.api_url).then((res) => {
            setData(res.data);
        });
    }, [props?.api_url, props?.projectid + refresh + params?.id + (props?.refresh ?? 0)]);

    return (
        <>
            {data && data.project ? (
                <div className="card" style={{ boxShadow: 'none' }}>
                    <SoftTypography variant="h6" fontWeight="bold">{t('Project Status')}</SoftTypography>
                    <div className="card-body mt-3 callcenter-area" style={{ height: '305px', backgroundColor: 'rgb(248, 249, 250)', borderRadius: '15px', overflowY: 'scroll' }}>
                        <div className="timeline timeline-one-side" data-timeline-axis-style="dotted">

                            {data.quations && data.quations.map((quation) => (
                                <>
                                    <div className="timeline-block mb-3">
                                        <span className="timeline-step">
                                            <i className="ni ni-bell-55 text-success text-gradient" />
                                        </span>
                                        <div className="timeline-content">
                                            <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Quotation')}</h6>
                                            <p className="text-secondary font-weight-bold text-xs mt-1">{dateFormat(quation.created_at, "dd.mm.yyyy")}</p>

                                            <p className="text-sm m-0">
                                                <SoftTypography variant="caption"><strong>{t('Quotation Nr.')}: </strong></SoftTypography> <SoftTypography variant="caption">{quation.id}</SoftTypography>
                                            </p>
                                        </div>
                                    </div>
                                </>
                            ))}

                            {data.orders && data.orders.map((order) => (
                                <>
                                    <div className="timeline-block mb-3">
                                        <span className="timeline-step">
                                            <i className="ni ni-bell-55 text-success text-gradient" />
                                        </span>
                                        <div className="timeline-content">
                                            <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Order')}</h6>
                                            <p className="text-secondary font-weight-bold text-xs mt-1">{dateFormat(order.created_at, "dd.mm.yyyy")}</p>

                                            <p className="text-sm m-0">
                                                <SoftTypography variant="caption"><strong>{t('Order Nr.')}: </strong></SoftTypography> <SoftTypography variant="caption">{order.id}</SoftTypography>
                                            </p>
                                        </div>
                                    </div>
                                </>
                            ))}

                            {data.assign_orders && data.assign_orders.map((assign_order) => (
                                <>
                                    {assign_order.dachmontag_elektromontage == 'Dachmontage' ? (
                                        <>
                                            <div className="timeline-block mb-3">
                                                <span className="timeline-step">
                                                    <i className="ni ni-bell-55 text-success text-gradient" />
                                                </span>
                                                <div className="timeline-content">
                                                    <h6 className="text-dark text-sm font-weight-bold mb-0">{assign_order.dachmontag_elektromontage}</h6>
                                                    <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(assign_order.created_at, "dd.mm.yyyy")}</p>
                                                    <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(assign_order.created_at, "HH:ss")}</p>
                                                </div>
                                            </div>

                                            {data.project.reclamations && data.project.reclamations.map((reclamation, index) => (
                                                <>
                                                    {reclamation?.department == 'Dachmontage' ? (
                                                        <div className="timeline-block mb-3">
                                                            <span className="timeline-step">
                                                                <i className="ni ni-bell-55 text-success text-gradient" />
                                                            </span>
                                                            <div className="timeline-content">
                                                                <h6 className="text-dark text-sm font-weight-bold mb-0">{'Reklamation '}</h6>
                                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(reclamation.created_at, "dd.mm.yyyy")}</p>
                                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(reclamation.created_at, "HH:ss")}</p>

                                                                <p className="text-sm m-0">
                                                                    <SoftTypography variant="caption"><strong>{t('Rekla.Nr')}: </strong></SoftTypography>
                                                                    <SoftTypography variant="caption"><NavLink to={'/reclamation/' + reclamation.id}>{'R-' + reclamation?.id}</NavLink></SoftTypography>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ) : ''}
                                                </>
                                            ))}

                                            <div className="timeline-block mb-3">
                                                <span className="timeline-step">
                                                    <i className="ni ni-bell-55 text-success text-gradient" />
                                                </span>
                                                <div className="timeline-content">
                                                    <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Beschaffung')}</h6>
                                                </div>
                                            </div>

                                            {data.project.reclamations && data.project.reclamations.map((reclamation, index) => (
                                                <>
                                                    {reclamation?.department == 'Einkauf' ? (
                                                        <div className="timeline-block mb-3">
                                                            <span className="timeline-step">
                                                                <i className="ni ni-bell-55 text-success text-gradient" />
                                                            </span>
                                                            <div className="timeline-content">
                                                                <h6 className="text-dark text-sm font-weight-bold mb-0">{'Reklamation '}</h6>
                                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(reclamation.created_at, "dd.mm.yyyy")}</p>
                                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(reclamation.created_at, "HH:ss")}</p>

                                                                <p className="text-sm m-0">
                                                                    <SoftTypography variant="caption"><strong>{t('Rekla.Nr')}: </strong></SoftTypography>
                                                                    <SoftTypography variant="caption"><NavLink to={'/reclamation/' + reclamation.id}>{'R-' + reclamation?.id}</NavLink></SoftTypography>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ) : ''}
                                                </>
                                            ))}
                                        </>
                                    ) : ''
                                    }
                                </>
                            ))}


                            {data.invoices && data.invoices.map((invoice, invoice_serial) => (
                                <>
                                    <div className="timeline-block mb-3">
                                        <span className="timeline-step">
                                            <i className="ni ni-bell-55 text-success text-gradient" />
                                        </span>
                                        <div className="timeline-content">
                                            <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Invoice') + ' ' + JSON.parse(invoice?.product_details)?.amountPerchantage + '%'}</h6>
                                            <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(invoice?.created_at, "dd.mm.yyyy")}</p>

                                            <p className="text-sm mt-1 mb-0">
                                                <SoftTypography variant="caption"><strong>{t('InvoiceNr.')}: </strong></SoftTypography> <SoftTypography variant="caption">{invoice?.id}</SoftTypography>
                                            </p>
                                        </div>
                                    </div>
                                </>
                            ))}

                            {data.project.reclamations && data.project.reclamations.map((reclamation, index) => (
                                <>
                                    {reclamation?.department == 'Buchhaltung' ? (
                                        <div className="timeline-block mb-3">
                                            <span className="timeline-step">
                                                <i className="ni ni-bell-55 text-success text-gradient" />
                                            </span>
                                            <div className="timeline-content">
                                                <h6 className="text-dark text-sm font-weight-bold mb-0">{'Reklamation '}</h6>
                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(reclamation.created_at, "dd.mm.yyyy")}</p>
                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(reclamation.created_at, "HH:ss")}</p>

                                                <p className="text-sm m-0">
                                                    <SoftTypography variant="caption"><strong>{t('Rekla.Nr')}: </strong></SoftTypography>
                                                    <SoftTypography variant="caption"><NavLink to={'/reclamation/' + reclamation.id}>{'R-' + reclamation?.id}</NavLink></SoftTypography>
                                                </p>
                                            </div>
                                        </div>
                                    ) : ''}
                                </>
                            ))}

                            {data.assign_orders && data.assign_orders.map((assign_order) => (
                                <>
                                    {assign_order.dachmontag_elektromontage == 'Elektromontage' ? (
                                        <div className="timeline-block mb-3">
                                            <span className="timeline-step">
                                                <i className="ni ni-bell-55 text-success text-gradient" />
                                            </span>
                                            <div className="timeline-content">
                                                <h6 className="text-dark text-sm font-weight-bold mb-0">{assign_order.dachmontag_elektromontage}</h6>
                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(assign_order.created_at, "dd.mm.yyyy")}</p>
                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(assign_order.created_at, "HH:ss")}</p>
                                            </div>
                                        </div>
                                    ) : ''
                                    }
                                </>
                            ))}

                            {data.project.reclamations && data.project.reclamations.map((reclamation, index) => (
                                <>
                                    {reclamation?.department == 'Elektromontage' ? (
                                        <div className="timeline-block mb-3">
                                            <span className="timeline-step">
                                                <i className="ni ni-bell-55 text-success text-gradient" />
                                            </span>
                                            <div className="timeline-content">
                                                <h6 className="text-dark text-sm font-weight-bold mb-0">{'Reklamation '}</h6>
                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(reclamation.created_at, "dd.mm.yyyy")}</p>
                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(reclamation.created_at, "HH:ss")}</p>

                                                <p className="text-sm m-0">
                                                    <SoftTypography variant="caption"><strong>{t('Rekla.Nr')}: </strong></SoftTypography>
                                                    <SoftTypography variant="caption"><NavLink to={'/reclamation/' + reclamation.id}>{'R-' + reclamation?.id}</NavLink></SoftTypography>
                                                </p>
                                            </div>
                                        </div>
                                    ) : ''}
                                </>
                            ))}

                            {data?.project?.fertigmeldung[0] ? (
                                <div className="timeline-block mb-3">
                                    <span className="timeline-step">
                                        <i className="ni ni-bell-55 text-success text-gradient" />
                                    </span>
                                    <div className="timeline-content">
                                        <h6 className="text-dark text-sm font-weight-bold mb-0">{'Fertigmeldung'}</h6>
                                        <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(data?.project?.fertigmeldung[0].created_at, "dd.mm.yyyy")}</p>
                                    </div>
                                </div>
                            ) : ''}

                        </div>
                    </div>
                </div>
            ) : <></>}
        </>
    )
}

export default TimelineNew;