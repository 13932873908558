import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import SoftAlert from "components/SoftAlert";
import SoftTypography from "components/SoftTypography";
import SoftEditor from "components/SoftEditor";
import SoftSnackbar from "components/SoftSnackbar";
import Cookies from 'js-cookie';

function EmailForm(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [totalEmails, setTotalEmails] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [emailText, setEmailText] = useState('');
    const [type, setType] = useState(props.type);
    const [id, setId] = useState(props.id);
    const [subject, setSubject] = useState('');
    const modal = useRef();
    const [errorSB, setErrorSB] = useState(false);
    const [fileData, setFileData] = useState(false);
    const closeErrorSB = () => setErrorSB(false);
    const [errorSBOrder, setErrorSBOrder] = useState(false);
    const closeErrorSBOrder = () => setErrorSBOrder(false);
    const [moduleAttach, setModuleAttach] = useState(true);
    const [speicherAttach, setSpeicherAttach] = useState(true);
    const [wechselrichterAttach, setWechselrichterAttach] = useState(true);
    const [newVersion, setNewVersion] = useState(false);
    const [files, setFiles] = useState([]);
    const [fileSize, setFileSize] = useState();
    const [errorText, setErrorText] = useState('');

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        unregister,
        formState: { errors },
    } = useForm();



    const onSubmit = (formData) => {
        setErrorMessage('');
        if(Number(fileSize) > 18){
            setErrorText('Attachments size must be less then 18 MB');
            setErrorSB(true);
            return false;
        }
        setSaving(true);
        formData.description = emailText;
        formData.subject = subject;
        formData.project_id = props.projectid;
        if (props?.pdf) {
            callFetch(props.type == 'angebot' ? "quation/" + props.id + '?pdf_generate'
                : props.type == 'auftrag' ? "order/" + props.id + '?pdf_generate'
                    : '',
                "GET", []).then((res) => {
                    if (res?.message == 'success') {
                        formData.pdf = true;
                        formData.files = JSON.stringify(files);
                        setSaving(true);
                        callFetch("sendemail", "POST", formData, setError).then((res) => {
                            // console.log(res);
                            setSaving(false);
                            document.getElementById('modalClose').click();
                            if (!res.ok) {
                                setErrorMessage('Something wrong! Please contact with administrator.');
                                return;
                            }
                            setSubmitSuccess(true);
                        });
                    } else {
                        setSaving(false);
                    }
                    //setSubmitSuccess(true);
                })
        } else {

            if (props?.type == 'fertigmeldung_attchments') {
                formData.attachment_ids = JSON.stringify(props?.attachments);
            } else {
                formData.pdf = false;
            }
            formData.files = JSON.stringify(files);

            callFetch("sendemail", "POST", formData, setError).then((res) => {
                // console.log(res);
                setSaving(false);
                document.getElementById('modalClose').click();

                if (!res.ok) {
                    setErrorMessage('Something wrong! Please contact with administrator.');
                    return;
                }

                if (props?.type == 'fertigmeldung_attchments') {
                    document.getElementById('timeline-refresh-btn').click();
                }
                setSubmitSuccess(true);
            });
        }
    };

    const addEmail = () => {
        const total = totalEmails + 1;
        setTotalEmails(total);
    }
  
    const removeModule = () => {
        setValue('module', 0);
        setModuleAttach(false);
    }
   
    const removeSpeicher = () => {
        setValue('speicher', 0);
        setSpeicherAttach(false);
    }
   
    const removeWechselrichter = () => {
        setValue('wechselrichter', 0);
        setWechselrichterAttach(false);
    }

    const removeEmail = () => {
        const i = totalEmails - 1;
        const total = i;
        unregister("email[" + i + "]");
        setTotalEmails(total);
    }

    const renderErrorSB = (
        <SoftSnackbar
            color="error"
            icon="info"
            title={errorText}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={errorSB}
            onClose={() => closeErrorSB(false)}
            close={() => closeErrorSB(false)}
            bgSuccess
        />
    );

    const renderErrorSBOrder = (
        <SoftSnackbar
            color="error"
            icon="info"
            title={t('Create order first')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={errorSBOrder}
            onClose={() => closeErrorSBOrder(false)}
            close={() => closeErrorSBOrder(false)}
            bgSuccess
        />
    );

    const beforeSendMail = () => {
        if (type == 'angebot') {
            if (props?.netto > 0) {
                document.getElementById('emailPopUp').click();
                callFetch("get-smtp-email-configaration/" + type, "GET", []).then((res) => {
                    setEmailText(res?.details?.description);
                    setSubject(res?.details?.subject);
                });
            } else {
                setErrorText(t('Create quation first'));
                setErrorSB(true);

            }
        } else if (type == 'auftrag') {
            if (props?.id > 0) {
                document.getElementById('emailPopUp').click();
                callFetch("get-smtp-email-configaration/" + type, "GET", []).then((res) => {
                    setEmailText(res?.details?.description);
                    setSubject(res?.details?.subject);
                });
            } else {
                setErrorSBOrder(true);
            }
        }else{
            document.getElementById('sendMail').click();
        }
    }

    const removeFile = (id, type, size) => {
        setFileSize(fileSize - Number(size)); 
        const filteredSectionData = files.filter((value) => value.id !== id || value.type !== type); // Remove the item with the given id and type
        const updatedFiles = filteredSectionData; // Update the section data in the copied object
        setFiles(updatedFiles); // Update the state with the modified data 
    }

    useEffect(() => {
        setValue('type_id', props?.id); 
        setValue('module', 0);
        setValue('speicher', 0);
        setValue('wechselrichter', 0);
        callFetch("get-quation-info-file/" + props?.projectid, "GET", []).then((res) => {
            if(res.version == 'old'){
                setFileData(res.data);
                setValue('newVersion', 0);
                if(res?.data?.module_file?.length > 0){
                    setModuleAttach(true); 
                    setValue('module', 1)
                }else{
                    setModuleAttach(false); 
                    setValue('speicher', 0);
                }
                
                if(res?.data?.speicher_file?.length > 0){
                    setSpeicherAttach(true); 
                    setValue('speicher', 1);
                }else{
                    setSpeicherAttach(false);
                    setValue('speicher', 0);
                }
                
                if(res?.data?.wechselrichter_file?.length > 0){
                    setWechselrichterAttach(true); 
                    setValue('wechselrichter', 1);
                }else{
                    setWechselrichterAttach(false);
                    setValue('wechselrichter', 0);
                }

                setValue('module_file', res?.data?.module_file);
                setValue('speicher_file', res?.data?.speicher_file);
                setValue('module_file_name', res?.data?.module_file_name);
                setValue('speicher_file_name', res?.data?.speicher_file_name);
                setValue('wechselrichter_file', res?.data?.wechselrichter_file);
                setValue('wechselrichter_file_name', res?.data?.wechselrichter_file_name);
            }else{
                setFiles(res.data);
                var fileSize = 0;
                res.data.map((file) => {
                    //console.log(file.file_size);
                    fileSize = Number(fileSize) + Number(file.file_size);
                });
                setFileSize(fileSize);
                //console.log(fileSize);
                setValue('newVersion', 1);
                setNewVersion(true);
            }


           // console.log(res.data);
            //console.log(res.productItems);
            // setPosts(res.project_posts);
            // setData(res.project);
            // console.log(res.project);
        });
        setValue('type', props?.type);
    }, [props, (props?.selectedFilesRefresh ? props?.selectedFilesRefresh : 0)]);

    return (
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <input type="hidden" {...register("type_id")} />
                <div className="form-group">
                    <label>{t('Angebot/Auftrag')} *</label>
                    <select
                        className="form-control"
                        {...register("type", {
                            required: true,
                        })}
                        required onChange={(e) => setType(e.target.value)}>
                        <option value="">{t('Select')}</option>
                        <option value="angebot">{t('Angebot PDF')}</option>
                        <option value="auftrag">{t('Auftrag PDF')}</option>
                        <option value="fertigmeldung_attchments">{t('Fertigmeldung')}</option>
                        <option value="vollmacht">{t('Vollmacht')}</option>
                    </select>
                    <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                </div>

                {[...Array(totalEmails)].map((x, i) =>
                    <div className="form-group" key={i}>
                        <label>{t('Email')} *</label>
                        <div className="input-group mb-3">
                            <input type="email" onChange={(e) => setValue('email[' + i + ']', e.target.value)} defaultValue={getValues('email[' + i + ']')} className="form-control" placeholder={t('eg.') + ' email@mail.com'} />
                            <div className="input-group-append">
                                {totalEmails == i + 1 ? (
                                    <span onClick={() => { removeEmail() }} style={{ cursor: "pointer" }} className="input-group-text border-0">X</span>
                                ) : <></>}
                            </div>
                        </div>
                    </div>
                )}

                <div className="form-group mb-3">
                    <a onClick={() => { addEmail() }}><i className="fa-solid fa-circle-plus"></i> Hinzufügen</a>
                </div>


                {submitSuccess ? (
                    <SoftAlert color={'success'} dismissible className="mt-2 dark-alert" style={{ fontWeight: '700', fontSize: '0.75rem' }}>
                        <SoftTypography variant="body2" color="white">
                            {t('E-Mail wurde erfolgreich versendet')}
                        </SoftTypography>
                    </SoftAlert>
                ) : ''}

                {errorMessage ? (
                    <SoftAlert color={'error'} dismissible className="mt-2 dark-alert" style={{ fontWeight: '700', fontSize: '0.75rem' }}>
                        <SoftTypography variant="body2" color="white">
                            {t(errorMessage)}
                        </SoftTypography>
                    </SoftAlert>
                ) : ''}

                <div className="row g-3">
                    <div className="col-12">
                        <input type="submit" id="sendMail" className="d-none" />
                        {!saving && (
                            <button type="button" onClick={() => beforeSendMail()} className="btn btn-primary mb-0">
                                {t('Send')}
                            </button>
                        )}
                        {saving && (
                            <button type="submit" className="btn btn-disabled mb-0" disabled>
                                {t('Sending ...')}
                            </button>
                        )}
                    </div>
                </div>
            </form>



            <button type="button" className="btn btn-primary d-none" id="emailPopUp" data-bs-toggle="modal" data-bs-target="#exampleModal"></button>
            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">

                        <div className="modal-body callcenter-area">

                            <div className="form-group">
                                <label>{t('Sender')}*</label>
                                <input type="text" readOnly value={'info@solacloud.de'} className="form-control" />
                            </div>

                            {[...Array(totalEmails)].map((x, i) =>
                                <div className="form-group" key={i}>
                                    <label>{t('Empfänger')} *</label>
                                    <div className="input-group mb-3">
                                        <input type="email" onChange={(e) => setValue('email[' + i + ']', e.target.value)} defaultValue={getValues('email[' + i + ']')} className="form-control" placeholder={t('eg.') + ' email@mail.com'} />
                                        <div className="input-group-append">
                                            {totalEmails == i + 1 ? (
                                                <span onClick={() => { removeEmail() }} style={{ cursor: "pointer" }} className="input-group-text border-0">X</span>
                                            ) : <></>}
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="form-group mb-3">
                                <a onClick={() => { addEmail() }}><i className="fa-solid fa-circle-plus"></i> Hinzufügen</a>
                            </div>

                            <div className="form-group">
                                <label>{t('Betreff')}*</label>
                                <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} className="form-control" />
                            </div>

                            <div>
                                {type == 'angebot' ?  <a target={"_blank"} className="email-attachment-wrap" rel="noreferrer" href={Cookies.get('backend_url') + "quation/" + props.id}>  <i class="fa-sharp fa-solid fa-file-pdf"></i> <span style={{ color: '#005498' }} className="text-capitalize">{type}</span></a>  : ' '}
                                {type == 'auftrag' ?  <a target={"_blank"} className="email-attachment-wrap" rel="noreferrer" href={Cookies.get('backend_url') + "order/" + props.id}>   <i class="fa-sharp fa-solid fa-file-pdf"></i> <span style={{ color: '#005498' }} className="text-capitalize">{type}</span></a>  : ' '}
                                
                                {fileData?.module_id > 0 && moduleAttach == true ? <span className="email-attachment-wrap"> <a target={"_blank"}  rel="noreferrer" href={Cookies.get('backend_url') + "storage/module/" + fileData?.module_file}>   {fileData?.module_file.endsWith('.pdf') ?  <i class="fa-sharp fa-solid fa-file-pdf"></i> : <i class="fa-sharp fa-regular fa-image"></i> } <span style={{ color: '#005498' }} className="text-capitalize">{fileData?.module_file_name?.substring(0, 20)}</span> </a> &nbsp;  <i className="fa fa-close" style={{cursor: 'pointer'}} onClick={() => removeModule()}></i> </span> : ' '}
                                {fileData?.spe_id > 0 && speicherAttach == true ? <span className="email-attachment-wrap"> <a target={"_blank"}  rel="noreferrer" href={Cookies.get('backend_url') + "storage/speicher/" + fileData?.speicher_file}>   {fileData?.speicher_file.endsWith('.pdf') ?  <i class="fa-sharp fa-solid fa-file-pdf"></i> : <i class="fa-sharp fa-regular fa-image"></i> } <span style={{ color: '#005498' }} className="text-capitalize">{fileData?.speicher_file_name?.substring(0, 20)}</span> </a> &nbsp; <i className="fa fa-close" style={{cursor: 'pointer'}} onClick={() => removeSpeicher()}></i> </span> : ' '}
                                {fileData?.wechselrichter_id > 0 && wechselrichterAttach == true ? <span className="email-attachment-wrap"> <a target={"_blank"}  rel="noreferrer" href={Cookies.get('backend_url') + "storage/wechselrichter/" + fileData?.wechselrichter_file}>   {fileData?.wechselrichter_file.endsWith('.pdf') ?  <i class="fa-sharp fa-solid fa-file-pdf"></i> : <i class="fa-sharp fa-regular fa-image"></i> } <span style={{ color: '#005498' }} className="text-capitalize">{fileData?.wechselrichter_file_name?.substring(0, 20)}</span> </a> &nbsp; <i className="fa fa-close" style={{cursor: 'pointer'}} onClick={() => removeWechselrichter()}></i> </span> : ' '}
                                {newVersion == true ? 
                                    files?.map((item, key) => {
                                         return  <span className="email-attachment-wrap"> <a target={"_blank"}  rel="noreferrer" href={Cookies.get('backend_url') + "storage/"+ item?.type + "/" + item?.file}> {item?.file.endsWith('.pdf') ?  <i class="fa-sharp fa-solid fa-file-pdf"></i> : <i class="fa-sharp fa-regular fa-image"></i> }  <span style={{ color: '#005498' }} className="text-capitalize">{item?.file_name?.substring(0, 20)}... {item?.file_size + ' MB'} </span> </a> &nbsp; <i className="fa fa-close" style={{cursor: 'pointer'}} onClick={() => removeFile(item?.id, item?.type, item?.file_size)}></i> </span>
                                    })
                                : ''}
                             </div>

                            <div className="form-group">
                                <label>{t('E-mail')}*</label>
                                <SoftEditor value={emailText} onChange={setEmailText} />
                            </div>

                        </div>
                        <div className="modal-footer"> 
                            <button type="button" style={{ position: 'relative', top: '2px' }} className="btn btn-secondary mt-3" ref={modal} id="modalClose" data-bs-dismiss="modal">{t('Abbrechen')}</button>
                            {!saving && (
                                <button type="submit" onClick={() => { document.getElementById('sendMail').click(); }} className="btn btn-primary mb-0">
                                    {t('Send')}
                                </button>
                            )}
                            {saving && (
                                <button type="submit" className="btn btn-disabled mb-0" disabled>
                                    {t('Sending ...')}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {renderErrorSB}
            {renderErrorSBOrder}
        </>
    );
}

export default EmailForm;