import { useMemo, useEffect, useState, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import Grid from "@mui/material/Grid";

import SoftTypography from "components/SoftTypography";
import MiniGradientLineChart from "examples/Charts/LineCharts/MiniGradientLineChart";
import { NumericFormat } from 'react-number-format'; 
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import callFetch from "helpers/callFetch";
import SoftSelect from "components/SoftSelect";
import LoadingCard from "components/LoadingCard";

function OrderLineChart() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [year, setYear] = useState('');
    const [allYears, setAllYears] = useState([
        { value: '2024', label: '2024' },
        { value: '2023', label: '2023' },
        { value: '2022', label: '2022' }, 
        { value: '2021', label: '2021' }, 
        { value: '2020', label: '2020' }, 
        { value: '2019', label: '2019' },
    ]);
    
    useEffect(() => {
        callFetch("dashboard/line-chart/order"+(year ? '/'+year : ''), "GET", []).then((res) => {
            setData(res);
        });
    }, [year]);


    return (
        <>
            {JSON.parse(Cookies.get('permissions')).indexOf("AllSN") !== -1 || JSON.parse(Cookies.get('permissions')).indexOf("OwnSN") !== -1 ? (
                <>
                {loading ? (
                    <LoadingCard
                        sx={{height: '162px'}}
                    />
                ) : (
                    <Grid item xs={12} sm={4} className="dashboard-linechart" style={{height: '190px'}}>
                        {data?.data ? (
                            <MiniGradientLineChart
                                title={t("Auftrag")}
                                description={
                                    <>
                                        <SoftTypography variant="h5" fontWeight="bold" className="dashboard-card-header">
                                            <NumericFormat 
                                                value={data.total} 
                                                displayType="text" 
                                                thousandSeparator={"."} 
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale
                                                suffix={' € '}
                                            />
                                            {/*<SoftTypography variant="button" color="success" fontWeight="bold">+90%</SoftTypography>*/}
                                        </SoftTypography>
                                        <div className="chart-dropdown-option">
                                            <SoftSelect
                                                placeholder={t('Select')}
                                                options={allYears}
                                                value={year ? {label: year, value: year} : allYears[0]}
                                                size={'small'}
                                                onChange={(e)=>{
                                                    setYear(parseInt(e.value));
                                                }}
                                            />
                                        </div>
                                    </>
                                }
                                
                                chart={data?.data}
                            />
                            
                        ) : <><PlaceholderCard icon={""} title={{ variant: "p", text: "Loading..." }} hasBorder height="165px" /></>}
                    </Grid>
                )}
                </>
            ) : <></>}
        </>
    )
}

export default OrderLineChart;