import { AppBar, Badge, Card, Grid, Tab, Tabs } from '@mui/material'
import SoftAvatar from 'components/SoftAvatar'
import React, { useEffect, useState } from 'react'
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import Cube from 'examples/Icons/Cube';
import Document from 'examples/Icons/Document';

import tableData from "layouts/pages/users/reports/data/tableData";
import { useParams } from 'react-router-dom';
import callFetch from 'helpers/callFetch';
import { styled } from '@mui/material/styles';
import Settings from 'examples/Icons/Settings';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import curved0 from "assets/images/curved-images/curved0.jpg";

import InvoiceTable from 'pages/finance/Invoice/IndexTable';


const LieferantenDetails = () => {
  const params = useParams();
  const { t } = useTranslation();
  const { columns, rows } = tableData;
  const [editDelay, setEditDelay] = useState(0);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [tabsOrientation2, setTabsOrientation2] = useState("horizontal");
  const [tabValue2, setTabValue2] = useState(0);
  const [lieferanten, setLieferanten] = useState({})
  const [customer, setCustomer] = useState({})
  const [customerOverview, setCustomerOverview] = useState({})
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const handleSetTabValue2 = (event, newValue) => setTabValue2(newValue);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (

          <div className='mt-4'>{children}</div>

        )}
      </div>
    );
  }
  useEffect(() => { setEditDelay(editDelay + 1) }, [0])
  useEffect(() => {
    if (editDelay == 1)
      callFetch("lieferanten/" + params?.id, "GET", []).then((res) => {
        setLieferanten(res?.data);
        setCustomerOverview(res?.data?.overview);
      });
  }, [editDelay, params?.id]);

  const SmallAvatar = styled(SoftAvatar)(({ theme }) => ({
    width: 22,
    height: 22,
    backgroundColor: "#fff",
    color: "darkgray",
    cursor: "pointer"
  }));
  return (
    <>
      <SoftBox position="relative">
        <SoftBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="12.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${curved0})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            backdropFilter: `saturate(200%) blur(30px)`,
            backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
            boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <SoftAvatar
                src={lieferanten?.logo ? process.env.REACT_APP_STORAGE_URL + lieferanten?.logo : '/assets/img/placeholder.png'}
                alt="profile-image"
                variant="rounded"
                size="xl"
                shadow="sm"
              />
            </Grid>
            <Grid item>
              <SoftBox height="100%" mt={0.5} lineHeight={1}>
                <SoftTypography variant="h5" fontWeight="medium">
                  {lieferanten?.firma_name}
                </SoftTypography>
                <SoftTypography variant="button" color="text" fontWeight="medium">
                  {lieferanten?.lieferanten_nummer}
                </SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
        </Card>
      </SoftBox>

      <div className="col-md-2 mt-4" >
        <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
          <AppBar position="static">
            <Tabs
              orientation={tabsOrientation}
              value={tabValue}
              onChange={handleSetTabValue}
              sx={{ background: "transparent" }}

            >
              <Tab style={{ fontSize: "11px", fontWeight: "bold" }} label={t("OVERVIEW")} icon={<Cube />} />
              <Tab style={{ fontSize: "11px", fontWeight: "bold" }} label={t("Invoices")} icon={<Settings />} />
            </Tabs>
          </AppBar>
        </Grid>
      </div>
      <TabPanel value={tabValue} index={0}>
        <div className='row' >
          <div className='col-md-8 col-lg-8' >
            <div className="card w-100" >
              <div className="card-body pb-4">
                <h6 className=' fw-normal' >Kunde</h6>
                <div className='d-flex align-content-center' >
                  <SoftTypography className="w-15" variant="button" fontWeight="medium">{t('Company Name')}:</SoftTypography>
                  <SoftTypography variant="small" color="text" fontSize="0.9rem">{lieferanten?.firma_name}</SoftTypography>
                </div>
                <div className='d-flex align-content-center' >
                  <SoftTypography className="w-15" variant="button" fontWeight="medium">{t('Phone')}:</SoftTypography>
                  <SoftTypography variant="small" color="text" fontSize="0.9rem">{lieferanten?.telephone}</SoftTypography>
                </div>
                <div className='d-flex align-content-center' >
                  <SoftTypography className="w-15" variant="button" fontWeight="medium">{t('E-Mail')}:</SoftTypography>
                  <SoftTypography variant="small" color="text" fontSize="0.9rem">{lieferanten?.email}</SoftTypography>
                </div>
                <div className='d-flex align-content-center'>
                  <SoftTypography className="w-15" variant="button" fontWeight="medium">{t('Address')}:</SoftTypography>
                  <SoftTypography variant="small" color="text" fontSize="0.9rem">{(lieferanten?.street && lieferanten?.street !== 'null' ? lieferanten?.street : '') + (lieferanten?.plz && lieferanten?.plz !== 'null' ? ', ' + lieferanten?.plz : '') + (lieferanten?.ort && lieferanten?.ort !== 'null' ? ', ' + lieferanten?.ort : '')}</SoftTypography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TabPanel>

      <TabPanel value={tabValue} index={1} >
        <div className="row">
          <div className="col-12">
            <div className="card mb-4">
              <div className="card-header pb-0"></div>
              <div className="card-body px-0 pt-0 pb-2">
                <InvoiceTable lieferantenId={lieferanten?.id} filterOptions={false}/>
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
    </>
  )
}

export default LieferantenDetails