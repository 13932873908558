import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch";
import flatpickr from "flatpickr";
import SoftSnackbar from "components/SoftSnackbar";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";

function ProjectPost(props) {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refesh3, setRefesh3] = useState(0);
    const [successSB, setSuccessSB] = useState(false);
    const [file, setFile] = useState();
    const [posts, setPosts] = useState([]);
    const [data, setData] = useState([]);
    const [remove, setRemove] = useState(false);
    const [errorSB, setErrorSB] = useState(false);

    const closeErrorSB = () => setErrorSB(false);

    //file upload
    function handleChange(e) {
        setFile(URL.createObjectURL(e.target.files[0]));
        setRemove(true);
    }

    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title={t('Success')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={() => setSuccessSB(false)}
            close={() => setSuccessSB(false)}
            bgSuccess
        />
    );

    const renderErrorSB = (
        <SoftSnackbar
            color="error"
            icon="info"
            title={t('Nachricht, Notiz oder Info dürfen nicht leer sein')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={errorSB}
            onClose={() => closeErrorSB(false)}
            close={() => closeErrorSB(false)}
            bgSuccess
        />
    );

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const [formattedPosts, setFormattedPosts] = useState([]);
    useEffect(() => {
        let previousSide = "justify-content-start"; // Start with the left side
        let lastUserId = data?.user?.id;
        data.side = previousSide;

        //console.log('lastUserId')
        //console.log(lastUserId)

        const newPosts = posts.slice().reverse().map((post, key) => {
            // Check if the current item's user_id matches the last user_id
            if (post.user_id != lastUserId) {
                // Toggle the side if it's a different user_id
                //console.log('post.user_id: '+post.user_id + 'lastUserId: '+lastUserId)
                previousSide = previousSide === "justify-content-start" ? "justify-content-end" : "justify-content-start";
            }

            // Update the lastUserId for the next iteration
            lastUserId = post.user_id;

            // Add the side information to the item
            return {
                ...post,
                side: previousSide
            };
        });

        

        setData(data);
        setFormattedPosts(newPosts);
    }, [posts, data?.user?.id]);


    //Edit Data load
    useEffect(() => {
        if(!props?.id) return;

        setValue('project_id', props.id);
        callFetch("get-project-posts/" + props.id, "GET", []).then((res) => {
            setData(res.project);
            setPosts(res.project_posts);
        });
    }, [props.id, refresh]);


    //Form Submit
    const onSubmit = (formData) => {
        setSaving(true);
        if (formData.description_post == null || formData.description_post.length < 2) {
            setErrorSB(true);
            setSaving(false);
            return false;
        }

        callFetch("store-project-post", "POST", formData, setError).then((res) => {
            setValue('description_post', '');
            setValue('attachment', '');
            setPosts(res.project_posts);
            setSuccessSB(true);
            setRefresh(refresh + 1);
            setFile('');
            setRemove(false);
            setSaving(false);
        });
    };

    return <>

        {props?.create !== false ? (
            <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <textarea {...register('description_post')} className="post-textarea" style={{ width: '100%', height: '180px', backgroundColor: '#FAFAFA', borderRadius: '10px', padding: '10px', fontSize: '0.875rem' }} placeholder={t("Ihre Nachricht, Notiz oder Info...")}></textarea>
                    <div className="d-flex mt-2 justify-content-end">
                        <div className="d-flex justify-content-start" style={{ position: 'relative' }}>
                            {remove ? <><img src={file} style={{ height: '38px', width: '100%', borderRadius: '5px' }} alt="" />  <span title="Remove Attachment" style={{ fontSize: '12px', right: '-8px', top: '-8px', position: 'absolute', zIndex: '999' }}><i style={{ background: '#fff', padding: '3px', borderRadius: '8px', color: 'red', cursor: 'pointer' }} onClick={() => { setRemove(false); setFile(''); setValue('attachment', '') }} className="fa fa-remove" aria-hidden="true"></i></span></> : ''}
                        </div>
                        <div className="d-flex justify-content-start">
                            <div className="choose_file choose_file_p" style={{ marginRight: '10px' }}>
                                <span title="Attach File" style={{ fontSize: '22px' }}><i className="fa fa-paperclip" style={{ cursor: 'pointer' }} aria-hidden="true"></i></span>
                                <input
                                    {...register('attachment')}
                                    id="fileUpload"
                                    type="file"
                                    onChange={handleChange}
                                    style={{
                                        width: '40px',
                                        height: '40px'
                                    }}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-start">
                            {saving ? <button className="btn btn-secondary" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button> : <button className="btn btn-dark">
                                {t('Notiz Speichern')}
                            </button>}

                        </div>
                    </div>
                </form>
                <hr />
            </>
        ) : ''}

        <p style={{ color: '#2D3748', fontWeight: '500' }}>Notizen</p>
        <div className="chat-container">
            <div className="chat-box">
                <div className="messages">
                    {formattedPosts && formattedPosts.slice().reverse().map((post, key) => (
                        <div className={`row mb-3 ${post.side}`} key={key}>
                            <div className="col-auto-custom">
                                <div className="card">
                                    <div className="card-header">
                                        <p style={{ fontSize: '0.875rem', color: '#344767' }}>Notiz erstellt von <strong>{post?.user?.name}</strong></p>
                                    </div>
                                    <div className="card-body py-2 px-3">
                                        <p className="mb-1" style={{ fontSize: '0.875rem', lineHeight: '1.3em', color: '#344767' }}>{post.description}</p>
                                        {post.attachment && post.attachment !== null ? (
                                            <img
                                                src={`${Cookies.get('backend_url')}storage/project_post/${post.attachment}`}
                                                alt=""
                                                style={{
                                                    height: 'auto',
                                                    borderRadius: '15px',
                                                    objectFit: 'cover',
                                                    width: '100%',
                                                    maxWidth: '244px',
                                                    marginTop: '5px'
                                                }}
                                            />
                                        ) : null}
                                        <div className="d-flex align-items-center text-sm opacity-6">
                                            <i className="ni ni-check-bold text-sm me-1"></i>
                                            <small>{dateFormat(post?.created_at, "dd.mm.yyyy, HH:MM")}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    {data?.description ? (
                        <div className={`row mb-3 ${data.side}`}>
                            <div className="col-auto-custom">
                                <div className="card">
                                    <div className="card-header">
                                        <p style={{ fontSize: '0.875rem', color: '#344767' }}>Notiz erstellt von <strong>{data?.user?.name}</strong></p>
                                    </div>
                                    <div className="card-body py-2 px-3">
                                        <p className="mb-1" style={{ fontSize: '0.875rem', lineHeight: '1.3em', color: '#344767' }}>{data?.description}</p>
                                        <div className="d-flex align-items-center text-sm opacity-6">
                                            <i className="ni ni-check-bold text-sm me-1"></i>
                                            <small>{dateFormat(data?.created_at, "dd.mm.yyyy, HH:MM")}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : ''}

                    {posts?.length || data?.description ? (
                        <></>
                    ) : <p className="text-center" style={{ lineHeight: '300px' }}>{t('Keine Nachrichten vorhanden')}</p>}
                </div>
            </div>
        </div>

        <button className="d-none" id="callcenterRefesh3" onClick={() => { setRefesh3(refesh3 + 1); }}>refesh3</button>
        {renderErrorSB}
        {renderSuccessSB}
    </>;
}

export default ProjectPost;
