import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "helpers/callFetch";
import Moment from 'react-moment';

import dateFormat from "dateformat";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";


const Activitys = (props) => {

    const [data, setData] = useState([]);

    useEffect(() => {
        if (!props?.projectId) return;

        callFetch("activity?project_id=" + props?.projectId, "GET", []).then((res) => {
            console.log(res?.data);
            setData(res?.data);
        });

    }, [props?.projectId]);


    const activityTitelGenerate = (activity) => {

        const replaceTypeWithOperation = {
            update_quation: 'aktualisiert angebot ' + activity?.description,
            status: 'aktualisiert '+activity?.related+' status ' + activity?.related_id,
            create: 'erstellt '+activity?.related+' ' + activity?.related_id,
            update: 'aktualisiert '+activity?.related+' ' + activity?.related_id,
            resale_status: 'aktualisiert '+activity?.related+' resale status ' + activity?.related_id,
            dc_approved: 'DC Approved',
            ac_approve: 'AC Approved',
            reclamation_dc_approved: 'reclamation dc approved '+ activity?.related_id,
            comisioning_status: 'aktualisiert '+activity?.related+' comisioning status ' + activity?.related_id,
            ac_reklmation_abnahme_create: 'erstellt AC reklmation abnahme ' + activity?.related_id,
            ac_reklmation_abnahme_update: 'aktualisiert AC reklmation abnahme ' + activity?.related_id,
            ac_abnahme_create: 'erstellt AC abnahme ',
            dc_abnahme_create: 'erstellt DC abnahme ',
            dc_reklmation_abnahme_create: 'erstellt DC reklmation abnahme ' + activity?.related_id,
            dc_reklmation_abnahme_update: 'aktualisiert DC reklmation abnahme ' + activity?.related_id,
            dc_abnahme_update: 'aktualisiert DC abnahme ',
            ac_beschaffung_approve: 'AC Beschaffung Fregegeben',
            dc_beschaffung_approve: 'DC Beschaffung Fregegeben',
        }


        return (
            <>{replaceTypeWithOperation[activity?.type] ? replaceTypeWithOperation[activity?.type] : ''}</>
        );
    }


    return (
        <>
            <ul class="me-sm-n4" style={{ width: '100%', padding: '0px' }}>
                {data.map((activity, key) => (
                    <li class="pt-3 pb-3" style={{ borderBottom: '1px solid #EFEFEF' }}>
                        <a class="border-radius-md" href="javascript:;">
                            <div class="d-flex py-1">
                                <div class="my-auto">
                                    <img src={'/assets/img/placeholder.png'} style={{ border: '0.125rem solid rgb(4, 75, 178)', borderRadius: '50%' }} class="avatar avatar-sm me-3 " alt="logo spotify" />
                                </div>
                                <div class="d-flex flex-column justify-content-center">
                                    <h6 class="text-sm font-weight-normal mb-1">
                                        <span class="font-weight-bold">{activity?.user?.name}</span> {activityTitelGenerate(activity)}
                                    </h6>
                                    <p class="text-xs text-secondary mb-0">
                                        <i class="fa fa-clock me-1" aria-hidden="true"></i> {dateFormat(activity?.created_at, "dd.mm.yyyy HH:MM")}
                                    </p>
                                </div>
                            </div>
                        </a>
                    </li>
                ))}
            </ul>
        </>
    )
}

export default Activitys