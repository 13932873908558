import { React, useEffect, useState, useRef } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import SoftBadge from "components/SoftBadge";
import Tooltip from "@mui/material/Tooltip";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import Divider from "@mui/material/Divider";
import { Grid, Menu, MenuItem } from "@mui/material";
import Select from 'react-select';
import SoftTypography from "components/SoftTypography";
import Icon from "@mui/material/Icon";
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";
import { useSoftUIController, setOpenConfigurator } from "context";
import Swal from "sweetalert2";
import Flatpickr from "react-flatpickr";
import TicketShow from 'pages/ticket/Show';

function IndexTable(props) {
    const { t } = useTranslation();
    const [tabledata, setTableData] = useState([]);
    const [action, setAction] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
    const [ready, setReady] = useState(false)
    const [id, setId] = useState(0);
    const [projectId, setProjectId] = useState(0);
    const [editId, setEditId] = useState(0);
    const [controller, dispatch] = useSoftUIController();
    const { openConfigurator } = controller;
    const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
    const fp = useRef(null);
    const fpt = useRef(null);
    const [searchData, setSearchData] = useState([])
    const [projectNumbers, setProjectNumbers] = useState([])
    const [kundenNames, setKundenNames] = useState([])
    const [orts, setOrts] = useState([])
    const [menuNumber, setMenuNumber] = useState(false);
    const [menu, setMenu] = useState(false);
    const [menuOrt, setMenuOrt] = useState(false);

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [startDateTerm, setStartDateTerm] = useState();
    const [endDateTerm, setEndDateTerm] = useState();
    var typingTimer;
    const [loading, setLoading] = useState(false);
    const [searchKey, setSearchKey] = useState("");
    const [vorname_name, setVornameName] = useState("");
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [vkb_name, setVKBName] = useState("");
    const [ort, setOrt] = useState("");
    const [project_nr, setProjectNr] = useState("");
    const [filterKeys, setFilterKeys] = useState({});
    const [openMenu, setOpenMenu] = useState(null);

    const [ticketNumbers, setTicketNumbers] = useState([]);
    const [menuTicketNumber, setMenuTicketNumber] = useState([]);
    const [ticketNr, setTicketNr] = useState([]);
    const [status, setStatus] = useState();

    const [viewTicket, setViewTicket] = useState({});

    const handleChangeAction = (action) => {
        setAction(action);
    }

    const showAlert = () => {
        const newSwal = Swal.mixin({
            customClass: {
                confirmButton: "button button-success",
                cancelButton: "button button-error",
            },
            buttonsStyling: false,
        });

        newSwal
            .fire({
                title: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    var ids = [];
                    selectedRows.map((row) => {
                        ids.push(row.id);
                    })

                    var formData = new FormData;
                    formData.ids = ids;

                    callFetch("delete-tickets", "POST", formData).then((res) => {
                        setRefresh(refresh + 1);
                    });

                    Swal.fire("Deleted!", "Your file has been deleted.", "success");
                }
            });
    };

    const handleSelectedRowsApply = () => {
        showAlert();
    }

    const [selectedRows, setSelectedRows] = useState(false);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    // Toggle the state so React Data Table changes to clearSelectedRows are triggered
    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }

    // Use the useEffect hook to change the button state for the sidenav type based on window size.
    useEffect(() => {
        setProjectNr(props.projectId);
        setOpenConfigurator(dispatch, false);
    }, []);

    const handleDateChange = (selectedDate) => {
        if (Object.keys(selectedDate).length <= 0) {
            setStartDate('undefined');
        } else {
            setStartDate(dateFormat(selectedDate, "yyyy-mm-dd"));
        }
    };

    const handleDateChangeTerm = (selectedDate) => {

        if (Object.keys(selectedDate).length <= 0) {
            setEndDate('undefined');
        } else {
            setEndDate(dateFormat(selectedDate, "yyyy-mm-dd"));
        }

    };
    const handleClearButton = () => {
        document.getElementById('projectidInput').value = '';
        document.getElementById('ticketInput').value = '';
        document.getElementById('kundenInput').value = '';
        fp.current.flatpickr.clear();
        fpt.current.flatpickr.clear();
    }

    const tableHeadings = [
        {
            name: t('Ticket'),
            selector: row => (
                <>
                    {props?.viewPopup == true ? (
                        <>
                            <a
                                data-bs-toggle="modal"
                                data-bs-target="#TicketViewInList"
                                style={{ cursor: 'pointer', color: '#005498' }}
                                onClick={() => {
                                    setViewTicket({
                                        id: row?.id,
                                        project_id: row?.project_id
                                    })
                                }}
                            >T-{row.id}</a>
                        </>
                    ) : <NavLink to={'/ticket/' + row.id} className={"text-primary font-weight-bold"}>T-{row.id}</NavLink>}
                </>

            ),
            sortable: true,
        },
        {
            name: t('Beschreibung'),
            width: '180px',
            sortable: true,
            cell: row => (
                <>
                    {row.title}
                </>
            )
        },
        {
            name: t('Users'),
            width: '180px',
            sortable: true,
            cell: row => (
                <>
                    <SoftBox display="flex" alignItems="center">
                        <SoftBox mt={0.5} pl={1}>
                            <SoftBox className="ticket-avator" display="flex">

                                {row?.employees && JSON.parse(row?.employees).map((employee) => (
                                    <Tooltip title={employee?.user?.name} placement="top">
                                        <SoftAvatar src={employee?.user?.photo ? Cookies.get('backend_url') + 'storage/avatar/' + employee?.user?.photo : '/assets/img/placeholder.png'} alt="" size="xs" sx={{
                                            border: ({ borders: { borderWidth }, palette: { white } }) =>
                                                `${borderWidth[2]} solid ${white.main}`,
                                            cursor: "pointer",
                                            position: "relative",
                                            ml: -1.5,

                                            "&:hover, &:focus": {
                                                zIndex: "10",
                                            },
                                        }} style={{ height: 'auto !important' }} />
                                    </Tooltip>
                                ))}
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>
                </>
            )
        },
        {
            name: t('Projekt'),
            sortable: true,
            selector: row => <NavLink to={'/projects/' + row.project_id} className="text-primary">{row.project_id}</NavLink>
        },
        {
            name: t('Client Name'),
            sortable: true,
            selector: row => row?.project?.vorname + ' ' + (row?.project?.name ? row?.project?.name : '')
        },
        {
            name: t('Start Date'),
            sortable: true,
            selector: row => dateFormat(row?.start_date, "dd.mm.yyyy"),
        },
        {
            name: t('End Date'),
            sortable: true,
            selector: row => dateFormat(row?.end_date, "dd.mm.yyyy"),
        },
        {
            name: t('Status'),
            sortable: true,
            selector: row => (row?.status == 1 ? <SoftBadge variant="contained" color="success" size="xs" badgeContent={t('Erledigt')} container /> : <SoftBadge variant="contained" color="error" size="xs" badgeContent={t('Open')} container />)
        },
        {
            name: t('Department'),
            sortable: true,
            selector: row => row.department
        }
    ];

    useEffect(() => {
        if (props?.ready) {
            setReady(true);
        }
    }, [props?.ready]);

    useEffect(() => {
        if (!ready) return;

        document.getElementsByClassName('flatpickr-input')[0].setAttribute('placeholder', 'Select Start Date');
        document.getElementsByClassName('flatpickr-input')[1].setAttribute('placeholder', 'Select End Date');
        var parameters = pageNumber + '&project_nr=' + project_nr;
        callFetch("ticket-service?page=" + parameters, "GET", []).then((res) => {
            setTableData(res.data);
        });
    }, [pageNumber, ready, refresh, props?.refresh, props?.projectId, project_nr]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {pageItems.map((page) => {
                            const className =
                                page === currentPage ? "page-item active" : "page-item";

                            return (
                                <li key={page} className={className}>
                                    <button
                                        className="page-link"
                                        onClick={handlePageNumber}
                                        value={page}
                                    >
                                        {page}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return <> <DataTable
        columns={tableHeadings}
        data={tabledata?.data}
        noDataComponent={t('')}
        className='data-table'
        pagination={props?.pagination == false ? false : true}
        highlightOnHover
        paginationComponentOptions={{ noRowsPerPage: true }}
        paginationServer
        selectableRows
        onSelectedRowsChange={handleChange}
        clearSelectedRows={toggledClearRows}
        paginationTotalRows={tabledata?.total}
        onChangePage={handlePageChange}
        paginationComponent={BootyPagination}
        subHeader={props?.subHeader == false ? false : true}
        subHeaderComponent={
            <>
                {selectedRows?.length >= 1 &&

                    <div style={{ position: 'absolute', left: '15px', width: '250px', marginTop: '15px' }}>
                        <div className='row'>
                            <div className='col-sm-8'>
                                <select className='form-control' onChange={(e) => handleChangeAction(e.target.value)}>
                                    <option value="delete">Löschen</option>
                                </select>
                            </div>
                            <div className='col-sm-4'>
                                <buttn className="btn btn-outline-dark" onClick={() => handleSelectedRowsApply()}>Apply</buttn>
                            </div>
                        </div>
                    </div>

                }
            </>
        }
    />

        {props?.viewPopup === true ? (
            <div
                className="modal fade"
                id="TicketViewInList"
                tabindex="-1"
                role="dialog"
                aria-labelledby="TicketViewInListLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl p-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="btn-close text-dark"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body p-0">
                            <TicketShow viewTicket={viewTicket} fullwidth={true} editButton={false} />
                        </div>
                    </div>
                </div>
            </div>
        ) : ''}


        <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
            <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="baseline"
                pt={3}
                pb={0.8}
                px={3}
            >
                <SoftBox>
                    <SoftTypography variant="h5">Filters</SoftTypography>
                </SoftBox>

                <Icon
                    sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                        fontSize: `${size.md} !important`,
                        fontWeight: `${fontWeightBold} !important`,
                        stroke: dark.main,
                        strokeWidth: "2px",
                        cursor: "pointer",
                        mt: 2,
                    })}
                    onClick={handleCloseConfigurator}
                >
                    close
                </Icon>
            </SoftBox>

            <Divider />

            <SoftBox pt={1.25} pb={3} px={3}>
                <label>{t('Ticket')} Nr</label>
                <div className='position-relative'>
                    <input
                        id="ticketInput"
                        type="text"
                        placeholder={t('Ticket') + ' Nr'}
                        className='form-control mb-2'
                        defaultValue={ticketNr}

                        onKeyUp={(e) => {
                            clearTimeout(typingTimer);
                            typingTimer = setTimeout(() => {
                                setTableData([]);
                                setTicketNr(e.target.value);
                            }, 1500);
                        }}
                        onKeyDown={(e) => {
                            clearTimeout(typingTimer);
                        }}
                    />
                    {
                        ticketNumbers?.length >= 0 && <div className="card position-absolute p-0 m-0 me-10 top-100 w-100 rounded-0 mt-1" style={{ zIndex: '999' }}>
                            {
                                menuTicketNumber && <div className="card-body p-0 m-0" >
                                    <ul className="p-1 m-0">
                                        {
                                            ticketNumbers.map((items, index) => (
                                                <MenuItem style={{ text: "black", hover: { color: 'none' } }} key={index}>
                                                    {
                                                        items?.id ? (
                                                            <NavLink
                                                                style={{ color: "#344767" }}
                                                                to={''}
                                                                onClick={() => {
                                                                    setTicketNumbers([]);
                                                                    document.getElementById('ticketInput').value = items.id;
                                                                    setTicketNr(items.id);
                                                                }}
                                                            >
                                                                {items?.id} <br />
                                                            </NavLink>
                                                        ) : ''
                                                    }
                                                </MenuItem>
                                            ))
                                        }
                                    </ul>
                                </div>
                            }
                        </div>
                    }
                </div>

                <label>Project Nr</label>
                <div className='position-relative'>
                    <input
                        id="projectidInput"
                        type="text"
                        placeholder={t("Projekt Nr") + '...'}
                        className='form-control mb-2'
                        defaultValue={project_nr}

                        onKeyUp={(e) => {
                            clearTimeout(typingTimer);
                            typingTimer = setTimeout(() => {
                                setTableData([]);
                                setProjectNr(e.target.value);
                            }, 1500);
                        }}
                        onKeyDown={(e) => {
                            clearTimeout(typingTimer);
                        }}
                    />
                    {
                        projectNumbers?.length >= 0 && <div className="card position-absolute p-0 m-0 me-10 top-100 w-100 rounded-0 mt-1" style={{ zIndex: '999' }}>
                            {
                                menuNumber && <div className="card-body p-0 m-0" >
                                    <ul className="p-1 m-0">
                                        {
                                            projectNumbers.map((items, index) => (
                                                <MenuItem style={{ text: "black", hover: { color: 'none' } }} key={index}>
                                                    {
                                                        items?.project_id ? (
                                                            <NavLink
                                                                style={{ color: "#344767" }}
                                                                to={''}
                                                                onClick={() => {
                                                                    setProjectNumbers([]);
                                                                    document.getElementById('projectidInput').value = items.project_id;
                                                                    setProjectNr(items.project_id);
                                                                }}
                                                            >
                                                                {items?.project_id} <br />
                                                            </NavLink>
                                                        ) : ''
                                                    }
                                                </MenuItem>
                                            ))
                                        }
                                    </ul>
                                </div>
                            }
                        </div>
                    }
                </div>

                <label>{t("Kundenname")}</label>
                <div className='position-relative'>
                    <input
                        id='kundenInput'
                        type="text"
                        placeholder={t("Kundenname") + '...'}
                        className='form-control mb-2'
                        defaultValue={vorname_name}
                        /*
                        onChange={(e)=> {
                            setSearchKey(e.target.value);
                        }}
                        */
                        onKeyUp={(e) => {
                            clearTimeout(typingTimer);
                            typingTimer = setTimeout(() => {
                                setTableData([]);
                                setVornameName(e.target.value);
                            }, 1500);
                        }}
                        onKeyDown={(e) => {
                            clearTimeout(typingTimer);
                        }}
                    />
                    {
                        kundenNames?.length >= 0 && <div className="card position-absolute p-0 m-0 me-10 top-100 w-100 rounded-0 mt-1" style={{ zIndex: '999' }}>
                            {
                                menu && <div className="card-body p-0 m-0" >
                                    <ul className="p-1 m-0">
                                        {
                                            kundenNames.map((items, index) => (
                                                <MenuItem style={{ text: "black", hover: { color: 'none' } }} key={index}>
                                                    {
                                                        items?.id ? (
                                                            <NavLink
                                                                style={{ color: "#344767" }}
                                                                to={''}
                                                                onClick={() => {
                                                                    setKundenNames([]);
                                                                    setVornameName(items.vorname + ' ' + items.name);
                                                                    document.getElementById('kundenInput').value = items.vorname + ' ' + items.name;
                                                                }}
                                                            >
                                                                {items.vorname + ' ' + items.name} <br />
                                                            </NavLink>
                                                        ) : ''
                                                    }
                                                </MenuItem>
                                            ))
                                        }
                                    </ul>
                                </div>
                            }
                        </div>
                    }
                </div>

                <label>{t('Start Date')}</label>
                <Flatpickr
                    ref={fp}
                    options={{
                        dateFormat: 'Y-m-d',
                    }}
                    onChange={handleDateChange}
                />
                <label>{t('End Date')}</label>
                <Flatpickr
                    ref={fpt}
                    options={{
                        dateFormat: 'Y-m-d',
                    }}
                    onChange={handleDateChangeTerm}
                />



                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Status
                    </SoftTypography>
                </SoftBox>
                <select onChange={(e) => setStatus(e.target.value)}
                    className="form-control"
                >
                    <option value={''}>{t('Select')}</option>
                    <option value={'Erledigt'}>{t('Erledigt')}</option>
                    <option value={'Open'}>{t('Open')}</option>
                </select>
                <button type='button' className='btn btn-outline-dark mt-4' onClick={() => handleClearButton()}>Clear</button>
            </SoftBox>
        </ConfiguratorRoot>
    </>
}

export default IndexTable;
