import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import SoftBadge from "components/SoftBadge";
import SoftBadgeDot from "components/SoftBadgeDot";
import { NumericFormat } from 'react-number-format';
import SoftTypography from "components/SoftTypography";
import { Grid, Menu } from "@mui/material";
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
//import Timeline from './Timeline';

function IndexTableBundle(props) {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [deleteButton, setDeleteButton] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);


    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        callFetch("bundle/create", "GET", []).then((res) => {
            setData(res.data);
        });
    }, []);

    useEffect(() => {
        setDeleteButton(false);
        if (getValues('selectedrow')) {
            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                if (selectedrow_value) {
                    setDeleteButton(true);
                }
            }
        }

    }, [refresh2]);

    const deleteSelectedItem = () => {
        setDeleting(true);
        var selectedValues = [];
        for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
            if (selectedrow_value) {
                selectedValues.push(selectedrow_key);
            }
        }

        let formData = {
            ids: JSON.stringify(selectedValues),
            type: props.type,
        };

        callFetch("import/delete", "POST", formData, setError).then((res) => {
            if (res.message == 'success') {
                setDeleting(false);
                setRefresh(refresh + 1);
                setRefresh2(refresh + 1);
            }
        })
    }

    const onSubmitSave = () => {
        setSaving(true);
        reset({});

        setTimeout(() => {
            var formData = {
                filename: props.filename,
            }
            callFetch("import/" + props.type + "/save", "POST", formData, setError).then((res) => {

                if (res.message == 'success') {
                    setRefresh(refresh + 1);
                    setRefresh2(refresh + 1);
                    document.getElementById('outputModal-button-close').click();
                    setSaving(false);
                    window.location.reload();
                }
            });
        }, 1000)
    }


    const onSubmit = (formData) => {
        onSubmitSave();
    };

    const updateFileData = (formData) => {
        formData.type = props.type;
        setLoading(true);
        callFetch("import/update", "POST", formData, setError).then((res) => {
            if (res.message == 'success') {
                setLoading(false);
                setRefresh(refresh + 1);
            }
        });
    }

    const tableHeadings = [
        {
            //name: t('Project Nr.'),
            name: t(''),
            width: '20px',
            cell: row => (
                <>
                    <input
                        {...register("selectedrow[" + row.id + "]", {
                            checked: true / false,
                            onChange: (e) => {
                                setRefresh2(refresh2 + 1);
                            }
                        })}
                        //checked={getValues("selectedrow["+row.id+"]") == row.id ? true : false }
                        type="checkbox" />
                    &nbsp;
                    {/*'# '+row.id*/}
                </>),
            sortable: true,
        },
        {
            name: t('Bundle Group'),
            width: '300px',
            cell: row => (<>
                <input
                    className="form-control"
                    {...register("bundle_group_name[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                bundle_group_name: e.target.value
                            });
                        }
                    })}
                    defaultValue={row.bundle_group_name}
                    required
                    type="text" />
            </>),
        },
        {
            name: t('Bundle Name'),
            // width: '450px',
            selector: row => (<>
                {row?.bundles ? JSON.parse(row?.bundles).map((bundle, i) => (
                    <input
                        className="form-control"
                        {...register("bundle_name[" + row.id + "_" + i + "]", {
                            required: true,
                            onBlur: (e) => {
                                var updateBundles = JSON.parse(row?.bundles);
                                if (updateBundles[i].name != e.target.value) {
                                    updateBundles[i].name = e.target.value;
                                    //console.log(updateBundles)
                                    updateFileData({
                                        id: row.id,
                                        bundles: JSON.stringify(updateBundles)
                                    });
                                }
                            }
                        })}
                        defaultValue={bundle.name}
                        required
                        type="text" />
                )) : ''}
            </>),
            sortable: false,
        },
        {
            name: t('Speicher'),
            // width: '450px',
            selector: row => (<>
                {row?.bundles ? JSON.parse(row?.bundles).map((bundle, i) => (
                    <>
                        {bundle?.items ? bundle?.items.map((item, j) => (
                            <>
                                {item?.type == 'speicher' ? (
                                    <select
                                        type="text"
                                        className="form-control"
                                        placeholder={t('Select Speicher')}
                                        defaultValue={item.product}
                                        onChange={(e) => {
                                            var updateBundles = JSON.parse(row?.bundles);
                                            //console.log(updateBundles[i].items[j].product)
                                            if (updateBundles[i].items[j].product != e.target.value) {
                                                updateBundles[i].items[j].product = e.target.value;
                                                //console.log(updateBundles)
                                                updateFileData({
                                                    id: row.id,
                                                    bundles: JSON.stringify(updateBundles)
                                                });
                                            }
                                        }}
                                    >
                                        <option value="">--</option>
                                        {data?.speicher && data?.speicher?.map((speiche, i) => (
                                            <option key={i} value={speiche.id}>{speiche.name}</option>
                                        ))}
                                    </select>
                                ) : ''}
                            </>
                        )) : ''}

                    </>
                )) : ''}
            </>),
            sortable: false,
        },
        {
            name: t('Anzahl Speicher'),
            // width: '450px',
            selector: row => (<>
                {row?.bundles ? JSON.parse(row?.bundles).map((bundle, i) => (
                    <>
                        {bundle?.items ? bundle?.items.map((item, j) => (
                            <>
                                {item?.type == 'speicher' ? (
                                    <input
                                        className="form-control"
                                        {...register("number[" + row.id + "_" + i + "_" + j + "]", {
                                            required: false,
                                            onBlur: (e) => {
                                                var updateBundles = JSON.parse(row?.bundles);
                                                //console.log(updateBundles[i].items[j].product)
                                                if (updateBundles[i].items[j].number != e.target.value) {
                                                    updateBundles[i].items[j].number = e.target.value;
                                                    //console.log(updateBundles)
                                                    updateFileData({
                                                        id: row.id,
                                                        bundles: JSON.stringify(updateBundles)
                                                    });
                                                }
                                            }
                                        })}
                                        defaultValue={item.number}
                                        type="text" />
                                ) : ''}
                            </>
                        )) : ''}

                    </>
                )) : ''}
            </>),
            sortable: false,
        },
        {
            name: t('Module'),
            // width: '450px',
            selector: row => (<>
                {row?.bundles ? JSON.parse(row?.bundles).map((bundle, i) => (
                    <>
                        {bundle?.items ? bundle?.items.map((item, j) => (
                            <>
                                {item?.type == 'module' ? (
                                    <select
                                        type="text"
                                        className="form-control"
                                        placeholder={t('Select Module')}
                                        defaultValue={item.product}
                                        onChange={(e) => {
                                            var updateBundles = JSON.parse(row?.bundles);
                                            //console.log(updateBundles[i].items[j].product)
                                            if (updateBundles[i].items[j].product != e.target.value) {
                                                updateBundles[i].items[j].product = e.target.value;
                                                //console.log(updateBundles)
                                                updateFileData({
                                                    id: row.id,
                                                    bundles: JSON.stringify(updateBundles)
                                                });
                                            }
                                        }}
                                    >
                                        <option value="">--</option>
                                        {data?.module && data?.module?.map((modul, i) => (
                                            <option key={i} value={modul.id}>{modul.name}</option>
                                        ))}
                                    </select>
                                ) : ''}
                            </>
                        )) : ''}

                    </>
                )) : ''}
            </>),
            sortable: false,
        },
        {
            name: t('Anzahl Module'),
            // width: '450px',
            selector: row => (<>
                {row?.bundles ? JSON.parse(row?.bundles).map((bundle, i) => (
                    <>
                        {bundle?.items ? bundle?.items.map((item, j) => (
                            <>
                                {item?.type == 'module' ? (
                                    <input
                                        className="form-control"
                                        {...register("number[" + row.id + "_" + i + "_" + j + "]", {
                                            required: false,
                                            onChange: (e) => {
                                                var updateBundles = JSON.parse(row?.bundles);
                                                //console.log(updateBundles[i].items[j].product)
                                                if (updateBundles[i].items[j].number != e.target.value) {
                                                    updateBundles[i].items[j].number = e.target.value;
                                                    //console.log(updateBundles)
                                                    updateFileData({
                                                        id: row.id,
                                                        bundles: JSON.stringify(updateBundles)
                                                    });
                                                }
                                            }
                                        })}
                                        defaultValue={item.number}
                                        type="text" />
                                ) : ''}
                            </>
                        )) : ''}

                    </>
                )) : ''}
            </>),
            sortable: false,
        },
        {
            name: t('Wechselrichter'),
            // width: '450px',
            selector: row => (<>
                {row?.bundles ? JSON.parse(row?.bundles).map((bundle, i) => (
                    <>
                        {bundle?.items ? bundle?.items.map((item, j) => (
                            <>
                                {item?.type == 'wechselrichter' ? (
                                    <select
                                        type="text"
                                        className="form-control"
                                        placeholder={t('Select Wechselrichter')}
                                        defaultValue={item.product}
                                        onChange={(e) => {
                                            var updateBundles = JSON.parse(row?.bundles);
                                            //console.log(updateBundles[i].items[j].product)
                                            if (updateBundles[i].items[j].product != e.target.value) {
                                                updateBundles[i].items[j].product = e.target.value;
                                                //console.log(updateBundles)
                                                updateFileData({
                                                    id: row.id,
                                                    bundles: JSON.stringify(updateBundles)
                                                });
                                            }
                                        }}
                                    >
                                        <option value="">--</option>
                                        {data?.wechselrichter && data?.wechselrichter?.map((modul, i) => (
                                            <option key={i} value={modul.id}>{modul.name}</option>
                                        ))}
                                    </select>
                                ) : ''}
                            </>
                        )) : ''}

                    </>
                )) : ''}
            </>),
            sortable: false,
        },
        {
            name: t('Anzahl Wechselrichter'),
            // width: '450px',
            selector: row => (<>
                {row?.bundles ? JSON.parse(row?.bundles).map((bundle, i) => (
                    <>
                        {bundle?.items ? bundle?.items.map((item, j) => (
                            <>
                                {item?.type == 'wechselrichter' ? (
                                    <input
                                        className="form-control"
                                        {...register("number[" + row.id + "_" + i + "_" + j + "]", {
                                            required: false,
                                            onChange: (e) => {
                                                var updateBundles = JSON.parse(row?.bundles);
                                                //console.log(updateBundles[i].items[j].product)
                                                if (updateBundles[i].items[j].number != e.target.value) {
                                                    updateBundles[i].items[j].number = e.target.value;
                                                    //console.log(updateBundles)
                                                    updateFileData({
                                                        id: row.id,
                                                        bundles: JSON.stringify(updateBundles)
                                                    });
                                                }
                                            }
                                        })}
                                        defaultValue={item.number}
                                        type="text" />
                                ) : ''}
                            </>
                        )) : ''}

                    </>
                )) : ''}
            </>),
            sortable: false,
        },
        {
            name: t('Bundle Price'),
            // width: '450px',
            selector: row => (<>
                {row?.bundles ? JSON.parse(row?.bundles).map((bundle, i) => (
                    <input
                        className="form-control"
                        {...register("bundle_price[" + row.id + "_" + i + "]", {
                            required: false,
                            onBlur: (e) => {
                                var updateBundles = JSON.parse(row?.bundles);
                                if (updateBundles[i].price != e.target.value) {
                                    updateBundles[i].price = e.target.value;
                                    //console.log(updateBundles)
                                    updateFileData({
                                        id: row.id,
                                        bundles: JSON.stringify(updateBundles)
                                    });
                                }
                            }
                        })}
                        defaultValue={bundle.price}
                        type="text" />
                )) : ''}
            </>),
            sortable: false,
        },
    ];

    useEffect(() => {
        if (props?.filename && props?.filename != '') {
            callFetch("import-data/" + props?.filename + '/' + props?.type + "?page=" + pageNumber, "GET", []).then((res) => {
                setTableData(res.data);
                // console.log(res.data);
            });
        }

    }, [pageNumber, refresh, props.filename]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />


                {/**/}
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>

                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {pageItems.map((page) => {
                            const className =
                                page === currentPage ? "page-item active" : "page-item";

                            return (
                                <li key={page} className={className}>
                                    <button
                                        className="page-link"
                                        onClick={handlePageNumber}
                                        value={page}
                                    >
                                        {page}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>

                <div className='text-center'>
                    <button className='btn btn-danger mx-1' onClick={() => {
                        document.getElementById('outputModal-button-close').click();
                    }}>{t('Cancel')}</button>

                    {loading == false ? (
                        <>
                            {!saving && (
                                <button type="submit" className="btn btn-primary">
                                    {t(props.type + ' upload')}
                                </button>
                            )}
                            {saving && (
                                <button type="submit" className="btn btn-disabled" disabled>
                                    {t(props.type + ' upload ...')}
                                </button>
                            )}
                        </>
                    ) : (
                        <button type="submit" className="btn btn-disabled" disabled>
                            {t('Loading ...')}
                        </button>
                    )}

                </div>
            </>
        );
    };

    return <>
        <div className='px-3'>
            <div className="row">
                <div className="col-sm-1">
                    <input type="checkbox" className='mt-2' id='select_all' onChange={(e) => {
                        if (e.target.checked) {
                            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                                setValue("selectedrow[" + selectedrow_key + "]", true)
                            }
                        } else {
                            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                                setValue("selectedrow[" + selectedrow_key + "]", false)
                            }
                        }
                        setRefresh2(refresh2 + 1);
                    }} />
                    <label htmlFor="select_all">{t('Select All')}</label>
                </div>
                <div className="col-sm-3">
                    {loading == false ? (
                        <>
                            {/* <input
                                type="date"
                                className='form-control form-control-sm'
                                style={{ maxWidth: '150px' }}
                                onChange={(e) => {
                                    for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                                        if (selectedrow_value) {
                                            setValue("date[" + selectedrow_key + "]", e.target.value);
                                            updateFileData({
                                                id: selectedrow_key,
                                                date: e.target.value
                                            });
                                        }
                                    }
                                }}
                            /> */}
                        </>
                    ) : (
                        <input
                            type="date"
                            className='form-control form-control-sm'
                            style={{ maxWidth: '150px' }}
                            disabled
                        />
                    )}

                </div>
                <div className="col-sm-8">
                    {deleteButton ? (
                        <>
                            {deleting ? (
                                <button className='btn btn-danger btn-sm mb-0 ms-3 float-end' disabled>{t('Deleting ...')}</button>

                            ) : (
                                <button className='btn btn-danger btn-sm mb-0 ms-3 float-end' onClick={() => {
                                    deleteSelectedItem();
                                }}>{t('Delete')}</button>
                            )}
                        </>
                    ) : (
                        <button className='btn btn-danger btn-sm mb-0 ms-3 float-end' disabled>{t('Delete')}</button>
                    )}
                </div>
            </div>

        </div>
        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <DataTable
                columns={tableHeadings}
                data={tabledata?.data}
                noDataComponent={t('There are no records to display')}
                className='data-table'
                pagination
                highlightOnHover
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationServer
                paginationTotalRows={tabledata?.total}
                onChangePage={handlePageChange}
                paginationComponent={BootyPagination}
            />
        </form>
    </>
}

export default IndexTableBundle;
