import { useEffect, useState } from 'react';
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import callFetch from 'helpers/callFetch';
import deleteAlert from 'helpers/deleteAlert';
import SoftTypography from 'components/SoftTypography';
import { NumericFormat } from 'react-number-format';


const UserBaseCustomer = () => {
    const { t } = useTranslation();
    const params = useParams()
    const [customers, setCustomers] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [searchKey, setSearchKey] = useState("")

    const tableHeadings = [
        {
            name: t('Project Number'),
            width: '170px',
            selector: row => <NavLink to={'/projects/' + row.id} className={"text-primary font-weight-bold"}>{row.id}</NavLink>,
            sortable: true,
        },
        {
            name: t('Name Surname'),
            selector: row => <NavLink to={'/projects/' + row.id}>{row?.salse_person && row?.salse_person?.name ? row.salse_person.name : ''}</NavLink>,
        },
        {
            name: t('Date'),
            width: '120px',
            selector: row => dateFormat(row.created_at, "dd.mm.yyyy"),
        },
        {
            name: t('Status'),
            width: '250px',
            cell: row => (
                <>

                    {row?.status && row?.sales_status?.id ? (
                        <><i className={'status-' + row?.sales_status?.color ?? "bg-dark"}></i><span className={"text-dark text-xs"}>{t(row?.sales_status?.name ?? row?.status)}</span></>
                    ) : row?.termine_qoute?.sales_status?.name && row?.termine_qoute?.status && row?.termine_qoute?.status != 'null' ? (
                        <><i className={'status-' + row?.termine_qoute?.sales_status?.color ?? "bg-dark"}></i><span className={"text-dark text-xs "}>{t(row?.termine_qoute?.sales_status?.name ?? row?.termine_qoute?.status)}</span></>
                    ) : <span className="text-dark text-xs">{'--'}</span>}
                </>
            ),
        },
        {
            name: t('Price'),
            selector: row => ((
                <NumericFormat
                    value={Number(row?.quations?.gesami_netto)}
                    displayType="text"
                    thousandSeparator={"."}
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    suffix={' € '}
                />
            )),
        },
        {
            name: t('Ort'),
            selector: row => row.ort,
        },
        // {
        //     name: t('Project Status'),
        //     selector: row => <>
        //         <div onClick={handleOpenMenu} className="d-flex align-items-center cursor-pointer" style={{ color: "#0048B1", textDecoration:"underline" }}>
        //             <span onClick={()=>projectData(row.id)} className="d-flex align-items-center cursor-pointer">
        //                 <Icon>info</Icon>&nbsp; {t('AC Montage')}
        //             </span>
        //         </div>

        //     </>,

        // },
        {
            name: t('Actions'),
            cell: row => (
                <>
                    {JSON.parse(Cookies.get('permissions')).indexOf("PrU") !== -1 ||
                        JSON.parse(Cookies.get('permissions')).indexOf("PrD") !== -1 ? (
                        <div className="text-center dropstart">
                            <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v text-xs"></i>
                            </a>
                            <ul className="dropdown-menu">
                                {JSON.parse(Cookies.get('permissions')).indexOf("PrU") !== -1 ? (
                                    <>
                                        <li>
                                            <NavLink to={'/projects/' + row.id} className="dropdown-item">
                                                {t('Edit')}
                                            </NavLink>
                                        </li>
                                        <li><hr className="dropdown-divider" /></li>
                                    </>
                                ) : <></>}
                                {JSON.parse(Cookies.get('permissions')).indexOf("PrD") !== -1 ? (
                                    <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'quations', row.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                                ) : <></>}
                            </ul>
                        </div>
                    ) : <></>}
                </>
            )
        }
    ];

    useEffect(() => {
        callFetch("customers/" + params.id, "GET", []).then((res) => {
            setCustomers(res.data.base_customer);
        });
    }, [pageNumber, refresh]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    useEffect(() => {
        if (searchKey.length > 0) {
            callFetch('customer/search/' + searchKey, "GET", []).then((res) => {
                setCustomers(res.data)
            })
        } else {
            setRefresh(refresh + 1)
        }
    }, [searchKey])




    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = customers.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === customers.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {customers.from} {t('to')} {customers.to} {t('of')} {customers.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {pageItems.map((page) => {
                            const className =
                                page === currentPage ? "page-item active" : "page-item";

                            return (
                                <li key={page} className={className}>
                                    <button
                                        className="page-link"
                                        onClick={handlePageNumber}
                                        value={page}
                                    >
                                        {page}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        )
    }
    return <DataTable
        columns={tableHeadings}
        data={customers?.data}
        noDataComponent={t('There are no records to display')}
        className='data-table'
        pagination
        highlightOnHover
        paginationComponentOptions={{ noRowsPerPage: true }}
        paginationServer
        paginationTotalRows={customers?.total}
        onChangePage={handlePageChange}
        paginationComponent={BootyPagination}
    // subHeader
    // subHeaderComponent={<input type="text" placeholder={ t("Search...") } className='form-control w-sm-50 w-md-25 w-lg-15' value={searchKey} onChange={(e)=>setSearchKey(e.target.value)} />}
    />;
}

export default UserBaseCustomer