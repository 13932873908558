// src/components/MapMarker.js
import React, { useState, useCallback } from 'react';
import { GoogleMap, LoadScript, DrawingManager, Rectangle } from '@react-google-maps/api';

const containerStyle = {
  width: '800px',
  height: '600px',
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const libraries = ['drawing'];

const MapMarker = ({ apiKey }) => {
  const [rectangle, setRectangle] = useState(null);
  const [drawingManager, setDrawingManager] = useState(null);

  const onRectangleComplete = useCallback((rect) => {
    if (rectangle) {
      // If a rectangle already exists, stop drawing mode and return
      drawingManager.setDrawingMode(null);
      return;
    }

    const bounds = rect.getBounds();
    setRectangle({
      id: 1, // A static ID since we only allow one rectangle
      bounds,
      rectangle: rect,
    });

    rect.addListener('bounds_changed', () => {
      const newBounds = rect.getBounds();
      setRectangle((prev) => (prev ? { ...prev, bounds: newBounds } : null));
    });

    // Disable drawing mode after the rectangle is drawn
    drawingManager.setDrawingMode(null);
  }, [rectangle, drawingManager]);

  const handleDrawingManagerLoad = useCallback((dm) => {
    setDrawingManager(dm);
    dm.setDrawingMode('rectangle');
  }, []);

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={libraries}>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
        <DrawingManager
          onLoad={handleDrawingManagerLoad}
          onRectangleComplete={onRectangleComplete}
          options={{
            drawingControl: true,
            drawingControlOptions: {
              //position: 'TOP_CENTER',
              drawingModes: ['rectangle'],
            },
            rectangleOptions: {
              editable: true,
              draggable: true,
              fillColor: 'rgba(0, 0, 0, 0.1)',
              strokeColor: 'red',
              strokeWeight: 2,
            },
          }}
        />
        {rectangle && (
          <Rectangle
            bounds={rectangle.bounds}
            options={{
              editable: true,
              draggable: true,
              fillColor: 'rgba(0, 0, 0, 0.1)',
              strokeColor: 'red',
              strokeWeight: 2,
            }}
            onBoundsChanged={() => {
              const bounds = rectangle.rectangle.getBounds();
              setRectangle((prev) => (prev ? { ...prev, bounds } : null));
            }}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapMarker;
