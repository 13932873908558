import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import { jsonGroupData } from "pages/Article/data";
import { getValue } from "@mui/system";
import SoftSnackbar from "components/SoftSnackbar";
function CreateProduct() {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [lieferanten, setlieferanten] = useState([]);
    const [category, setCategory] = useState('');
    const [articalCategory, setArticalCategory] = useState('');
    const [errorSB, setErrorSB] = useState(false);
    const [id, setID] = useState('');

    const closeErrorSB = () => setErrorSB(false);
    const renderErrorSB = (
        <SoftSnackbar
            color="error"
            icon="error"
            title={t('The file must not be greater than 5 MB.')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={errorSB}
            onClose={() => closeErrorSB(false)}
            close={() => closeErrorSB(false)}
            bgDanger
        />
    );
    // Filter subcategories based on the selected category
    const subcategories =
        jsonGroupData?.categories
            .find((category) => category.name === articalCategory)
            ?.group || [];

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        clearErrors,
        getValues,
        formState: { errors },
    } = useForm();

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            clearErrors('file');
        }
        e.target.value = '';
    }

    const handleFileRemove = () => {
        setSelectedFile(null)
    }

    useEffect(() => {
        callFetch("product/create", "GET", [], setError).then((res) => {
            if (!res.ok) return;
            setValue('identity', res?.data?.identity)
            setValue('id', res?.data?.id)
            setID(res?.data?.id);
            setlieferanten(res?.data?.lieferanten)
        });
    }, [refresh]);

    const onSubmit = (formData) => {
        setSaving(true);
        console.log(formData);
        formData.status = (formData?.status ? 1 : 0);

        if (category === 'Dienstleistung') {
            formData.article_category = '';
            formData.article_group = '';
        }

        if (selectedFile) {
            formData.file = selectedFile;
        }

        callFetch("product", "POST", formData, setError).then((res) => {
            if (res && res.errors && res.errors.file) {
                setErrorSB(true);
            }
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to='/zusatzprodukte' /> :
        <>

            <div className="row">
                <div className="col-md-9">
                    <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <div className="card mb-4">
                            <div className="card-header pb-0">
                                <h6>{t('Zusatzprodukte')}</h6>
                            </div>
                            <div className="card-body">
                                <div className="row g-3">
                                    <div className="col-md-4">
                                        <label>{t('Produktnummber')} *</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("identity", {
                                                required: true,
                                            })}
                                            required
                                            readOnly
                                        />
                                        <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                    </div>
                                </div>

                                <div className="row g-3">
                                    <div className="col-md-4">
                                        <label>{t('Hersteller / Lieferanten')} *</label>
                                        <select
                                            className="form-control mb-4"
                                            {...register("lieferanten_id", {
                                                required: true,
                                            })}
                                            required
                                        >
                                            <option value={''}>{t("Select Lieferanten")}</option>
                                            {lieferanten.map((lief) => (
                                                <option value={lief?.id}>{lief?.lieferanten_nummer + (lief?.firma_name ? ' - ' + lief?.firma_name : '')}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">{errors.lieferanten_id && errors.lieferanten_id.message}</div>
                                    </div>

                                    <div className="col-md-4" style={{ paddingTop: '14px' }}>
                                        <div className="form-check pt-4">
                                            <label>{t('Active')}</label>
                                            <input className="form-check-input" type="checkbox" {...register("status")} />
                                        </div>
                                        <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                                    </div>
                                </div>
                                <div className="row g-3">
                                    <div className="col-md-12">
                                        <label>{t('Produktname')} *</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("name", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                    </div>

                                    <div className="col-md-3">
                                        <label>{t('DC- oder AC Produkt')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("category", {
                                                required: true,
                                                onChange: (e) => {
                                                    setCategory(e.target.value);

                                                    if (e.target.value === 'Dienstleistung') {
                                                        setValue('article_category', '');
                                                        setArticalCategory('');
                                                    }
                                                }
                                            })}
                                            required
                                        >
                                            <option value="">{t('auswählen')}</option>
                                            <option value="ac">{t('AC Produkt')}</option>
                                            <option value="dc">{t('DC Produkt')}</option>
                                            <option value="Dienstleistung">{t('Dienstleistung')}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.category && errors.category.message}</div>
                                    </div>

                                    {category !== 'Dienstleistung' ? (
                                        <>
                                            <div className="col-md-3">
                                                <label>{t('Kategorie')}</label>
                                                <select
                                                    className="form-control"
                                                    {...register('article_category', {
                                                        required: false,
                                                        onChange: (e) => {
                                                            setArticalCategory(e.target.value);
                                                        }
                                                    })}
                                                    required={false}
                                                >
                                                    <option value="">{t('----')}</option>
                                                    {
                                                        jsonGroupData && jsonGroupData?.categories?.map((category, index) => (
                                                            <option key={index} value={category?.name}>{t(category?.name)}</option>
                                                        ))
                                                    }
                                                </select>
                                                <div className="invalid-feedback">{errors.article_category && errors.article_category.message}</div>
                                            </div>

                                            <div className="col-md-3">
                                                <label>{t('Gruppe')}</label>
                                                <select className="form-control"
                                                    {...register('article_group', {
                                                        required: false,
                                                    })}
                                                    required={false}
                                                >
                                                    <option value="">{t("----")}</option>
                                                    {
                                                        subcategories && subcategories?.map((group, index) => (
                                                            <option key={index} value={group}>{t(group)}</option>
                                                        ))
                                                    }

                                                </select>
                                                <div className="invalid-feedback">{errors.article_group && errors.article_group.message}</div>
                                            </div>
                                        </>
                                    ) : ''}



                                    <div className="col-md-3">
                                        <label>{t('Einheit')} *</label>
                                        <select
                                            className="form-control"
                                            {...register('unit', { required: true })}
                                            required
                                        >
                                            <option value="">{t("----")}</option>
                                            <option value="Meter">{t("Meter")}</option>
                                            <option value="Stück">{t("Stück")}</option>
                                            <option value="Kg">{t("Kg")}</option>
                                            <option value="Liter">{t("Liter")}</option>
                                            <option value="m2">{t("m2")}</option>
                                            <option value="%">%</option>
                                            <option value="watt">Watt (W)</option>

                                        </select>
                                        <div className="invalid-feedback">{errors.unit && errors.unit.message}</div>
                                    </div>

                                    <div className="col-12 p-0 m-0"></div>

                                    <div className="col-md-3">
                                        <label>{t('Einkaufspreis in €')} *</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            step={0.01}
                                            placeholder={t('00,00 €')}
                                            {...register("einkaufs_price", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.einkaufs_price && errors.einkaufs_price.message}</div>
                                    </div>
                                    <div className="col-md-3">
                                        <label>{t('Verkaufspreis in €')} *</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            step={0.01}
                                            placeholder={t('00,00 €')}
                                            {...register("verkauf_price", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.verkauf_price && errors.verkauf_price.message}</div>
                                    </div>


                                    <div className="col-md-12">
                                        <label>{t('Description')}</label>
                                        <textarea
                                            className="form-control"
                                            rows="4"
                                            placeholder={t('')}
                                            {...register("description")}></textarea>
                                        <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                    </div>



                                    <div className="col-12 mb-4">
                                        {!saving && (
                                            <button type="submit" className="btn btn-primary">
                                                {t('Save')}
                                            </button>
                                        )}
                                        {saving && (
                                            <button type="submit" className="btn btn-disabled" disabled>
                                                {t('Saving ...')}
                                            </button>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>

                <div className="col-3">
                    <div className="card">
                        <div className="card-header">
                            <label>{t('Datenblätter etc.')}</label>
                            <input
                                type="file"
                                className="form-control"
                                onChange={(e) => { handleFileChange(e); }}
                            />
                            <div className="invalid-feedback d-block">{errors.file && errors.file.message}</div>
                        </div>
                        <div className="card-body pt-0 position-relative">
                            {selectedFile?.type.startsWith('image/') ? (
                                <>
                                    <img
                                        src={selectedFile && URL.createObjectURL(selectedFile)}
                                        alt="Selected"
                                        width="100%"
                                        height="100%"
                                        className="border-radius-md w-100 h-100"
                                    />
                                    &nbsp;
                                    <i style={{ top: "-5px" }} className="fa-solid fa-circle-xmark text-danger cursor-pointer position-absolute end-4" onClick={handleFileRemove}></i>
                                </>
                            ) : selectedFile?.type.startsWith('application/') ? (
                                <>
                                    <iframe
                                        src={URL.createObjectURL(selectedFile)}
                                        title="PDF Viewer"
                                        width="100%"
                                        height="350px" // Adjust the height as needed
                                        className="border-radius-md"
                                    ></iframe>
                                    &nbsp;
                                    <i style={{ top: "-5px" }} className="fa-solid fa-circle-xmark text-danger cursor-pointer position-absolute end-4" onClick={handleFileRemove}></i>
                                </>
                            ) : <></>}
                        </div>
                    </div>
                </div>
            </div>
        </>
}

export default CreateProduct;