import { React, useEffect, useState, useRef } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import SoftBadge from "components/SoftBadge";
import Tooltip from "@mui/material/Tooltip";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import Divider from "@mui/material/Divider";
import { Grid, Menu, MenuItem } from "@mui/material";
import Select from 'react-select';  
import SoftTypography from "components/SoftTypography";
import Icon from "@mui/material/Icon";
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot"; 
import {
    useSoftUIController,
    setOpenConfigurator,
    setTransparentSidenav,
    setMiniSidenav,
    setFixedNavbar,
    setSidenavColor,
  } from "context";
import Swal from "sweetalert2";
import Flatpickr from "react-flatpickr";
function IndexTable(props) {
    const { t } = useTranslation();
    const [tabledata, setTableData] = useState([]);
    const [action, setAction] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
    const [reclamations, setReclamations] = useState([]); 
    const [id, setId] = useState(0);
    const [projectId, setProjectId] = useState(0);
    const [editId, setEditId] = useState(0);
    const [controller, dispatch] = useSoftUIController();
    const { openConfigurator, transparentSidenav, miniSidenav, fixedNavbar, sidenavColor } =
    controller;

    const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);  

    const fp = useRef(null);
    const fpt = useRef(null);
    const [searchData,setSearchData] = useState([])
    const [projectNumbers, setProjectNumbers] = useState([])
    const [kundenNames, setKundenNames] = useState([])
    const [orts, setOrts] = useState([])
    const [menuNumber, setMenuNumber] = useState(false);
    const [menu, setMenu] = useState(false);
    const [menuOrt, setMenuOrt] = useState(false);

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState(); 
    const [startDateTerm, setStartDateTerm] = useState();
    const [endDateTerm, setEndDateTerm] = useState();
    var typingTimer;
    const [loading, setLoading] = useState(false); 
    const [searchKey, setSearchKey] = useState("");
    const [vorname_name, setVornameName] = useState("");
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [vkb_name, setVKBName] = useState("");
    const [ort, setOrt] = useState("");
    const [project_nr, setProjectNr] = useState("");
    const [filterKeys, setFilterKeys] = useState({});
    const [openMenu, setOpenMenu] = useState(null);

    const [ticketNumbers, setTicketNumbers] = useState([]);
    const [menuTicketNumber, setMenuTicketNumber] = useState([]);  
    const [ticketNr, setTicketNr] = useState([]);  
    const [status, setStatus] = useState();  

    const handleChangeAction = (action) => {
        setAction(action);
      }
     
      const showAlert = () => {
        const newSwal = Swal.mixin({
          customClass: {
            confirmButton: "button button-success",
            cancelButton: "button button-error",
          },
          buttonsStyling: false,
        });
    
        newSwal
          .fire({
            title: t('Are you sure?'),
            text: t("You won't be able to revert this!"),
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('Yes, do it!'),
            cancelButtonText: t('Cancel') 
          })
          .then((result) => {
            if (result.isConfirmed) {
                var ids = []; 
                selectedRows.map((row) => {
                    ids.push(row.id);
                })

                var formData = new FormData;
                formData.ids = ids;      
                formData.action = action;      

                callFetch("delete-tickets", "POST", formData ).then((res) => { 
                    setRefresh(refresh+1);
                }); 

                Swal.fire(t('Success!'),  t('Your action has succeeded.'), "success");
            }
          });
      };
     
      const handleSelectedRowsApply = () => {
        showAlert();
      }

    const [selectedRows, setSelectedRows] = useState(false);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    const handleChange = ({ selectedRows }) => {
        console.log(selectedRows);
        setSelectedRows(selectedRows);
    };

    // Toggle the state so React Data Table changes to clearSelectedRows are triggered
    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }

    var now = new Date(); 
    var month = Number(now.getMonth()) + 1;
    var date_day = Number(now.getDate());

    if (month <= 9) {
     month = "0" + month;
    }
    if (date_day <= 9) {
    date_day = "0" + date_day;
    }

    const current = now.getFullYear() + '-' + month + '-' + date_day;
 

    const conditionalRowStyles = [
        {
            when: row => row?.seen == 1,
            style: {
              backgroundColor: '#FBDCB3', 
              '&:hover': {
                cursor: 'pointer',
              },
            },
        },
        {
          when: row => Date.parse(current) > Date.parse(row?.end_date) && (row?.status == 0),
          style: {
            backgroundColor: '#FDE3E3', 
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
        
    ];

    const tableHeadings = [
        {
            name: t('Ticket'),
            width: '110px',
            selector: row => (
                <NavLink to={'/ticket/' + row.id} className={"text-primary font-weight-bold"}>T-{row.id}</NavLink>
            ),
            sortable: true,
        },
        {
            name: t('Beschreibung'),
            sortable: true,
            cell: row => (
                <>
                    {row.title}
                </>
            )
        },
        {
            name: t('Users'),
            width: '180px',
            sortable: true,
            cell: row => (
                <>
                    <SoftBox display="flex" alignItems="center">
                        <SoftBox mt={0.5} pl={1}>
                            <SoftBox className="ticket-avator" display="flex">

                                {row?.employees && JSON.parse(row?.employees).map((employee) => (
                                    <Tooltip title={employee?.user?.name} placement="top">
                                        <SoftAvatar src={employee?.user?.photo ? process.env.REACT_APP_STORAGE_URL + employee?.user?.photo : '/assets/img/placeholder.png'} alt="" size="xs" sx={{
                                            border: ({ borders: { borderWidth }, palette: { white } }) =>
                                                `${borderWidth[2]} solid ${white.main}`,
                                            cursor: "pointer",
                                            position: "relative",
                                            ml: -1.5,

                                            "&:hover, &:focus": {
                                                zIndex: "10",
                                            },
                                        }} style={{ height: 'auto !important' }} />
                                    </Tooltip>
                                ))}
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>
                </>
            )
        },
        {
            name: t('Projekt'),
            width: '90px',
            sortable: true,
            selector: row => <NavLink to={'/projects/' + row.project_id} className="text-primary">{row.project_id}</NavLink>
        },
        {
            name: t('Client Name'),
            sortable: true,
            selector: row => row?.project?.vorname + ' ' + (row?.project?.name ? row?.project?.name : '')
        },
        {
            name: t('Start Date'),
            width: '120px',
            sortable: true,
            selector: row => dateFormat(row?.start_date, "dd.mm.yyyy"),
        },
        {
            name: t('Deadline'),
            width: '110px',
            sortable: true,
            selector: row => dateFormat(row?.end_date, "dd.mm.yyyy"),
        }, 
    ];

    useEffect(() => { 
        var parameters = pageNumber + '&type='+props.type; 
        callFetch("ticket-tabs?page=" + parameters, "GET", []).then((res) => {
            setTableData(res.data); 
        });
    }, [pageNumber, refresh, props?.refresh, props.type]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {tabledata?.links.map((page) => {
                            //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                            const className = (page.active === true ? "page-item active" : "page-item");
                            return (
                                <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                    <button
                                        className="page-link"
                                        onClick={page.label != '...' ? handlePageNumber : ''}
                                        value={page.label}
                                        disabled={page.label == '...' ? true : false}
                                    >
                                        {page.label}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return <> <DataTable
        columns={tableHeadings}
        data={tabledata?.data}
        noDataComponent={t('There are no records to display')}
        className='data-table'
        pagination
        highlightOnHover
        paginationComponentOptions={{ noRowsPerPage: true }}
        paginationServer  
        conditionalRowStyles={conditionalRowStyles}
        clearSelectedRows={toggledClearRows}
        paginationTotalRows={tabledata?.total}
        onChangePage={handlePageChange}
        paginationComponent={BootyPagination} 
                
    />

    </>
}

export default IndexTable;
