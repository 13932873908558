import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import GoodsIndexTable from "./GoodsIndexTable";

const GoodsIndex = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="d-sm-flex justify-content-between">
                <div>
                {JSON.parse(Cookies.get('permissions')).indexOf("MC") !== -1 ? (
                    <NavLink to="/ware-houses/goods-received/create" className="btn btn-icon btn-primary">
                        {t('Wareneingänge buchen')}
                    </NavLink>
                ) : <></>}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('Wareneingänge')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <GoodsIndexTable/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
  }
export default GoodsIndex