import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import SoftAlert from "components/SoftAlert";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { NavLink } from "react-router-dom";

function ProjectFeedBack(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const [attachments, setAttachment] = useState([]);
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const removeAttachment = (id) => {
        const beforeRemove = attachments;
        const afterRemove = beforeRemove.filter(value => {
            return value.id != id;
        });

        setAttachment(afterRemove);
    }

    useEffect(() => {
        if (!props?.data?.id) return;

        setValue('id', props?.data?.id)
        setValue('description', props?.data?.description)
        setValue('attachments', props?.data?.attachments)
        setAttachment(JSON.parse(props?.data?.attachments));

    }, [params?.id, props?.data?.id, props?.data?.description, props?.data?.attachments]);


    const onSubmit2 = () => {

        var formData = getValues();
        formData.attachments = JSON.stringify(attachments);

        setSaving(true);
        callFetch("project-feedback/update", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (res?.ok) {
                setSubmitSuccess(true);
                props?.setrefresh(Math.floor(Math.random() * 100));
            }
        });
    }

    return (
        <>
            <a className="d-none" id="projectStatusWithFileUploadOpenBtnUpdate" data-bs-toggle="modal" data-bs-target="#projectStatusWithFileUploadUpdate"></a>
            <div className="modal fade" id="projectStatusWithFileUploadUpdate" tabIndex="-1" role="dialog" aria-labelledby="projectStatusWithFileUploadUpdate" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" >
                        <div className="modal-header">
                            <h5 className="modal-title" id="projectStatusWithFileUploadLabel">{t(props?.activity?.sales_status?.name)}</h5>
                            <button type="button" className="btn-close text-dark" id="projectStatusWithFileUploadClose" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            {JSON.parse(Cookies.get('permissions')).indexOf("QU") !== -1 ? (
                                <SoftAlert color={'error'} sm={{ fontWeight: '700', fontSize: '0.75rem' }}>
                                    {t('Bitte laden sie ein Bild mit Visitenkarte am Postkasten des Kunden hoch.')}
                                </SoftAlert>
                            ) : ''}

                            <SoftBox
                                display="flex"
                                flexDirection="column"
                                justifyContent="flex-end"
                                height="100%"
                            >
                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        {t('Description')}
                                    </SoftTypography>
                                </SoftBox>
                                {JSON.parse(Cookies.get('permissions')).indexOf("QU") !== -1 ? (
                                    <textarea  {...register("description", { required: true, })} className="form-control"></textarea>
                                ) : props?.data?.description}
                            </SoftBox>


                            <div className="col-sm-12 mt-3">
                                {JSON.parse(Cookies.get('permissions')).indexOf("QU") !== -1 ? (
                                    <div className="form-group">
                                        <label htmlFor="feedBack_upload_files" className="text-primary" style={{ cursor: 'pointer' }}><i className="fa-solid fa-link"></i> {t('Upload Files')}</label>
                                        <input
                                            type="file"
                                            onChange={(e) => {
                                                setLoading(true);
                                                var formData = {};
                                                for (let [key, val] of Object.entries(e.target.files)) {
                                                    formData['files[' + key + ']'] = e.target.files[key];
                                                }

                                                callFetch('attachment', "POST", formData, setError).then((res) => {
                                                    setLoading(false);
                                                    if (res.message == 'success') {
                                                        let data = attachments;
                                                        for (let [key, val] of Object.entries(res.data)) {
                                                            data[data.length] = val;
                                                        }
                                                        setAttachment(data);
                                                        setValue('attachments', JSON.stringify(data));
                                                    }
                                                });
                                            }}
                                            id="feedBack_upload_files"
                                            className="d-none"
                                            multiple
                                            accept=".jpeg,.jpg,.png,.pdf,.doc,.docx"
                                        />
                                    </div>
                                ) : ''}
                            </div>


                            <SoftBox p={0} className="order-processing">
                                <Grid container spacing={3}>
                                    {attachments && attachments.map(function (data, i) {
                                        return (
                                            <Grid item key={i} className="text-center">
                                                <div>
                                                    <a target="_blank" download href={Cookies.get('backend_url') + 'storage/attachments/' + data?.attachment}>
                                                        {['jpeg', 'jpg', 'png'].indexOf(data?.extension) !== -1 ? (
                                                            <img src={Cookies.get('backend_url') + 'storage/attachments/' + data?.attachment} />
                                                        ) : (
                                                            <div className="placeholder">{data?.extension}</div>
                                                        )}

                                                    </a>
                                                </div>
                                                {JSON.parse(Cookies.get('permissions')).indexOf("QU") !== -1 ? (
                                                    <a
                                                        onClick={() => {
                                                            removeAttachment(data?.id);
                                                        }}
                                                    >{t('Remove')}</a>
                                                ) : ''}
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </SoftBox>

                        </div>
                        {JSON.parse(Cookies.get('permissions')).indexOf("QU") !== -1 ? (
                            <div className="modal-footer">
                                {!saving && (
                                    <button type="button" onClick={(e) => { onSubmit2(); }} className="btn btn-primary mb-0" data-bs-dismiss="modal" aria-label="Close">
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="button" className="btn btn-disabled mb-0">
                                        {t('Save...')}
                                    </button>
                                )}
                            </div>
                        ) : ''}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProjectFeedBack;
