import { useMemo, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import dateFormat, { masks } from "dateformat";
import { useForm } from "react-hook-form";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftSnackbar from "components/SoftSnackbar";

// Soft UI Dashboard PRO React example components
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import { Navigate, NavLink, useNavigate, withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import OrderLineChart from "pages/dashboard/layouts/OrderLineChart";
import QuotationLineChart from "pages/dashboard/layouts/QuotationLineChart";

import EvaluationLineChart from 'pages/dashboard/layouts/EvaluationLineChart';
import ProjectStatusPieChart from 'pages/dashboard/layouts/ProjectStatusPieChart';
import ProjectStatusCards from 'pages/dashboard/layouts/ProjectStatusCards';
import TermineQouteTable from 'pages/dashboard/layouts/TermineQouteTable';
import ProjectBezahltPieChart from 'pages/dashboard/layouts/ProjectBezahltPieChart';
import ProjectTable from 'pages/dashboard/layouts/ProjectTable';
import NewAufgabe from 'pages/dashboard/layouts/NewAufgabe';
import TechnicianSalesStatusPieChartV2 from 'pages/dashboard/layouts/TechnicianSalesStatusPieChartV2';
import DashboardCalendar from 'pages/dashboard/Calendar';
import TodaysCardList from "pages/dashboard/employees-dashboard/component/TodaysCardList";

//import boards from "layouts/applications/kanban/data";
import Board from "@asseinfo/react-kanban";
import parse from "html-react-parser";
import { v4 as uuidv4 } from "uuid";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import UpdateStatusProject from "pages/dashboard/employees-dashboard/component/UpdateStatusProjects";

function ProjectOverView() {
    const { t } = useTranslation();
    const [filterData, setFilterData] = useState({
        verkaufer: 0,
        created_at: 'Current Week',
    });

    const [menu, setMenu] = useState(null);



    const [newCardForm, setNewCardForm] = useState(false);
    const [formValue, setFormValue] = useState("");
    const openNewCardForm = (event, id) => setNewCardForm(id);
    const closeNewCardForm = () => setNewCardForm(false);
    const handeSetFormValue = ({ currentTarget }) => setFormValue(currentTarget.value);


    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = () => setMenu(null);

    const renderMenu = (
        <Menu
            anchorEl={menu}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(menu)}
            onClose={closeMenu}
            keepMounted
        >
            <MenuItem onClick={() => {
                closeMenu();
                var data = filterData;
                data.created_at = 'All';
                setFilterData(data);
            }}>{t('All')}</MenuItem>
            <MenuItem onClick={() => {
                closeMenu();
                var data = filterData;
                data.created_at = 'Today';
                setFilterData(data);
            }}>{t('Today')}</MenuItem>
            <MenuItem onClick={() => {
                closeMenu();
                var data = filterData;
                data.created_at = 'Yeasterday';
                setFilterData(data);
            }}>{t('Yeasterday')}</MenuItem>
            <MenuItem onClick={() => {
                closeMenu();
                var data = filterData;
                data.created_at = 'Current Week';
                setFilterData(data);
            }}>{t('Current Week')}</MenuItem>
            <MenuItem onClick={() => {
                closeMenu();
                var data = filterData;
                data.created_at = 'Last 7 days';
                setFilterData(data);
            }}>{t('Last 7 days')}</MenuItem>
            <MenuItem onClick={() => {
                closeMenu();
                var data = filterData;
                data.created_at = 'Last 30 days';
                setFilterData(data);
            }}>{t('Last 30 days')}</MenuItem>
        </Menu>
    );

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            content="Changes saved successfully"
            dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
        />
    );

    return (
        <>
            <SoftBox display="flex" gap={3} justifyContent="flex-end" ml={2} mb={3}>
                {JSON.parse(Cookies.get('permissions')).indexOf("Export") !== -1 && false ? (
                    <SoftButton variant="outlined" color="secondary">
                        export&nbsp;&nbsp;
                        <Icon>folder</Icon>
                    </SoftButton>
                ) : <></>}

                {
                    Cookies.get("permissions").indexOf("ProBezPC") !== -1 ||
                        Cookies.get("permissions").indexOf("ProTab") !== -1 ||
                        Cookies.get("permissions").indexOf("StaCar") !== -1 ||
                        Cookies.get("permissions").indexOf("PSPCh") !== -1 ? (
                        <>
                            <SoftButton variant="gradient" color="dark" onClick={openMenu}>
                                {t(filterData?.created_at)}&nbsp;
                                <Icon>expand_more</Icon>
                            </SoftButton>
                            {renderMenu}
                        </>
                    ) : <></>}
            </SoftBox>

            {Cookies.get("permissions").indexOf("StaCar") !== -1 ? (
                <>
                    <ProjectStatusCards filterData={filterData} />
                </>
            ) : <></>}

            {Cookies.get("permissions").indexOf("SPAnalyAllD") !== -1 || Cookies.get("permissions").indexOf("SPAnaly") !== -1 ? (
                <Board
                    initialBoard={{
                        columns: [
                            {
                                id: uuidv4(),
                                title: "In review",
                                cards: [
                                    {
                                        id: uuidv4(),
                                        template: (
                                            <TechnicianSalesStatusPieChartV2
                                                title={'Today'}
                                                filterData={{
                                                    created_at: 'Today'
                                                }}
                                            />
                                        ),
                                    },
                                ],
                            },
                            {
                                id: uuidv4(),
                                title: "In review",
                                cards: [
                                    {
                                        id: uuidv4(),
                                        template: (
                                            <TechnicianSalesStatusPieChartV2
                                                title={'Week'}
                                                filterData={{
                                                    created_at: 'Current Week'
                                                }}
                                            />
                                        ),
                                    },
                                ],
                            },
                            {
                                id: uuidv4(),
                                title: "In review",
                                cards: [
                                    {
                                        id: uuidv4(),
                                        template: (
                                            <TechnicianSalesStatusPieChartV2
                                                title={'Month'}
                                                filterData={{
                                                    created_at: 'Last 30 days'
                                                }}
                                            />
                                        ),
                                    },
                                ],
                            },
                            {
                                id: uuidv4(),
                                title: "In review",
                                cards: [
                                    {
                                        id: uuidv4(),
                                        template: (
                                            <TechnicianSalesStatusPieChartV2
                                                title={'Flexible'}
                                                filterData={{
                                                    created_at: 'All'
                                                }}
                                            />
                                        ),
                                    },
                                ],
                            },
                        ],
                    }}
                    //allowAddCard
                    //allowAddColumn
                    renderColumnHeader={({ id, title }, { addCard }) => (
                        <>

                        </>
                    )}
                    renderCard={({ id, template }, { dragging }) => (
                        <>
                            <SoftBox
                                key={id}
                                dragging={dragging.toString() || undefined}
                                //dragging={false}
                                display="block"
                                width="calc(440px - 40px)"
                                //bgColor="white"
                                color="text"
                                borderRadius="md"
                                mt={2.5}
                                py={1.875}
                                pr={3}
                                lineHeight={1.5}
                                sx={{
                                    fontSize: ({ typography: { size } }) => size.md,
                                }}
                            >
                                {typeof template === "string" ? parse(template) : template}
                            </SoftBox>
                        </>
                    )}
                    //onCardNew={() => null}
                />
            ) : <></>}
            {renderSuccessSB}
        </>
    );
}

export default ProjectOverView;
