import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import DataTableOne from "pages/analyses/ProductionEvulation/DataTableOne";
import DataTableTwo from "pages/analyses/ProductionEvulation/DataTableTwo";
import BigDataTable from "pages/analyses/ProductionEvulation/BigDataTable";
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";

function ProductionEvulationIndex() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fontSize = width < 768 ? "12px" : "16px";

  return (
    <>
      <SoftBox sx={{ fontSize: fontSize }} py={0}>
        <Grid container spacing={3} marginTop={-4}>
          <Grid item xs={12} lg={12}>
            <SoftTypography color="black" fontSize="15px">
              VKB Name
            </SoftTypography>

            <SoftBox borderRadius="lg" width="15rem" bgColor="white">
              <SoftSelect
                placeholder="Selektieren"
                options={[
                  { value: "value", label: "label" },
                  { value: "value", label: "label" },
                  { value: "value", label: "label" },
                  { value: "value", label: "label" },
                ]}
              />
            </SoftBox>
          </Grid>

          <Grid item xs={12} lg={12} marginTop={-2}>
            <SoftBox py={3}>
              <BigDataTable />
            </SoftBox>
          </Grid>

          <Grid item xs={12} md={12} sm={12} lg={6} marginBottom="1rem">
            <DataTableOne />
          </Grid>
          <Grid item xs={12} md={12} sm={12} lg={6} marginBottom="1rem">
            <DataTableTwo />
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
}

export default ProductionEvulationIndex;
