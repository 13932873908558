import { React, useEffect, useState } from "react"; 
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next'; 
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography" 
import Tooltip from "@mui/material/Tooltip"; 
import Checkbox from "@mui/material/Checkbox"; 
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import SoftAvatar from "components/SoftAvatar";
import Create from "./Create";
import Edit from "./Edit";
import dateFormat from "dateformat"; 
import { NavLink } from "react-router-dom";
function Index() {
    const { t } = useTranslation(); 
    const [filterKeys, setFilterKeys] = useState({});
    const [refresh, setRefresh] = useState(0);  
    const [todo, setToDo] = useState([]); 
    const [id, setID] = useState([]); 
    const [activeTab, setActiveTab] = useState(0); 

    useEffect(() => {  
        // callFetch("get-todo", "GET", []).then((res) => {
        //     setToDo(res); 
        // }); 
        loadToDo(activeTab);
    }, [refresh]);

    const handleCompleteToDo = (id) => {
        callFetch("todo-complete/"+id, "PUT", []).then((res) => {
            loadToDo(activeTab);
        }); 
    }
   
    const loadToDo = (status) => {
        // if(status == 'open'){
        //     callFetch("get-todo", "GET", []).then((res) => {
        //         setToDo(res); 
        //         console.log(res); 
        //     }); 
        // }else{
        //     callFetch("get-todo-completed", "GET", []).then((res) => {
        //         setToDo(res); 
        //     }); 
        // }

        callFetch("get-todo/"+status, "GET", []).then((res) => {
            setToDo(res); 
            console.log(res); 
        }); 
    }


    const users =[
        { image: team3, name: "Nick Daniel" },
        { image: team4, name: "Peterson" },
        { image: team1, name: "Elena Morison" },
        { image: team2, name: "Ryan Milly" },
    ]

    const renderUsers = (users) => {
        return users?.map(({ photo: image, name }) => (
            <Tooltip key={name} title={name} placement="bottom">
                <SoftAvatar
                    src={image?.length > 0 ? process.env.REACT_APP_STORAGE_URL + 'storage/avatar/' + image : '/assets/img/placeholder.png'}
                    alt={name}
                    size="xs"
                    sx={({ borders: { borderWidth }, palette: { white } }) => ({
                        border: `${borderWidth[2]} solid #dddddd`,
                        cursor: "pointer",
                        position: "relative",
                        ml: -1.25,
    
                        "&:hover, &:focus": {
                            zIndex: "10",
                        },
                    })}
                />
            </Tooltip>
        ));
    };


    return <>
        <div className="card mb-4">
            <div className="card-body">
                <SoftTypography variant="h6" fontWeight="bold" className="todo-header-text" color="#2D3748">
                    Aufgaben
                </SoftTypography>

                <div className="to-do-btn-top">  
                    <button className={activeTab == 0 ? 'active' : ''} onClick={() => {loadToDo(0); setActiveTab(0); }}>Offene Aufgaben</button>
                    <button className={activeTab == 1 ? 'active' : ''} onClick={() => { loadToDo(1); setActiveTab(1)}}>Erledigte Aufgaben</button>
                </div>

                <div>
                <SoftBox mt={3}>
                    <div className="inner-main" style={{maxHeight: '300px', minHeight: '300px'}}> 
                    {todo?.data?.map((item) => (
                        <div className="row todo-item">
                            <div className="col-sm-1 col-todo-4" style={{textAlign: 'center'}}> <Checkbox checked={item.status} onChange={() => {handleCompleteToDo(item.id)}}/></div>
                            <div className="col-sm-11 col-todo-5">
                                <SoftTypography  className="todo-title" variant="h6">
                                    {item.title}
                                </SoftTypography>
                                <div className="row mt-2">
                                    <div className="col-sm-4 col-todo-cm-1 ">
                                        <SoftTypography variant="button" fontSize="12px" fontWeight="regular" color="secondary"> Date </SoftTypography><br/>
                                        <SoftTypography variant="button" fontSize="0.875rem" fontWeight="bold" color="secondary"> {dateFormat(item?.date, "dd.mm.yyyy")} </SoftTypography>
                                    </div>
                                    <div className="col-sm-4 col-todo-cm-2 ">
                                       {item?.project_id != null ? 
                                       <> <SoftTypography variant="button" fontSize="12px" fontWeight="regular" color="secondary"> Project </SoftTypography> <br/>
                                        <SoftTypography variant="button" fontSize="0.875rem" fontWeight="bold" color="secondary"> <NavLink to={'/projects/' + item.project_id}><span style={{color: '#0048B1'}}>{item.project_id}</span></NavLink> </SoftTypography></> : ''}
                                    </div>
                                    <div className="col-sm-4  col-todo-cm-3 pt-3">
                                        <SoftBox display="flex">{renderUsers(item.assigned_users)}</SoftBox>
                                    </div>
                                </div>  
                            </div> 
                        </div> 
                    ))} 

                    </div>
                    
                    <div className="row">
                        <div className="col-sm-12">
                            <button data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => setRefresh(refresh+1)} className="to-do-btn-bottom"> <i className="fa fa-plus"></i> Aufgabe Hinzufügen</button>
                        </div>
                    </div> 
                </SoftBox>
                </div>
            </div>
        </div>
        <Create setRefresh={setRefresh}></Create>
        <Edit setRefresh={setRefresh} id={id}></Edit>
    </>
}

export default Index;