import { Divider } from '@mui/material';
import SoftTypography from 'components/SoftTypography';
import callFetch from 'helpers/callFetch';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SoftSnackbar from "components/SoftSnackbar";
import RoutenCard from './RoutenCard';

const Index = (props) => {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [employees, setEmployees] = useState([]);
    const [allreadyBookedEmployees, setAllreadyBookedEmployees] = useState([]);

    const [routeNumber, setRouteNumber] = useState(0);
    const [projectIds, setProjectIds] = useState([]);
    const [hours, setHours] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [absenceDate, setAbsenceDate] = useState('');

    // multiple datePicker
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    const [routeCard, setRouteCard] = useState([])
    const [schedules, setSchedules] = useState([])

    const [messageSb, setMessageSB] = useState(false);
    const [messageSbColor, setMessageSbColor] = useState('success');
    const [messageSbTitle, setMessageSbTitle] = useState('Something wrong!');
    const [messageSbIcon, setMessageSbIcon] = useState('check');

    const {
        register,
        handleSubmit,
        getValues,
        setError,
        formState: { errors },
        reset
    } = useForm();

    const renderMessageSB = (
        <SoftSnackbar
            color={messageSbColor}
            backgroundColor={'success'}
            icon={messageSbIcon}
            title={t(messageSbTitle)}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={messageSb}
            onClose={() => setMessageSB(false)}
            close={() => setMessageSB(false)}
        //bgWhite={messageSbColor}
        />
    );

    useEffect(() => {

    }, [saving]);

    useEffect(() => {
        if (!props?.projectIds) return;
        setRouteNumber(props?.routeNumber ? parseInt(props?.routeNumber) : 0);
        setEmployees(props?.employees ? props?.employees : []);
        setStartDate(props?.startDate ? props?.startDate : '');
        setEndDate(props?.endDate ? props?.endDate : '');
        setAbsenceDate(props?.absenceDate ? props?.absenceDate : '');
        //setRoutePlan(props?.routePlan ? props?.routePlan : []);
        //setRefresh(refresh+1);

        var selectedprojectIds = [];
        var hours = [];
        for (let [selectedrow_key, selectedrow_value] of Object.entries(props?.projectIds)) {
            if (selectedrow_value) {
                selectedprojectIds.push(selectedrow_key + '-' + props?.plan_form[selectedrow_key] + '-' + props?.hours[selectedrow_key]);
                hours[selectedrow_key] = props?.hours[selectedrow_key];
            }
        }
        setProjectIds(selectedprojectIds);
        setHours(hours);

    }, [props?.startDate, props?.endDate, props?.absenceDate, props?.routeNumber, props?.projectIds, props?.hours, props?.plan_form, props?.employees]);

    useEffect(() => {
        if (!projectIds.length) return;

        var formData = {};
        formData.projectIds = JSON.stringify(projectIds);
        formData.routeNumber = JSON.stringify(routeNumber);
        formData.planType = props?.planType;
        formData.startDate = startDate;
        formData.endDate = endDate;
        formData.absenceDate = absenceDate;

        callFetch("routeplan", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setRouteCard(res.data);
            //setSchedules(res.schedules);
            setAllreadyBookedEmployees(res?.allreadyBookedEmployees)
            setRefresh(refresh + 1);
        });
    }, [projectIds, startDate, endDate, absenceDate]);

    const onSubmit = (data) => {



        setSaving(true);
        props?.setPlanSaving(true);

        //if (validationSuccess()) {
        if (true) {
            var selectedids = [];
            var date = [];
            var time = [];
            var end_date = [];
            var end_time = [];
            var miltarbeiter = [];
            var dachmontag_elektromontage = [];
            var plan_form = [];
            for (let [routeCard_key, routeCardvalue] of Object.entries(routeCard)) {
                for (let [itemKey, itemValue] of Object.entries(routeCardvalue?.data)) {
                    if (itemValue?.id) {

                        if (itemValue?.startDate && itemValue?.startTime && itemValue?.endDate && itemValue?.endTime) {
                            selectedids.push(itemValue?.id);
                            //date.push(schedules[itemKey].date);
                            //time.push(schedules[itemKey].start);
                            date.push(itemValue?.startDate);
                            time.push(itemValue?.startTime);
                            end_date.push(itemValue?.endDate);
                            end_time.push(itemValue?.endTime);
                            miltarbeiter.push(getValues('miltarbeiter[' + routeCard_key + ']'));
                            dachmontag_elektromontage.push(itemValue?.icon == "DC" ? "Dachmontage" : "Elektromontage");
                            plan_form.push(itemValue?.plan_form);
                        } else {

                            setMessageSbTitle('Die Anzahl der Routen reicht für den ausgewählten Zeitraum nicht aus.');
                            setMessageSbIcon('');
                            setMessageSbColor('error');
                            setMessageSB(true);

                            setSaving(false);
                            props?.setPlanSaving(false);
                            return;
                        }
                    }
                }
            }

            var formData = {};
            formData.project_id = JSON.stringify(selectedids);
            formData.date = JSON.stringify(date);
            formData.time = JSON.stringify(time);
            formData.end_date = JSON.stringify(end_date);
            formData.end_time = JSON.stringify(end_time);
            formData.miltarbeiter = JSON.stringify(miltarbeiter);
            formData.dachmontag_elektromontage = JSON.stringify(dachmontag_elektromontage);
            formData.plan_form = JSON.stringify(plan_form);

            /*
            console.log('onSubmit Line 109')
            console.log(formData)
            setSaving(false);
            props?.setPlanSaving(false);
            return;
            */

            callFetch("orders-to-plan", "POST", formData, setError).then((res) => {
                setRefresh(refresh + 1);
                props.setRefresh((Math.random() * 1000) + 1000);
                props.refresh((Math.random() * 1000) + 1000);
                props.setShowRoutePlanScreen(false);
                setSaving(false);
                props?.setPlanSaving(false);
                //setPlanSubmitButton(false);
            });

            props.setRefresh((Math.random() * 1000) + 1000);
            props.refresh((Math.random() * 1000) + 1000);
            props.setShowRoutePlanScreen(false);
            reset({});

        } else {
            setMessageSbTitle('Some plan schedule already booked');
            setMessageSbIcon('');
            setMessageSbColor('error');
            setMessageSB(true);
            setSaving(false);
            props?.setPlanSaving(false);
        }
    }

    return (
        <>
            <form style={{ height: '32vh', overflowY: 'auto', overflowX: 'hidden' }} className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <div className="row ">
                    <div className="col-12">
                        {
                            routeCard.map((route, i) => (
                                <>
                                    {route?.data?.length ? (
                                        <div className="row d-flex" key={i}>
                                            <div className="col-9">
                                                <div className="z-index-1" >
                                                    <SoftTypography variant="h5" fontSize="14px" className={`mb-2 ${i === 0 ? 'mt-4' : 'mt-4'} d-inline-block`} >{route?.title}</SoftTypography>
                                                    <div className="d-flex overflow-auto scrollbar">
                                                        {
                                                            route?.data.map((item, index) =>
                                                                <div key={index}>
                                                                    <RoutenCard data={item} distance={((index + 1) == route.data.length ? '--' : route?.data[index + 1].distance)} />
                                                                    {/*<RoutenCard data={item} distance={((index + 1) == route.data.length ? '--' : (route?.data[index + 1] ? ((route?.data[index + 1].distance) - (route?.data[index].distance)) : '--'))} />*/}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mt-5">
                                                    <div className="form-group">
                                                        <label>{t('Team Zuweisen')}</label>
                                                        <select
                                                            className="form-control form-control-sm"
                                                            {...register("miltarbeiter[" + i + "]", {
                                                                required: true,
                                                            })}
                                                        >
                                                            <option value="">{t('Select Team Member')}</option>
                                                            {employees?.map((technician, index) => (
                                                                <option className={allreadyBookedEmployees.indexOf(String(technician?.id)) == -1 ? '' : 'already-booked-employee'} value={technician.id}>{technician.name}</option>
                                                            ))}
                                                        </select>
                                                        <div className="invalid-feedback">{errors.gender && errors.gender.message}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''}
                                </>
                            ))
                        }
                    </div>
                    <div className="col-12 my-3 d-none">
                        {!saving && (
                            <button type="submit" className="btn btn-primary" id="routePlanSave">
                                {t('Save')}
                            </button>
                        )}
                        {saving && (
                            <button type="submit" className="btn btn-disabled" disabled>
                                {t('Saving ...')}
                            </button>
                        )}
                    </div>
                </div>
            </form>
            {renderMessageSB}
        </>
    );
}

export default Index