import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';


import EmailForm from "./EmailForm";
import Cookies from 'js-cookie';

// @mui core components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import Select from 'react-select';

function Create() {
    const { t } = useTranslation();
    const [speichers, setSpeichers] = useState([]);
    const [modules, setModules] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [identity_number, setIdentity_number] = useState('-');
    const [creator_name, setCreatorName] = useState('-');
    const [salesStatus, setSalesStatus] = useState([]);
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        setError,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("get-sales-status", "GET", []).then((res) => {
            setSalesStatus(res?.data)
        });
    }, []);

    useEffect(() => {
        console.log('user');
        console.log(Cookies.get('user'));

        callFetch("quation/create", "GET", []).then((res) => {
            setIdentity_number(res.data.id);
            setCreatorName(res.data.creator_name);
        });

        callFetch("speicher/all", "GET", []).then((res) => {
            setSpeichers(res.data);
        });

        callFetch("module/all", "GET", []).then((res) => {
            setModules(res.data);
        });
    }, [refresh]);

    const onChangeHandeler = (e) => {
        const name = e.target.name;
        if (name == 'eigenverbrauch' || name == 'stromkosten') {
            kwh_preis_calculate();
        }

        if (name == 'angebot_per_post_senden') {
            if (getValues('angebot_per_post_senden') == 'Ja') {
                console.log(getValues('angebot_per_post_senden'));
                setValue('email', 'info@solacloud.de');
            } else {
                setValue('email', '');
            }
        }

        if (name == 'gesami_netto') {
            const gesami_netto = getValues('gesami_netto');
            const gesami_brutto = gesami_netto * 1.19;
            setValue('gesami_brutto', gesami_brutto.toFixed(2))
        }
    }

    const kwh_preis_calculate = () => {
        const stromkosten = getValues('stromkosten');
        const eigenverbrauch = getValues('eigenverbrauch');
        const value = stromkosten / eigenverbrauch;
        setValue('kwh_preis', value.toFixed(2))
    }



    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("quation", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to='/quations' /> :
        <div className="row">
            <div className="col-md-8">
                <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <Card id="personalinformation" sx={{ overflow: "visible" }}>
                        {/*<SoftBox p={3}>
                        <SoftTypography variant="h5">{t('Create Quations')}</SoftTypography>
                    </SoftBox>*/}

                        <SoftBox p={3}>
                            <SoftTypography variant="h5">{t('Personliche Informationen')}</SoftTypography>
                        </SoftBox>
                        <SoftBox component="form" pb={3} px={3}>
                            <div className="row g-3">
                                <div className="col-md-12">

                                    <div className="form-group">
                                        <label>{t('Status')}</label>
                                        <select
                                            className="form-control"
                                            {...register("status")}
                                        >
                                            <option value="">--</option>
                                            {salesStatus && salesStatus.map((status) => (
                                                <option key={status.id} value={status.id}>{status.id + '-' + t(status.name)}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('Firma')}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("firma")} />
                                    <div className="invalid-feedback">{errors.firma && errors.firma.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Anrede')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("anrede", {
                                                required: true,
                                            })}
                                            required>
                                            <option value="">--</option>
                                            <option value="Ausstehend">{t('Ausstehend')}</option>
                                            <option value="Verkauft">{t('Verkauft')}</option>
                                            <option value="Herr">{t('Herr')}</option>
                                            <option value="Frau">{t('Frau')}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.anrede && errors.anrede.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('Vorname')} *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("vorname", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.vorname && errors.vorname.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('Name')} *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("name", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                </div>
                            </div>
                        </SoftBox>
                    </Card>

                    <Card id="kontaktinformationen" sx={{ overflow: "visible" }} className="mt-3">
                        <SoftBox p={3}>
                            <SoftTypography variant="h5">{t('Kontaktinformationen')}</SoftTypography>
                        </SoftBox>
                        <SoftBox component="form" pb={3} px={3}>
                            <div className="row g-3">
                                <div className="col-md-12">
                                    <label>{t('Straße')} *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("street", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.street && errors.street.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('Hausnummer')} *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("hausnummer", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.hausnummer && errors.hausnummer.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('PLZ')} *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("plz", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.plz && errors.plz.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('Ort')} *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("ort", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.ort && errors.ort.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('Telefonnummer')} *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("telefonnummer", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.telefonnummer && errors.telefonnummer.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('Mobilnummer')}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("mobilnummer")}
                                    />
                                    <div className="invalid-feedback">{errors.mobilnummer && errors.mobilnummer.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('E-mail Adresse')} *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("email", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                </div>
                            </div>
                        </SoftBox>
                    </Card>

                    <Card id="questions" sx={{ overflow: "visible" }} className="mt-3">
                        <SoftBox p={3}>
                            <SoftTypography variant="h5">{t('Datenerfassung')}</SoftTypography>
                        </SoftBox>
                        <SoftBox component="form" pb={3} px={3}>
                            <div className="row g-3">

                                <div className="col-md-12">
                                    <label>{t('Neigung')} *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("neigung", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.total_plant_production && errors.total_plant_production.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('Sonnenstunden')} *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("sonnenstunden", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.sonnenstunden && errors.sonnenstunden.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('Anlagenproduktion ges. in Kw/h')} *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("total_plant_production", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.total_plant_production && errors.total_plant_production.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('Eigenverbrauch in Kw/h')} *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("own_consumption", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.own_consumption && errors.own_consumption.message}</div>
                                </div>


                                <div className="col-md-12">
                                    <label>{t('Einspeisung in Kw/h')} *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("feed_in", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.feed_in && errors.feed_in.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Garantieerweiterung auf 20 Jahre')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("warranty_extension", {
                                                required: true,
                                            })} required>
                                            <option value="">--</option>
                                            <option value="JA">JA</option>
                                            <option value="NEIN (NUR BEI SENEC)">NEIN (NUR BEI SENEC)</option>
                                        </select>
                                        <div className="invalid-feedback"></div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Zählerkastenumbau notwendig')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("meter_box_conversion", {
                                                required: true,
                                            })} required>
                                            <option value="">--</option>
                                            <option value="JA">JA</option>
                                            <option value="NEIN">NEIN</option>
                                            <option value="nicht sicher">nicht sicher</option>
                                        </select>
                                        <div className="invalid-feedback"></div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Kabelführung')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("cable_routing", {
                                                required: true,
                                            })} required>
                                            <option value="">--</option>
                                            <option value="Leerrohr">Leerrohr</option>
                                            <option value="Schacht/Kamin">Schacht/Kamin</option>
                                            <option value="Fassade">Fassade</option>
                                            <option value="Sonstige">Sonstige</option>
                                        </select>
                                        <div className="invalid-feedback"></div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Ersatzziegel vorhanden')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("spare_bricks", {
                                                required: true,
                                            })} required>
                                            <option value="">--</option>
                                            <option value="JA">JA</option>
                                            <option value="NEIN">NEIN</option>
                                        </select>
                                        <div className="invalid-feedback"></div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Alle möglichen Fördermittel beantragen ')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("apply_for_all_possible_grants", {
                                                required: true,
                                            })} required>
                                            <option value="">--</option>
                                            <option value="JA">JA</option>
                                            <option value="NEIN">NEIN</option>
                                        </select>
                                        <div className="invalid-feedback"></div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Wallbox')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("wallbox_senec", {
                                                required: true,
                                            })} required>
                                            <option value="">--</option>
                                            <option value="JA">JA</option>
                                            <option value="NEIN">NEIN</option>
                                        </select>
                                        <div className="invalid-feedback"></div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Besitzt der Kunde eine Wärmepumpe: ')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("have_heat_pump", {
                                                required: true,
                                            })} required>
                                            <option value="">--</option>
                                            <option value="JA">JA</option>
                                            <option value="NEIN">NEIN</option>
                                        </select>
                                        <div className="invalid-feedback"></div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Sondervereinbarung')} *</label>
                                        <textarea className="form-control" rows={4} {...register('remark_financial_advisers')}></textarea>
                                        <div className="invalid-feedback"></div>
                                    </div>
                                </div>

                                <h6>Bemerkung Montage : (Kabelweg, Zählerschrank, Grabungsarbeiten, Zwischenzähler, WR / Batterie Platz)</h6>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Zählerplatz frei:')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("counter_space_free", {
                                                required: true,
                                            })} required>
                                            <option value="">--</option>
                                            <option value="JA">JA</option>
                                            <option value="NEIN">NEIN</option>
                                        </select>
                                        <div className="invalid-feedback"></div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Zähleranzahl')} *</label>
                                        <input type="text" className="form-control" {...register('number_of_counters', {
                                            required: true,
                                        })} required />
                                        <div className="invalid-feedback"></div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Zählerzusammenfassung')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("counter_summary", {
                                                required: true,
                                            })} required>
                                            <option value="">--</option>
                                            <option value="JA">JA</option>
                                            <option value="NEIN">NEIN</option>
                                        </select>
                                        <div className="invalid-feedback"></div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('wenn ja, welche Zähler, Zählernummer:')} *</label>
                                        <input type="text" className="form-control" {...register('meter_number', {
                                            required: true,
                                        })} required />
                                        <div className="invalid-feedback"></div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Welche Zähler werden ausgebaut?, Zählernummer:')} *</label>
                                        <input type="text" className="form-control" {...register('remove_meter_number', {
                                            required: true,
                                        })} required />
                                        <div className="invalid-feedback"></div>
                                    </div>
                                </div>



                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Internetanschluss(wenn nein ist der Kunde verpflichtet einen zu machen):')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("internet_connection", {
                                                required: true,
                                            })} required>
                                            <option value="">--</option>
                                            <option value="JA">JA</option>
                                            <option value="NEIN">NEIN</option>
                                        </select>
                                        <div className="invalid-feedback"></div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Bestandsanlage(wenn ja mit Inbetriebnahmedatum, Anlagengröße und Anlagennummer):')} *</label>
                                        <input type="text" className="form-control" {...register('existing_system', {
                                            required: true,
                                        })} required />
                                        <div className="invalid-feedback"></div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Potenzialausgleich vorhanden:')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("equipotential_bonding_available", {
                                                required: true,
                                            })} required>
                                            <option value="">--</option>
                                            <option value="JA">JA</option>
                                            <option value="NEIN">NEIN</option>
                                        </select>
                                        <div className="invalid-feedback"></div>
                                    </div>
                                </div>


                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('PV-Module zu WR:')} *</label>
                                        <input type="text" className="form-control" {...register('pv_modules_wr', {
                                            required: true,
                                        })} required />
                                        <div className="invalid-feedback"></div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Wechselrichter/Speicher zu Zählerschrank:')} *</label>
                                        <input type="text" className="form-control" {...register('inverter_storage_to_meter_cabinet', {
                                            required: true,
                                        })} required />
                                        <div className="invalid-feedback"></div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Zählerort(Stockwerk)')}*</label>
                                        <Select
                                            placeholder={t('Select')}
                                            options={[
                                                { value: 'KG', label: 'KG' },
                                                { value: 'EG', label: 'EG' },
                                                { value: 'OG', label: 'OG' },
                                            ]}
                                            onChange={(e) => {
                                                setValue('meter_location_floor', JSON.stringify(e));
                                            }}
                                            isMulti
                                        />
                                        <div className="invalid-feedback">{errors.house_connection_location && errors.house_connection_location.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Hausanschluss(HA) ORT')}*</label>
                                        <Select
                                            placeholder={t('Select')}
                                            options={[
                                                { value: 'KG', label: 'KG' },
                                                { value: 'EG', label: 'EG' },
                                                { value: 'OG', label: 'OG' },
                                            ]}
                                            onChange={(e) => {
                                                setValue('house_connection_location', JSON.stringify(e));
                                            }}
                                            isMulti
                                        />
                                        <div className="invalid-feedback">{errors.house_connection_location && errors.house_connection_location.message}</div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Speicher/WR Platz')}*</label>
                                        <Select
                                            placeholder={t('Select')}
                                            options={[
                                                { value: 'KG', label: 'KG' },
                                                { value: 'EG', label: 'EG' },
                                                { value: 'OG', label: 'OG' },
                                            ]}
                                            onChange={(e) => {
                                                setValue('storage_wrspace', JSON.stringify(e));
                                            }}
                                            isMulti
                                        />
                                        <div className="invalid-feedback">{errors.storage_wrspace && errors.storage_wrspace.message}</div>
                                    </div>
                                </div>

                            </div>
                        </SoftBox>

                    </Card>

                    <Card id="eckdaten-pV" sx={{ overflow: "visible" }} className="mt-3">
                        <SoftBox p={3}>
                            <SoftTypography variant="h5">{t('Eckdaten PV')}</SoftTypography>
                        </SoftBox>
                        <SoftBox component="form" pb={3} px={3}>
                            <div className="row g-3">
                                <div className="col-md-12">
                                    <label>{t('Stromverbrauch in kwh')} *</label>
                                    <input
                                        type="number" step="0.01"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("eigenverbrauch", {
                                            required: true,
                                            onChange: (e) => onChangeHandeler(e)
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.eigenverbrauch && errors.eigenverbrauch.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('Stromkosten(€)')} *</label>
                                    <input
                                        type="number" step="0.01"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("stromkosten", {
                                            required: true,
                                            onChange: (e) => onChangeHandeler(e)
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.stromkosten && errors.stromkosten.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('Kwh Preis(€)')} *</label>
                                    <input
                                        type="number" step="0.01"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("kwh_preis", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.kwh_preis && errors.kwh_preis.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Speicher')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("speicher", {
                                                required: true,
                                            })} required>
                                            <option value="">--</option>
                                            {speichers && speichers.map((speicher) => (
                                                <option key={speicher.id} value={speicher.id}>{speicher.name}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">{errors.speicher && errors.speicher.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Modul')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("modul", {
                                                required: true,
                                            })} required>
                                            <option value="">--</option>
                                            {modules && modules.map((modul) => (
                                                <option key={modul.id} value={modul.id}>{modul.name}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">{errors.modul && errors.modul.message}</div>
                                    </div>
                                </div>


                                <div className="col-md-12">
                                    <label>{t('Anzahl Module')} *</label>
                                    <input
                                        type="number" step="0.01"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("anzahl_module", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.anzahl_module && errors.anzahl_module.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('Anzahl der Dächer')} *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("anzahl_der_dacher", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.anzahl_der_dacher && errors.anzahl_der_dacher.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('Gesamt Bebauungsflache in qm')} *</label>
                                    <input
                                        type="number" step="0.01"
                                        className="form-control"
                                        placeholder={t('')}
                                        {...register("ges_dachflache", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.ges_dachflache && errors.ges_dachflache.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Dachtyp')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("dachtyp", {
                                                required: true,
                                            })}
                                            required>
                                            <option value="">--</option>
                                            <option value="Sonstiges">{t('Sonstiges')}</option>
                                            <option value="Satteldach">{t('Satteldach')}</option>
                                            <option value="Pultdach">{t('Pultdach')}</option>
                                            <option value="Flachdach">{t('Flachdach')}</option>
                                            <option value="Walmdach">{t('Walmdach')}</option>
                                            <option value="2/ Hausdach + Garagendach">{t('2/ Hausdach + Garagendach')}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.dachtyp && errors.dachtyp.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Dachausrichtung')} *</label>

                                        <select
                                            className="form-control"
                                            {...register("dachausrichtung", {
                                                required: true,
                                            })}
                                            required>
                                            <option value="">--</option>
                                            <option value="Süd">{t('Süd')}</option>
                                            <option value="S-O u. S-W">{t('S-O u. S-W')}</option>
                                            <option value="Süd West">{t('Süd West')}</option>
                                            <option value="West Süd">{t('West Süd')}</option>
                                            <option value="West">{t('West')}</option>
                                            <option value="WNW">{t('WNW')}</option>
                                            <option value="SSO">{t('SSO')}</option>
                                            <option value="Süd Ost">{t('Süd Ost')}</option>
                                            <option value="OSO">{t('OSO')}</option>
                                            <option value="Ost">{t('Ost')}</option>
                                            <option value="ONO">{t('ONO')}</option>
                                            <option value="Nord">{t('Nord')}</option>
                                            <option value="Nord-Ost">{t('Nord-Ost')}</option>
                                            <option value="Nord-West">{t('Nord-West')}</option>
                                            <option value="Ost-West">{t('Ost-West')}</option>
                                            <option value="Süd u. Nord">{t('Süd u. Nord')}</option>
                                            <option value="West u. Ost">{t('West u. Ost')}</option>
                                            <option value="Süd & Ost & West">{t('Süd & Ost & West')}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.dachausrichtung && errors.dachausrichtung.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Dacheindeckung')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("dacheindeckung", {
                                                required: true,
                                            })}
                                            required>
                                            <option value="">--</option>
                                            <option value="Sonstiges">{t('Sonstiges')}</option>
                                            <option value="Ziegel">{t('Ziegel')}</option>
                                            <option value="Blech">{t('Blech')}</option>
                                            <option value="Kies">{t('Kies')}</option>
                                            <option value="Schiefer">{t('Schiefer')}</option>
                                            <option value="Schindel">{t('Schindel')}</option>
                                            <option value="Betumen">{t('Betumen')}</option>
                                            <option value="Gründach">{t('Gründach')}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.dacheindeckung && errors.dacheindeckung.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Zusatzvereinbarungen')}</label>
                                        <Select
                                            placeholder={t('Select')}
                                            options={[
                                                { value: 'Zwischenzähler', label: 'Zwischenzähler' },
                                                { value: 'Power to Heat', label: 'Power to Heat' },
                                                { value: 'inkl. Technikpaket', label: 'inkl. Technikpaket' },
                                                { value: 'inkl. Garantieverlängerungen auf 20 Jahre SENEC', label: 'inkl. Garantieverlängerungen auf 20 Jahre SENEC' },
                                                { value: 'Zählerzusammenlegung', label: 'Zählerzusammenlegung' },
                                                { value: 'inkl. Aufständerung Ost/West', label: 'inkl. Aufständerung Ost/West' },
                                                { value: 'inkl. Aufständerung Süd', label: 'inkl. Aufständerung Süd' },
                                                { value: 'Steuerberatergutschein SENEC', label: 'Steuerberatergutschein SENEC' },
                                                { value: 'inkl. Kreuzverbund', label: 'inkl. Kreuzverbund' },
                                                { value: 'inkl. Schlüsselfertiger Preis / All in one Paket', label: 'inkl. Schlüsselfertiger Preis / All in one Paket' },
                                                { value: 'inkl. Blechziegel', label: 'inkl. Blechziegel' },
                                                { value: 'TIGO Optimierer', label: 'TIGO Optimierer' },
                                                { value: 'inkl. Notstrombox SENEC', label: 'inkl. Notstrombox SENEC' },
                                                { value: 'inkl. Notstrombox E3DC', label: 'inkl. Notstrombox E3DC' },
                                                { value: 'inkl. Wallbox 22kw', label: 'inkl. Wallbox 22kw' },
                                                { value: 'inkl. Wallbox 11kw', label: 'inkl. Wallbox 11kw' },
                                                { value: 'Taubenschutz', label: 'Taubenschutz' },
                                                { value: 'Schneefanggitter', label: 'Schneefanggitter' }
                                            ]}
                                            onChange={(e) => {
                                                setValue('zusatzvereinbarungen', JSON.stringify(e));
                                            }}
                                            isMulti
                                        />
                                        <div className="invalid-feedback">{errors.zusatzvereinbarungen && errors.zusatzvereinbarungen.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Wirtschaftlichkeitsberechnung')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("wirtschaftlichkeitsberechnung", {
                                                required: true,
                                            })}
                                            required>
                                            <option value="">--</option>
                                            <option value="Ja">{t('Ja')}</option>
                                            <option value="Nein">{t('Nein')}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.wirtschaftlichkeitsberechnung && errors.wirtschaftlichkeitsberechnung.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Angebot per Post senden')}</label>
                                        <select
                                            className="form-control"
                                            {...register("angebot_per_post_senden", {
                                                onChange: (e) => onChangeHandeler(e)
                                            })}>
                                            <option value="">--</option>
                                            <option value="Ja">{t('Ja')}</option>
                                            <option value="Nein">{t('Nein')}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.angebot_per_post_senden && errors.angebot_per_post_senden.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('Gesamt Netto')} *</label>
                                    <div className="input-group mb-3">
                                        <input
                                            type="number" step="0.01"
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("gesami_netto", {
                                                required: true,
                                                onChange: (e) => onChangeHandeler(e)
                                            })}
                                            required
                                        />
                                        <div className="input-group-append ms-2">
                                            <span className="input-group-text px-5">€</span>
                                        </div>
                                    </div>
                                    <div className="invalid-feedback">{errors.gesami_netto && errors.gesami_netto.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('Gesamt Brutto')} *</label>
                                    <div className="input-group mb-3">
                                        <input
                                            type="number" step="0.01"
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("gesami_brutto", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="input-group-append ms-2">
                                            <span className="input-group-text px-5">€</span>
                                        </div>
                                    </div>
                                    <div className="invalid-feedback">{errors.gesami_brutto && errors.gesami_brutto.message}</div>
                                </div>
                            </div>
                        </SoftBox>
                    </Card>


                    <div className="row g-3">
                        <div className="col-12 my-4">
                            {!saving && (
                                <button type="submit" className="btn btn-primary">
                                    {t('Save')}
                                </button>
                            )}
                            {saving && (
                                <button type="submit" className="btn btn-disabled" disabled>
                                    {t('Saving ...')}
                                </button>
                            )}
                        </div>
                    </div>
                </form>
            </div>

            <div className="col-md-4">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-body p-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                            <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                        </div>
                                    </div>
                                    <div className="ms-3">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Quotation Nr.')}</p>
                                            <h5 className="font-weight-bolder mb-0">
                                                {identity_number}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-body p-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                            <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                        </div>
                                    </div>
                                    <div className="ms-3">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Creator')}</p>
                                            <h5 className="font-weight-bolder mb-0">
                                                {creator_name}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mb-3">
                    <div className="card-header pb-0">
                        <h6>{t('Email')}</h6>
                    </div>
                    <div className="card-body">
                        <EmailForm id={0} />
                    </div>
                </div>
            </div>

        </div>;
}

export default Create;