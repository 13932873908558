import { React, useEffect, useState } from 'react';
import { NavLink, useParams } from "react-router-dom";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
const ModalContentProductStock = (props) => {
    const { t } = useTranslation();
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const params = useParams();
    const tableHeadings = [
        {
            name: t('Bestand'),
            selector: row => '-' + row.quantity,
            sortable: true,
            width: '95px'
        },
        {
            name: t('Einheit'),
            selector: row => 'Stück',
        },
        
        {
            name: t('Projektnr.'),
            cell: row => row.project_id,
        },

        {
            name: t('Client Name'),
            cell: row => row.project.vorname + ' ' + row.project.name,
            width: '200px'
        },
        
        {
            name: t('Lieferdatum'),
            cell: row =>  <>
            {row?.created_at && row?.created_at != null ? (
                <>{dateFormat(row?.created_at, "dd.mm.yyyy")}</>
            ) : '--'}
        </>,
            selector: '',
        },
        {
            name: t('Projectstatus'),
            cell: row => (
                <>
                    <span className="badge badge-dot me-4">
                        {row.project.stornieren == 1 ? (
                            <>
                                <i className="bg-danger"></i>
                                <span className="text-dark text-xs">{'Storno'}</span>
                            </>
                        ) : (
                            <>
                                {row?.project?.status && row.project.status != 'null' ? (
                                    <>
                                        {row.project.status == '1-Verkauft' ? <i className="bg-success"></i>
                                            : row.project.status == '2-Kontte nicht verkauft werden' ? <i className="bg-primary"></i>
                                                : row.project.status == '3-Angebot' ? <i className="bg-secondary"></i>
                                                    : row.project.status == '4-Überleger' ? <i className="bg-info"></i>
                                                        : row.project.status == '5-Kunde war nicht da' ? <i className="bg-warning"></i>
                                                            : row.project.status == '6-Kein Interesse / Keine Beratung' ? <i className="bg-dark"></i>
                                                                : row.project.status == '7-Technisch nicht möglich' ? <i className="bg-dark"></i>
                                                                    : row.project.status == '0-Zeitlich nicht geschaft' ? <i className="bg-dark"></i>
                                                                        : <i className="bg-dark"></i>
                                        }
                                        <span className="text-dark text-xs">{t(row.project.status)}</span>
                                    </>
                                ) : row?.project.termine_qoute?.status && row?.project.termine_qoute?.status != 'null' ? (
                                    <>
                                        {row?.project?.termine_qoute?.status == '1-Verkauft' ? <i className="bg-success"></i>
                                            : row?.project?.termine_qoute?.status == '2-Kontte nicht verkauft werden' ? <i className="bg-primary"></i>
                                                : row?.project?.termine_qoute?.status == '3-Angebot' ? <i className="bg-secondary"></i>
                                                    : row?.project?.termine_qoute?.status == '4-Überleger' ? <i className="bg-info"></i>
                                                        : row?.project?.termine_qoute?.status == '5-Kunde war nicht da' ? <i className="bg-warning"></i>
                                                            : row?.project?.termine_qoute?.status == '6-Kein Interesse / Keine Beratung' ? <i className="bg-dark"></i>
                                                                : row?.project?.termine_qoute?.status == '7-Technisch nicht möglich' ? <i className="bg-dark"></i>
                                                                    : row?.project?.termine_qoute?.status == '0-Zeitlich nicht geschaft' ? <i className="bg-dark"></i>
                                                                        : <i className="bg-dark"></i>
                                        }
                                        <span className="text-dark text-xs">{t(row?.project?.termine_qoute?.status)}</span>
                                    </>
                                ) : <span className="text-dark text-xs">{'--'}</span>}
                            </>
                        )
                        }

                    </span>
                </>
            ),
            selector: '',
        }, 
    ];

    useEffect(() => {
        callFetch("stock-out-products?page=" + pageNumber+'&id='+props.id+'&warehouseid='+params?.id, "GET", []).then((res) => {
            setTableData(res.data);
            console.log(res.data);
        });
    }, [pageNumber, refresh, props.id]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {pageItems.map((page) => {
                            const className =
                                page === currentPage ? "page-item active" : "page-item";

                            return (
                                <li key={page} className={className}>
                                    <button
                                        className="page-link"
                                        onClick={handlePageNumber}
                                        value={page}
                                    >
                                        {page}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return <DataTable
        columns={tableHeadings}
        data={tabledata?.data}
        noDataComponent={t('There are no records to display')}
        className='data-table'
        pagination
        highlightOnHover
        paginationComponentOptions={{ noRowsPerPage: true }}
        paginationServer
        paginationTotalRows={tabledata?.total}
        onChangePage={handlePageChange}
        paginationComponent={BootyPagination}
    />;
}

export default ModalContentProductStock