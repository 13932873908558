import { React, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard PRO React components
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Cookies from 'js-cookie';

// Custom styles for the SidenavCard
import {
  card,
  cardContent,
  cardIconBox,
  cardIcon,
} from "examples/Sidenav/styles/sidenavCard";

// Soft UI Dashboard PRO React context
import { useSoftUIController } from "context";

function SidenavCard() {
    const { t } = useTranslation();
  const [controller] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;

  return (
    <Card sx={(theme) => card(theme, { miniSidenav })}>
      <CardContent sx={(theme) => cardContent(theme, { sidenavColor })}>
        <SoftBox
          bgColor="white"
          width="2rem"
          height="2rem"
          borderRadius="md"
          shadow="md"
          mb={2}
          sx={cardIconBox}
        >
          <Icon
            fontSize="medium"
            sx={(theme) => cardIcon(theme, { sidenavColor })}
          >
            star
          </Icon>
        </SoftBox>
        <SoftBox lineHeight={1}>
          <SoftTypography variant="h6" color="white">
            {t('Need help?')}
          </SoftTypography>
          <SoftBox mb={1.825} mt={-1}>
            <SoftTypography variant="caption" color="white" fontWeight="medium">
              {t('Please check our docs')}
            </SoftTypography>
          </SoftBox>
            {JSON.parse(Cookies.get('permissions')).indexOf("SupR") !== -1 ? (
                <>
                    <SoftBox mb={1}>
                        <SoftButton
                            component={Link}
                            href="/support"
                            rel="noreferrer"
                            size="small"
                            color="white"
                            fullWidth
                        >
                            {t('Support')}
                        </SoftButton>
                    </SoftBox>
                </>
            ) : <></>}
          

          <SoftBox mb={1}>
            <SoftButton
              component={Link}
              href="/management"
              rel="noreferrer"
              size="small"
              color="white"
              fullWidth
            >
              Videos
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </CardContent>
    </Card>
  );
}

export default SidenavCard;
