import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import SoftTypography from "components/SoftTypography";
import dateFormat from "dateformat";

import ReplayForm from "./layouts/ReplayForm";
import AllReplay from "./layouts/Replay";
import SoftBadge from "components/SoftBadge";

function Show() {
    let params = useParams();
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if(!params.id) return;

        callFetch("support/" + params.id, "GET", []).then((res) => {
            setData(res.data);
        });

        setTimeout(()=>{
            setRefresh(refresh+1);
        }, 30000);

    }, [params.id, refresh]);

    return(
        <div className="row">
            <div className="col-md-4">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>#{data.id+' - '+data.subject}</h6>
                    </div>
                    <div className="card-body pt-0">
                        <table>
                            <tr>
                                <td width="110"><SoftTypography variant="caption"><strong>{t('Department')}: </strong></SoftTypography></td>
                                <td><SoftTypography variant="caption">{data?.department?.name}</SoftTypography></td>
                            </tr>
                            <tr>
                                <td width="110"><SoftTypography variant="caption"><strong>{t('Submitted')}: </strong></SoftTypography></td>
                                <td><SoftTypography variant="caption">{dateFormat(data?.created_at, "dd.mm.yyyy hh:MM")}</SoftTypography></td>
                            </tr>
                            <tr>
                                <td width="110"><SoftTypography variant="caption"><strong>{t('Contact')}: </strong></SoftTypography></td>
                                <td><SoftTypography variant="caption">{data?.contact?.name}</SoftTypography></td>
                            </tr>
                            <tr>
                                <td width="110"><SoftTypography variant="caption"><strong>{t('Status')}: </strong></SoftTypography></td>
                                <td>
                                    <SoftBadge className="mx-1" color={'success'} size="xs"  badgeContent={data?.status} container />
                                </td>
                            </tr>
                            <tr>
                                <td width="110"><SoftTypography variant="caption"><strong>{t('Priority')}: </strong></SoftTypography></td>
                                <td><SoftBadge className="mx-1" color={'warning'} size="xs"  badgeContent={data?.priority} container /></td>
                            </tr>
                            <tr>
                                <td width="110"><SoftTypography variant="caption"><strong>{t('Description')}: </strong></SoftTypography></td>
                                <td><SoftTypography variant="caption">{data?.description}</SoftTypography></td>
                            </tr>
                        </table>              
                    </div>
                </div>
            </div>
            <div className="col-md-8">
                <ReplayForm refresh={refresh} setRefresh={setRefresh}/>               
                <AllReplay refresh={refresh} />               
            </div>
        </div>
    )
}

export default Show;
