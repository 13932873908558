import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import SoftBadge from "components/SoftBadge";
import Icon from "@mui/material/Icon";

function CorrectionRequestView(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    var serial = 1;
    
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        unregister,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        setValue('id', props.data.id);
        setValue('status', 'Done');
    }, [props]);

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("correction-request/"+props.data.id, "POST", formData, setError).then((res) => {
            setValue('description', '');
            setSaving(false);
            props.setRefresh(props.refresh+1);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return(
        <>
            {props.data.status == 'Done' ? 
                <SoftBadge data-bs-toggle="modal" data-bs-target={"#correctionRequestView"+props.data.id} className="mx-1" color={'error'} size="xs"  badgeContent={'#'+(props.serial)+' '+'Revision'} container />
            : ''}
            <SoftBadge data-bs-toggle="modal" data-bs-target={"#correctionRequestView"+props.data.id} className="mx-1" color={props.data.status == 'Revision' ? 'error' : 'success'} size="xs"  badgeContent={'#'+(props.serial)+' '+props.data.status} container />
                        
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <input type="hidden" defaultValue="PUT" {...register("_method")} />
                <input type="hidden" {...register("id")} />
                <input type="hidden" {...register("status")} />

                <div className="modal fade" id={"correctionRequestView"+props.data.id} tabIndex="-1" role="dialog" aria-labelledby={"correctionRequestView"+props.data.id} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content" >
                            <div className="modal-header">
                                <h5 className="modal-title" id="correctionRequestLabel">{t('Correction Request')} <SoftBadge className="mx-1" color={props.data.status == 'Revision' ? 'error' : 'success'} size="xs"  badgeContent={'#'+(props.serial)+' '+props.data.status} container /></h5>
                                <button type="button" className="btn-close text-dark" id="correctionRequestClose" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body" style={{minHeight: '400px', maxHeight: '400px'}} data-simplebar>
                                <p>{props?.data?.description}</p>
                            </div>
                            <div className="modal-footer">
                                {!saving && (
                                    <button type="submit" className="btn btn-primary mb-0" data-bs-dismiss="modal" aria-label="Close">
                                        <i className="fa-solid fa-circle-check"></i> {t('Done')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled mb-0" disabled data-bs-dismiss="modal" aria-label="Close">
                                        <i className="fa-solid fa-circle-check"></i> {t('Done ...')}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default CorrectionRequestView;
