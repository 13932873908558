import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import OpenInvoiceIndexTable from './OpenInvoiceIndexTable'
import { Divider, Icon, Menu, MenuItem } from '@mui/material'
import SoftTypography from 'components/SoftTypography'
import SoftButton from 'components/SoftButton'
import SoftBox from 'components/SoftBox'
const OpenInvoiceIndex = () => {
  const { t } = useTranslation()

  const [menu, setMenu] = useState(null);
  const [status, setStatus] = useState('');

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = (statu = '') => {
    setMenu(null);
    setStatus(statu);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={()=> { closeMenu('') }}
      keepMounted
    >
      <MenuItem onClick={()=> { closeMenu('') }}>{t('All')}</MenuItem>
      <MenuItem onClick={()=> { closeMenu('30% Speicher Rechnung Erstellen') }}>30% Speicher Rechnung Erstellen</MenuItem>
      <MenuItem onClick={()=> { closeMenu('70% DC Rechnung Erstellen') }}>70% DC Rechnung Erstellen</MenuItem>
      <MenuItem onClick={()=> { closeMenu('Rechnungen erstellt') }}>Rechnungen erstellt</MenuItem>
      {/* <Divider sx={{ margin: "0.5rem 0" }} />
        <MenuItem onClick={closeMenu}>
          <SoftTypography variant="button" color="error" fontWeight="regular">
            Remove Filter
          </SoftTypography>
        </MenuItem> */}
    </Menu>
  );
  return (
    <>
      <div className="d-flex justify-content-end">
        <div className="mb-2">
          <SoftButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
            {status ? status : 'GESAMT'}&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </SoftButton>
          {renderMenu}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card mb-4">
            <div className="card-header pb-0">
              <h6>{t('Alle offenen Rechnungen')}</h6>
            </div>
            <div className="card-body px-0 pt-0 pb-2">
              <OpenInvoiceIndexTable status={status} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OpenInvoiceIndex