import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { NavLink } from "react-router-dom";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Grid, Menu, MenuItem, Typography } from "@mui/material";
import SoftButton from "components/SoftButton";
import dateFormat, { masks } from "dateformat";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import callFetch from "helpers/callFetch";
import Timeline from "pages/project/Timeline";

const CardCarousel = ({
  color,
  acColor,
  icon,
  timelineIcon,
  project_nr,
  title,
  description,
  value,
  chooseMessage,
  data
}) => {
  const { t } = useTranslation();
  const BGColor = icon === "DC" ? "#E53E3E" : icon === "AC" ? "#48BB78" : "";
  const [openMenu, setOpenMenu] = useState(null);
  const [datas, setData] = useState([]);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const titles = {title:icon,project_nr:project_nr};
  const projectData = (id) =>{
    setData([])
    if(id){
        callFetch("project/timelines/"+id, "GET", []).then((res) => {
            setData(res.data);
        }); 
    }
}
  const itemTag =
    icon === "DC" ? (
      <Typography
        variant="h6"
        fontSize="12px"
        fontWeight="medium"
        style={{ color: "#E53E3E" }}
      >
        Neu
      </Typography>
    ) : icon === "AC" ? (
      <Typography
        variant="h6"
        fontSize="12px"
        fontWeight="medium"
        style={{ color: "#3A416F" }}
      >
        Revision
      </Typography>
    ) : (
      ""
    );
  const renderMenu = () => (
    datas?.project && <Menu
                anchorEl={openMenu}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={Boolean(openMenu)}
                onClose={handleCloseMenu}
                keepMounted
              >
                <CloseIcon
                  onClick={handleCloseMenu}
                  style={{
                    width: "17px",
                    height: "25px",
                    cursor: "pointer",
                    zIndex: "1",
                  }}
                />
                <Grid mt={0} item xs={12} lg={12} pl={8}>
                  <Timeline datas={datas} title="Overview" />
                </Grid>
              </Menu>
  );

  return (
    <>
      <Card>
        <SoftBox pt={2} display="flex">
          <SoftBox>
            <SoftBox pb={1} pl={2} mx={1} display="flex">
              <SoftBox
                display="grid"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="2.1rem"
                borderRadius="md"
                style={{
                  backgroundColor: `${
                    icon === "DC"
                      ? "#FED7D7"
                      : icon === "AC"
                      ? "rgba(38, 132, 255, 0.29)"
                      : ""
                  }`,
                }}
              >
                {itemTag}
              </SoftBox>
            </SoftBox>
            <SoftBox pb={2} pl={3} ml={0} mr={0} display="flex">
              <SoftBox
                display="grid"
                justifyContent="center"
                alignItems="center"
                color="white"
                width="4rem"
                height="4rem"
                shadow="md"
                borderRadius="lg"
                variant="gradient"
                style={{ backgroundColor: `${BGColor}` }}
                mr={2}
              >
                <Typography>{icon}</Typography>
              </SoftBox>
            </SoftBox>
          </SoftBox>

          <SoftBox mt={0}>
            <SoftTypography display="flex" variant="button" fontWeight="medium">
              {title}:{" "}
              <SoftTypography
                style={{
                  fontWeight: "400",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                color="primary"
                mr={1}
                variant="p"
                fontWeight="medium"
                fontSize="14px"
              >
              <NavLink to={'/projects/' + project_nr}>{project_nr}</NavLink> 
              </SoftTypography>
            </SoftTypography>
            <SoftTypography
              display="block"
              variant="button"
              fontWeight="medium"
              mt={1}
            >
              Kundendaten:
            </SoftTypography>
            <SoftTypography
              display="flex"
              color="secondary"
              variant="h6"
              fontWeight="medium"
              fontSize="13px"
            >
              Name:{" "}
              <SoftTypography
                color="text"
                mr={1}
                variant="h6"
                fontWeight="medium"
                fontSize="13px"
              >
                {data.name}
              </SoftTypography>
            </SoftTypography>
            <SoftTypography
              display="flex"
              color="secondary"
              variant="h6"
              fontWeight="medium"
              fontSize="13px"
            >
              Adress:{" "}
              <SoftTypography
                color="text"
                mr={1}
                variant="h6"
                fontWeight="medium"
                fontSize="13px"
              >
                {data.street}
              </SoftTypography>
            </SoftTypography>
            <SoftTypography
              display="flex"
              color="secondary"
              variant="h6"
              fontWeight="medium"
              fontSize="13px"
            >
              E-mail:{" "}
              <SoftTypography
                color="text"
                mr={1}
                variant="h6"
                fontWeight="medium"
                fontSize="13px"
              >
                {data.email}
              </SoftTypography>
            </SoftTypography>
            <SoftTypography
              display="flex"
              color="secondary"
              variant="h6"
              fontWeight="medium"
              fontSize="13px"
            >
              PLZ, Ort:{" "}
              <SoftTypography
                color="text"
                mr={1}
                variant="h6"
                fontWeight="medium"
                fontSize="13px"
              >
               {data.plz_ort}
              </SoftTypography>
            </SoftTypography>
            <SoftTypography
              display="flex"
              color="secondary"
              variant="h6"
              fontWeight="medium"
              fontSize="13px"
            >
              Telefon:{" "}
              <SoftTypography
                color="text"
                mr={1}
                variant="h6"
                fontWeight="medium"
                fontSize="13px"
              >
                {data.phone}
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <Divider /> 
        <SoftBox
          display="flex" 
          justifyContent="end"
          px={3}
          mb={1}
          py={1}
        >
          {data.assembly_date ? <SoftTypography style={{position: 'absolute', left : '22px'}}
              color="secondary"
              alignItems="left"
              variant="h6"
              fontWeight="medium"
              fontSize="13px"
            >
              Wunschtermin:{" "} <br/>
              <SoftTypography
                color="text"
                alignItems="left" 
                variant="h6"
                fontWeight="medium"
                fontSize="13px"
              >
                { data.assembly_date ? dateFormat(data.assembly_date, "dd.mm.yyyy") : ''}
              </SoftTypography>
            </SoftTypography> : ''}
          
          <SoftButton
            onClick={handleOpenMenu}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              textTransform: "capitalize",
            }}
            variant="text"
            color="dark"
            
          >
            <div onClick={()=>projectData(project_nr)}><Icon>info</Icon>&nbsp; {t('Overview')} </div> 
          </SoftButton>
          {renderMenu()}
          
          <SoftButton
            onClick={() => chooseMessage(titles)}
            id={"triggercardNewPlanBtn"+project_nr}
            data-bs-toggle="modal"
            data-bs-target="#cardNewPlan"
            variant="outlined"
            style={{
              backgroundColor: `${BGColor}`,
              textTransform: "capitalize",
            }}
          >
            <Icon>edit</Icon>&nbsp; Neu Planen
          </SoftButton>
        </SoftBox>
      </Card>

      
    </>
  );
};

export default CardCarousel;
