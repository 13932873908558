import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch";
import Moment from 'react-moment';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import Cookies from 'js-cookie';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Download from "yet-another-react-lightbox/plugins/download";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/counter.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";
const DCFiles = (props) => {
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(0);
  const [dataa, setDataa] = useState([]);
  const [dachansicht, setDachansicht] = useState([]);
  const [attachments, setAttachment] = useState([]);
  const [ref, setRef] = useState(0);

  const [images, setImages] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [currentImg, setCurrentImg] = useState(0);
    const [position, setPosition] = useState('bottom');
    const [width, setWidth] = useState(120);
    const [height, setHeight] = useState(80);
    const [border, setBorder] = useState(3);
    const [borderRadius, setBorderRadius] = useState(4);
    const [padding, setPadding] = useState(4);
    const [gap, setGap] = useState(16);
    const [preload, setPreload] = useState(2);
    const [showToggle, setShowToggle] = useState(false);

    const [animationDuration, setAnimationDuration] = useState(500);
    const [maxZoomPixelRatio, setMaxZoomPixelRatio] = useState(10);
    const [zoomInMultiplier, setZoomInMultiplier] = useState(2);
    const [doubleTapDelay, setDoubleTapDelay] = useState(300);
    const [doubleClickDelay, setDoubleClickDelay] = useState(300);
    const [doubleClickMaxStops, setDoubleClickMaxStops] = useState(2);
    const [keyboardMoveDistance, setKeyboardMoveDistance] = useState(50);
    const [wheelZoomDistanceFactor, setWheelZoomDistanceFactor] = useState(100);
    const [pinchZoomDistanceFactor, setPinchZoomDistanceFactor] = useState(100);
    const [scrollToZoom, setScrollToZoom] = useState(true);

   

    const openImgsViewer  = (index, event) => {
        setCurrentImg(index);
        setIsOpen(true);
    }

   

  const showAlert = (id, sectionKey) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          const updatedData = { ...dataa }; // Create a copy of the data object
          const sectionData = updatedData[sectionKey]; // Get the data array for the section
          const filteredSectionData = sectionData.filter((value) => value.id !== id); // Remove the item with the given id
          updatedData[sectionKey] = filteredSectionData; // Update the section data in the copied object
          setDataa(updatedData); // Update the state with the modified data 

          var formData = new FormData();
          formData.attachments = JSON.stringify(updatedData);
          formData.id = id;
          formData.project_id = props.project_id;
          callFetch("update-dc-files", "POST", formData).then((res) => {
            setRef(ref + 1);
          });

          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
  };
  const removeAttachment = (id, sectionKey) => {
    showAlert(id, sectionKey);
  }

   // Function to get the array of image objects with src properties
   const generateImageArray = (data) => {
      const baseURL = Cookies.get('backend_url') + 'storage/attachments/'; // Replace this with your actual base URL
      const imgs = [];

      for (const key in data) {
          if (data.hasOwnProperty(key)) {
              data[key].forEach(item => {
                  imgs.push({ src: baseURL + item.attachment });
              });
          }
      }

      console.log(imgs);

      setImages(imgs);
  };

  useEffect(() => {
    callFetch("get-dc-uploaded-files/" + props.project_id, "GET", []).then((res) => {
      setDataa(JSON.parse(res.filesdata));
      generateImageArray(JSON.parse(res.filesdata));
    });
  }, [props.project_id, props.refresh]);
  return (
    <>
      <div className="row mb-5">
        <SoftBox p={3} className="order-processing">
          <Grid container spacing={3}>
            {Object.keys(dataa).map((sectionKey, index) => (
              <>
                {dataa[sectionKey].map((imageData) => (
                  <Grid item key={index}>
                    <span key={index} onClick={(e) => openImgsViewer(index, e)} style={{cursor: 'pointer'}}>
                      <img src={Cookies.get('backend_url') + 'storage/attachments/' + imageData.attachment} alt={imageData.name} />
                    </span>
                    <br />
                    <a onClick={() => { removeAttachment(imageData.id, sectionKey); }} className="d-block text-center mt-2" > {t('Remove')} </a>
                  </Grid>
                ))}
              </>
            ))}

                <Lightbox
                        index={currentImg}
                        open={isOpen}
                        close={() => setIsOpen(false)}
                        slides={images.map((slide) => ({
                            ...slide,
                            download: `${slide.src}?download`,
                          }))}
                        plugins={[Download, Zoom, Counter, Thumbnails]}
                        counter={{ container: { style: { top: "unset", bottom: 0 } } }}
                        thumbnails={{
                            position,
                            width,
                            height,
                            border,
                            borderRadius,
                            padding,
                            gap,
                            showToggle,
                          }}
                        zoom={{
                            maxZoomPixelRatio,
                            zoomInMultiplier,
                            doubleTapDelay,
                            doubleClickDelay,
                            doubleClickMaxStops,
                            keyboardMoveDistance,
                            wheelZoomDistanceFactor,
                            pinchZoomDistanceFactor,
                            scrollToZoom,
                        }}
                    />
          </Grid>
        </SoftBox>
      </div>
      <ToastContainer />
    </>
  )
}

export default DCFiles