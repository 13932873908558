import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import IndexTable from './IndexTable';
import ReklamationIndexTable from './ReklamationIndexTable';

function Index() {
    const { t } = useTranslation();
    const [chooseplan, setChoosePlan] = useState('Projects');
    const [search, setSearch] = useState('');

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h6>
                                        <span className='float-start'>{t('Purchase')}</span>

                                        <div class="form-check float-start mx-3">
                                            <input
                                                onClick={(e) => {
                                                    setChoosePlan('Projects');
                                                }}
                                                class="form-check-input"
                                                type="radio"
                                                name="chooseplan"
                                                id="Projects"
                                                defaultChecked
                                            />
                                            <label class="form-check-label" for="Projects">{t('Projects')}</label>
                                        </div>

                                        <div class="form-check float-start mx-3">
                                            <input
                                                onClick={(e) => {
                                                    setChoosePlan('Reklamationnen');
                                                }}
                                                class="form-check-input"
                                                type="radio"
                                                name="chooseplan"
                                                id="acPlan"
                                            />
                                            <label class="form-check-label" for="acPlan">{t('Reklamationnen')}</label>
                                        </div>
                                    </h6>
                                </div>
                                <div className='col-md-6'>
                                    <input
                                        className='form-control float-end'
                                        placeholder={t('Search...')}
                                        style={{ maxWidth: '300px' }}
                                        onChange={(e)=> {
                                            setSearch(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            {chooseplan == 'Projects' ? <IndexTable chooseplan={chooseplan} search={search} /> : <ReklamationIndexTable chooseplan={chooseplan} search={search} />}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;
