import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate,useParams } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import { NumericFormat } from 'react-number-format';
import SoftAlert from "components/SoftAlert";
function Add() {
    let params = useParams();
    const { t } = useTranslation();
    const [quationId, setQuationId] = useState(0);
    const [auftragId, setAftragId] = useState(0);
    const [currency, setCurrency] = useState('EUR');
    const [contactPersons, setContactPersons] = useState([]);
    const [data, setData] = useState([]);
    const [emails, setEmails] = useState([{ name: '', email: '', currentEmail: '' }]);
    const [items, setItems] = useState([{ id: 0, title: '', quantity: 0, price: 0, currentPrice: 0, total: 0,artikel_id:0 }]);
    const [itemSummery, setItemSummery] = useState({ items: [], emails: [], subTotal: 0,amountPerchantage: 30, discountPercentage: 0, discount: 0, taxPercentage: 19, tax: 0, total: 0 });
    const [quotation, setQuotation] = useState(0);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [receivedError, setReceivedError] = useState(null);
    
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        setValue('quation_order_id', 'order*'+params.id);
        setValue('auftrag_id', 'order*'+params.id);
        setValue('order_id', params.id);
        console.log('order*'+params.id);
        var subTotal = 0;
        items.map((item) => {
            subTotal += item.total;
        });
        itemSummery.subTotal = subTotal;
        itemSummery.amount   = parseFloat((itemSummery.amountPerchantage / 100) * itemSummery.subTotal);
        // setSubtotal(subTotal);
        setValue('amountPerchantage', itemSummery.amountPerchantage);
        setValue('tax', itemSummery.taxPercentage);
        itemSummery.tax = parseFloat((itemSummery.amount * (itemSummery.taxPercentage / 100)).toFixed(2));
        itemSummery.discount = parseFloat((itemSummery.amount * (itemSummery.discountPercentage / 100)).toFixed(2));
        itemSummery.total = ((itemSummery.amount  + itemSummery.tax) - itemSummery.discount);
        itemSummery.items = items;
        setItemSummery(itemSummery);
        setRefresh2(refresh2 + 1);


        callFetch("order-add-invoice/" + params.id, "GET", []).then((res) => {
            if(res.error == '100'){ 
                setReceivedError(t('Inovice has created for receivable amount')); 
            }
            if(res?.data?.receivable){
                items[0].id = 1;
                items[0].price = Number(res.data.order.netto);
                items[0].quantity = 1;
                items[0].currentPrice = Number(res.data.order.netto);
                items[0].total = items[0].currentPrice * items[0].quantity;

                setItems(items);
                setRefresh(refresh + 1);
                setRefresh2(refresh2 + 1); 
                setValue('amountPerchantage', res?.data?.receivable);
                itemSummery.amountPerchantage = res?.data?.receivable;
                setItemSummery(itemSummery);
                setReceivedError(null); 
            }
            // items[0].id = 1;
            // items[0].price = Number(res.data.order.netto);
            // items[0].quantity = 1;
            // items[0].currentPrice = Number(res.data.order.netto);
            // items[0].total = items[0].currentPrice * items[0].quantity;

            // setItems(items);
            // setRefresh(refresh + 1);
            // setRefresh2(refresh2 + 1);
        });
    }, [refresh]);

    useEffect(() => { }, [refresh2]);

    useEffect(() => {        
        if(quationId > 0){
            callFetch("quation/" + quationId + "/edit", "GET", []).then((res) => {
                items[0].id = res.data.speicher;
                items[0].price = Number(res.data.gesami_netto);
                items[0].quantity = 1;
                items[0].currentPrice = Number(res.data.gesami_netto);
                items[0].total = items[0].currentPrice * items[0].quantity;

                setItems(items);                
                setRefresh(refresh + 1);
                setRefresh2(refresh2 + 1);
            });
        }else if(auftragId > 0){
            callFetch("order-add-invoice/" + auftragId, "GET", []).then((res) => {
                if(res.error == '100'){ 
                    setReceivedError(t('Inovice has created for receivable amount')); 
                }
                if(res?.data?.receivable){
                    items[0].id = 1;
                    items[0].price = Number(res.data.order.netto);
                    items[0].quantity = 1;
                    items[0].currentPrice = Number(res.data.order.netto);
                    items[0].total = items[0].currentPrice * items[0].quantity;

                    setItems(items);
                    setRefresh(refresh + 1);
                    setRefresh2(refresh2 + 1); 
                    setValue('amountPerchantage', res?.data?.receivable);
                    itemSummery.amountPerchantage = res?.data?.receivable;
                    setItemSummery(itemSummery);
                    setReceivedError(null); 
                }
                // items[0].id = 1;
                // items[0].price = Number(res.data.order.netto);
                // items[0].quantity = 1;
                // items[0].currentPrice = Number(res.data.order.netto);
                // items[0].total = items[0].currentPrice * items[0].quantity;

                // setItems(items);
                // setRefresh(refresh + 1);
                // setRefresh2(refresh2 + 1);
            });
        }
    }, [quationId+auftragId, params.id]);

    useEffect(() => {
        setValue('quation_order_id', 'order*'+params.id);
        setValue('auftrag_id', 'order*'+params.id);
        flatpickr(".flat-pickr");

        callFetch("invoices/create", "GET", []).then((res) => {
            setData(res.data);
            setValue('id', res.data.id);
            setRefresh(refresh + 1)
        });
    }, []);

    function deleteProduct() {
        var titems = [];
        items.map((t) => {
            if (!t)
                return;
            titems.push(t);
        });
        setItems(titems);
        setRefresh(refresh + 1);
    }

    function deleteEmail() {
        var temails = [];
        emails.map((e) => {
            if (!e && e !== '')
                return;
            temails.push(e);
        });
        setEmails(temails);
        itemSummery.emails = temails;
        setItemSummery(itemSummery);
        setRefresh2(refresh2 + 1);
    }

    const onSubmit = (formData) => {
        setSaving(true);
        formData.subTotal = itemSummery.subTotal;
        formData.netto = itemSummery.amount;
        formData.brutto = itemSummery.total;
        formData.product_details = JSON.stringify(itemSummery);
        callFetch("invoice-add", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setQuotation(res.data);
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to={'/invoices'} /> :
        <div className="row">
            <div className="col-12">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t('Add Invoices')}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <div className="row g-3">
                                <div className="col-md-3">
                                    <label>{t('Invoice Nr.')} *</label>
                                     <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('eg: 700001')}
                                        {...register("id", {
                                            required: true,
                                        })}
                                        readOnly
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.identity_number && errors.identity_number.message}</div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>
                                            {t('Angebot/Auftrag')} *
                                        </label>
                                        <br />
                                        <select readOnly
                                            className="form-control"
                                            {...register("quation_order_id", {
                                                required: true,
                                            })}
                                            onChange={(e) => {
                                                let quationOrder = e.target.value.split('*');
                                                if(quationOrder[0] == 'quation'){
                                                    setQuationId(quationOrder[1]);
                                                    setAftragId(0);
                                                    setValue('quation_id', quationOrder[1]);
                                                    setValue('auftrag_id', 0);
                                                }else if(quationOrder[0] == 'order'){
                                                    setAftragId(quationOrder[1]);
                                                    setQuationId(0);
                                                    setValue('auftrag_id', quationOrder[1]);
                                                    setValue('quation_id', 0);
                                                }
                                            }}
                                            required
                                        >
                                            <option value="">--</option>
                                            {data.quations && data.quations.map((quation) => (
                                                <option key={quation.id} value={'quation*'+quation.id}>{quation.id}</option>
                                            ))}
                                            {data.orders && data.orders.map((order) => (
                                                <option key={order.id} value={'order*'+order.id}>{order.id}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">{errors.quation_id && errors.quation_id.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>
                                            {t('Currency')} *
                                        </label>
                                        <br />
                                        <select
                                            className="form-control"
                                            {...register("currency", {
                                                required: true,
                                            })}
                                            onChange={(e) => {
                                                setCurrency(e.target.value)
                                            }}
                                            required
                                        >
                                            <option value="EUR">EUR (€)</option>
                                            <option value="USD">USD ($)</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.currency && errors.currency.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <label>
                                        {t('Invoice Date')} *
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control mb-4 flat-pickr"
                                        placeholder={t('eg. 2001-03-20')}
                                        defaultValue={dateFormat(new Date(), "yyyy-mm-dd")}
                                        {...register("date", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.start_date && errors.start_date.message}</div>
                                </div>
                            </div>

                            <div className="row mt-3 d-none">
                                <div className="col-md-12">
                                    <label>{t('Description')}</label>
                                    <textarea className="form-control" rows="3"
                                        defaultValue=""
                                        placeholder="eg. Description"
                                        {...register("description")}></textarea>
                                </div>
                            </div>

                            <div className="mt-3">
                                {items && items.map((item, i) => (
                                    <div key={i} className="d-none">
                                        {i > 0 && <hr />}
                                        <div className="row mt-2">
                                            <div className="col-md-6">
                                                <div>
                                                    <label>{t('Product')}</label>
                                                    <select value={items[i].id} className="form-control" placeholder={t('Select Product')} onChange={(e) => {
                                                        items[i].id = e.target.value;
                                                        items[i].title = '';
                                                        items[i].price = 0;
                                                        items[i].currentPrice =0;
                                                        items[i].quantity = 1;
                                                        items[i].total = 0;
                                                        items[i].artikel_id = e.target.value;
                                                        setItems(items);
                                                        setRefresh(refresh + 1);
                                                    }}>
                                                        <option value="">--</option>
                                                        {data.products && data.products.map((product, i) => (
                                                            <option key={i} value={product.id}>{product.name}</option>
                                                        ))}
                                                </select>
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div>
                                                <label>{t('Qty/Hrs')}</label>
                                                <input type="number" className="form-control" placeholder="eg. 0.00" defaultValue="1" onChange={(e) => {
                                                    let value = 0;
                                                    if (e.target.value.length && !isNaN(e.target.value))
                                                        value = e.target.value;
                                                    items[i].quantity = parseFloat(value);
                                                    items[i].total = items[i].currentPrice * items[i].quantity;
                                                    setItems(items);
                                                    setRefresh(refresh + 1);
                                                }} />
                                            </div>
                                            </div>
                                            <div className="col-md-2">
                                                <label>{t('Unit Price')}</label>
                                                <input type="number" className="form-control" placeholder="eg. 0.00" value={item.currentPrice ? item.currentPrice : ''} onChange={(e) => {
                                                    let value = 0;
                                                    if (e.target.value.length && !isNaN(e.target.value))
                                                        value = e.target.value;
                                                    items[i].currentPrice = parseFloat(value);
                                                    items[i].total = items[i].currentPrice * items[i].quantity;
                                                    setItems(items);
                                                    setRefresh(refresh + 1);
                                                }} />
                                            </div>
                                            <div className="col-md-2">
                                                <label>{t('Amount')}</label>
                                                <input type="number" className="form-control d-inline" style={{ width: '84%' }} placeholder="eg. 0.00" value={item.total} readOnly />
                                                &nbsp;
                                                <i className="fa-solid fa-circle-xmark text-danger" data-key={i} onClick={() => { delete items[i]; deleteProduct(); }}></i>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div className="row mt-3 d-none">
                                    <div className="col-md-12">
                                        <p className="btn btn-dark" style={{ cursor: 'pointer' }} onClick={() => { setItems([...items, { id: 0, title: '', quantity: 0, price: 0, currentPrice: 0, total: 0 }]); setRefresh(refresh + 1); }}><i className="fa-solid fa-circle-plus"></i>&nbsp;{t('Add Item')}</p>
                                    </div>
                                </div>

                                <hr />

                                <div className="row">
                                    <div className="col-md-6 offset-md-6 border">
                                        <div className="row">
                                            <div className="col-md-7 border-end">
                                                <label className="d-block text-end">{t('Sub Total')}</label>
                                                <select {...register('amountPerchantage')} onChange={(e) =>{ itemSummery.amountPerchantage = parseFloat(e.target.value); setItemSummery(itemSummery);
                                                    setRefresh(refresh + 1) }} className="form-control mb-2" style={{width: '70%', position: 'relative', left: '68px'}}>
                                                    <option value="0">--</option>
                                                    <option value="100">100%</option>
                                                    <option value="70">70%</option>
                                                    <option value="30">30%</option>
                                                </select>
                                            </div>
                                            <div className="col-md-5">
                                                <p className="text-end">
                                                    <NumericFormat 
                                                        value={itemSummery.amount}
                                                        displayType="text" 
                                                        thousandSeparator={"."} 
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                    />
                                                    </p>
                                            </div>
                                        </div>
                                        <div className="row border-top d-none">
                                            <div className="col-md-3 border-end">
                                                <label className="d-block text-end">{t('Discount')}</label>
                                            </div>
                                            <div className="col-md-3 border-end">
                                                <input type="number" className="form-control" onChange={(e) => {
                                                    let value = 0;
                                                    if (e.target.value.length && !isNaN(e.target.value))
                                                        value = e.target.value;
                                                    itemSummery.discountPercentage = parseFloat(value);
                                                    setItemSummery(itemSummery);
                                                    setRefresh(refresh + 1);
                                                }} />
                                            </div>
                                            <div className="col-md-1 border-end">
                                                <p className="text-end">%</p>
                                            </div>
                                            <div className="col-md-5">
                                                <p className="text-end">
                                                    <NumericFormat 
                                                        value={itemSummery.discount}
                                                        displayType="text" 
                                                        thousandSeparator={"."} 
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row border-top">
                                            <div className="col-md-3 border-end">
                                                <label className="d-block text-end">{t('Tax')}</label>
                                            </div>
                                            <div className="col-md-4 border-end">
                                               <select {...register('tax')} className="form-control" onChange={(e) => {
                                                    let value = 19;
                                                    if (e.target.value.length && !isNaN(e.target.value))
                                                        value = e.target.value;
                                                    itemSummery.taxPercentage = parseFloat(value);
                                                    setItemSummery(itemSummery);
                                                    setRefresh(refresh + 1);
                                                }}>
                                                    <option value="19">19%</option>
                                                    <option value="0">0%</option> 
                                               </select>
                                            </div>
                                            <div className="col-md-5">
                                                <p className="text-end">
                                                    <NumericFormat 
                                                        value={itemSummery.tax}
                                                        displayType="text" 
                                                        thousandSeparator={"."} 
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row bg-gray-200">
                                            <div className="col-md-7">
                                                <label className="d-block text-end">{t('Total')}</label>
                                            </div>
                                            <div className="col-md-5">
                                                <p className="text-end">
                                                    <NumericFormat 
                                                        value={itemSummery.amount + itemSummery.tax}
                                                        displayType="text" 
                                                        thousandSeparator={"."} 
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {/*
                            <hr />
                            {emails && emails.map((email, i) => (
                                <div key={i} className="row mt-3">
                                    <div className="col-md-4">
                                        <label>{t('Name')}</label>
                                        <select className="form-control" value={email.name + '*' + email.email} onChange={(e) => {
                                            var cp = e.target.value.split('*');
                                            emails[i].name = cp[0];
                                            emails[i].email = cp[1];
                                            emails[i].currentEmail = cp[1];
                                            setEmails(emails);
                                            itemSummery.emails = emails;
                                            setItemSummery(itemSummery);
                                            setRefresh2(refresh2 + 1);
                                        }}>
                                            <option value="">--</option>
                                            {contactPersons && contactPersons.map((person, pi) => person.name && (
                                                <option key={pi} value={person.name + '*' + person.emails[0]}>{person.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label>{t('Email')}</label>
                                        <input type="email" style={{ width: '93%' }} className="form-control d-inline-block mt-1" value={email.currentEmail} onChange={(e) => {
                                            emails[i].currentEmail = e.target.value;
                                            setEmails(emails);
                                            itemSummery.emails = emails;
                                            setItemSummery(itemSummery);
                                            setRefresh2(refresh2 + 1);
                                        }} placeholder="eg. email@mail.com" />
                                        &nbsp;
                                        <i className="fa-solid fa-circle-xmark text-danger" data-key={i} onClick={() => { delete emails[i]; deleteEmail(); }}></i>

                                        <br />
                                    </div>
                                </div>
                            ))}
                            <p className="btn btn-dark mt-2" style={{ cursor: 'pointer' }} onClick={() => { setEmails([...emails, { name: '', email: '', currentEmail: '' }]); setRefresh2(refresh2 + 1); }}><i className="fa-solid fa-circle-plus"></i>&nbsp;{t('Add Contact Person')}</p>
                            */}

                            <div className="row mt-3">
                                <div className="col-md-5">{receivedError && <SoftAlert color="error" dismissible> {receivedError} </SoftAlert> }</div>
                                <div className="col-md-12 mt-3">
                                    {!saving && (
                                        <button type="submit" className="btn btn-primary">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>;
}

export default Add;
