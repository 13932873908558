import { React, useEffect, useState } from "react";
import Calendar from "./calendar/Calendar";
import callFetch from "helpers/callFetch";
import { useForm } from "react-hook-form";

function OperationalPlan(props) {

  const [resources, setResources] = useState([]);
  const [events, setEvents] = useState([]);
  const [search, setSearch] = useState('');
  const [refresh, setRefresh] = useState(0);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {

    var formData = new FormData();
    formData.search = search;

    callFetch("resource-timeline", "POST", formData, setError).then((res) => {
      setResources(res.data.resources);
      setEvents(res.data.events);
    });
  }, [search, props?.refresh, refresh]);

  return (
    <>
      <Calendar resources={resources} setRefresh={setRefresh} events={events} setSearch={setSearch} setEventTechnicianLocation={props?.setEventTechnicianLocation} />
    </>
  );
}

export default OperationalPlan;
