import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

function Edit() {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [editDelay, setEditDelay] = useState(0);
    const [data, setData] = useState([]);
    const [file, setFile] = useState(false);
    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        setValue,
        formState: { errors },
    } = useForm();

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            clearErrors('file');
        }
        e.target.value = '';
    }

    const handleFileRemove = () => {
        setSelectedFile(null)
    }

    useEffect(() => {
        if (!params.id) return;

        callFetch("wechselrichter/" + params.id + "/edit", "GET", []).then((res) => {
            setData(res);
            if (res?.data?.file?.length > 0) {
                setFile(true);
            }
        });
    }, [params.id]);

    useEffect(() => {
        if (!data?.data) return;
        for (let [key, value] of Object.entries(data.data)) {
            setValue(key, (value == null || value == 'null' ? "" : value));
        }
    }, [data]);

    const onSubmit = (formData) => {
        if (selectedFile) {
            formData.file = selectedFile;
        }

        setSaving(true);
        callFetch("wechselrichter/" + params.id, "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to='/products/wechselrichter' /> :
        <div className="row">
            <div className="col-md-9">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t('Edit Wechselrichter')}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <input type="hidden" defaultValue="PUT" {...register("_method")} />

                            <div className="row g-3">
                                <div className="col-md-4">
                                    <label>{t('Nummber')} *</label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('')}
                                        {...register("identity", {
                                            required: true,
                                        })}
                                        required
                                        readOnly
                                    />
                                    <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                </div>
                            </div>

                            <div className="row g-3">
                                <div className="col-md-4">
                                    <label>{t('Hersteller / Lieferanten')} *</label>
                                    <select
                                        className="form-control mb-4"
                                        {...register("lieferanten_id", {
                                            required: true,
                                        })}
                                        required
                                    >
                                        <option value={''}>{t("Select Lieferanten")}</option>
                                        {data?.lieferanten && data?.lieferanten.map((lief) => (
                                            <option value={lief?.id}>{lief?.lieferanten_nummer + (lief?.firma_name ? ' - ' + lief?.firma_name : '')}</option>
                                        ))}
                                    </select>
                                    <div className="invalid-feedback">{errors.lieferanten_id && errors.lieferanten_id.message}</div>
                                </div>
                            </div>

                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label>{t('Title')} *</label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('')}
                                        {...register("name", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                </div>

                                <div className="col-md-6">
                                    <label>{t('Angebotstitel')} *</label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('')}
                                        {...register("angebotstitel", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.angebotstitel && errors.angebotstitel.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('Description')}</label>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        placeholder={t('')}
                                        {...register("description")}></textarea>
                                    <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                </div>

                                <div className="col-12 mb-4">
                                    {!saving && (
                                        <button type="submit" className="btn btn-primary">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="col-3">
                <div className="card">
                    <div className="card-header">
                        <label>{t('Datenblätter etc.')}</label>
                        <input
                            type="file"
                            className="form-control"
                            onChange={(e) => { handleFileChange(e); }}
                        />
                        <div className="invalid-feedback d-block">{errors.file && errors.file.message}</div>
                    </div>
                    <div className="card-body pt-0 position-relative">
                        {selectedFile?.type.startsWith('image/') ? (
                            <>
                                <img
                                    src={selectedFile && URL.createObjectURL(selectedFile)}
                                    alt="Selected"
                                    width="100%"
                                    height="100%"
                                    className="border-radius-md w-100 h-100"
                                />
                                &nbsp;
                                <i style={{ top: "-5px" }} className="fa-solid fa-circle-xmark text-danger cursor-pointer position-absolute end-4" onClick={handleFileRemove}></i>
                            </>
                        ) : selectedFile?.type.startsWith('application/') ? (
                            <>
                                <iframe
                                    src={URL.createObjectURL(selectedFile)}
                                    title="PDF Viewer"
                                    width="100%"
                                    height="350px" // Adjust the height as needed
                                    className="border-radius-md"
                                ></iframe>
                                &nbsp;
                                <i style={{ top: "-5px" }} className="fa-solid fa-circle-xmark text-danger cursor-pointer position-absolute end-4" onClick={handleFileRemove}></i>
                            </>
                        ) : data?.data?.file?.endsWith('.pdf') ? <iframe
                            src={Cookies.get('backend_url') + 'storage/wechselrichter/' + data?.data?.file}
                            title="PDF Viewer"
                            width="100%"
                            height="350px" // Adjust the height as needed
                        /> : data?.data?.file ? <img
                            src={Cookies.get('backend_url') + 'storage/wechselrichter/' + data?.data?.file}
                            alt="Selected"
                            width="100%"
                            height="100%"
                            className="border-radius-md w-100 h-100"
                        /> : ''}
                    </div>
                </div>
            </div>

        </div>;
}

export default Edit;
