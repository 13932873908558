import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

function Edit() {
    const { t } = useTranslation();
    const [designations, setDesignations] = useState([]);
    const [roles, setRoles] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [saving, setSaving] = useState(false);
    const [teamMembers, setTeamMembers] = useState(null);
    const [head, setHead] = useState([]);
    const [manager, setManager] = useState([]);
    const [frontofficeAggent, setFrontofficeAggent] = useState([]);
    const [data, setData] = useState(null);
    const [employees, setEmployees] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    let { id } = useParams();
    const [teamType, setTeamType] = useState('');

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const teamCreateData = (team_type = '') => {
        callFetch("teams/create?team_type=" + team_type, "GET", []).then((res) => {

            var editData = data;
            editData.manager = res.data.manager;
            editData.members = res.data.members;
            editData.headofSales = res.data.headofSales;
            setData(editData);
            setRefresh(refresh + 1);

        });
    }

    useEffect(() => {
        if (!id) return;
        callFetch("teams/edit/" + id, "GET", []).then((res) => {
            setData(res.data);
            const teamData = res.data.data;
            setValue('team_id', teamData.team_id);
            setValue('frontoffice_agent', teamData.frontoffice_agent);
            setValue('name', teamData.team_name);
            setValue('team_type', teamData.team_type);
            setTeamType(teamData.team_type);


            var allheads = [];
            var allManagers = [];
            var allTeamMembers = [];
            var allFrontofficeAggent = [];
            for (let [key, value] of Object.entries(teamData.head)) {
                if (value?.user?.id) {
                    allheads.push(value?.user);
                }
            }

            for (let [key, value] of Object.entries(teamData.manager)) {
                if (value?.user?.id) {
                    allManagers.push(value?.user);
                }
            }

            for (let [key, value] of Object.entries(teamData.teammember)) {
                if (value?.employe?.id) {
                    allTeamMembers.push(value?.employe);
                }
            }

            for (let [key, value] of Object.entries(teamData.frontoffice_aggent)) {
                if (value?.user?.id) {
                    allFrontofficeAggent.push(value?.user);
                }
            }

            setHead(allheads);
            setManager(allManagers);
            setTeamMembers(allTeamMembers);
            setFrontofficeAggent(allFrontofficeAggent);
        });
    }, [id]);

    const onSubmit = (formData) => {
        setSaving(true);
        formData.team_id = id;
        formData.head_of_sales = JSON.stringify(head);
        formData.team_manager = JSON.stringify(manager);
        formData.teamMembers = JSON.stringify(teamMembers);
        formData.frontoffice_agent = JSON.stringify(frontofficeAggent);
        callFetch("teams/update", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to={'/teams/' + getValues('team_type')} /> :
        <div className="row">
            <div className="col-12 col-md-9">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t('Add Team')}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <label>
                                        {t('Team ID')} *
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('eg. 123')}
                                        {...register("team_id", {
                                            required: true,
                                        })}
                                        required
                                        readOnly
                                    />
                                    <div className="invalid-feedback">{errors.team_id && errors.team_id.message}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label>
                                        {t('Team Name')} *
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('eg. Sales Team')}
                                        {...register("name", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>{t('Bereich auswählen')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("team_type", {
                                                required: true,
                                                onChange: (e) => {
                                                    teamCreateData(getValues('team_type'));
                                                    setTeamType(e.target.value);
                                                    setHead([]);
                                                    setManager([]);
                                                    setTeamMembers([]);
                                                    setFrontofficeAggent([]);
                                                }
                                            })}
                                            required
                                        >
                                            <option value="">--</option>
                                            <option value="sales">{t('Sales Team')}</option>
                                            <option value="resell">{t('Resell Team')}</option>
                                            <option value="ac">{t('AC Team')}</option>
                                            <option value="dc">{t('DC Team')}</option>

                                        </select>
                                        <div className="invalid-feedback">{errors.team_type && errors.team_type.message}</div>
                                    </div>
                                </div>

                                {teamType ? (
                                    <>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>{t(teamType == 'sales' || teamType == 'resell' ? 'Head of Sales' : teamType == 'ac' ? 'Technical Manager AC' : teamType == 'dc' ? 'Technical Manager DC' : '')} hinzufügen *</label>
                                                <Select
                                                    placeholder={t('Select')}
                                                    options={data?.headofSales ? data?.headofSales : { value: '', label: '---' }}
                                                    value={head}
                                                    getOptionLabel={(option) => option?.name}
                                                    getOptionValue={(option) => option?.id}
                                                    onChange={(e) => {
                                                        setHead(e);
                                                        //setValue('teamMembers', JSON.stringify(e));
                                                    }}
                                                    isMulti
                                                />
                                                <div className="invalid-feedback">{errors.head_of_sales && errors.head_of_sales.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>{t('Team Manager')} hinzufügen</label>
                                                <Select
                                                    placeholder={t('Select')}
                                                    options={data?.manager ? data?.manager : { value: '', label: '---' }}
                                                    value={manager}
                                                    getOptionLabel={(option) => option?.name}
                                                    getOptionValue={(option) => option?.id}
                                                    onChange={(e) => {
                                                        setManager(e);
                                                    }}
                                                    isMulti
                                                />
                                                <div className="invalid-feedback">{errors.team_manager && errors.team_manager.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>{t('Team Members')} hinzufügen</label>
                                                <Select
                                                    placeholder={t('Select')}
                                                    options={data?.members ? data?.members : { value: '', label: '---' }}
                                                    value={teamMembers}
                                                    getOptionLabel={(option) => option?.name}
                                                    getOptionValue={(option) => option?.id}
                                                    onChange={(e) => {
                                                        setTeamMembers(e);
                                                    }}
                                                    isMulti
                                                />
                                                <div className="invalid-feedback">{errors.teamMembers && errors.teamMembers.message}</div>
                                            </div>
                                        </div>

                                        {teamType == 'sales' || teamType == 'resell' ? (
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('Innendienstmitarbeiter hinzufügen')}</label>
                                                    <Select
                                                        placeholder={t('Select')}
                                                        options={data?.frontoffice_agent ? data?.frontoffice_agent : { value: '', label: '---' }}
                                                        value={frontofficeAggent}
                                                        getOptionLabel={(option) => option?.name}
                                                        getOptionValue={(option) => option?.id}
                                                        onChange={(e) => {
                                                            setFrontofficeAggent(e);
                                                        }}
                                                        isMulti
                                                    />
                                                    <div className="invalid-feedback">{errors.frontoffice_agent && errors.frontoffice_agent.message}</div>
                                                </div>
                                            </div>
                                        ) : ''}

                                    </>
                                ) : ''}

                                <div className="col-12 mb-4">
                                    {!saving && (
                                        <button type="submit" className="btn btn-primary">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </div >;
}

export default Edit;
