import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "helpers/callFetch";
import { useForm } from "react-hook-form";

function Product(props) {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0);
    const [saving, setSaving] = useState(false);
    const [products, setProducts] = useState([]);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if(!props?.productModalData?.products) return;
        setProducts(props?.productModalData?.products)
    }, [props]);


    const onSubmit = (formData) => {
        setSaving(true);
        formData = {};
        formData.products = JSON.stringify(products);
        formData.editable = props?.editable;
        callFetch("product-purchase", "POST", formData).then((res) => {

            setSaving(false);

            if (document.getElementById('purchase-list-refresh')) {
                document.getElementById('purchase-list-refresh').click();
            }
        });
    }

    return (
        <>
            <button
                type="button"
                id="ZusatzvereinbarungBtn"
                className="btn bg-gradient-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#Zusatzvereinbarung"
            >{t('Zusatzvereinbarung')}</button>

            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <div className="modal fade" id="Zusatzvereinbarung" tabindex="-1" role="dialog" aria-labelledby="ZusatzvereinbarungLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title" id="ZusatzvereinbarungLabel" style={{ fontWeight: 'bold' }}>{t('Zusatzvereinbarung')}</h6>
                                <button
                                    type="button"
                                    id="ZusatzvereinbarungClose"
                                    className="btn-close text-dark"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        // clearFormData();
                                    }}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body tb-ac-cs">

                                <div className='row'>
                                    {products && products.map((p, i) => (
                                        <>
                                            {p?.product?.category == props?.editable ? (
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>{p?.product?.name} </label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            placeholder={t('')}
                                                            value={p?.purchase_date}
                                                            /*
                                                            {...register("purchase_date[" + p.id + "]", {
                                                                required: true,
                                                            })}
                                                            */
                                                            
                                                            onChange={(e) => {
                                                                var data = products
                                                                data[i].purchase_date = e.target.value;
                                                               setProducts(data);
                                                               setRefresh(refresh+1)
                                                            }}
                                                            
                                                            required
                                                            readOnly={p?.product?.category == props?.editable ? false : true}
                                                        />
                                                    </div>
                                                </div>
                                            ) : ''}
                                        </>
                                    ))}
                                </div>
                            </div>
                            <div className="modal-footer">
                                {saving ? (
                                    <button type="submit" data-bs-dismiss="modal" aria-label="Close" className="btn btn-disabled" disabled>
                                        {t('Saving ...')}
                                    </button>
                                ) : (
                                    <button type="submit" data-bs-dismiss="modal" aria-label="Close" className="btn btn-primary">
                                        {t('Save')}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Product;
