import { React, useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import callFetch from "../../../helpers/callFetch";


function NewAufgabe() {
    const { t } = useTranslation();

    function doSignout(e) {
        e.preventDefault();
        callFetch("signout", "POST", [], null).then((res) => {
            Cookies.remove("user");
            Cookies.remove("token");
            var newTabClose = document.getElementById("newTabClose");
            newTabClose.click();
        });
    }

    return (
        <>
            <button
                type="button"
                id="eventEditModalOpen"
                className="btn bg-gradient-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#eventEditModal"
            >
                View Calander Click Event Data
            </button>

            <div
                className="modal fade"
                id="newTabModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="newTabLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="newTabLabel">
                                {t("")}
                            </h5>
                            <button
                                type="button"
                                id="newTabClose"
                                className="btn-close text-dark"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body apps-icon-link">
                            <div className="grid grid-cols-3 gap-4">
                                {Cookies.get("permissions").indexOf("QC") !==
                                    -1 ? (
                                    <NavLink
                                        to="/quations/create"
                                        data-bs-dismiss="modal"
                                        className="d-block p-4 text-center rounded-2xl hover:bg-gray-100"
                                    >
                                        <svg
                                            className="mx-auto mb-1 w-7 h-7 text-gray-500"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <div className="text-sm font-medium text-gray-900">
                                            {t("Neues Angebot")}
                                        </div>
                                    </NavLink>
                                ) : (
                                    <></>
                                )}

                                {Cookies.get("permissions").indexOf("EC") !==
                                    -1 ? (
                                    <NavLink
                                        to="/employees/create"
                                        data-bs-dismiss="modal"
                                        className="d-block p-4 text-center rounded-2xl hover:bg-gray-100"
                                    >
                                        <svg
                                            className="mx-auto mb-1 w-7 h-7 text-gray-500"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                                        </svg>
                                        <div className="text-sm font-medium text-gray-900">
                                            {t("Add Employee")}
                                        </div>
                                    </NavLink>
                                ) : (
                                    <></>
                                )}

                                <NavLink
                                    to="/chat/send-message"
                                    data-bs-dismiss="modal"
                                    className="d-block p-4 text-center rounded-2xl hover:bg-gray-100"
                                >
                                    <svg
                                        className="mx-auto mb-1 w-7 h-7 text-gray-500"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm0 2h10v7h-2l-1 2H8l-1-2H5V5z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                    <div className="text-sm font-medium text-gray-900">
                                        {t("New Message")}
                                    </div>
                                </NavLink>

                                {Cookies.get("permissions").indexOf("PrC") !== -1 ? (
                                    <NavLink
                                        to="/projects-create"
                                        data-bs-dismiss="modal"
                                        className="d-block p-4 text-center rounded-2xl hover:bg-gray-100"
                                    >
                                        <i className="fa-solid fa-briefcase mx-auto mb-1 w-7 h-7 text-gray-500"></i>
                                        <div className="text-sm font-medium text-gray-900">
                                            {t("New Project")}
                                        </div>
                                    </NavLink>
                                ) : (
                                    <></>
                                )}

                                <NavLink
                                    to="/roles"
                                    data-bs-dismiss="modal"
                                    className="d-block p-4 text-center rounded-2xl hover:bg-gray-100"
                                >
                                    <svg
                                        className="mx-auto mb-1 w-7 h-7 text-gray-500"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                    <div className="text-sm font-medium text-gray-900">
                                        {t("Settings")}
                                    </div>
                                </NavLink>

                                {Cookies.get("permissions").indexOf("PC") !== -1 ? (
                                    <NavLink
                                        to="/products"
                                        data-bs-dismiss="modal"
                                        className="d-block p-4 text-center rounded-2xl hover:bg-gray-100"
                                    >
                                        <svg
                                            className="mx-auto mb-1 w-7 h-7 text-gray-500"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                                            <path
                                                fill-rule="evenodd"
                                                d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <div className="text-sm font-medium text-gray-900">
                                            {t("New Product")}
                                        </div>
                                    </NavLink>
                                ) : (
                                    <></>
                                )}

                                {Cookies.get("permissions").indexOf("IC") !== -1 ? (
                                    <NavLink
                                        to="/invoices/create"
                                        data-bs-dismiss="modal"
                                        className="d-block p-4 text-center rounded-2xl hover:bg-gray-100"
                                    >
                                        <svg
                                            className="mx-auto mb-1 w-7 h-7 text-gray-500"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"></path>
                                            <path
                                                fill-rule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <div className="text-sm font-medium text-gray-900">
                                            {t("Create Invoice")}
                                        </div>
                                    </NavLink>
                                ) : (
                                    <></>
                                )}

                                {Cookies.get("permissions").indexOf("OC") !== -1 ? (
                                    <NavLink
                                        to="/orders"
                                        data-bs-dismiss="modal"
                                        className="d-block p-4 text-center rounded-2xl hover:bg-gray-100"
                                    >
                                        <svg
                                            className="mx-auto mb-1 w-7 h-7 text-gray-500"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M5 2a2 2 0 00-2 2v14l3.5-2 3.5 2 3.5-2 3.5 2V4a2 2 0 00-2-2H5zm2.5 3a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm6.207.293a1 1 0 00-1.414 0l-6 6a1 1 0 101.414 1.414l6-6a1 1 0 000-1.414zM12.5 10a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <div className="text-sm font-medium text-gray-900">
                                            {t("Create Order")}
                                        </div>
                                    </NavLink>
                                ) : (
                                    <></>
                                )}

                                <NavLink
                                    to="#0"
                                    onClick={(e) => doSignout(e)}
                                    data-bs-dismiss="modal"
                                    className="d-block p-4 text-center rounded-2xl hover:bg-gray-100"
                                >
                                    <svg
                                        className="mx-auto mb-1 w-7 h-7 text-gray-500"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                                        ></path>
                                    </svg>
                                    <div className="text-sm font-medium text-gray-900">Logout</div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewAufgabe;
