import { React, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import IndexTable from './IndexTable';

function Index() {
    const { t } = useTranslation();
    const [status, setStatus] = useState('all');

    return (
        <>
            <div className="d-sm-flex justify-content-between">
                <div className="relative-h">
                    {JSON.parse(Cookies.get('permissions')).indexOf("QC") !== -1 ? (
                        <NavLink to="/invoices/create" className="btn btn-icon btn-primary">
                            {t('Create Invoice')}
                        </NavLink>
                    ) : <></>}

                    {JSON.parse(Cookies.get('permissions')).indexOf("QC") !== -1 ? (
                        <button
                            onClick={() => {
                                document.getElementById('incoices-export-csv').click();
                            }}
                            className="btn btn-outline-dark pull-right">
                            {t('Export CSV')}
                        </button>
                    ) : <></>}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('All Invoices')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2 projects-menu">
                            <div style={{ padding: '0px 15px' }}>
                                <ul className="nav nav-tabs nav-underline" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button onClick={() => { setStatus('all') }} className={"nav-link " + (status == 'all' ? 'active' : '')} id="all-tab" data-bs-toggle="tab" data-bs-target="#all-tab-pane" type="button" role="tab" aria-controls="all-tab-pane" aria-selected="true" style={{ color: '#0048B1', fontSize: '0.875rem' }}>{t('All Invoices')}</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button onClick={() => { setStatus('paid') }} className={"nav-link " + (status == 'paid' ? 'active' : '')} id="paid-tab" data-bs-toggle="tab" data-bs-target="#paid-tab-pane" type="button" role="tab" aria-controls="paid-tab-pane" aria-selected="false" style={{ color: '#0048B1', fontSize: '0.875rem' }}>{t('2/2 Getilgt')}</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button onClick={() => { setStatus('paritial') }} className={"nav-link " + (status == 'paritial' ? 'active' : '')} id="paritial-tab" data-bs-toggle="tab" data-bs-target="#paritial-tab-pane" type="button" role="tab" aria-controls="paritial-tab-pane" aria-selected="false" style={{ color: '#0048B1', fontSize: '0.875rem' }}>{t('1/2 Getilgt')}</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button onClick={() => { setStatus('open') }} className={"nav-link " + (status == 'open' ? 'active' : '')} id="open-tab" data-bs-toggle="tab" data-bs-target="#open-tab-pane" type="button" role="tab" aria-controls="open-tab-pane" aria-selected="false" style={{ color: '#0048B1', fontSize: '0.875rem' }}>{t('Open')}</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button onClick={() => { setStatus('gutschrift') }} className={"nav-link " + (status == 'gutschrift' ? 'active' : '')} id="gutschrift-tab" data-bs-toggle="tab" data-bs-target="#gutschrift-tab-pane" type="button" role="tab" aria-controls="gutschrift-tab-pane" aria-selected="false" style={{ color: '#0048B1', fontSize: '0.875rem' }}>{t('Gutschrift')}</button>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <button onClick={() => { setStatus('mahnung') }} className={"nav-link " + (status == 'mahnung_fallig' ? 'active' : '')} id="mahnung_fallig-tab" data-bs-toggle="tab" data-bs-target="#mahnung_fallig-tab-pane" type="button" role="tab" aria-controls="mahnung_fallig-tab-pane" aria-selected="false" style={{ color: '#0048B1', fontSize: '0.875rem' }}>{t('Mahnung fällig')}</button>
                                    </li>
                                </ul>
                            </div>
                            <IndexTable status={status} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;
