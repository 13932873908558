import { useMemo, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import dateFormat, { masks } from "dateformat";
import { useForm } from "react-hook-form";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftSnackbar from "components/SoftSnackbar";

// Soft UI Dashboard PRO React example components
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import { Navigate, NavLink, useNavigate, withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import OrderLineChart from "./layouts/OrderLineChart";
import QuotationLineChart from "./layouts/QuotationLineChart";

import EvaluationLineChart from './layouts/EvaluationLineChart';
import ProjectStatusPieChart from './layouts/ProjectStatusPieChart';
import ProjectStatusCards from './layouts/ProjectStatusCards';
import TermineQouteTable from './layouts/TermineQouteTable';
import ProjectBezahltPieChart from './layouts/ProjectBezahltPieChart';
import ProjectTable from './layouts/ProjectTable';
import NewAufgabe from './layouts/NewAufgabe';
import TechnicianSalesStatusPieChart from './layouts/TechnicianSalesStatusPieChart';
import DashboardCalendar from './Calendar';
import TodaysCardList from "./employees-dashboard/component/TodaysCardList";

import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";
import ivancik from "assets/images/bg-solar.png";
import CalendarNew from './CalendarNew';
import TodoList from "./to-do-list/Index";

import UpdateStatusProject from "./employees-dashboard/component/UpdateStatusProjects";

function Index() {
  const { t } = useTranslation();
  const [user, setUser] = useState(JSON.parse(Cookies.get('user')));
  const [technicianSalesTab, setTechnicianSalesTab] = useState('Today');
  const [menu, setMenu] = useState(null);
  const [technicianSalesFilterMenu, setTechnicianSalesFilterMenu] = useState(null);
  const TechnicianSalesFilterOpenMenu = (event) => setTechnicianSalesFilterMenu(event.currentTarget);
  const TechnicianSalesFiltercloseMenu = () => setTechnicianSalesFilterMenu(null);

  const [filterData, setFilterData] = useState({
    verkaufer: 0,
    created_at: 'Current Week',
  });


  const [newCardForm, setNewCardForm] = useState(false);
  const [formValue, setFormValue] = useState("");
  const openNewCardForm = (event, id) => setNewCardForm(id);
  const closeNewCardForm = () => setNewCardForm(false);
  const handeSetFormValue = ({ currentTarget }) => setFormValue(currentTarget.value);


  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={() => {
        closeMenu();
        var data = filterData;
        data.created_at = 'All';
        setFilterData(data);
      }}>{t('All')}</MenuItem>
      <MenuItem onClick={() => {
        closeMenu();
        var data = filterData;
        data.created_at = 'Today';
        setFilterData(data);
      }}>{t('Today')}</MenuItem>
      <MenuItem onClick={() => {
        closeMenu();
        var data = filterData;
        data.created_at = 'Yeasterday';
        setFilterData(data);
      }}>{t('Yeasterday')}</MenuItem>
      <MenuItem onClick={() => {
        closeMenu();
        var data = filterData;
        data.created_at = 'Current Week';
        setFilterData(data);
      }}>{t('Current Week')}</MenuItem>
      <MenuItem onClick={() => {
        closeMenu();
        var data = filterData;
        data.created_at = 'Last 7 days';
        setFilterData(data);
      }}>{t('Last 7 days')}</MenuItem>
      <MenuItem onClick={() => {
        closeMenu();
        var data = filterData;
        data.created_at = 'Last 30 days';
        setFilterData(data);
      }}>{t('Last 30 days')}</MenuItem>
    </Menu>
  );

  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const renderSuccessSB = (
    <SoftSnackbar
      color="success"
      icon="check"
      title="Success"
      content="Changes saved successfully"
      dateTime="Just Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  return (
    <>
      <Grid container spacing={3} className="mt-3">
        {JSON.parse(Cookies.get('permissions')).indexOf("TodayCL") !== -1 ? (
          <Grid item xs={12} lg={12} xl={12}>
            <UpdateStatusProject />
          </Grid>
        ) : <></>}
      </Grid>

      <Grid container spacing={3}>
        <Grid item container xs={12} sm={5} lg={5} xl={4} style={{ height: '100%' }}>
          <BackgroundBlogCard
            image={ivancik}
            title={user && user.name ? "Wilkommen,  " + user.name + '!' : ''}
            description={
              <>
                Hier finden Sie alle wichtigen Informationen und Statistiken auf einen Blick. Viel Erfolg bei der Nutzung Ihres Dashboards! <br /><br />
              </>
            }
            buttonText="Meine Projekte"
            action={{ type: "internal", route: "/projects", label: "Meine Projekte" }}
          />

          {Cookies.get("permissions").indexOf("SPAnalyAllD") !== -1 || Cookies.get("permissions").indexOf("SPAnaly") !== -1 ? (
            <SoftBox style={{ width: '100%' }} className="mt-4">
              <CalendarNew></CalendarNew>
            </SoftBox>
          ) : <></>}
        </Grid>

        <Grid container item spacing={3} xs={12} sm={7} lg={7} xl={8} style={{ height: '100%' }}>
          <OrderLineChart />

          <QuotationLineChart />

          {(Cookies.get("user") && JSON.parse(Cookies.get("user")).rolename == "Call Center") ||
            JSON.parse(Cookies.get("user")).rolename == "DC Technician" ? (
            <></>
          ) : (
            <>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ height: "190px", cursor: "pointer" }}
                data-bs-toggle="modal"
                data-bs-target="#newTabModal"
              >
                <PlaceholderCard
                  title={{ variant: "h6", text: t("New Tab") }}
                  hasBorder
                  height="165px"
                />
              </Grid>
            </>
          )}

          {Cookies.get("permissions").indexOf("SPAnalyAllD") !== -1 || Cookies.get("permissions").indexOf("SPAnaly") !== -1 ? (
            <Grid item xs={12} sm={12} lg={12}>

              <div className="card">
                <div className="card-header d-flex" style={{ borderBottom: '1px solid #ddd', padding: '15px 15px 0px 15px' }}>
                  <div className="d-flex justify-content-start dashboard-tabs-cs w-70">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" style={{ backgroundColor: '#fff' }}>

                      <li class="nav-item" role="presentation"
                        onClick={() => setTechnicianSalesTab('Today')}
                      >
                        <button class="nav-link active" id="pills-today-tab" data-bs-toggle="pill" data-bs-target="#pills-today" type="button" role="tab" aria-controls="pills-today" aria-selected="true">{t('Today')}</button>
                      </li>
                      <li class="nav-item" role="presentation"
                        onClick={() => setTechnicianSalesTab('Current Week')}
                      >
                        <button class="nav-link" id="pills-week-tab" data-bs-toggle="pill" data-bs-target="#pills-week" type="button" role="tab" aria-controls="pills-week" aria-selected="false">{t('week')}</button>
                      </li>
                      <li class="nav-item" role="presentation"
                        onClick={() => setTechnicianSalesTab('Current Month')}
                      >
                        <button class="nav-link" id="pills-month-tab" data-bs-toggle="pill" data-bs-target="#pills-month" type="button" role="tab" aria-controls="pills-month" aria-selected="false">{t('Month')}</button>
                      </li>
                      <li class="nav-item" role="presentation"
                        onClick={() => setTechnicianSalesTab('All')}
                      >
                        <button class="nav-link" id="pills-flexible-tab" data-bs-toggle="pill" data-bs-target="#pills-flexible" type="button" role="tab" aria-controls="pills-flexible" aria-selected="false">{t('Flexible')}</button>
                      </li>
                    </ul>
                  </div>
                  <div className="w-30">
                    <button className="m-0 btn btn-outline-secondary float-end" onClick={TechnicianSalesFilterOpenMenu}>
                      <img src="/assets/img/Icon.png" style={{ height: '10px', position: 'relative', top: '-2px' }} /> &nbsp; {t('Filter')}
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <TechnicianSalesStatusPieChart
                    title={technicianSalesTab}
                    filterData={{
                      created_at: technicianSalesTab
                    }}
                    technicianSalesFilterMenu={technicianSalesFilterMenu}
                    TechnicianSalesFiltercloseMenu={TechnicianSalesFiltercloseMenu}
                  />
                </div>
              </div>

            </Grid>
          ) : <></>}

          <Grid item xs={12} sm={12}>
            <TodoList></TodoList>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3} className="mt-3">

        {Cookies.get("permissions").indexOf("ProTab") !== -1 && (
          <Grid item xs={12} sm={12} lg={12}>
            <ProjectTable />
          </Grid>
        )}

        {Cookies.get("permissions").indexOf("TerQTa") !== -1 && (
          <Grid item xs={12} sm={12} lg={7}>
            <TermineQouteTable />
          </Grid>
        )}

        <Grid item xs={12} sm={12} lg={5}>
          {Cookies.get("permissions").indexOf("ProBezPC") !== -1 && (
            <SoftBox>
              <ProjectBezahltPieChart filterData={filterData} />
            </SoftBox>
          )}

          {Cookies.get("permissions").indexOf("NeuT") !== -1 && (
            <SoftBox mt={3} height="10rem">
              <NavLink to="/projects-create">
                <PlaceholderCard
                  title={{ variant: "h6", text: "NeuT" }}
                  hasBorder
                />
              </NavLink>
            </SoftBox>
          )}
        </Grid>



      </Grid>

      <Grid item xs={12}>
        <DashboardCalendar
          teamsEvent={false}
          readyToLoad={true}
          projects={true}
          assign_orders={true}
          reclamations={true}
          absence={true}
        />
      </Grid>

      {renderSuccessSB}

      <NewAufgabe />
      {false && JSON.parse(Cookies.get('permissions')).indexOf("TodayCL") !== -1 ? (
        <TodaysCardList />
      ) : <></>}
    </>
  );
}

export default Index;
