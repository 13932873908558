// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import SoftAlert from "components/SoftAlert";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

function AfterConnect(props) {
  let params = useParams();
  const { t } = useTranslation();  
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  const [saving, setSaving] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false); 

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
} = useForm();


    const parseURLParams = (url) => {
        var queryStart = url.indexOf("?") + 1,
            queryEnd   = url.indexOf("#") + 1 || url.length + 1,
            query = url.slice(queryStart, queryEnd - 1),
            pairs = query.replace(/\+/g, " ").split("&"),
            parms = {}, i, n, v, nv;
    
        if (query === url || query === "") return;
    
        for (i = 0; i < pairs.length; i++) {
            nv = pairs[i].split("=", 2);
            n = decodeURIComponent(nv[0]);
            v = decodeURIComponent(nv[1]);
    
            if (!parms.hasOwnProperty(n)) parms[n] = [];
            parms[n].push(nv.length === 2 ? v : null);
        }
        return parms;
    }

  useEffect(() => {
    const urlData = parseURLParams(window.location.href);
    if(!urlData?.code?.[0]) return;

    var fromData = {};
    if(urlData?.scope?.[0] == 'https://www.googleapis.com/auth/calendar'){
        fromData.google_auth_code = urlData?.code?.[0];
    }
    
    setSaving(true);
    callFetch("google-access-token/save", "POST", fromData, setError).then((res) => {
        setSaving(false);
        if (res.message == 'success'){
          setSubmitSuccess(true);
        }else{
          setSubmitSuccess(false);
        }
    });
    
}, [refresh]);

  return (
    <Card id="after-connect-social" className={props?.className}>
      <SoftBox p={3}>
        <SoftTypography variant="h5">{saving ? t('Connecting...') : (submitSuccess ? 'Connected Successfull' : 'Something Wrong! Please Try Again.')}</SoftTypography> 
        {/*submitSuccess ? <SoftAlert color="success" mt={3} dismissible>{t('Password Changed Successfully')}</SoftAlert> : ' '*/} 
      </SoftBox>
      <SoftBox  pb={3} px={3}>

        
      </SoftBox>
      
    </Card>
  );
}

export default AfterConnect;
