import React, { useState, useEffect } from "react";
import callFetch from "../../helpers/callFetch";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
const CalendarNew = () => {
    const { t } = useTranslation();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);
    const [projectsByDate, setProjectsByDate] = useState({});
    const [projects, setProjects] = useState([]);
    const [projectsWeek, setProjectsWeek] = useState([]);
    const [refresh, setRefresh] = useState(0);

    const getWeekNumber = (date) => {
        const startOfYear = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
        const pastDaysOfYear = (date - startOfYear) / 86400000;
        return Math.ceil((pastDaysOfYear + startOfYear.getUTCDay() + 1) / 7);
    };

    const weekNumber = getWeekNumber(currentDate);

    const startOfWeek = (date) => {
        const firstDayOfWeek = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() - date.getUTCDay()));
        return new Date(firstDayOfWeek.setUTCHours(0, 0, 0, 0));
    };

    // Fetch projects from API and group them by date
    useEffect(() => {
        const year = currentDate.getUTCFullYear();
        const month = String(currentDate.getUTCMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getUTCDate()).padStart(2, "0");
        var date = `${year}-${month}-${day}`;

        if (!selectedDate) {
            setSelectedDate(currentDate);
        } else {
            const year = selectedDate.getUTCFullYear();
            const month = String(selectedDate.getUTCMonth() + 1).padStart(2, "0");
            const day = String(selectedDate.getUTCDate()).padStart(2, "0");
            var date = `${year}-${month}-${day}`;
        }

        callFetch("get-projects-by-date" + '/' + date, "GET", []).then((res) => {
            const projectsData = res.data;
            const groupedProjects = projectsData.reduce((acc, project) => {
                const projectDate = project.date;
                if (!acc[projectDate]) {
                    acc[projectDate] = [];
                }
                acc[projectDate].push(project);
                return acc;
            }, {});

            console.log(groupedProjects);

            setProjectsByDate(groupedProjects);
            setProjects(projectsData);
        });

    }, [currentDate, selectedDate]);


    useEffect(() => {
        // if(refresh > 0){
        const firstDayOfWeek = new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate() - currentDate.getUTCDay()));
        const year = firstDayOfWeek.getUTCFullYear();
        const month = String(firstDayOfWeek.getUTCMonth() + 1).padStart(2, "0");
        const day = String(firstDayOfWeek.getUTCDate()).padStart(2, "0");
        var date = `${year}-${month}-${day}`;

        const lastDayOfWeek = new Date(firstDayOfWeek);
        lastDayOfWeek.setUTCDate(firstDayOfWeek.getUTCDate() + 6);

        const yearLast = lastDayOfWeek.getUTCFullYear();
        const monthLast = String(lastDayOfWeek.getUTCMonth() + 1).padStart(2, "0");
        const dayLast = String(lastDayOfWeek.getUTCDate()).padStart(2, "0");
        var dateLast = `${yearLast}-${monthLast}-${dayLast}`;

        callFetch("get-projects-by-date-week" + '/' + date + '/' + dateLast, "GET", []).then((res) => {
            const projectsData = res.data;
            const groupedProjects = projectsData.reduce((acc, project) => {
                const projectDate = project.date;
                if (!acc[projectDate]) {
                    acc[projectDate] = [];
                }
                acc[projectDate].push(project);
                return acc;
            }, {});

            setProjectsByDate(groupedProjects);
            setProjectsWeek(projectsData);
        });
        // }  
    }, [refresh]);



    const nextWeek = () => {
        setCurrentDate(new Date(currentDate.setUTCDate(currentDate.getUTCDate() + 7)));
        setSelectedDate(currentDate);
        setRefresh(refresh + 1);
    };

    const prevWeek = () => {
        setCurrentDate(new Date(currentDate.setUTCDate(currentDate.getUTCDate() - 7)));
        setSelectedDate(currentDate);
        setRefresh(refresh + 1);
    };

    const nextMonth = () => {
        setCurrentDate(new Date(currentDate.setUTCMonth(currentDate.getUTCMonth() + 1)));
        setSelectedDate(currentDate);
        setRefresh(refresh + 1);
    };

    const prevMonth = () => {
        setCurrentDate(new Date(currentDate.setUTCMonth(currentDate.getUTCMonth() - 1)));
        setSelectedDate(currentDate);
        setRefresh(refresh + 1);
    };

    const handleDayClick = (date) => {
        setSelectedDate(date);
    };

    const renderDays = () => {
        const weekStart = startOfWeek(new Date(currentDate));
        const days = [];
        const today = new Date();

        for (let i = 0; i < 7; i++) {
            const day = new Date(weekStart);
            day.setUTCDate(weekStart.getUTCDate() + i);

            const formattedDate = day.toISOString().split('T')[0];
            const dayProjects = projectsWeek.filter(project => project.date === formattedDate);
            const isSelected = selectedDate && formattedDate === selectedDate.toISOString().split('T')[0];

            days.push(
                <div key={i} className="day" onClick={() => handleDayClick(day)}>
                    <span className="day-of-week"> {day.toLocaleString("default", { weekday: "short" }).slice(0, 2)}</span>
                    <span className={isSelected ? 'day-number today' : 'day-number'}>{day.getUTCDate()}</span>
                    {dayProjects.length > 0 && (
                        <div className="event-indicator">
                            {dayProjects.length > 0 ? <span className="dot-p"></span> : ""}
                        </div>
                    )}
                </div>
            );
        }

        return days;
    };

    // Render events for the selected date
    const renderSelectedDateEvents = () => {
        if (!selectedDate) return null;

        const dateString = selectedDate.toDateString();

        return (
            <div className="inner-main cl-height">
                {projects?.length > 0 ? (
                    <div style={{ paddingRight: '8px' }}>
                        {projects.map((project) => (
                            <div class="card-custom">
                                <div class="card-content">
                                    <div>
                                        <p class="card-text one"><span style={{ color: '#2D3748' }}>{project.time}</span> -  <NavLink to={'/projects/' + project.id}><span style={{ color: '#0048B1' }}>{project.id}</span> - <span style={{ color: '#718096' }}> {project?.salse_person?.name} </span></NavLink></p>
                                        <p class="card-text two">VK - {project.vorname + ' ' + project.name}, Tel: {project.telefonnummer}</p>
                                        <p class="card-text three"><a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + project.street + '+' + project.nr + '+' + project.plz + '+' + project.ort}>{project.street + ' ' + project.nr + ', ' + project.plz + ' ' + project.ort}</a></p>
                                    </div>
                                    <div>
                                        <NavLink to={'/projects/' + project.id} className="btn btn-custom d-none d-lg-block">Zum Projekt</NavLink>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : <p className="no-info-text">{t('Keine Projekte  Für heute')}</p>}
            </div>
        );
    };

    return (
        <div className="card">
            <div className="card-body">
                <div className="calendar-container">

                    <div className="header">
                        <span onClick={prevMonth} className="btn-cl left"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M5.70721 0.29357C5.89468 0.481097 6 0.735405 6 1.00057C6 1.26573 5.89468 1.52004 5.70721 1.70757L2.41421 5.00057L5.70721 8.29357C5.88937 8.48217 5.99017 8.73477 5.98789 8.99697C5.98561 9.25917 5.88044 9.50998 5.69503 9.69539C5.50962 9.8808 5.25881 9.98597 4.99661 9.98824C4.73442 9.99052 4.48182 9.88973 4.29321 9.70757L0.293214 5.70757C0.105743 5.52004 0.000427246 5.26573 0.000427246 5.00057C0.000427246 4.7354 0.105743 4.4811 0.293214 4.29357L4.29321 0.29357C4.48074 0.106098 4.73505 0.000782967 5.00021 0.000782967C5.26538 0.000782967 5.51969 0.106098 5.70721 0.29357Z" fill="#667085" /></svg></span>
                        <span className="current-month">
                            {currentDate.toLocaleString("default", { month: "long" })} {currentDate.getFullYear()}
                        </span>
                        <span onClick={nextMonth} className="btn-cl right"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.292787 9.70643C0.105316 9.5189 0 9.2646 0 8.99943C0 8.73427 0.105316 8.47996 0.292787 8.29243L3.58579 4.99943L0.292787 1.70643C0.110629 1.51783 0.00983372 1.26523 0.0121121 1.00303C0.0143906 0.740833 0.11956 0.49002 0.304968 0.304612C0.490376 0.119204 0.741189 0.014035 1.00339 0.0117566C1.26558 0.00947813 1.51818 0.110273 1.70679 0.292431L5.70679 4.29243C5.89426 4.47996 5.99957 4.73427 5.99957 4.99943C5.99957 5.2646 5.89426 5.5189 5.70679 5.70643L1.70679 9.70643C1.51926 9.8939 1.26495 9.99922 0.999786 9.99922C0.734622 9.99922 0.480314 9.8939 0.292787 9.70643Z" fill="#667085" /></svg></span>
                    </div>

                    <div className="top-section">
                        <div className="day-info">
                            <span className="day-name">
                                {selectedDate?.toLocaleString("default", { weekday: "long" })}
                            </span>
                            ,{" "}
                            <span className="month-date">
                                {selectedDate?.toLocaleString("default", { month: "long" })}{" "}
                                {selectedDate?.getDate()}
                            </span>
                        </div>
                        <div className="week-info">
                            Woche {weekNumber} of {currentDate.getFullYear()}
                        </div>
                    </div>

                    <div className="week-navigation">
                        <div className="btn-wrap-week">
                            <button className="left" onClick={prevWeek}><svg width="8" height="14" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M5.70721 0.29357C5.89468 0.481097 6 0.735405 6 1.00057C6 1.26573 5.89468 1.52004 5.70721 1.70757L2.41421 5.00057L5.70721 8.29357C5.88937 8.48217 5.99017 8.73477 5.98789 8.99697C5.98561 9.25917 5.88044 9.50998 5.69503 9.69539C5.50962 9.8808 5.25881 9.98597 4.99661 9.98824C4.73442 9.99052 4.48182 9.88973 4.29321 9.70757L0.293214 5.70757C0.105743 5.52004 0.000427246 5.26573 0.000427246 5.00057C0.000427246 4.7354 0.105743 4.4811 0.293214 4.29357L4.29321 0.29357C4.48074 0.106098 4.73505 0.000782967 5.00021 0.000782967C5.26538 0.000782967 5.51969 0.106098 5.70721 0.29357Z" fill="#667085" /></svg></button>
                            <div className="week-days">{renderDays()}</div>
                            <button className="right" onClick={nextWeek}><svg width="8" height="14" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.292787 9.70643C0.105316 9.5189 0 9.2646 0 8.99943C0 8.73427 0.105316 8.47996 0.292787 8.29243L3.58579 4.99943L0.292787 1.70643C0.110629 1.51783 0.00983372 1.26523 0.0121121 1.00303C0.0143906 0.740833 0.11956 0.49002 0.304968 0.304612C0.490376 0.119204 0.741189 0.014035 1.00339 0.0117566C1.26558 0.00947813 1.51818 0.110273 1.70679 0.292431L5.70679 4.29243C5.89426 4.47996 5.99957 4.73427 5.99957 4.99943C5.99957 5.2646 5.89426 5.5189 5.70679 5.70643L1.70679 9.70643C1.51926 9.8939 1.26495 9.99922 0.999786 9.99922C0.734622 9.99922 0.480314 9.8939 0.292787 9.70643Z" fill="#667085" /></svg></button>
                        </div>
                    </div>

                    <p className="number-of-projects"><span></span> {projects.length} {t('Projekte Heute')}</p>

                    <div className="event-container">{renderSelectedDateEvents()}</div>
                </div>
            </div>
        </div>
    );
};
export default CalendarNew;