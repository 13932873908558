import { useMemo, useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import dateFormat, { masks } from "dateformat";
import { useForm } from "react-hook-form";
// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Navigate, NavLink, useNavigate, withRouter } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";

// Data
import Cookies from 'js-cookie';
import callFetch from "../../helpers/callFetch";
import DashboardCalendar from 'pages/dashboard/Calendar';

function Index(props) {
  const { t } = useTranslation();
  const [teams, setTeams] = useState([]);
  const [teamsColor, setTeamsColor] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [byDefaultSelectedTeams, setByDefaultSelectedTeams] = useState(null);
  const [readyToLoad, setReadyToLoad] = useState(false);
  const [projectDisplay, setProjectDisplay] = useState(true); 
  const [isUpdate, setIsUpdate] = useState(false);
  const [editEvent, setEditEvent] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const [openId, setOpenId] = useState(0);


  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
} = useForm();

  const handleColorPickerToggle = (id) => { 
    console.log(id);
    if(openId == id){
      setOpenId(0);
    }else{
      setOpenId(id);
    } 
  };

  const updateTeamColor = (id, code, bgcode, old) => { 
    
    var formData = new FormData();
    formData.id =  id;
    //code is for checkbox backgruond color
    formData.code = code;
    formData.old_color = old;
    //bg code is for calendar item background color
    formData.bgcode = bgcode;

    if(code == old){
      //to avoid a single color for 2 or more team
      setTeams(teams =>
        teams.map(team =>
          team.color_code === code ? { ...team, color_code: '' } : team
        )
      ); 
    }else{
      //to avoid a single color for 2 or more team
      setTeams(teams =>
        teams.map(team =>
          team.color_code === code ? { ...team, color_code: '' } : team
        )
      );
      
      //update state 
      setTeams(teams =>
        teams.map(team =>
          team.id === id ? { ...team, color_code: code } : team
        )
      ); 
    }

    //update on server/db
    callFetch("update-team-color", "POST", formData, setError).then((res) => {
        console.log(id,code,bgcode,old);
        setOpenId(0); 
    });

  };

  const checkColorCodeExists = (color_code) => {
    return teams.some(team => team.color_code === color_code);
  }

  const checkAll = (teamtype, check) => {
    console.log('teamtype=' + teamtype)
    console.log('check=' + check)



    var selectedAllTeams = selectedTeams;
    var selectedAllTeamMember = selectedTeamMembers;
    var members = selectedAllTeamMember;
    var selectedTeam = selectedAllTeams;

    for (let [key, team] of Object.entries(teams)) {

      if (team?.team_type == teamtype) {
        if (selectedTeams.indexOf(parseInt(team?.id)) == -1 && check) {
          selectedTeam.push(parseInt(team?.id));
        } else if (selectedTeams.indexOf(parseInt(team?.id)) !== -1 && check == false) {
          selectedTeam = selectedTeam.filter(e => e !== parseInt(team?.id))
        }

        Object.keys(team?.teammember).map(function (key) {
          if (selectedTeamMembers.indexOf(parseInt(team.teammember[key]?.employe?.id)) == -1 && check) {
            members.push(parseInt(team.teammember[key]?.employe?.id));
          } else if (selectedTeamMembers.indexOf(parseInt(team.teammember[key]?.employe?.id)) !== -1 && check == false) {
            members = members.filter(e => e !== parseInt(team.teammember[key]?.employe?.id))
          }
        });

        Object.keys(team?.manager).map(function (key) {
          //console.log('check all')
          //console.log(team.manager[key]?.user?.id)

          if (selectedTeamMembers.indexOf(parseInt(team.manager[key]?.user?.id)) == -1 && check) {
            //console.log('check manager')
            members.push(parseInt(team.manager[key]?.user?.id));
          } else if (selectedTeamMembers.indexOf(parseInt(team.manager[key]?.user?.id)) !== -1 && check == false) {
            //console.log('uncheck manager')
            members = members.filter(e => e !== parseInt(team.manager[key]?.user?.id))
          }
        });


      }
    }

    members = members.filter(e => e !== null)
    members = members.filter(e => !isNaN(e))
    
    setSelectedTeams(selectedTeam);
    setSelectedTeamMembers(members);
    
    setRefresh(refresh * (Math.random() * 10) * 10);
    //filteredCalendarData();
  }

  useEffect(() => {

    callFetch("my-teams?team_type=" + (props?.team_type ? props?.team_type : 'sales,ac,dc'), "GET", []).then((res) => {
      setByDefaultSelectedTeams(res.selected_teams)
      // console.log(res.teams);
      setTeamsColor(res.teams);
      if (res.selected_team_members != null) {
        setSelectedTeamMembers(res.selected_team_members)
      }
      // console.log(res.data);
      setTeams(res.data);
    });
  }, [isUpdate]);

  useEffect(() => {
    if (!teams.length) return;

    if (byDefaultSelectedTeams == null) {
      var vals = [];
      for (let [key, team_value] of Object.entries(teams)) {
        vals.push(parseInt(team_value.id));
      }
    } else {
      var vals = JSON.parse(byDefaultSelectedTeams);
    }

    filteredCalendarData(vals);
  }, [teams]);

  const filteredCalendarData = (teamIds = '', mobile_teams = '') => {
    if (teamIds) {
      var vals = teamIds;
    } else {
      if (mobile_teams == '') {
        var input_teams = document.getElementsByName('teams[]');
      } else {
        var input_teams = document.getElementsByName('mobile_teams[]');
      }
      var vals = [];
      for (var i = 0, n = input_teams.length; i < n; i++) {
        if (input_teams[i].checked) {
          vals.push(parseInt(input_teams[i].value));
        }
      }
    }

    setSelectedTeams(vals);
    setProjectDisplay(props?.vkEventHide == true ? false : true);
    setEditEvent(props?.editEvent == true ? true : false);
    setReadyToLoad(true);
  }

  return (
    <>
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <div className="row">
            <div className="col-md-12">
              <Grid container spacing={0}>
                <Grid item xs={12} lg={3} xl={3} className="d-lg-none">
                  <SoftBox mt={3} style={{ backgroundColor: '#EEEEEE', paddingLeft: '15px', paddingTop: '12px', borderRadius: '10px' }}>
                    <h5
                      className="h5cs"
                      style={{ borderBottom: '1px solid #ddd', paddingBottom: '5px', color: '#6A6A6A', fontSize: '1.125rem !important', fontWeight: '700 !important' }}
                    >&nbsp;Teams
                      <SoftBox className="mb-2" display="flex" alignItems="center">
                        <Checkbox
                          defaultChecked={false}
                          onChange={(e) => {
                            checkAll('ac', e.target.checked);
                          }}
                          value={'ac'}
                        />
                        <SoftBox mr={0.7} lineHeight={1}><SoftTypography variant="button" fontWeight="medium">{'AC Check / Uncheck'}</SoftTypography></SoftBox>
                      </SoftBox>

                      <SoftBox className="mb-2" display="flex" alignItems="center">
                        <Checkbox
                          defaultChecked={false}
                          onChange={(e) => {
                            checkAll('dc', e.target.checked);
                          }}
                          value={'dc'}
                        />
                        <SoftBox mr={0.7} lineHeight={1}><SoftTypography variant="button" fontWeight="medium">{'DC Check / Uncheck'}</SoftTypography></SoftBox>
                      </SoftBox>
                    </h5>

                    <div className="App2" style={{ paddingTop: '10px' }}>
                      {teams ? teams.map((team) => (
                        <>
                          <SoftBox className="mb-2" display="flex" alignItems="center">
                            <Checkbox
                              value={team.id}
                              name="mobile_teams[]"
                              defaultChecked={false}
                              checked={selectedTeams.indexOf(team.id) !== -1 ? true : false}
                              onChange={(e) => {



                                if (team?.teammember) {
                                  var selectedAllTeamMember = selectedTeamMembers;
                                  var members = selectedAllTeamMember;

                                  Object.keys(team?.teammember).map(function (key) {
                                    if (selectedTeamMembers.indexOf(parseInt(team.teammember[key]?.employe?.id)) == -1 && e.target.checked) {
                                      members.push(parseInt(team.teammember[key]?.employe?.id));
                                    } else if (selectedTeamMembers.indexOf(parseInt(team.teammember[key]?.employe?.id)) !== -1 && e.target.checked == false) {
                                      members = members.filter(e => e !== parseInt(team.teammember[key]?.employe?.id))
                                    }
                                  });

                                  Object.keys(team?.manager).map(function (key) {
                                    console.log('check all')
                                    console.log(team.manager[key]?.user?.id)

                                    if (selectedTeamMembers.indexOf(parseInt(team.manager[key]?.user?.id)) == -1 && e.target.checked) {
                                      members.push(parseInt(team.manager[key]?.user?.id));
                                    } else if (selectedTeamMembers.indexOf(parseInt(team.manager[key]?.user?.id)) !== -1 && e.target.checked == false) {
                                      members = members.filter(e => e !== parseInt(team.manager[key]?.user?.id))
                                    }
                                  });


                                  setSelectedTeamMembers(members);
                                  setRefresh(refresh * (Math.random() * 10) * 10)
                                }

                                filteredCalendarData('', 'mobile_teams');
                              }}
                            />
                            <SoftBox mr={0.7} lineHeight={1}>
                              <SoftTypography variant="button" fontWeight="medium">{team.team_name}</SoftTypography>
                              <i
                                className="fc-icon fc-icon-plus-square ps-2"
                                onClick={(e) => {
                                  if (e.target.getAttribute("data-members") == 'show') {
                                    e.target.setAttribute("data-members", 'hide');
                                    document.getElementById("teamMembers" + team.id).classList.add("d-none");
                                  } else {
                                    e.target.setAttribute("data-members", 'show');
                                    document.getElementById("teamMembers" + team.id).classList.remove("d-none");
                                  }
                                }}
                                data-members="show"
                                style={{ cursor: 'pointer' }}
                              ></i>
                            </SoftBox>
                          </SoftBox>
                          <div className="team-members ps-4" id={'teamMembers' + team.id}>

                            {team?.manager ? team?.manager?.map((item) => (
                              <>
                                {item?.user?.name ? (
                                  <SoftBox className="mb-2" display="flex" alignItems="center">
                                    <Checkbox
                                      value={item?.user?.id}
                                      name="team_members[]"
                                      checked={(selectedTeamMembers.indexOf(parseInt(item?.user?.id)) !== -1 ? true : false)}
                                      onChange={(e) => {

                                        var selectedAllTeamMember = selectedTeamMembers;
                                        var members = selectedAllTeamMember;

                                        if (selectedTeamMembers.indexOf(parseInt(item?.user?.id)) == -1 && e.target.checked) {
                                          members.push(parseInt(e.target.value));
                                        } else {
                                          members = members.filter(e => e !== parseInt(item?.user?.id))
                                        }
                                        setSelectedTeamMembers(members);
                                        setRefresh(refresh * (Math.random() * 10) * 10)
                                      }}
                                    />
                                    <SoftBox mr={0.7} lineHeight={1}><SoftTypography variant="button" fontWeight="medium">{item?.user?.name}</SoftTypography></SoftBox>
                                  </SoftBox>
                                ) : ''}
                              </>
                            )) : ''}

                            {team?.teammember ? team?.teammember?.map((item) => (
                              <>
                                {item?.employe?.name ? (
                                  <SoftBox className="mb-2" display="flex" alignItems="center">
                                    <Checkbox
                                      value={item?.employe?.id}
                                      name="team_members[]"
                                      checked={(selectedTeamMembers.indexOf(parseInt(item?.employe?.id)) !== -1 ? true : false)}
                                      onChange={(e) => {

                                        var selectedAllTeamMember = selectedTeamMembers;
                                        var members = selectedAllTeamMember;

                                        if (selectedTeamMembers.indexOf(parseInt(item?.employe?.id)) == -1 && e.target.checked) {
                                          members.push(parseInt(e.target.value));
                                        } else {
                                          members = members.filter(e => e !== parseInt(item?.employe?.id))
                                        }
                                        setSelectedTeamMembers(members);
                                        setRefresh(refresh * (Math.random() * 10) * 10)
                                      }}
                                    />
                                    <SoftBox mr={0.7} lineHeight={1}><SoftTypography variant="button" fontWeight="medium">{item?.employe?.name}</SoftTypography></SoftBox>
                                  </SoftBox>
                                ) : ''}
                              </>
                            )) : ''}
                          </div>
                        </>
                      )) : <></>}
                    </div>
                  </SoftBox>
                </Grid>

                <Grid item xs={12} lg={10} xl={10}>
                  <DashboardCalendar
                    class={'calendar-main'}
                    teamsEvent={true}
                    teams={selectedTeams}
                    teamMembers={selectedTeamMembers}
                    readyToLoad={readyToLoad}
                    projects={projectDisplay}
                    assign_orders={true}
                    reclamations={true}
                    absence={false}
                    editEvent={editEvent}
                    refresh={refresh}
                  />
                </Grid>

                <Grid item xs={12} lg={2} xl={2} className="d-none d-lg-block">
                  <SoftBox style={{ backgroundColor: '#EEEEEE', height: '100%', paddingLeft: '15px', paddingTop: '12px', borderRadius: '0px 10px 10px 0px' }}>
                    <h5
                      className="h5cs"
                      style={{ borderBottom: '1px solid #ddd', paddingBottom: '5px', color: '#6A6A6A', fontSize: '1.125rem !important', fontWeight: '700 !important' }}
                    >&nbsp;Teams

                      {/*
                      <select
                        className="form-control form-control-sm"
                        onChange={(e) => {
                          
                        }}
                      >
                        <option className="">---</option>
                        <option className="ac">AC</option>
                        <option className="dc">DC</option>
                      </select>
                      */}
                      <SoftBox className="mb-2" display="flex" alignItems="center">
                        <Checkbox
                          defaultChecked={false}
                          onChange={(e) => {
                            checkAll('ac', e.target.checked);
                          }}
                          value={'ac'}
                        />
                        <SoftBox mr={0.7} lineHeight={1}><SoftTypography variant="button" fontWeight="medium">{'AC Check / Uncheck'}</SoftTypography></SoftBox>
                      </SoftBox>

                      <SoftBox className="mb-2" display="flex" alignItems="center">
                        <Checkbox
                          defaultChecked={false}
                          onChange={(e) => {
                            checkAll('dc', e.target.checked);
                          }}
                          value={'dc'}
                        />
                        <SoftBox mr={0.7} lineHeight={1}><SoftTypography variant="button" fontWeight="medium">{'DC Check / Uncheck'}</SoftTypography></SoftBox>
                      </SoftBox>
                    </h5>

                    <div className="App2 team-checkboxs" style={{ paddingTop: '10px', overflowY: 'auto', maxHeight: '1015px' }}>
                      {teams ? teams.map((team) => (
                        <>
                          <SoftBox className="mb-2" display="flex" alignItems="center">
                            <Checkbox
                              value={team.id}
                              name="teams[]"
                              //defaultChecked={false}
                              className={selectedTeams.indexOf(team.id) !== -1 ? team.color_code : ''}
                              checked={selectedTeams.indexOf(team.id) !== -1 ? true : false}
                              onChange={(e) => {



                                if (team?.teammember) {
                                  var selectedAllTeamMember = selectedTeamMembers;
                                  var members = selectedAllTeamMember;

                                  Object.keys(team?.teammember).map(function (key) {
                                    if (selectedTeamMembers.indexOf(parseInt(team.teammember[key]?.employe?.id)) == -1 && e.target.checked) {
                                      members.push(parseInt(team.teammember[key]?.employe?.id));
                                    } else if (selectedTeamMembers.indexOf(parseInt(team.teammember[key]?.employe?.id)) !== -1 && e.target.checked == false) {
                                      members = members.filter(e => e !== parseInt(team.teammember[key]?.employe?.id))
                                    }
                                  });

                                  Object.keys(team?.manager).map(function (key) {
                                    console.log('check all')
                                    console.log(team.manager[key]?.user?.id)

                                    if (selectedTeamMembers.indexOf(parseInt(team.manager[key]?.user?.id)) == -1 && e.target.checked) {
                                      members.push(parseInt(team.manager[key]?.user?.id));
                                    } else if (selectedTeamMembers.indexOf(parseInt(team.manager[key]?.user?.id)) !== -1 && e.target.checked == false) {
                                      members = members.filter(e => e !== parseInt(team.manager[key]?.user?.id))
                                    }
                                  });


                                  setSelectedTeamMembers(members);
                                  setRefresh(refresh * (Math.random() * 10) * 10)
                                }

                                filteredCalendarData();
                              }}
                            />
                            <SoftBox mr={0.7} lineHeight={1} style={{position: 'relative'}}>
                              <SoftTypography variant="button" fontWeight="medium">{team.team_name}</SoftTypography>
                              <i
                                className="fc-icon fc-icon-plus-square ps-2"
                                onClick={(e) => {
                                  if (e.target.getAttribute("data-members") == 'show') {
                                    e.target.setAttribute("data-members", 'hide');
                                    document.getElementById("teamMembers" + team.id).classList.add("d-none");
                                  } else {
                                    e.target.setAttribute("data-members", 'show');
                                    document.getElementById("teamMembers" + team.id).classList.remove("d-none");
                                  }
                                }}
                                data-members="show"
                                style={{ cursor: 'pointer' }}
                              ></i>

                              <i class="fa-solid fa-ellipsis-vertical team-color-picker-icon" onClick={() => handleColorPickerToggle(team.id)}></i>

                              <div className="team-color-picker" style={{ display: team.id == openId ? 'block' : 'none' }}>
                                  <span onClick={() => updateTeamColor(team.id, 'team-color-1', 'team-bg-color-1', team.color_code)} className="color" style={{position: 'relative', backgroundColor: '#D68B00'}}> {checkColorCodeExists('team-color-1') ? <i  style={{position: 'absolute', right: '6px'}} className="fa-solid fa-check"></i> : ''} </span> 
                                  <span onClick={() => updateTeamColor(team.id, 'team-color-2', 'team-bg-color-2', team.color_code)} className="color" style={{position: 'relative', backgroundColor: '#48BB78'}}> {checkColorCodeExists('team-color-2') ? <i  style={{position: 'absolute', right: '6px'}} className="fa-solid fa-check"></i> : ''} </span>  
                                  <span onClick={() => updateTeamColor(team.id, 'team-color-3', 'team-bg-color-3', team.color_code)} className="color" style={{position: 'relative', backgroundColor: '#CB0C9F'}}> {checkColorCodeExists('team-color-3') ? <i  style={{position: 'absolute', right: '6px'}} className="fa-solid fa-check"></i> : ''}  </span> 
                                  <span onClick={() => updateTeamColor(team.id, 'team-color-4', 'team-bg-color-4', team.color_code)} className="color" style={{position: 'relative', backgroundColor: '#668650', marginRight: '0px'}}> { checkColorCodeExists('team-color-4') ? <i  style={{position: 'absolute', right: '6px'}} className="fa-solid fa-check"></i> : ''}  </span> 
                                  <span onClick={() => updateTeamColor(team.id, 'team-color-5', 'team-bg-color-5', team.color_code)} className="color" style={{position: 'relative', backgroundColor: '#2424B2'}}> {checkColorCodeExists('team-color-5') ? <i  style={{position: 'absolute', right: '6px'}} className="fa-solid fa-check"></i> : ''}  </span> 
                                  <span onClick={() => updateTeamColor(team.id, 'team-color-6', 'team-bg-color-6', team.color_code)} className="color" style={{position: 'relative', backgroundColor: '#7E5A59'}}> { checkColorCodeExists('team-color-6') ? <i  style={{position: 'absolute', right: '6px'}} className="fa-solid fa-check"></i> : ''}  </span> 
                                  <span onClick={() => updateTeamColor(team.id, 'team-color-7', 'team-bg-color-7', team.color_code)} className="color" style={{position: 'relative', backgroundColor: '#ABB521'}}> { checkColorCodeExists('team-color-7') ? <i  style={{position: 'absolute', right: '6px'}} className="fa-solid fa-check"></i> : ''}  </span> 
                                  <span onClick={() => updateTeamColor(team.id, 'team-color-8', 'team-bg-color-8', team.color_code)} className="color" style={{position: 'relative', backgroundColor: '#2D3748', marginRight: '0px'}}> {checkColorCodeExists('team-color-8') ? <i  style={{position: 'absolute', right: '6px'}} className="fa-solid fa-check"></i> : ''}  </span> 
                                  <span onClick={() => updateTeamColor(team.id, 'team-color-9', 'team-bg-color-9', team.color_code)} className="color" style={{position: 'relative', backgroundColor: '#00C6D6', marginBottom: '0px'}}>  {checkColorCodeExists('team-color-9') ? <i  style={{position: 'absolute', right: '6px'}} className="fa-solid fa-check"></i> : ''}  </span> 
                                  <span onClick={() => updateTeamColor(team.id, 'team-color-10', 'team-bg-color-10', team.color_code)} className="color" style={{position: 'relative', backgroundColor: '#D1063D', marginBottom: '0px'}}>  {checkColorCodeExists('team-color-10') ? <i  style={{position: 'absolute', right: '6px'}} className="fa-solid fa-check"></i> : ''}  </span> 
                                  <span onClick={() => updateTeamColor(team.id, 'team-color-11', 'team-bg-color-11', team.color_code)} className="color" style={{position: 'relative', backgroundColor: '#5F00CC', marginBottom: '0px'}}>  { checkColorCodeExists('team-color-11') ? <i  style={{position: 'absolute', right: '6px'}} className="fa-solid fa-check"></i> : ''}  </span> 
                                  <span onClick={() => updateTeamColor(team.id, 'team-color-12', 'team-bg-color-12', team.color_code)} className="color" style={{marginRight: '0px', position: 'relative', backgroundColor: '#DBBA00', marginBottom: '0px'}}>  { checkColorCodeExists('team-color-12') ? <i  style={{position: 'absolute', right: '6px'}} className="fa-solid fa-check"></i> : ''}  </span>  
                              </div>

                            </SoftBox>
                          </SoftBox>
                          <div className="team-members ps-4" id={'teamMembers' + team.id}>

                            {team?.manager ? team?.manager?.map((item) => (
                              <>
                                {item?.user?.name ? (
                                  <SoftBox className="mb-2" display="flex" alignItems="center">
                                    <Checkbox
                                      value={item?.user?.id}
                                      name="team_members[]"
                                      checked={(selectedTeamMembers.indexOf(parseInt(item?.user?.id)) !== -1 ? true : false)}
                                      className={(selectedTeamMembers.indexOf(parseInt(item?.user?.id)) !== -1 ? team.color_code : false)}
                                      onChange={(e) => {

                                        var selectedAllTeamMember = selectedTeamMembers;
                                        var members = selectedAllTeamMember;

                                        if (selectedTeamMembers.indexOf(parseInt(item?.user?.id)) == -1 && e.target.checked) {
                                          members.push(parseInt(e.target.value));
                                        } else {
                                          members = members.filter(e => e !== parseInt(item?.user?.id))
                                        }
                                        setSelectedTeamMembers(members);
                                        setRefresh(refresh * (Math.random() * 10) * 10)
                                      }}
                                    />
                                    <SoftBox mr={0.7} lineHeight={1}><SoftTypography variant="button" fontWeight="medium">{item?.user?.name}</SoftTypography></SoftBox>
                                  </SoftBox>
                                ) : ''}
                              </>
                            )) : ''}

                            {team?.teammember ? team?.teammember?.map((item) => (
                              <>
                                {item?.employe?.name ? (
                                  <SoftBox className="mb-2" display="flex" alignItems="center">
                                    <Checkbox
                                      value={item?.employe?.id}
                                      name="team_members[]"
                                      checked={(selectedTeamMembers.indexOf(parseInt(item?.employe?.id)) !== -1 ? true : false)}
                                      className={(selectedTeamMembers.indexOf(parseInt(item?.employe?.id)) !== -1 ? team.color_code : false)}
                                      onChange={(e) => {

                                        var selectedAllTeamMember = selectedTeamMembers;
                                        var members = selectedAllTeamMember;

                                        if (selectedTeamMembers.indexOf(parseInt(item?.employe?.id)) == -1 && e.target.checked) {
                                          members.push(parseInt(e.target.value));
                                        } else {
                                          members = members.filter(e => e !== parseInt(item?.employe?.id))
                                        }
                                        setSelectedTeamMembers(members);
                                        setRefresh(refresh * (Math.random() * 10) * 10)
                                      }}
                                    />
                                    <SoftBox mr={0.7} lineHeight={1}><SoftTypography variant="button" fontWeight="medium">{item?.employe?.name}</SoftTypography></SoftBox>
                                  </SoftBox>
                                ) : ''}
                              </>
                            )) : ''}
                          </div>
                        </>
                      )) : <></>}
                    </div>
                  </SoftBox>
                </Grid>
              </Grid>
            </div>
          </div>
        </SoftBox>
      </SoftBox>
    </>
  );
}

export default Index;
