import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function LoadingCard(props) {
  const { t } = useTranslation();

  return (
    <>
        <Card
          raised
          sx={props.sx}
        >
          <SoftBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
            height="100%"
            p={3}
          >
            <SoftTypography variant={'p'} color="secondary">
              {t('Loading...')}
            </SoftTypography>
          </SoftBox>
        </Card>        
    </>
  );
}
export default LoadingCard;
