import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import ArticleIndexTable from "./ArticleIndexTable";


const ArticleIndex = () => {
  const { t } = useTranslation();

  return (
      <>
          <div className="d-sm-flex justify-content-between">
              <div>
              {JSON.parse(Cookies.get('permissions')).indexOf("MC") !== -1 ? (
                  <NavLink to="/ware-houses/articles/create" className="btn btn-icon btn-primary">
                      {t('Add Article')}
                  </NavLink>
              ) : <></>}
              </div>
          </div>
          <div className="row">
              <div className="col-12">
                  <div className="card mb-4">
                      <div className="card-header pb-0">
                          <h6>{t('All Article')}</h6>
                      </div>
                      <div className="card-body px-0 pt-0 pb-2">
                          <ArticleIndexTable/>
                      </div>
                  </div>
              </div>
          </div>
      </>
  );
}
export default ArticleIndex