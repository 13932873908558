import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import ArtikelIndecTable from "./ArtikelIndecTable";

const ArtikelIndex = () => {
    const { t } = useTranslation();
  return (
    <>
            <div className="d-sm-flex justify-content-between">
                <div className="relative-h">
                {JSON.parse(Cookies.get('permissions')).indexOf("PayC") !== -1 ? (
                    <NavLink to="/artikels/create" className="btn btn-icon btn-primary">
                        {t('Add Artikel')}
                    </NavLink>
                ) : <></>}
                
                {/* {JSON.parse(Cookies.get('permissions')).indexOf("invoice") !== -1 ? (
                    <a target={"_blank"} rel="noreferrer" href={Cookies.get('backend_url')+'payroll-export'} className="btn btn-outline-dark pull-right">
                        {t('Export CSV')}
                    </a>
                ) : <></>} */}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('All Artikels')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <ArtikelIndecTable />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default ArtikelIndex
