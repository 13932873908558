import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import { NumericFormat } from 'react-number-format';
import SoftAlert from "components/SoftAlert";
import SoftSnackbar from "components/SoftSnackbar";
import { Checkbox } from "@mui/material";
import Select from 'react-select';
import { Divider, Icon, Menu, MenuItem } from '@mui/material';
import SoftButton from 'components/SoftButton';

import LieferantentCreateModel from 'pages/hr/Lieferanten/CreateModel';
import Transaction from "pages/finance/EngangsInvoice/Transactions";

const IncomingInviceCreate = (props) => {
    const { t } = useTranslation();
    const [quationId, setQuationId] = useState(0);
    const [auftragId, setAftragId] = useState(0);
    const [currency, setCurrency] = useState('EUR');
    const [contactPersons, setContactPersons] = useState([]);
    const [data, setData] = useState([]);
    const [emails, setEmails] = useState([{ name: '', email: '', currentEmail: '' }]);
    const [items, setItems] = useState([{ id: 0, title: '', quantity: 1, price: 0, currentPrice: 0, total: 0, artikel: '' }]);
    const [itemSummery, setItemSummery] = useState({ items: [], emails: [], subTotal: 0, amountPerchantage: 30, discountPercentage: 0, discount: 0, taxPercentage: 19, tax: 0, total: 0 });
    const [quotation, setQuotation] = useState(0);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [refresh3, setRefresh3] = useState(0);
    const [receivedError, setReceivedError] = useState(null);
    const [invoice_id, setInvoiceId] = useState(0)
    const [qq, setQqq] = useState([{ quantity: 0 }])
    const [isShow, setIsShow] = useState(true)
    const [einkaufData, setEinkaufData] = useState([])
    const [selectedProjectId, setSelectedProjectId] = useState({ id: '' });
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    const [menu, setMenu] = useState(null);
    const [status, setStatus] = useState('');

    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = (statu = '') => {
        setMenu(null);
        setStatus(statu);
    };

    const renderMenu = (
        <Menu
            anchorEl={menu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(menu)}
            onClose={() => { closeMenu('') }}
            keepMounted
        >
            <MenuItem onClick={() => {
                closeMenu('');
                document.getElementById('createLieferantentModalBtn').click();
            }}>{t('Lieferanten Hinzufugen')}</MenuItem>
        </Menu>
    );

    useEffect(() => {
        var subTotal = 0;
        items.map((item) => {
            subTotal += item.total;
        });
        itemSummery.subTotal = subTotal;
        itemSummery.amount = parseFloat((itemSummery.amountPerchantage / 100) * itemSummery.subTotal);
        // setSubtotal(subTotal);
        setValue('amountPerchantage', itemSummery.amountPerchantage);
        setValue('tax', itemSummery.taxPercentage);
        itemSummery.tax = parseFloat((itemSummery.amount * (itemSummery.taxPercentage / 100)).toFixed(2));
        itemSummery.discount = parseFloat((itemSummery.amount * (itemSummery.discountPercentage / 100)).toFixed(2));
        itemSummery.total = subTotal - itemSummery.discount + itemSummery.tax;
        itemSummery.items = items;
        setRefresh2(refresh2 + 1);
    }, [refresh]);

    useEffect(() => { }, [refresh2]);

    useEffect(() => {
        if (quationId > 0) {
            callFetch("quation/" + quationId + "/edit", "GET", []).then((res) => {
                items[0].id = res.data.speicher;
                items[0].price = Number(res.data.gesami_netto);
                items[0].quantity = 1;
                items[0].currentPrice = Number(res.data.gesami_netto);
                items[0].total = items[0].currentPrice * items[0].quantity;

                setItems(items);
                setRefresh(refresh + 1);
                setRefresh2(refresh2 + 1);
            });
        } else if (auftragId > 0) {
            callFetch("order-add-invoice/" + auftragId, "GET", []).then((res) => {
                if (res.error == '100') {
                    setReceivedError(t('Inovice has created for receivable amount'));
                }
                if (res?.data?.receivable) {
                    items[0].id = 1;
                    items[0].price = Number(res.data.order.netto);
                    items[0].quantity = 1;
                    items[0].currentPrice = Number(res.data.order.netto);
                    items[0].total = items[0].currentPrice * items[0].quantity;

                    setItems(items);
                    setRefresh(refresh + 1);
                    setRefresh2(refresh2 + 1);
                    setValue('amountPerchantage', res?.data?.receivable);
                    itemSummery.amountPerchantage = res?.data?.receivable;
                    setItemSummery(itemSummery);
                    setReceivedError(null);
                }
                // items[0].id = 1;
                // items[0].price = Number(res.data.order.netto);
                // items[0].quantity = 1;
                // items[0].currentPrice = Number(res.data.order.netto);
                // items[0].total = items[0].currentPrice * items[0].quantity;

                // setItems(items);
                // setRefresh(refresh + 1);
                // setRefresh2(refresh2 + 1);
            });
        }
    }, [quationId + auftragId]);

    useEffect(() => {
        flatpickr(".flat-pickr");
        callFetch("incoming-invoice/create", "GET", []).then((res) => {
            setData(res?.data);
            setValue('eingangs_rechungs_nummer', res?.data?.incommingInvouceNumber);
            setRefresh(refresh + 1)
        });
    }, [refresh3]);

    useEffect(() => {

        if(!props?.projectId) return;

        setValue('project_id', props?.projectId);
        setSelectedProjectId({
            id: props?.projectId,
            //vorname: e?.vorname,
            //name: e?.name,
            //telefonnummer: e?.telefonnummer,
        });
    }, [props?.projectId]);

    function deleteProduct() {
        var titems = [];
        items.map((t) => {
            if (!t)
                return;
            titems.push(t);
        });
        setItems(titems);
        setRefresh(refresh + 1);
    }

    function deleteEmail() {
        var temails = [];
        emails.map((e) => {
            if (!e && e !== '')
                return;
            temails.push(e);
        });
        setEmails(temails);
        itemSummery.emails = temails;
        setItemSummery(itemSummery);
        setRefresh2(refresh2 + 1);
    }

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            content="Incomming Invoice successfully created"
            dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
        />
    );

    const onSubmit = (formData) => {
        setSaving(true);
        formData.brutto = itemSummery.total;
        // formData.quation_id = quationId;
        formData.product_details = JSON.stringify(itemSummery);
        callFetch("incoming-invoice", "POST", formData, setError).then((res) => {
            if (receivedError == null && res.message === "success") {
                openSuccessSB();
            }
            setSaving(false);

            if (!res.ok) return;

            if (document.getElementById('ZahlungseingangeListRefresh')) {
                document.getElementById('ZahlungseingangeListRefresh').click();
            }

            if (document.getElementById('InvoiceoverviewRefresh')) {
                document.getElementById('InvoiceoverviewRefresh').click();
            }

            if (props?.redirect == false) {
                if (props?.popupClose == true) {
                    document.getElementById('createIncommingInvoiceClose').click();
                }
                if (props?.IncommingInvoiceRefresh == true) {
                    document.getElementById('IncommingInvoiceRefresh').click();
                }
            } else {
                setSubmitSuccess(true);
            }
        });
    };

    return submitSuccess ? <Navigate to={`/eingangsrechnungen`} /> :
        <div className="row">
            <div className={props?.col ? props?.col : "col-md-9"}>
                <div className="card">
                    <div className="card-header pb-0">
                        <h6>{t('Eingangsrechnung hinzufügen')}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <div className="row g-3">
                                <div className="col-md-3">
                                    <label>{t('EingangsRechnungennummer.')} *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('eg: 700001')}
                                        {...register("eingangs_rechungs_nummer", {
                                            required: true,
                                        })}
                                        readOnly
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.eingangs_rechungs_nummer && errors.eingangs_rechungs_nummer.message}</div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>
                                            {t('Lieferant & Partner')} *
                                        </label>
                                        <br />
                                        <select
                                            className="form-control"
                                            {...register("lieferanten_id", {
                                                required: true,
                                            })}

                                            required
                                        >
                                            <option value="">--</option>
                                            {data?.lieferantan && data?.lieferantan?.map((item) => (
                                                <option key={item.id} value={item.id}>{item.lieferanten_nummer + ' - ' + (item?.firma_name ? item?.firma_name : '')}</option>
                                            ))}

                                        </select>
                                        <div className="invalid-feedback">{errors.lieferanten_id && errors.lieferanten_id.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>
                                            {t('Rechungsnummer')} *
                                        </label>
                                        <br />
                                        <input type="text" className="form-control" placeholder={t('Eingangsrechnungnummer')} {...register('rechungs_nummer')} />
                                        <div className="invalid-feedback">{errors.currency && errors.currency.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <label>
                                        {t('Rechnungsdatum')} *
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control mb-4 flat-pickr"
                                        placeholder={t('eg. 2001-03-20')}
                                        defaultValue={dateFormat(new Date(), "yyyy-mm-dd")}
                                        {...register("rechungs_date", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.rechungs_date && errors.rechungs_date.message}</div>
                                </div>
                            </div>

                            <div className="row">
                                <div className={`${isShow ? 'col-md-3' : 'col-md-3'}`}>
                                    <div className=" d-inline-flex">
                                        <Checkbox className="form-control" style={{ marginTop: '45px' }} id="idforProjekt" onChange={(e) => setIsShow(!isShow)} defaultChecked />
                                        <label htmlFor="idforProjekt" className="cursor-pointer" style={{ marginTop: '45px' }}>{t('Projektbezogene Rechnung buchen')}</label>
                                    </div>
                                </div>
                                {
                                    isShow &&
                                    <div className="col-md-3">
                                        <label>
                                            {t('Projekte')} *
                                        </label>

                                        <Select
                                            placeholder={t('Select')}
                                            options={data?.project ? data?.project : {}}
                                            getOptionLabel={(option) => ((option.id ? option.id : '') + (option.vorname ? ' - ' + option.vorname : '') + ' ' + (option?.name ? option?.name : ''))}
                                            getOptionValue={(option) => option.id}
                                            value={selectedProjectId}
                                            onChange={(e) => {
                                                setValue('project_id', e.id);
                                                setSelectedProjectId({
                                                    id: e?.id,
                                                    vorname: e?.vorname,
                                                    name: e?.name,
                                                    telefonnummer: e?.telefonnummer,
                                                });
                                            }}
                                            required
                                        />
                                        {selectedProjectId?.id ? (
                                            <p className="mt-1" style={{ fontSize: '12px', textDecoration: 'underline', fontWeight: '500' }}>{((selectedProjectId?.id ? selectedProjectId?.id : '') + (selectedProjectId?.vorname ? ' - ' + selectedProjectId?.vorname : '') + ' ' + (selectedProjectId?.name ? selectedProjectId?.name : '') + ', ' + (selectedProjectId?.telefonnummer ? selectedProjectId?.telefonnummer : ''))}</p>
                                        ) : ''}
                                    </div>
                                }
                                <div className={`${isShow ? 'col-md-3' : 'col-md-3'}`}>
                                    <label>
                                        {t('Liefer- Abwicklungsdatum')} *
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control mb-4 flat-pickr"
                                        placeholder={t('eg. 2001-03-20')}
                                        defaultValue={dateFormat(new Date(), "yyyy-mm-dd")}
                                        {...register("processing_date", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.processing_date && errors.processing_date.message}</div>
                                </div>
                                <div className={`${isShow ? 'col-md-3' : 'col-md-3'}`}>
                                    <label>
                                        {t('Zahlung Erfolgt')} *
                                    </label>
                                    <select className="form-control" {...register('payment_made', { required: true })} required>
                                        <option value="Ja">{t('Ja')}</option>
                                        <option value="Nein">{t('Nein')}</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.payment_made && errors.payment_made.message}</div>
                                </div>

                                <div className='col-md-3'>
                                    <label>
                                        {t('Abteilung')} *
                                    </label>
                                    <select className="form-control mb-2" {...register('abteilung', { required: true })} required>
                                        <option value={''}>{t('Select')}</option>
                                        <option value={'Dachmontage'}>Dachmontage</option>
                                        <option value={'Elektromontage'}>Elektromontage</option>
                                        <option value={'Kleinmaterial'}>Kleinmaterial</option>
                                        <option value={'Module'}>Module</option>
                                        <option value={'Speicher'}>Speicher</option>
                                        <option value={'Wechselrichter'}>Wechselrichter</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.abteilung && errors.abteilung.message}</div>
                                </div>
                            </div>


                            <div className="mt-3">
                                {items && items.map((item, i) => (
                                    <div key={i} className="">
                                        {i > 0 && <hr />}
                                        <div className="row mt-2">
                                            <div className="col-md-8">
                                                <div>
                                                    <label>{t('Product')}</label>
                                                    <input type="text" className="form-control" placeholder={t('Select Product')} onChange={(e) => {
                                                        items[i].id = e.target.value;
                                                        items[i].artikel = e.target.value;
                                                        setItems(items);
                                                        setRefresh(refresh + 1);
                                                    }} />
                                                    {/* <option value="">--</option>
                                                      {data?.artikel && data?.artikel?.map((product, i) => (
                                                          <option key={i} value={product.id}>{product.artikel_id}</option>
                                                      ))} */}

                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div>
                                                    <label>{t('Qty/Hrs')}</label>
                                                    <input type="number" className="form-control" placeholder="eg. 0.00" defaultValue={1} onChange={(e) => {
                                                        let value = 0;
                                                        if (e.target.value.length && !isNaN(e.target.value))
                                                            value = e.target.value;
                                                        if (value > 0) {
                                                            setQqq(parseFloat(value));
                                                        }
                                                        items[i].quantity = parseFloat(value);
                                                        items[i].total = items[i].currentPrice * items[i].quantity;
                                                        setItems(items);
                                                        setRefresh(refresh + 1);
                                                    }} />

                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div>
                                                    <label>{t('Betrag in € (Amount)')}</label>
                                                    <input type="number" className="form-control d-inline" style={{ width: '84%' }} placeholder="eg. 0.00" value={item.currentPrice ? item.currentPrice : ''} onChange={(e) => {
                                                        let value = 0;
                                                        if (e.target.value.length && !isNaN(e.target.value))
                                                            value = e.target.value;
                                                        items[i].currentPrice = parseFloat(value);
                                                        items[i].total = items[i].currentPrice * items[i].quantity;
                                                        setItems(items);
                                                        setRefresh(refresh + 1);
                                                    }} />
                                                    &nbsp;
                                                    <i className="fa-solid fa-circle-xmark text-danger" data-key={i} onClick={() => { delete items[i]; deleteProduct(); }}></i>
                                                </div>
                                            </div>


                                            {/* <div className="col-md-2">
                                              <label>{t('Unit Price')}</label>
                                              <input type="number" className="form-control" placeholder="eg. 0.00" value={item.currentPrice ? item.currentPrice : ''} onChange={(e) => {
                                                  let value = 0;
                                                  if (e.target.value.length && !isNaN(e.target.value))
                                                      value = e.target.value;
                                                  items[i].currentPrice = parseFloat(value);
                                                  items[i].total = items[i].currentPrice * items[i].quantity;
                                                  setItems(items);
                                                  setRefresh(refresh + 1);
                                              }} />
                                          </div> */}
                                            {/* <div className="col-md-2">
                                              <label>{t('Amount')}</label>
                                              <input type="number" className="form-control d-inline" style={{ width: '84%' }} placeholder="eg. 0.00" value={item.total} readOnly />
                                              &nbsp;
                                              <i className="fa-solid fa-circle-xmark text-danger" data-key={i} onClick={() => { delete items[i]; deleteProduct(); }}></i>
                                          </div> */}
                                        </div>
                                    </div>
                                ))}

                                <div className="row mt-3 ">
                                    <div className="col-md-12">
                                        <p className="btn btn-dark" style={{ cursor: 'pointer' }} onClick={() => { setItems([...items, { id: 0, title: '', quantity: 0, price: 0, currentPrice: 0, total: 0 }]); setRefresh(refresh + 1); }}><i className="fa-solid fa-circle-plus"></i>&nbsp;{t('Add Item')}</p>
                                    </div>
                                </div>

                                <hr />

                                <div className="row">
                                    <div className="col-md-6 offset-md-6 border">
                                        <div className="row">
                                            <div className="col-md-7 border-end">
                                                <label className="d-block text-end">{t('Sub Total')}</label>
                                                {/* <select {...register('amountPerchantage')} onChange={(e) =>{ itemSummery.amountPerchantage = parseFloat(e.target.value); setItemSummery(itemSummery);
                                                  setRefresh(refresh + 1) }} className="form-control mb-2" style={{width: '70%', position: 'relative', left: '68px'}}>
                                                  <option value="0">--</option>
                                                  <option value="100">100%</option>
                                                  <option value="70">70%</option>
                                                  <option value="30">30%</option>
                                              </select> */}
                                            </div>
                                            <div className="col-md-5">
                                                <p className="text-end">
                                                    <NumericFormat
                                                        value={itemSummery.subTotal}
                                                        displayType="text"
                                                        thousandSeparator={"."}
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row border-top d-none">
                                            <div className="col-md-3 border-end">
                                                <label className="d-block text-end">{t('Discount')}</label>
                                            </div>
                                            <div className="col-md-3 border-end">
                                                <input type="number" className="form-control" onChange={(e) => {
                                                    let value = 0;
                                                    if (e.target.value.length && !isNaN(e.target.value))
                                                        value = e.target.value;
                                                    itemSummery.discountPercentage = parseFloat(value);
                                                    setItemSummery(itemSummery);
                                                    setRefresh(refresh + 1);
                                                }} />
                                            </div>
                                            <div className="col-md-1 border-end">
                                                <p className="text-end">%</p>
                                            </div>
                                            <div className="col-md-5">
                                                <p className="text-end">
                                                    <NumericFormat
                                                        value={itemSummery.subTotal}
                                                        displayType="text"
                                                        thousandSeparator={"."}
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row border-top">
                                            <div className="col-md-3 border-end">
                                                <label className="d-block text-end">{t('Tax')}</label>
                                            </div>
                                            <div className="col-md-4 border-end">
                                                <select {...register('tax')} className="form-control" onChange={(e) => {
                                                    let value = 19;
                                                    if (e.target.value.length && !isNaN(e.target.value))
                                                        value = e.target.value;
                                                    itemSummery.taxPercentage = parseFloat(value);
                                                    setItemSummery(itemSummery);
                                                    setRefresh(refresh + 1);
                                                }}>
                                                    <option value="19">19%</option>
                                                    <option value="0">0%</option>
                                                </select>
                                            </div>
                                            <div className="col-md-5">
                                                <p className="text-end">
                                                    <NumericFormat
                                                        value={itemSummery.tax}
                                                        displayType="text"
                                                        thousandSeparator={"."}
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row bg-gray-200">
                                            <div className="col-md-7">
                                                <label className="d-block text-end">{t('Total')}</label>
                                            </div>
                                            <div className="col-md-5">
                                                <p className="text-end">
                                                    <NumericFormat
                                                        value={itemSummery.total}
                                                        displayType="text"
                                                        thousandSeparator={"."}
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*
                          <hr />
                          {emails && emails.map((email, i) => (
                              <div key={i} className="row mt-3">
                                  <div className="col-md-4">
                                      <label>{t('Name')}</label>
                                      <select className="form-control" value={email.name + '*' + email.email} onChange={(e) => {
                                          var cp = e.target.value.split('*');
                                          emails[i].name = cp[0];
                                          emails[i].email = cp[1];
                                          emails[i].currentEmail = cp[1];
                                          setEmails(emails);
                                          itemSummery.emails = emails;
                                          setItemSummery(itemSummery);
                                          setRefresh2(refresh2 + 1);
                                      }}>
                                          <option value="">--</option>
                                          {contactPersons && contactPersons.map((person, pi) => person.name && (
                                              <option key={pi} value={person.name + '*' + person.emails[0]}>{person.name}</option>
                                          ))}
                                      </select>
                                  </div>
                                  <div className="col-md-4">
                                      <label>{t('Email')}</label>
                                      <input type="email" style={{ width: '93%' }} className="form-control d-inline-block mt-1" value={email.currentEmail} onChange={(e) => {
                                          emails[i].currentEmail = e.target.value;
                                          setEmails(emails);
                                          itemSummery.emails = emails;
                                          setItemSummery(itemSummery);
                                          setRefresh2(refresh2 + 1);
                                      }} placeholder="eg. email@mail.com" />
                                      &nbsp;
                                      <i className="fa-solid fa-circle-xmark text-danger" data-key={i} onClick={() => { delete emails[i]; deleteEmail(); }}></i>

                                      <br />
                                  </div>
                              </div>
                          ))}
                          <p className="btn btn-dark mt-2" style={{ cursor: 'pointer' }} onClick={() => { setEmails([...emails, { name: '', email: '', currentEmail: '' }]); setRefresh2(refresh2 + 1); }}><i className="fa-solid fa-circle-plus"></i>&nbsp;{t('Add Contact Person')}</p>
                          */}

                            <div className="row mt-3">
                                <div className="col-md-5">{receivedError && <SoftAlert color="error" dismissible> {receivedError} </SoftAlert>}</div>
                                <div className="col-md-12 mt-3">
                                    {!saving && (
                                        <button type="submit" className="btn btn-primary">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="button" className="btn btn-disabled" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}


                                    <SoftButton className={'mb-3 mx-3'} variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
                                        {status ? status : t('Action')}&nbsp;
                                        <Icon>keyboard_arrow_down</Icon>
                                    </SoftButton>
                                    {renderMenu}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {props?.transections != false ? (
                <div className="col-md-3">
                    {selectedProjectId?.id && isShow ? (
                        <Transaction projectId={selectedProjectId?.id} />
                    ) : ''}
                </div>
            ) : ''}
            {renderSuccessSB}

            <LieferantentCreateModel setRefresh={setRefresh3} />
        </div>;
}

export default IncomingInviceCreate
