import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { useCallFetchv2 } from 'helpers/callFetchv2';
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import { NumericFormat } from 'react-number-format';
import SoftAlert from "components/SoftAlert";
import SoftSnackbar from "components/SoftSnackbar";
import { Switch } from '@mui/material'

const Create = () => {
    const { t } = useTranslation();
    const callFetch = useCallFetchv2();
    const [currency, setCurrency] = useState('EUR');
    const [data, setData] = useState([]);
    const [bundles, setBundles] = useState([{ bundle_name: '', price: 0.00, items: [{ product: '', type: '', number: '' }] }]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [receivedError, setReceivedError] = useState(null);
    const [status, setStatus] = useState(true);
    const handleSetStatus = () => setStatus(!status);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);

    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            //content="Invoice successfully created"
            //dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgSuccess
        />
    );


    useEffect(() => {
        flatpickr(".flat-pickr");
        callFetch("bundle/create", "GET", []).then((res) => {
            setData(res?.data);
            setValue('bundle_nummer', res?.data?.bundlenummer);
            setRefresh(refresh + 1)
        });
    }, []);

    const onSubmit = (formData) => {
        setSaving(true);
        formData.bundles = JSON.stringify(bundles);
        formData.status = status ? 1 : 0;

        callFetch("bundle", "POST", formData, setError).then((res) => {
            setTimeout(() => {
                setSaving(false);
                if (!res.ok) return;
                setSubmitSuccess(true);
            }, 2000)
        });
    };

    return submitSuccess ? <Navigate to={`/bundle-preis`} /> :
        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <div className="row">
                <div className="col-9">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('Bundle Price')}</h6>
                        </div>
                        <div className="card-body">
                            <div className="row g-3">
                                <div className="col-md-12">
                                    <label className="me-3">{t('Status')}</label>
                                    <Switch checked={status} onChange={handleSetStatus} />
                                </div>

                                <div className="col-md-4">
                                    <label>{t('Bundle-Preis Nr.')} *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('eg: buldle-001')}
                                        {...register("bundle_nummer", {
                                            required: true,
                                        })}
                                        readOnly
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.bundle_nummer && errors.bundle_nummer.message}</div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('Bundle Group Name')} *
                                        </label>
                                        <br />
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("bundle_group_name", {
                                                required: true,
                                            })}
                                            onChange={(e) => {
                                                setCurrency(e.target.value)
                                            }}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.currency && errors.currency.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>
                                            {t('Currency')} *
                                        </label>
                                        <br />
                                        <select
                                            className="form-control"
                                            {...register("currency", {
                                                required: true,
                                            })}
                                            onChange={(e) => {
                                                setCurrency(e.target.value)
                                            }}
                                            required
                                        >
                                            <option value="EUR">EUR (€)</option>
                                            <option value="USD">USD ($)</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.currency && errors.currency.message}</div>
                                    </div>
                                </div>
                            </div>

                            {bundles.map((bundle, j) => (
                                <div className="row mt-3">
                                    <div className="col-3">
                                        <div className="form-group">
                                            <label className="mb-0 ps-0 pb-0">{t('Bundle Name')}</label>
                                            <input
                                                type="text"
                                                placeholder={t("Enter Bundle Name")}
                                                className="form-control"
                                                value={bundles[j].bundle_name}
                                                onChange={(e) => {
                                                    var updateBundle = bundles;
                                                    updateBundle[j].bundle_name = e.target.value;
                                                    setBundles(updateBundle);
                                                    setRefresh(refresh + 1);
                                                }}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="form-group">
                                            <label className="mb-0 ps-0 pb-0">{t('Price')}</label>
                                            <input
                                                type="number"
                                                step={0.01}
                                                placeholder="Enter Bundle Price"
                                                className="form-control"
                                                value={bundles[j].price}
                                                onChange={(e) => {
                                                    var updateBundle = bundles;
                                                    updateBundle[j].price = e.target.value;
                                                    setBundles(updateBundle);
                                                    setRefresh(refresh + 1);
                                                }}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-7">
                                        {bundles[j].items.map((bundle_item, i) => (
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        {i == 0 ? <label className="mb-0 pb-0">{t('Product')}</label> : ''}
                                                        <select
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={t('Select Product')}
                                                            value={bundles[j].items[i].type + '-' + bundles[j].items[i].product}
                                                            onChange={(e) => {

                                                                if (e.target.value) {
                                                                    var value = e.target.value;
                                                                    var sepValue = value.split('-');
                                                                } else {
                                                                    var sepValue = ['', ''];
                                                                }

                                                                var updateBundle = bundles;
                                                                updateBundle[j].items[i].product = sepValue[1];
                                                                updateBundle[j].items[i].type = sepValue[0];
                                                                setBundles(updateBundle);
                                                                setRefresh(refresh + 1);
                                                            }}
                                                            required
                                                        >
                                                            <option value="">--</option>
                                                            {data?.module && data?.module?.map((modul, i) => (
                                                                <option key={i} value={'module-' + modul.id}>{modul.name}</option>
                                                            ))}
                                                            {data?.speicher && data?.speicher?.map((speiche, i) => (
                                                                <option key={i} value={'speicher-' + speiche.id}>{speiche.name}</option>
                                                            ))}
                                                            {data?.wechselrichter && data?.wechselrichter?.map((wechselrichte, i) => (
                                                                <option key={i} value={'wechselrichter-' + wechselrichte.id}>{wechselrichte.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-5">
                                                    <div className="form-group">
                                                        {i == 0 ? <label className="mb-0 pb-0">{t('Anzahl')}</label> : ''}
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={bundles[j].items[i].number}
                                                            onChange={(e) => {

                                                                var updateBundle = bundles;
                                                                updateBundle[j].items[i].number = e.target.value;
                                                                setBundles(updateBundle);
                                                                setRefresh(refresh + 1);
                                                            }}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-1 text-center">
                                                    <div className="form-group">
                                                        {i == 0 ? <><label className="mb-0 pb-0">{t('Action')}</label><br /></> : ''}
                                                        <i
                                                            className="fa fa-close mt-2"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                var updateBundles = bundles;
                                                                var updateItems = updateBundles[j].items;

                                                                if (updateItems.length === 1) {
                                                                    console.log('remove bundle= ' + j);
                                                                    updateBundles.splice(j, 1);
                                                                } else {
                                                                    console.log('remove bundle item= ' + i);
                                                                    updateItems.splice(i, 1);
                                                                    updateBundles[j].items = updateItems;
                                                                }

                                                                setBundles(updateBundles);
                                                                setRefresh(refresh + 1);
                                                            }}
                                                        ></i>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <button
                                            type="button"
                                            className="btn btn-sm btn-dark"
                                            onClick={(e) => {
                                                var addBundleItem = bundles;
                                                addBundleItem[j].items.push({ product: '', type: '', number: '' });
                                                console.log('addBundleItem')
                                                console.log(addBundleItem)
                                                setBundles(addBundleItem);
                                                setRefresh(refresh + 1);
                                            }}
                                        >{t('Add Product')}</button>
                                    </div>
                                </div>
                            ))}

                            <button
                                type="button"
                                className="btn btn-sm btn-dark mt-3"
                                onClick={(e) => {
                                    var addBundle = bundles;
                                    addBundle.push({ bundle_name: '', price: 0.00, items: [{ product: '', type: '', number: '' }] });
                                    setBundles(addBundle);
                                    setRefresh(refresh + 1);
                                }}
                            >{t('Add Bundle')}</button>

                            <div className="row mt-3">
                                <div className="col-md-5">{receivedError && <SoftAlert color="error" dismissible> {receivedError} </SoftAlert>}</div>
                                <div className="col-md-12 mt-3">
                                    {!saving && (
                                        <button type="submit" className="btn btn-primary">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {renderSuccessSB}
            </div>
        </form>;
}

export default Create